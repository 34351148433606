import { useMutation } from "react-query";
import { apiClient } from "../utils/api-client";
import storage from "../utils/storage";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { Notify } from "../components/notification";

export const useChangeBankAccount = (payload) => {
  const history = useHistory();
  const tokenExpired = () => {
    storage.clear();
    history.push("/login");
  };
  const token = storage.fetch("token");
  apiClient.defaults.headers.common["auth_token"] = token;
  return useMutation(async () => {
    const { data } = await apiClient
      .post(`/merchant/user/changeBankAccount`, payload)
      .catch(function (error) {
        // handle error

        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
      });
    if (data?.code === "900009") {
      Modal.error({
        title: "Session Timeout!",
        content: "Sorry, please login again to continue",
        onOk: () => {
          tokenExpired();
          Modal.destroyAll();
        },
      });
    } else {
      return data;
    }
  });
};

export const useValidateBankAccount = (payload) => {
  const history = useHistory();
  const tokenExpired = () => {
    storage.clear();
    history.push("/login");
  };
  const token = storage.fetch("token");
  apiClient.defaults.headers.common["auth_token"] = token;
  return useMutation(async () => {
    const { data } = await apiClient
      .post(`/merchant/user/validateBankAccount`, payload)
      .catch(function (error) {
        // handle error
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
      });
    if (data?.code === "900009") {
      Modal.error({
        title: "Session Timeout!",
        content: "Sorry, please login again to continue",
        onOk: () => {
          tokenExpired();
          Modal.destroyAll();
        },
      });
    } else {
      return data;
    }
  });
};
