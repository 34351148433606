import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "../../../../components/spinner";
import { FiPlus } from "react-icons/fi";
import { Notify } from "../../../../components/notification";
import storage from "../../../../utils/storage";
import { useCreateClass, useQueryClass } from "../../../../hooks/useClass";
import { useCreateFee, useQueryFee } from "../../../../hooks/useFee";
import { useQueryPayLink, useCreatePayLink } from "../../../../hooks/usePaymentLink";
import { useImageUploadPayLink } from "../../../../hooks/useFileUpload";
import { reverseLinkTypes } from "../../../../utils/constants/linkType";
import { reverseLinkStatus } from "../../../../utils/constants/linkStatus";
import "react-toastify/dist/ReactToastify.css";
import file from "../../../../helpers/file";
import {
  AddFeeIcon,
  BigCloseIcon,
  FeeArrowUpIcon,
  MutipleFeeIcon,
  NoPaymentLinkIcon,
  SingleFeeIcon,
} from "../../../../assests/icons/Icons";
import {
  formatDateTime,
} from "../../../../utils/functions";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FeeTable } from "../../../../components/table/FeeTable";
import {
  SchoolIcon,
  CloseModalIcon,
  GovtIcon,
  DonationIcon,
  FeeArrowDownIcon,
} from "../../../../assests/icons/Icons";
import { SpinnerWhite } from "../../../../components/spinner/Spinner";
import Select from "react-select";

import "./styles.scss";
import "./feesmodal.scss";
import "./showmodal.scss";
import SupervisorLayout from "../../../../components/layout/SupervisorLayout";

const column = ["Page Title", "Status", "Type", "Date Created", "Link"];

export const SupervisorFeePaymentPage = () => {
  const history = useHistory();
  const selectRef = useRef(null);
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [showModal, setShowModal] = useState(false);
  const [showSchoolFees, setShowSchoolFees] = useState(false);
  const [showSchoolFees2, setShowSchoolFees2] = useState(false);
  const [showSchoolFees3, setShowSchoolFees3] = useState(false);
  const [showSchoolFees4, setShowSchoolFees4] = useState(false);
  const [showSchoolFees5, setShowSchoolFees5] = useState(false);
  const [showSchoolFees6, setShowSchoolFees6] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showAddClass, setShowAddClass] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSinglePaymentModal, setShowSinglePaymentModal] = useState(false);
  const [showMutiplePaymentModal, setShowMultiplePaymentModal] =
    useState(false);
  const [showMutiplePaymentModal2, setShowMultiplePaymentModal2] =
    useState(false);
  const [showMutiplePaymentModal3, setShowMultiplePaymentModal3] =
    useState(false);
  const [showMutiplePaymentModal4, setShowMultiplePaymentModal4] =
    useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [showInputAmount, setShowInputAmount] = useState(true);
  const [showInputAmountDonate, setShowInputAmountDonate] = useState(true);
  const [amount, setAmount] = useState(0);
  const [className, setClassName] = useState("");
  const [feeName, setFeeName] = useState("");
  const [remark, setRemark] = useState("");
  const [classCategory, setClassCategory] = useState("");
  const [fileSuffix, setFileSuffix] = useState("");
  const [base64Certificate, setBase64Certificate] = useState("");
  const [imageName, setImageName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [payLinks, setPayLinks] = useState(null);
  const [selectedFeeOption, setSelectedFeeOption] = useState([]);
  const [feeOptions, setFeeOptions] = useState([]);
  const [addInfoCount, setAddInfoCount] = useState(0);
  const [payLinkDetails, setPayLinkDetails] = useState(null);
  const [linkType, setLinkType] = useState(1);
  const [imageNameTemp, setImageNameTemp] = useState("");
  const [selectedClassOption, setSelectedClassOption] = useState([]);
  const [classOptions, setClassOptions] = useState(null);
  const [fetchingClass, setFetchingClass] = useState(false);
  const [classPresent, setClassPresent] = useState(false);
  const [callFeeUpdate, setCallFeeUpdate] = useState(false);
  const [feeDetails, setFeeDetails] = useState([]);
  const [imageId, setImageId] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const uploadRef = useRef();

  const {
    isLoading: isFetchingClass,
    data: classData,
    mutate: fetchClass,
  } = useCreateClass({
    cusId,
    className,
    classCategory,
  });

  const {
    isLoading: isCreatingFee,
    data: feeData,
    mutate: fetchCreateFee,
  } = useCreateFee({
    cusId,
    feeName,
    amount,
    remark,
    ...feeDetails,
  });

  const {
    isLoading: isQueryingFee,
    data: feeQueryData,
    mutate: fetchQueryFee,
  } = useQueryFee({
    cusId,
  });

  const {
    isLoading: isCreatingPayLink,
    data: payLinkData,
    mutate: fetchCreatePaylink,
  } = useCreatePayLink({
    cusId,
    linkType: linkType,
    status: 1,
    ...payLinkDetails,

  });

  const {
    isLoading: isFetchingClassDetails,
    data: queryClassData,
    mutate: fetchQueryClass,
  } = useQueryClass({
    cusId,
  });

  const {
    isLoading: isFetchingPayLink,
    data: queryPayLinkData,
    mutate: fetchQueryPayLink,
  } = useQueryPayLink({
    cusId,
  });

  const {
    isLoading: isImageUploading,
    data: imageUploadData,
    mutate: fetchImageUpload,
  } = useImageUploadPayLink({
    cusId,
    fileSuffix: fileSuffix,
    base64: base64Certificate,
  });


  // ADD/REMOVE CHECKED ITEM FROM LIST
  const handleChecked = (event, object) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, object]);
    } else {
      setSelectedItems(
        selectedItems.filter((item) => item.classList !== object.classList)
      );
    }
  };

  //REMOVE CHECKBOX DROP DOWN
  const handleRemoveItem = (item) => {
    setSelectedItems(
      selectedItems.filter((obj) => obj.classList !== item.classList)
    );
  };
  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setShowCheckBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      field: [{ className: "", classCategory: "" }],
      fieldFee: [{ feeName: "", feeAmount: "", feeDescription: "" }],
      fieldClass: [{ className: "", classCategory: "" }],
    },
  });

  const checkAmount = watch(`fieldSingleLink.custInputAmount`);
  const checkAmountDonate = watch(`fieldDonateLink.custInputAmount`);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "field",
  });

  //CLOSE MODALS
  const closeModal = () => {
    setShowModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowPaymentModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal4(false);
    setShowDonation(false);
    resetField("fieldFee");
    resetField("field");
    resetField("fieldCreateFee");
    resetField(`fieldCreateFee.addInfo`);
    resetField("fieldSingleLink");
    resetField(`fieldSingleLink.addInfo`);
    resetField("fieldClass");
    resetField("classFeeApplied");
    setSelectedClassOption([]);
    setClassOptions([]);
    setAddInfoCount(0);
    setImageName("");
    setImageId("");
    setSelectedFeeOption([]);
    setAddInfoCount(0);
    remove();
    append({ labelName: "", checkOptional: "" });
    setPayLinkDetails(null);
  };

  const startSchoolModal = () => {
    setShowModal(true);
    setShowPaymentModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowDonation(false);
    fetchQueryClass();
    setFetchingClass(true);
    setClassPresent(false);
    setLinkType(2);
  };

  const openPaymentModal = () => {
    setShowModal(false);
    setShowPaymentModal(true);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal4(false);
    setSelectedFeeOption([]);
    setLinkType(1);
  };

  const openSinglePaymentModal = () => {
    setShowModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(true);
    setShowMultiplePaymentModal(false);
  };

  const openMutiplePaymentModal = () => {
    setShowModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal4(false);
    setShowMultiplePaymentModal(true);
    fetchQueryFee();
  };

  const openMutiplePaymentModal2 = () => {
    setShowModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(true);
    setShowMultiplePaymentModal3(false);
  };

  const openMutiplePaymentModal3 = () => {
    setShowModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal4(false);
    setShowMultiplePaymentModal3(true);
  };

  const openMutiplePaymentModal4 = () => {
    setShowModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal4(true);
  };

  const openSchoolModal = () => {
    setShowModal(false);
    setShowSchoolFees(true);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    fetchQueryClass();
    setFetchingClass(true);
    setLinkType(2);
  };

  const openAddClassModal = () => {
    setShowModal(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowSchoolFees(false);
    setShowAddClass(true);
  };

  const openFeesModal = () => {
    setShowModal(false);
    setShowSchoolFees2(false);
    setShowSchoolFees(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowAddClass(false);
    setShowSchoolFees3(true);
    fetchQueryFee();
  };

  const openClassFeeModal = () => {
    setShowModal(false);
    setShowSchoolFees(false);
    setShowSchoolFees3(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowSchoolFees4(true);
  };

  const openSelectSchoolFeeModal = () => {
    setShowModal(false);
    setShowSchoolFees(false);
    setShowSchoolFees3(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees4(false);
    setShowSchoolFees6(false);
    setShowSchoolFees5(true);
  };

  const openSchoolEndModal = () => {
    setShowModal(false);
    setShowSchoolFees(false);
    setShowSchoolFees3(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees5(false);
    setShowSchoolFees4(false);
    setShowSchoolFees6(true);
  };

  const openDonationModal = () => {
    setShowModal(false);
    setShowDonation(true);
    setLinkType(3);
  };

  const openMoreDetails = () => {
    setShowMoreDetails(true);
  };

  const closeMoreDetails = () => {
    setShowMoreDetails(false);
  };

  const showCheckBoxes = () => {
    if (!expanded) {
      setShowCheckBox(true);
      setExpanded(true);
    } else {
      setShowCheckBox(false);
      setExpanded(false);
    }
  };

  useEffect(() => {
    fetchQueryPayLink();
  }, []);

  useEffect(() => {
    if (classData?.code === "000000") {
      Notify(
        "success",
        "Class added successful!",
        "A new class was created successfully"
      );
      openSchoolModal();
      reset();
    } else if (classData?.code === "US0066") {
      Notify(
        "error",
        "Class error",
        "Sorry! this class already exists, please add a new class.",
        10
      );
      openSchoolModal();
      reset();
    }
  }, [classData]);

  useEffect(() => {
    if (queryClassData?.code === "000000") {
      var classOption = [];
      if (queryClassData.data.length > 0) {
        queryClassData.data.forEach(function (entry) {
          var signClass = {};
          signClass["value"] = entry.classId;
          signClass["label"] = entry.className;
          signClass["category"] = entry.classCategory;
          classOption.push(signClass);
        });
      }
      setClassOptions(classOption);
      classOption.length > 0 ? setClassPresent(true) : setClassPresent(false);
      setFetchingClass(false);
    }
  }, [queryClassData]);

  useEffect(() => {
    if (feeData?.code === "000000") {
      Notify("success", "Fee successful!", "Fee created successfully");
      resetField("fieldFee");
      resetField("classFeeApplied");
      if (linkType === 1) {
        openMutiplePaymentModal3();
      } else {
        openSelectSchoolFeeModal();
      }
      if (callFeeUpdate) {
        fetchQueryFee();
        setCallFeeUpdate(false);
      }
    } else if (feeData?.code === "US0066") {
      setAmount(0);
      setRemark("");
      setFeeName("");

      // Open the new modal

      if (linkType === 1) {
        openMutiplePaymentModal3();
      } else {
        openSelectSchoolFeeModal();
      }

      if (callFeeUpdate) {
        fetchQueryFee();
        setCallFeeUpdate(false);
      }

      Notify(
        "error",
        "Fee error",
        "Sorry! this fee name already exists, input a new fee name.",
        10
      );
    }
  }, [feeData]);

  useEffect(() => {
    if (feeQueryData?.code === "000000") {
      var feeOptions = [];
      if (feeQueryData.data.length > 0) {
        if (linkType === 1) {
          openMutiplePaymentModal3();
        } else {
          openSelectSchoolFeeModal();
        }
        feeQueryData.data.forEach(function (entry) {
          var signFee = {};
          signFee["value"] = entry.feeId;
          signFee["label"] = entry.feeName;
          signFee["amount"] = entry.amount;
          feeOptions.push(signFee);
        });
      }
      setFeeOptions(feeOptions);
    }
  }, [feeQueryData]);

  useEffect(() => {
  }, [selectedFeeOption]);

  useEffect(() => {
    if (payLinkDetails) {
      fetchCreatePaylink();
    }
  }, [payLinkDetails]);

  useEffect(() => {
    if (payLinkData?.code === "000000") {
      Notify(
        "success",
        "Link created successful!",
        "Your payment link is created successfully"
      );

      closeModal();
      fetchQueryPayLink();
    } else if (payLinkData?.code === "US0066") {
      Notify(
        "error",
        "Custom link error",
        "Sorry! this custom link already exists, please input a new custom link or leave field empty.",
        10
      );
    }
  }, [payLinkData]);

  useEffect(() => {
    if (queryPayLinkData?.code === "000000") {
      const formattedPayLink = queryPayLinkData.data.map((f) => {
        return {
          pageTitle: f.pageTitle,
          linkCode: f.linkCode,
          createTime: formatDateTime(f.createTime),
          linkType: reverseLinkTypes.get(f.linkType),
          status: reverseLinkStatus.get(f.status),
          linkId: f.linkId,
        };
      });
      setPayLinks(formattedPayLink);
    }
  }, [queryPayLinkData]);

  useEffect(() => {
    if (imageUploadData?.code === "000000") {
      setImageId(imageUploadData?.data.imageId);
      setImageUrl(imageUploadData?.data.imageUrl);
      setImageName(imageNameTemp);
      Notify(
        "success",
        "Image upload successful!",
        "Your image was uploaded successfully"
      );

    }else if (imageUploadData?.code === "US0068"){
      Notify(
        "error",
        "Image upload failed",
        "Sorry! your image upload failed. Format not supported",
        10
      );
    }
  }, [imageUploadData]);

  useEffect(() => {
    if (checkAmount === true) {
      setShowInputAmount(false);
    } else if (checkAmount === false) {
      setShowInputAmount(true);
    }
  }, [checkAmount]);

  useEffect(() => {
    if (checkAmountDonate === true) {
      setShowInputAmountDonate(false);
    } else if (checkAmountDonate === false) {
      setShowInputAmountDonate(true);
    }
  }, [checkAmountDonate]);

  //SUBMIT CLASS DATA
  const onSubmitClass = (data) => {
    for (let i in data.fieldClass) {
      var classInfo = data.fieldClass[i];
      const { className, classCategory } = classInfo;
      if (className) {
        setClassCategory(classCategory);
        setClassName(className);
        fetchClass();
      }
    }
  };

  //SUBMIT SINGLE PAYMENT DATA
  const onSubmitSingleHandler = (data) => {
    var createForm = {};
    createForm["pageTitle"] = data.fieldSingleLink.pageTitle;
    createForm["pageDescription"] = data.fieldSingleLink.description;
    createForm["redirectUrl"] = data.fieldSingleLink.redirect;
    createForm["message"] = data.fieldSingleLink.afterPaymentMsg;
    if (data.fieldSingleLink.customLinkCode) {
      if (data.fieldSingleLink.customLinkCode !== "") {
        createForm["linkCode"] = data.fieldSingleLink.customLinkCode;
      }
    }

    createForm["imageId"] = imageId;
    createForm["amount"] =
      data.fieldSingleLink.custInputAmount === true
        ? null
        : data.fieldSingleLink.amount;
    createForm["customerInputAmount"] =
      data.fieldSingleLink.custInputAmount === true ? 1 : 0;

    if (data.fieldSingleLink.collectPhoneNum) {
      createForm["collectPhone"] = 1;
    } else {
      createForm["collectPhone"] = 0;
    }

    var labels = [];
    for (let i in data.fieldSingleLink.addInfo) {
      var singleInfo = {};
      var adInfo = data.fieldSingleLink.addInfo[i];
      if (adInfo?.labelName) {
        singleInfo["labelName"] = adInfo.labelName;
        singleInfo["optional"] = adInfo.checkOptional === true ? 1 : 0;
        labels.push(singleInfo);
      }
    }
    createForm["labels"] = labels;
    setPayLinkDetails(createForm);
  };

  //SUBMIT MULTIPLE PAYMENT DATA
  const onSubmitMultipleHandler = (data) => {
    var createForm = {};

    for (let i in data.fieldFee) {
      const { feeName, feeAmount, feeDescription } = data.fieldFee[i];
      setAmount(feeAmount);
      setRemark(feeDescription);
      setFeeName(feeName);

      if (parseInt(i) === data.fieldFee.length - 1) {
        setCallFeeUpdate(true);
      }
      // call create fee api
      if (feeName) {
        fetchCreateFee();
      }
    }

    createForm["pageTitle"] = data?.fieldCreateFee?.pageTitle;
    createForm["pageDescription"] = data?.fieldCreateFee?.description;
    createForm["redirectUrl"] = data?.fieldCreateFee?.redirect;
    createForm["message"] = data?.fieldCreateFee?.afterPaymentMsg;
    createForm["linkCode"] = data?.fieldCreateFee?.customLinkCode;

    createForm["imageId"] = imageId;

    if (data?.fieldCreateFee?.collectPhoneNum) {
      createForm["collectPhone"] = 1;
    } else {
      createForm["collectPhone"] = 0;
    }

    var labels = [];
    for (let i in data?.fieldCreateFee?.addInfo) {
      var singleInfo = {};
      var adInfo = data?.fieldCreateFee?.addInfo[i];
      if (adInfo?.labelName) {
        singleInfo["labelName"] = adInfo.labelName;
        singleInfo["optional"] = adInfo.checkOptional === true ? 1 : 0;
        labels.push(singleInfo);
      }
    }
    createForm["labels"] = labels;

    var fees = [];
    for (let i in selectedFeeOption) {
      var singleFee = {};
      var fee = selectedFeeOption[i];
      if (fee?.value) {
        singleFee["feeId"] = fee.value;
        fees.push(singleFee);
      }
    }
    createForm["fees"] = fees;

    if (data?.fieldCreateFee?.pageTitle) {
      setPayLinkDetails(createForm);
    }
  };

  //SUBMIT DONATION DATA
  const onSubmitDonationleHandler = (data) => {
    var createForm = {};
    createForm["pageTitle"] = data.fieldDonateLink.pageTitle;
    createForm["pageDescription"] = data.fieldDonateLink.description;
    createForm["redirectUrl"] = data.fieldDonateLink.redirect;
    createForm["message"] = data.fieldDonateLink.afterPaymentMsg;
    createForm["linkCode"] = data.fieldDonateLink.customLinkCode;
    createForm["imageId"] = imageId;
    createForm["amount"] =
      data.fieldDonateLink.custInputAmount === true
        ? null
        : data.fieldDonateLink.amount;
    createForm["customerInputAmount"] =
      data.fieldDonateLink.custInputAmount === true ? 1 : 0;

    if (data.fieldDonateLink.collectPhoneNum) {
      createForm["collectPhone"] = 1;
    } else {
      createForm["collectPhone"] = 0;
    }

    var labels = [];
    for (let i in data.fieldDonateLink.addInfo) {
      var singleInfo = {};
      var adInfo = data.fieldDonateLink.addInfo[i];
      if (adInfo?.labelName) {
        singleInfo["labelName"] = adInfo.labelName;
        singleInfo["optional"] = adInfo.checkOptional === true ? 1 : 0;
        labels.push(singleInfo);
      }
    }
    createForm["labels"] = labels;
    setPayLinkDetails(createForm);
  };

  //SUBMIT SCHOOL DATA
  const onSubmitHandler = (data) => {
    for (let i in data.fieldFee) {
      if (data.fieldFee[i]) {
        const { feeName, feeAmount, feeDescription } = data?.fieldFee[i];
        var createFee = {};
        createFee["feeName"] = feeName;
        createFee["amount"] = feeAmount;
        createFee["remark"] = feeDescription;
        var appliedClass = [];
        if (data?.classFeeApplied) {
          for (let n in data?.classFeeApplied[i]) {
            var singleClassId = {};
            var classes = data?.classFeeApplied[i][n];
            if (classes?.value) {
              singleClassId["classId"] = classes?.value;
              appliedClass.push(singleClassId);
            }
          }
        }

        createFee["appliedClasses"] = appliedClass;
        setFeeDetails(createFee);
        if (parseInt(i) === data.fieldFee.length - 1) {
          setCallFeeUpdate(true);
        }
        // call create fee api
        if (feeName) {
          fetchCreateFee();
        }
      }
    }

    var createForm = {};
    createForm["pageTitle"] = data?.fieldSchoolLink?.pageTitle;
    createForm["pageDescription"] = data?.fieldSchoolLink?.description;
    createForm["redirectUrl"] = data?.fieldSchoolLink?.redirect;
    createForm["message"] = data?.fieldSchoolLink?.afterPaymentMsg;
    createForm["linkCode"] = data?.fieldSchoolLink?.customLinkCode;
    createForm["imageId"] = imageId;

    if (data?.fieldSchoolLink?.collectPhoneNum) {
      createForm["collectPhone"] = 1;
    } else {
      createForm["collectPhone"] = 0;
    }
    var labels = [];
    for (let i in data?.fieldSchoolLink?.addInfo) {
      var singleInfo = {};
      var adInfo = data?.fieldSchoolLink?.addInfo[i];
      if (adInfo?.labelName) {
        singleInfo["labelName"] = adInfo.labelName;
        singleInfo["optional"] = adInfo.checkOptional === true ? 1 : 0;
        labels.push(singleInfo);
      }
    }
    createForm["labels"] = labels;

    var fees = [];
    for (let i in selectedFeeOption) {
      var singleFee = {};
      var fee = selectedFeeOption[i];
      if (fee?.value) {
        singleFee["feeId"] = fee.value;
        fees.push(singleFee);
      }
    }
    createForm["fees"] = fees;

    if (data?.fieldSchoolLink?.pageTitle) {
      setPayLinkDetails(createForm);
    }
  };

  const handleFileUpload = async (e) => {
    const document = e.target.files[0];
    const fileType = document.type.split("/");
    setImageNameTemp(document?.name);
    setFileSuffix(fileType[1]);
    const base64Certificate = await file.convertToBase64(document);
    setBase64Certificate(base64Certificate);
    // call image upload
    fetchImageUpload();
  };

  const uploadimage = () => {
    if (!isImageUploading) {
      uploadRef.current.click();
    }
  };

  // useEffect(() => {
  //   const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));
  //   if (!onboardingDetails.onboarded) {
  //     history.push("/dashboard/merchant/onboarding");
  //     Notify(
  //       "info",
  //       "Onboarding pending",
  //       "Please complete the onboarding process to start using your DeemPay account!",
  //       10
  //     );
  //   }
  // }, []);

  return (
    <SupervisorLayout>
      <header>
        <main className="fee__n">
          <div className="home3-bar">
            <h3>
              Payment links
            </h3>
            <div className="topbar">
              <span className="top-notify">
                <span
                  className="btn_blue_card card__space"
                  onClick={() => setShowModal(true)}
                >
                  <FiPlus className="icon__mg " />
                  <span className="blueCard_text">New</span>
                </span>
              </span>
            </div>
          </div>

          {isFetchingPayLink || payLinks === null ? (
            <div class="mt-[200px]">
              <Spinner />
            </div>
          ) : payLinks?.length > 0 ? (
            <FeeTable
              column={column}
              data={payLinks}
              link={"/dashboard/supervisor/payment-link/"}
            />
          ) : (
            <>
              <section className="noData">
                <NoPaymentLinkIcon />
                <h2>No Payment Link</h2>
                <h4>
                  Start collecting fee and customers details online. No code
                  required. Share a link to a payment page with your customer to
                  pay you fast.
                </h4>
              </section>
            </>
          )}

          {/* MODAL FOR PAYMENT LINK */}
          {showModal ? (
            <div className="option__modal">
              <div className="option--wrap">
                <form>
                  <div className="header-part">
                    <div className="add--line--bottom">
                      <h2>
                        Create payment link
                        <span className="close__modal" onClick={closeModal}>
                          <CloseModalIcon />
                        </span>
                      </h2>
                    </div>
                  </div>
                  {/* BODY */}
                  <div class="my-6 md:px-[50px] px-[28px] ">
                    <div
                      onClick={openPaymentModal}
                      class="md:p-[22px] p-[20px] h-[101px] bg-[#ffffff] border border-[#e5e5e5] hover:border-[#1a71ff] hover:bg-[#f5f9ff] rounded-lg flex space-x-4 mb-4 cursor-pointer "
                    >
                      <span class="my-auto bg-[#ccdfff] rounded-[45px] h-[45px] md:w-[50px] w-[80px] justify-items-center relative ">
                        <span class="absolute top-[30%] left-[31%] ">
                          <GovtIcon />
                        </span>
                      </span>
                      <span>
                        <h4 class="text-[#595959] text_14_500 ">
                          Collect payment
                        </h4>
                        <h5 class="text-[#595959] text_12_400 ">
                          For those that need to collect payment online from one
                          or more customer
                        </h5>
                      </span>
                    </div>
                    <div
                      onClick={openSchoolModal}
                      class="md:p-[22px] p-[20px] h-[101px] bg-[#ffffff] border border-[#e5e5e5] hover:border-[#1a71ff] hover:bg-[#f5f9ff] rounded-lg flex space-x-4 mb-4 cursor-pointer "
                    >
                      <span class="my-auto bg-[#ccdfff] rounded-[45px] h-[45px] md:w-[45px] w-[70px] justify-items-center relative ">
                        <span class="absolute top-[30%] left-[30%] ">
                          <SchoolIcon />
                        </span>
                      </span>
                      <span>
                        <h4 class="text-[#595959] text_14_500 ">
                          Collect school fees
                        </h4>
                        <h5 class="text-[#595959] text_12_400 ">
                          Best for schools that need to collect fee and user
                          details online
                        </h5>
                      </span>
                    </div>

                    <div
                      onClick={openDonationModal}
                      class="md:p-[22px] p-[20px] h-[101px] bg-[#ffffff] border border-[#e5e5e5] hover:border-[#1a71ff] hover:bg-[#f5f9ff] rounded-lg flex space-x-4 mb-4 cursor-pointer "
                    >
                      <span class="my-auto bg-[#ccdfff] rounded-[45px] h-[45px] w-[45px] justify-items-center relative ">
                        <span class="absolute top-[30%] left-[30%] ">
                          <DonationIcon />
                        </span>
                      </span>
                      <span>
                        <h4 class="text-[#595959] text_14_500 ">
                          Accept a donation
                        </h4>
                        <h5 class="text-[#595959] text_12_400 ">
                          Accept donations for your cause
                        </h5>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : null}

          {/* OTHER PAYMENT MODAL */}
          {showPaymentModal ? (
            <div className="option__modal">
              <div className="option--wrap">
                <form>
                  <div className="header-part">
                    <div className="add--line--bottom">
                      <h2>
                        Create payment link
                        <span className="close__modal" onClick={closeModal}>
                          <CloseModalIcon />
                        </span>
                      </h2>
                    </div>
                  </div>
                  {/* BODY */}
                  <div class="my-6 md:px-[50px] px-[28px] ">
                    <div
                      onClick={openSinglePaymentModal}
                      class="md:p-[22px] p-[20px] h-[101px] bg-[#ffffff] border border-[#e5e5e5] hover:border-[#1a71ff] hover:bg-[#f5f9ff] rounded-lg flex space-x-4 mb-4 cursor-pointer "
                    >
                      <span class="my-auto bg-[#ccdfff] rounded-[45px] h-[45px] md:w-[45px] w-[55px] justify-items-center relative ">
                        <span class="absolute top-[30%] left-[34%] ">
                          <SingleFeeIcon />
                        </span>
                      </span>
                      <span>
                        <h4 class="text-[#595959] text_14_500 ">
                          Single payment link
                        </h4>
                        <h5 class="text-[#595959] text_12_400 ">
                          For quick and easy transactions with just one click
                        </h5>
                      </span>
                    </div>
                    <div
                      onClick={openMutiplePaymentModal}
                      class="md:p-[22px] p-[20px] h-[101px] bg-[#ffffff] hover:bg-[#f5f9ff] border border-[#e5e5e5] hover:border-[#1a71ff] rounded-lg flex space-x-4 mb-4 cursor-pointer "
                    >
                      <span class="my-auto bg-[#ccdfff] rounded-[45px] h-[45px] md:w-[60px] w-[95px] justify-items-center relative ">
                        <span class="absolute top-[30%] left-[31%] ">
                          <MutipleFeeIcon />
                        </span>
                      </span>
                      <span>
                        <h4 class="text-[#595959] text_14_500 ">
                          Multiple fee payment
                        </h4>
                        <h5 class="text-[#595959] text_12_400 ">
                          Provide convenient way to collect payments from
                          customers for various services or fees.
                        </h5>
                      </span>
                    </div>
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4 justify-end content-end md:pr-14 pr-[28px]">
                    <button onClick={startSchoolModal} class="white_modal_btn">
                      Back
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}

          {/* MODAL FOR SINGLE PAYMENT LINK */}
          {showSinglePaymentModal ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <form
                  onSubmit={handleSubmit(onSubmitSingleHandler)}
                  class="flex flex-col"
                >
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title ">Create payment link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8 py-8 overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div class="mb-7">
                      <label class="laBel">Page title</label>
                      <input
                        type="text"
                        class={`in_Put ${
                          errors.fieldSingleLink?.pageTitle && "inPut_error"
                        }`}
                        placeholder="E.g Payment"
                        {...register(`fieldSingleLink.pageTitle`, {
                          required: "Page title is required",
                        })}
                      />
                      {errors.fieldSingleLink?.pageTitle && (
                        <p class=" mt-1 text-sm text-[red]">
                          {errors.fieldSingleLink?.pageTitle?.message}
                        </p>
                      )}
                    </div>
                    <div class="col-span-full">
                      <label class="laBel">Page Description</label>
                      <div class="mt-2">
                        <textarea
                          rows="3"
                          class={`"block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff]" ${
                            errors.fieldSingleLink?.description &&
                            "block w-full h-[87px] rounded-lg border-[red] outline-none border-[2px] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[red]"
                          }`}
                          placeholder="Give customers more details of what they are paying for"
                          {...register(`fieldSingleLink.description`, {
                            required: "Description is required",
                          })}
                        ></textarea>
                        {errors.fieldSingleLink?.description && (
                          <p class=" mt-1 text-sm text-[red]">
                            {errors.fieldSingleLink?.description?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div class="col-span-full">
                      <label class="laBel mt-7">Add image</label>

                      <div
                        onClick={() => {
                          uploadimage();
                        }}
                        class="mt-2 flex bg-[#f7f7f7] cursor-pointer justify-center rounded-lg border border-dashed border-[#7b7b7b] px-1 py-1 h-[85px]"
                      >
                        <div class="text-center">
                          {isImageUploading ? (
                            <div class="mt-2 justify-center py-4">
                              <Spinner />
                            </div>
                          ) : (
                            <>
                              <div class="mt-4 flex text-sm leading-6 ">
                                <label class="mx-auto relative cursor-pointer rounded-md font-medium text-[#000000]">
                                  <span>Choose file</span>
                                  <input
                                    id="upload"
                                    type="file"
                                    ref={uploadRef}
                                    onChange={handleFileUpload}
                                    class="sr-only"
                                  />
                                </label>
                              </div>
                              <p class="text-[10px] leading-5 text-[#adadad]">
                                {imageName === "" ? (
                                  <>(JPG, JPEG, PNG, SVG, 1MB max)</>
                                ) : (
                                  <>{imageName}</>
                                )}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="mt-7">
                      {showInputAmount ? (
                        <>
                          <label class="laBel mb-2">Amount</label>
                          <div class="flex">
                            <span class="half_label">NGN</span>
                            <input
                              type="number"
                              class={`" block flex-1 min-w-0 w-full text-sm rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] border-[none] p-4 text-gray-900 ring-1 ring-inset ring-[#f7f7f7] " ${
                                errors.fieldSingleLink?.amount &&
                                "block flex-1 min-w-0 w-full text-sm rounded-none rounded-r-lg border-[red] outline-none border-[2px] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7] "
                              }`}
                              placeholder="5,000"
                              {...register("fieldSingleLink.amount", {
                                required: "Amount is required",
                              })}
                            />
                          </div>
                          {errors.fieldSingleLink?.amount && (
                            <p class=" mt-1 text-sm text-[red]">
                              {errors.fieldSingleLink?.amount.message}
                            </p>
                          )}
                        </>
                      ) : null}
                      <div class="flex items-center pt-3">
                        <input
                          type="checkbox"
                          class="sm:w-4 md:h-4 w-3 h-3 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                          {...register(`fieldSingleLink.custInputAmount`)}
                        />
                        <label class="block ml-2 text_14_400 text-[#595959]">
                          Let customers input amount
                        </label>
                      </div>
                      <div class="flex items-center pt-3">
                        <input
                          type="checkbox"
                          class="sm:w-4 sm:h-4 w-3 h-3 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                          {...register(`fieldSingleLink.collectPhoneNum`)}
                        />
                        <label class="block ml-2 text_14_400 text-[#595959]">
                          Collect customer phone number
                        </label>
                      </div>
                    </div>

                    <div class="mt-7">
                      <label class="block mb-2 text-[#595959] laBel">
                        Do you want to collect any other information
                      </label>
                      {/* ADD MORE FIELDS */}
                      {fields.map((item, index) => {
                        return (
                          <div class="mt-5" key={item.id}>
                            <div class="flex relative">
                              <span class="half_label">Label Name</span>
                              <input
                                type="text"
                                class="rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm px-4 sm:h-[56px] h-[46px]  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]"
                                placeholder="E.g Student name"
                                {...register(
                                  `fieldSingleLink.addInfo.${index}.labelName`
                                )}
                              />

                              {index !== 0 && (
                                <svg
                                  onClick={() => {
                                    remove(index);
                                    setAddInfoCount(addInfoCount - 1);
                                    resetField(
                                      `fieldSingleLink.addInfo.${index}`
                                    );
                                  }}
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5 absolute top-[30%] right-[4.5%] cursor-pointer "
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              )}
                            </div>
                            <div class="flex items-center pt-3">
                              <input
                                type="checkbox"
                                class="sm:w-4 w-3 sm:h-4 h-3 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                                {...register(
                                  `fieldSingleLink.addInfo.${index}.checkOptional`
                                )}
                              />
                              <label class="block ml-2 text_14_400 text-[#595959]">
                                Mark as optional
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* ADD MORE FIELDS */}
                    <button
                      disabled={addInfoCount >= 4}
                      type="button"
                      onClick={() => {
                        append({ labelName: "", checkOptional: "" });
                        setAddInfoCount(addInfoCount + 1);
                      }}
                      class="full_blue_btn items-center justify-center flex space-x-2 mb-6 mt-7"
                    >
                      <AddFeeIcon />
                      <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                        Add another field
                      </span>
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        setShow(!show);
                        if (show) {
                          closeMoreDetails();
                        } else {
                          openMoreDetails();
                        }
                      }}
                    >
                      {show ? (
                        <span class="flex space-x-4">
                          <h4 class="text_14_500 text-[#1a71ff] ">
                            Hide advanced options
                          </h4>
                          <span onClick={closeMoreDetails}>
                            <FeeArrowUpIcon />
                          </span>
                        </span>
                      ) : (
                        <span class="flex space-x-4">
                          <h4 class="text_14_500 text-[#1a71ff] ">
                            Show advanced options
                          </h4>
                          <span onClick={openMoreDetails}>
                            <FeeArrowDownIcon />
                          </span>
                        </span>
                      )}
                    </button>
                    {showMoreDetails ? (
                      <>
                        <div class="mt-7">
                          <label class="block mb-2 text_14_400 text-[#595959]">
                            Custom link
                          </label>
                          <div class="flex">
                            <span class="half_label">
                              https://pay.deempay.com/
                            </span>
                            <input
                              type="text"
                              class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm px-4 sm:h-[56px] h-[46px]  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                                errors.fieldSingleLink?.customLinkCode &&
                                "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[red] focus:border-[red] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[1a71ff] border-[red] outline-none border-[2px]"
                              }`}
                              placeholder="Your custom link"
                              {...register(`fieldSingleLink.customLinkCode`, {
                                minLength: {
                                  value: 6,
                                  message:
                                    "Characters should be upto 6 in numbers",
                                },
                              })}
                            />
                          </div>
                          {errors.fieldSingleLink?.customLinkCode && (
                            <p class=" mt-1 text-sm text-[red]">
                              {errors.fieldSingleLink?.customLinkCode?.message}
                            </p>
                          )}
                        </div>

                        <div class="mb-7 mt-7">
                          <label class="laBel">Redirect after payment</label>
                          <input
                            type="url"
                            class="in_Put"
                            placeholder="https://redirect.link"
                            {...register(`fieldSingleLink.redirect`)}
                          />
                        </div>
                        <div class="mb-7">
                          <label class="laBel">Message after payment</label>
                          <input
                            type="text"
                            class="in_Put"
                            placeholder="Message to show after payment"
                            {...register(`fieldSingleLink.afterPaymentMsg`)}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button onClick={openPaymentModal} class="white_modal_btn">
                      Back
                    </button>
                    <button type="submit" class="blue_modal_btn ml-4 ">
                      {isCreatingPayLink ? <SpinnerWhite /> : "Create"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}

          {/* MODAL FOR MULTIPLE PAYMENT */}
          {showMutiplePaymentModal ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <form class="flex flex-col">
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="text-base font-medium text-[#595959] items-center justify-center flex">
                      Create payment link
                    </h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8 py-[38px] overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div>
                      {isQueryingFee ? (
                        <Spinner color={"#1a71ff"} />
                      ) : (
                        <button
                          onClick={openMutiplePaymentModal2}
                          class="py-3 items-center justify-center flex space-x-2 full_blue_btn"
                        >
                          <AddFeeIcon />
                          <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                            Add fee
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button onClick={openPaymentModal} class="white_modal_btn">
                      Back
                    </button>
                    <button
                      class="text-[#ffffff] rounded-lg text-base font-medium bg-[#595959] px-3.5 py-2 ml-4 cursor-not-allowed"
                      disabled
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}

          {/* MODAL TO ADD MULTIPLE FEES */}
          <form onSubmit={handleSubmit(onSubmitMultipleHandler)}>
            {showMutiplePaymentModal2 ? (
              <div className="fee__modal">
                <div className="fee__wrap">
                  <div class="flex flex-col">
                    {/* HEADER */}
                    <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                      <h4 class="text-base font-medium text-[#595959] items-center justify-center flex">
                        Add Fees
                      </h4>
                      <span
                        onClick={closeModal}
                        class="absolute top-[38%] right-[9.8%] cursor-pointer"
                      >
                        <CloseModalIcon />
                      </span>
                    </div>
                    {/* BODY */}
                    <div class="sm:px-14 px-8 py-[30px] overflow-y-auto max-h-[660px] min-h-[450px]">
                      {fields.map((item, index) => {
                        return (
                          <div key={item.id}>
                            <div class="mb-7">
                              <label class="laBel justify-between flex">
                                <span>Fee name</span>

                                {index !== 0 && (
                                  <span
                                    onClick={() => remove(index)}
                                    class="cursor-pointer text-[red] hover:text-[red]"
                                  >
                                    Clear
                                  </span>
                                )}
                              </label>
                              <input
                                type="text"
                                class={`in_Put ${
                                  errors.fieldFee?.[index]?.feeName &&
                                  "inPut_error"
                                }`}
                                placeholder="E.g Transport levy"
                                {...register(`fieldFee.${index}.feeName`, {
                                  required: true,
                                })}
                              />
                              <p class="hidden mt-1 text-sm text-red-600 dark:text-red-500">
                                Enter fee name
                              </p>
                            </div>

                            <label class="laBel mb-2">Fee amount</label>
                            <div class="flex mb-7">
                              <span class="half_label">NGN</span>
                              <input
                                type="number"
                                class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                                  errors.fieldFee?.[index]?.feeAmount &&
                                  "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[red] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[red] border-2 outline-none "
                                }`}
                                placeholder="Eg. 5,000"
                                {...register(`fieldFee.${index}.feeAmount`, {
                                  required: true,
                                })}
                              />
                            </div>

                            <div class="col-span-full mb-7">
                              <label for="about" class="laBel">
                                Fee Description ( Optional )
                              </label>
                              <div class="mt-2">
                                <textarea
                                  rows="3"
                                  class="block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff] "
                                  placeholder="Type something"
                                  {...register(
                                    `fieldFee.${index}.feeDescription`
                                  )}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <button
                        type="button"
                        onClick={() =>
                          append({
                            feeName: "",
                            feeAmount: "",
                            feeDescription: "",
                          })
                        }
                        class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                      >
                        <AddFeeIcon />
                        <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                          Add another fee
                        </span>
                      </button>
                    </div>
                    {/* FOOTER */}
                    <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                      <button
                        onClick={openMutiplePaymentModal}
                        class="white_modal_btn"
                      >
                        Back
                      </button>
                      <button
                        class="blue_modal_btn ml-4"
                      >
                        {isCreatingFee ? <SpinnerWhite /> : "Add"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* MODAL TO SELECT MUTIPLE PAYMENT FEES  */}
            {showMutiplePaymentModal3 ? (
              <div className="fee__modal">
                <div className="fee__wrap">
                  <div class="flex flex-col">
                    {/* HEADER */}
                    <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                      <h4 class="text-base font-medium text-[#595959] items-center justify-center flex">
                        Create payment link
                      </h4>
                      <span
                        onClick={closeModal}
                        class="absolute top-[38%] right-[9.8%] cursor-pointer"
                      >
                        <CloseModalIcon />
                      </span>
                    </div>
                    {/* BODY */}
                    <div class="sm:px-14 px-8 py-[38px] overflow-y-auto max-h-[660px] min-h-[450px]">
                      <div class="col-span-full"></div>
                      <div class="w-full mb-7">
                        <div
                          class="relative"
                          ref={selectRef}
                        >
                          <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                            Select fee
                          </span>

                          <div class=" text-sm text-[#8d8d8d] border border-[#f7f7f7] rounded-lg bg-[#f7f7f7]">
                            <div className="App">
                              <Select
                                value={selectedFeeOption}
                                onChange={setSelectedFeeOption}
                                options={feeOptions}
                                placeholder="Select fee"
                                isMulti
                                name="fees"
                                className="basic-multi-select"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "#f7f7f7",
                                    borderWidth: "2px",
                                    borderRadius: "8px",
                                    minHeight: "52px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {selectedFeeOption?.map((list, index) => (
                        <div
                          key={index}
                          class="text-[#606060] rounded-lg py-3 px-4 flex justify-between bg-[#ececec] w-full mb-2"
                        >
                          <span class="text-[#606060]">{list.label}</span>
                          <span class="flex space-x-2">
                            <span class="">NGN {list.amount}</span>
                          </span>
                        </div>
                      ))}

                      <div class="py-8">
                        <button
                          onClick={openMutiplePaymentModal2}
                          class="py-3 items-center justify-center flex space-x-2 full_blue_btn text_14_400 text-[#ffffff]"
                        >
                          <AddFeeIcon />
                          <span class="items-center justify-center flex">
                            Add new fee
                          </span>
                        </button>
                      </div>
                    </div>
                    {/* FOOTER */}
                    <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                      <button
                        onClick={openPaymentModal}
                        class="white_modal_btn"
                      >
                        Back
                      </button>
                      <button
                        disabled={selectedFeeOption?.length === 0}
                        onClick={openMutiplePaymentModal4}
                        class="blue_modal_btn ml-4 "
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* MODAL TO CREATE MUTIPLE PAYMENT LINK */}
            {showMutiplePaymentModal4 ? (
              <div className="fee__modal">
                <div className="fee__wrap">
                  <div class="flex flex-col">
                    {/* HEADER */}
                    <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                      <h4 class="text-base font-medium text-[#595959] items-center justify-center flex">
                        Create payment link
                      </h4>
                      <span
                        onClick={closeModal}
                        class="absolute top-[38%] right-[9.8%] cursor-pointer"
                      >
                        <CloseModalIcon />
                      </span>
                    </div>
                    {/* BODY */}
                    <div class="sm:px-14 px-8 py-4 overflow-y-auto max-h-[660px] min-h-[450px]">
                      <div class="mb-7">
                        <label class="laBel">Page title</label>
                        <input
                          type="text"
                          class={`in_Put ${
                            errors.fieldCreateFee?.pageTitle && "inPut_error"
                          }`}
                          placeholder="E.g Payment"
                          {...register(`fieldCreateFee.pageTitle`, {
                            required: "Page title is required",
                          })}
                        />
                        {errors.fieldCreateFee?.pageTitle && (
                          <p class=" mt-1 text-sm text-[red]">
                            {errors.fieldCreateFee?.pageTitle?.message}
                          </p>
                        )}
                      </div>
                      <div class="col-span-full">
                        <label for="about" class="laBel">
                          Page Description
                        </label>
                        <div class="mt-2">
                          <textarea
                            rows="3"
                            class={`"block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff]" ${
                              errors.fieldCreateFee?.description &&
                              "block w-full h-[87px] rounded-lg border-[red] outline-none border-[2px] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[red]"
                            }`}
                            placeholder="Give customers more details of what they are paying for"
                            {...register(`fieldCreateFee.description`, {
                              required: "Description is required",
                            })}
                          ></textarea>
                          {errors.fieldCreateFee?.description && (
                            <p class=" mt-1 text-sm text-[red]">
                              {errors.fieldCreateFee?.description?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="col-span-full">
                        <label class="laBel mt-7">Add image</label>

                        <div
                          onClick={() => {
                            uploadimage();
                          }}
                          class="mt-2 flex bg-[#f7f7f7] cursor-pointer justify-center rounded-lg border border-dashed border-[#7b7b7b] px-1 py-1 h-[85px]"
                        >
                          <div class="text-center">
                            {isImageUploading ? (
                              <div class="mt-2 justify-center py-4">
                                <Spinner />
                              </div>
                            ) : (
                              <>
                                <div class="mt-4 flex text-sm leading-6 ">
                                  <label class="mx-auto relative cursor-pointer rounded-md font-medium text-[#000000]">
                                    <span>Choose file</span>
                                    <input
                                      id="upload"
                                      type="file"
                                      ref={uploadRef}
                                      onChange={handleFileUpload}
                                      class="sr-only"
                                    />
                                  </label>
                                </div>
                                <p class="text-[10px] leading-5 text-[#adadad]">
                                  {imageName === "" ? (
                                    <>(JPG, JPEG, PNG, SVG, 1MB max)</>
                                  ) : (
                                    <>{imageName}</>
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class="mt-7">
                        <div class="flex items-center pt-3">
                          <input
                            type="checkbox"
                            class="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                            {...register(`fieldCreateFee.collectPhoneNum`)}
                          />
                          <label class="block ml-2 text-sm text-[#595959]">
                            Collect customer phone number
                          </label>
                        </div>
                      </div>

                      <div class="mt-7">
                        <label class="block mb-2 text-sm font-normal text-[#595959] dark:text-[#595959]">
                          Do you want to collect any other information
                        </label>

                        {/* ADD MORE FIELDS */}
                        {fields.map((item, index) => {
                          return (
                            <div class="mt-5" key={item.id}>
                              <div class="flex relative">
                                <span class="half_label">Label Name</span>
                                <input
                                  type="text"
                                  class="rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]"
                                  placeholder="E.g Student name"
                                  {...register(
                                    `fieldCreateFee.addInfo.${index}.labelName`
                                  )}
                                />

                                {index !== 0 && (
                                  <svg
                                    onClick={() => {
                                      remove(index);
                                      setAddInfoCount(addInfoCount - 1);
                                      resetField(
                                        `fieldCreateFee.addInfo.${index}`
                                      );
                                    }}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.8"
                                    stroke="currentColor"
                                    class="w-5 h-5 absolute top-[30%] right-[4.5%] cursor-pointer "
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                )}
                              </div>
                              <div class="flex items-center pt-3">
                                <input
                                  type="checkbox"
                                  class="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                                  {...register(
                                    `fieldCreateFee.addInfo.${index}.checkOptional`
                                  )}
                                />
                                <label class="block ml-2 text-sm text-[#595959]">
                                  Mark as optional
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {
                        <button
                          type="button"
                          disabled={addInfoCount >= 4}
                          onClick={() => {
                            append({ labelName: "", checkOptional: "" });
                            setAddInfoCount(addInfoCount + 1);
                          }}
                          class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7 text_14_400 text-[#ffffff]"
                        >
                          <AddFeeIcon />
                          <span class="items-center justify-center flex">
                            Add another field
                          </span>
                        </button>
                      }

                      <button
                        type="button"
                        onClick={() => {
                          setShow(!show);

                          if (show) {
                            closeMoreDetails();
                          } else {
                            openMoreDetails();
                          }
                        }}
                      >
                        {show ? (
                          <span class="flex space-x-4">
                            <h4 class="text-sm text-[#1a71ff] font-medium ">
                              Hide advanced options
                            </h4>
                            <span
                              onClick={() => {
                                closeMoreDetails();
                              }}
                            >
                              <FeeArrowUpIcon />
                            </span>
                          </span>
                        ) : (
                          <span class="flex space-x-4">
                            <h4 class="text-sm text-[#1a71ff] font-medium ">
                              Show advanced options
                            </h4>
                            <span onClick={openMoreDetails}>
                              <FeeArrowDownIcon />
                            </span>
                          </span>
                        )}
                      </button>
                      {showMoreDetails ? (
                        <>
                          <div class="mt-7">
                            <label class="block mb-2 text-sm font-normal text-[#595959] dark:text-[#595959]">
                              Custom link
                            </label>
                            <div class="flex">
                              <span class="half_label">
                                https://pay.deempay.com/
                              </span>
                              <input
                                type="text"
                                class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                                  errors.fieldCreateFee?.customLinkCode &&
                                  "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[red] focus:border-[red] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[1a71ff] border-[red] outline-none border-[2px]"
                                }`}
                                placeholder="Your custom link"
                                {...register(`fieldCreateFee.customLinkCode`, {
                                  minLength: {
                                    value: 6,
                                    message:
                                      "Characters should be upto 6 in numbers",
                                  },
                                })}
                              />
                            </div>
                            {errors.fieldCreateFee?.customLinkCode && (
                              <p class=" mt-1 text-sm text-[red]">
                                {errors.fieldCreateFee?.customLinkCode?.message}
                              </p>
                            )}
                          </div>

                          <div class="mb-7 mt-7">
                            <label class="laBel">Redirect after payment</label>
                            <input
                              type="url"
                              class="in_Put"
                              placeholder="https://redirect.link"
                              {...register(`fieldCreateFee.redirect`)}
                            />
                          </div>
                          <div class="mb-7">
                            <label class="laBel">Message after payment</label>
                            <input
                              type="text"
                              class="in_Put"
                              placeholder="Message to show after payment"
                              {...register(`fieldCreateFee.afterPaymentMsg`)}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    {/* FOOTER */}
                    <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                      <button
                        onClick={openMutiplePaymentModal3}
                        class="white_modal_btn"
                      >
                        Back
                      </button>
                      <button type="submit" class="blue_modal_btn ml-4 ">
                        {isCreatingPayLink ? <SpinnerWhite /> : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </form>

          {/* MODAL FOR SCHOOL PAYMENT ( TO ADD CLASS ) */}
          {showSchoolFees ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <form class="flex flex-col">
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title">Create payment link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8 pt-[38px] overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div class="md:block  mb-[45px] relative">
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#CCDFFF] border border-[#1A71FF] rounded-[32px] mr-1">
                        <span class="text-[#1a71ff] text-center w-full">1</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px]  bg-[#1a71ff] flex items-center text-center w-full absolute left-[40px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#f3f3f3] border border-[#f3f3f3] rounded-[32px] mr-1 absolute md:left-[208px] left-[120px] top-[0px]">
                        <span class="text-[#595959] text-center w-full">2</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#e1e1e1] flex items-center text-center w-full absolute md:left-[248px] left-[160px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#f3f3f3] border border-[#f3f3f3] rounded-[32px] mr-1 absolute md:left-[417px] left-[240px] top-[0px]">
                        <span class="text-[#595959] text-center w-full">3</span>
                      </div>
                    </div>

                    {fetchingClass ? (
                      <Spinner color={"#1a71ff"} />
                    ) : classPresent ? (
                      <>
                        <div class="w-full mb-7">
                          <div
                            class="relative"
                            ref={selectRef}
                            onClick={showCheckBoxes}
                          >
                            <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                              Select class
                            </span>

                            <div class=" text-sm text-[#8d8d8d] border border-[#f7f7f7] rounded-lg bg-[#f7f7f7]">
                              <div className="App">
                                <Select
                                  value={selectedClassOption}
                                  onChange={setSelectedClassOption}
                                  options={classOptions}
                                  placeholder="Select class"
                                  isMulti
                                  name="classes"
                                  className="basic-multi-select"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      backgroundColor: "#f7f7f7",
                                      borderWidth: "2px",
                                      borderRadius: "8px",
                                      minHeight: "52px",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedClassOption?.map((list) => (
                          <div class="text-[#606060] rounded-lg py-3 px-4 flex justify-between bg-[#ececec] w-full mb-2">
                            <span key={list.label} class="text-[#606060]">
                              {list.label}
                            </span>
                            <span class="flex space-x-2">
                              <span key={list.category}>{list.category}</span>
                            </span>
                          </div>
                        ))}
                        <div>
                          <button
                            onClick={openAddClassModal}
                            class="py-3 items-center justify-center flex space-x-2 full_blue_btn mt-7 mb-auto"
                          >
                            <AddFeeIcon />
                            <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                              Add new class
                            </span>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="py-2">
                          <button
                            onClick={openAddClassModal}
                            class="py-3 items-center justify-center flex space-x-2 full_blue_btn mb-auto"
                          >
                            <AddFeeIcon />
                            <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                              Add class
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4 mt-[38px] border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button
                      onClick={() => {
                        startSchoolModal();
                        setClassPresent(false);
                      }}
                      class="white_modal_btn"
                    >
                      Back
                    </button>
                    <button
                      onClick={openFeesModal}
                      disabled={selectedClassOption?.length === 0}
                      class="blue_modal_btn ml-4"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}

          {/* MODAL TO ADD CLASS */}
          {showAddClass ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <form
                  onSubmit={handleSubmit(onSubmitClass)}
                  class="flex flex-col"
                >
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] mb-[10px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title">Add class</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}

                  <div class="sm:px-14 px-8 overflow-y-auto max-h-[660px] min-h-[450px]">
                    {fields.map((item, index) => {
                      return (
                        <div key={item.id}>
                          <div class="my-7">
                            <label class="laBel justify-between flex">
                              <span>Class name</span>

                              {index !== 0 && (
                                <span
                                  onClick={() => remove(index)}
                                  class="cursor-pointer text-[red] hover:text-[red]"
                                >
                                  Clear
                                </span>
                              )}
                            </label>
                            <input
                              type="text"
                              class={`in_Put ${
                                errors.fieldClass?.[index]?.className &&
                                "inPut_error"
                              }`}
                              placeholder="E.g Primary 4"
                              {...register(`fieldClass.${index}.className`, {
                                required: true,
                              })}
                            />
                          </div>

                          <div class="relative">
                            <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                              Class category
                            </span>
                            <select
                              {...register(
                                `fieldClass.${index}.classCategory`,
                                {
                                  required: true,
                                }
                              )}
                              class={`"form-select block w-full mt-1 p-4 mb-1 text-sm text-[#000000] border-[2px] border-[#f7f7f7] rounded-lg bg-[#f7f7f7] " appearance-none ${
                                errors.fieldClass?.[index]?.classCategory &&
                                " outline-none border-[2px] border-[red] rounded-lg bg-[#f7f7f7] appearance-none"
                              }`}
                            >
                              <option value="" disabled selected>
                                Select category
                              </option>
                              <option>Creche</option>
                              <option>Nursery</option>
                              <option>Primary</option>
                              <option>Secondary</option>
                              <option>Tertiary</option>
                            </select>
                            <svg
                              width="15"
                              height="8"
                              fill="none"
                              class="w-4 h-4 absolute left-[91%] bottom-[18%] pointer-events-none block"
                            >
                              <path
                                d="M13.71.296a1 1 0 0 0-1.42 0l-4.58 4.58a1 1 0 0 1-1.42 0L1.71.296a1 1 0 1 0-1.42 1.41l4.59 4.59a3 3 0 0 0 4.24 0l4.59-4.59a1 1 0 0 0 0-1.41Z"
                                fill="#8D8D8D"
                              />
                            </svg>
                          </div>
                        </div>
                      );
                    })}

                    <button
                      type="button"
                      onClick={() => {
                        append({ className: "", classCategory: "" });
                      }}
                      class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                    >
                      <AddFeeIcon />
                      <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                        Add another class
                      </span>
                    </button>
                  </div>

                  {/* FOOTER */}
                  <div class="flex py-4 mt-[38px] border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button
                      onClick={() => {
                        openSchoolModal();
                        reset();
                      }}
                      class="white_modal_btn"
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      disabled={isFetchingClass}
                      class="blue_modal_btn ml-4 "
                    >
                      {isFetchingClass ? <SpinnerWhite /> : "Add"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}

          {/* MODAL TO SELECT CLASS */}
          {showSchoolFees2 ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <div class="flex flex-col">
                  {/* HEADER */}
                  <div class="flex py-4 mb-[38px] border-b-2 border-[#c1c1c1] justify-center relative">
                    <h4 class="modal_title">Create Payment link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8 overflow-y-auto h-[450px]">
                    <div class="md:block hidden  mb-[45px] relative">
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#CCDFFF] border border-[#1A71FF] rounded-[32px] mr-1">
                        <span class="text-[#1a71ff] text-center w-full">1</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#1a71ff] flex items-center text-center w-full absolute left-[40px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#f3f3f3] border border-[#f3f3f3] rounded-[32px] mr-1 absolute md:left-[208px] left-[120px] top-[0px]">
                        <span class="text-[#595959] text-center w-full">2</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#e1e1e1] flex items-center text-center w-full absolute md:left-[248px] left-[160px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#f3f3f3] border border-[#f3f3f3] rounded-[32px] mr-1 absolute md:left-[417px] left-[240px] top-[0px]">
                        <span class="text-[#595959] text-center w-full">3</span>
                      </div>
                    </div>
                    <div class="w-full mb-7">
                      <div
                        class="relative"
                        ref={selectRef}
                        onClick={showCheckBoxes}
                      >
                        <span class="laBel text-[#595959]">Select class</span>
                        <select class="form-select block w-full mt-1 p-4 mb-1 text-sm text-[#8d8d8d] border border-[#f7f7f7] rounded-lg bg-[#f7f7f7] appearance-none ">
                          <option>Select class</option>
                        </select>
                        <svg
                          width="15"
                          height="8"
                          fill="none"
                          class="w-4 h-4 absolute left-[91%] bottom-[18%] pointer-events-none block"
                        >
                          <path
                            d="M13.71.296a1 1 0 0 0-1.42 0l-4.58 4.58a1 1 0 0 1-1.42 0L1.71.296a1 1 0 1 0-1.42 1.41l4.59 4.59a3 3 0 0 0 4.24 0l4.59-4.59a1 1 0 0 0 0-1.41Z"
                            fill="#8D8D8D"
                          />
                        </svg>
                      </div>
                      {showCheckBox ? (
                        <div
                          ref={selectRef}
                          class="shadow-lg bg-[#ffffff] rounded-lg pt-3 px-4 "
                        >
                          {classList.map((object) => (
                            <label key={object.classList} class="block pb-3.5">
                              <input
                                type="checkbox"
                                value={object.classList}
                                onChange={(event) =>
                                  handleChecked(event, object)
                                }
                              />
                              <span class="pl-2 font-normal text-sm">
                                {object.classList}
                              </span>
                            </label>
                          ))}
                          <button
                            onClick={openAddClassModal}
                            class="pb-4 items-center justify-center flex space-x-1 cursor-pointer "
                          >
                            <AddFeeIcon />
                            <span class=" text_14_400 text-[#ffffff] items-center justify-center flex">
                              Add class
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                    {selectedItems.map((list) => (
                      <div class="text-[#606060] rounded-lg py-3 px-4 flex justify-between bg-[#ececec] w-full mb-2">
                        <span key={list.classList} class="text-[#606060]">
                          {list.classList}
                        </span>
                        <span class="flex space-x-2">
                          <span key={list.category}>{list.category}</span>
                          <span
                            onClick={() => handleRemoveItem(list)}
                            class="cursor-pointer"
                          >
                            <BigCloseIcon />
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4 mt-[38px] border-t-2 border-[#c1c1c1] justify-end content-end sm:pr-14 pr-8">
                    <button onClick={openAddClassModal} class="white_modal_btn">
                      Back
                    </button>
                    <button
                      onClick={openFeesModal}
                      class="blue_modal_btn ml-4 "
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* MODAL TO FOR CLASS FEES */}
          {showSchoolFees3 ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <div class="flex flex-col">
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] mb-[38px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title">Create Payment link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8 overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div class="md:block hidden  mb-[45px] relative">
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#1a71ff] border border-[#1A71FF] rounded-[32px] mr-1">
                        <span class="text-[#ffffff] text-center w-full">1</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#1a71ff] flex items-center text-center w-full absolute left-[40px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#ccdfff] border border-[#1a71ff] rounded-[32px] mr-1 absolute md:left-[208px] left-[120px] top-[0px]">
                        <span class="text-[#1a71ff] text-center w-full">2</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#e1e1e1] flex items-center text-center w-full absolute md:left-[248px] left-[160px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#f3f3f3] border border-[#f3f3f3] rounded-[32px] mr-1 absolute md:left-[417px] left-[240px] top-[0px]">
                        <span class="text-[#595959] text-center w-full">3</span>
                      </div>
                    </div>
                    <div>
                      <div>
                        {isQueryingFee ? (
                          <Spinner color={"#1a71ff"} />
                        ) : (
                          <button
                            onClick={openClassFeeModal}
                            class="py-3 items-center justify-center flex space-x-2 full_blue_btn mb-72"
                          >
                            <AddFeeIcon />
                            <span class="items-center justify-center flex text_14_400 text-[#ffffff]">
                              Add fee
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4 mt-[38px] border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button onClick={openSchoolModal} class="white_modal_btn">
                      Back
                    </button>

                    <button
                      onClick={openClassFeeModal}
                      class="blue_modal_btn ml-4 "
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* MODAL TO ADD CLASS FEES */}
          {showSchoolFees4 ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <div class="flex flex-col">
                  <form
                    onSubmit={handleSubmit(onSubmitHandler)}
                    class="flex flex-col"
                  >
                    {/* HEADER */}
                    <div class="flex pt-[20px] pb-[16px] mb-[10px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                      <h4 class="modal_title">Add fee</h4>
                      <span
                        onClick={closeModal}
                        class="absolute top-[38%] right-[9.8%] cursor-pointer"
                      >
                        <CloseModalIcon />
                      </span>
                    </div>
                    {/* BODY */}
                    <div class="sm:px-14 px-8 py-[30px]  overflow-y-auto max-h-[660px] min-h-[450px]">
                      {fields.map((item, index) => {
                        return (
                          <div key={item.id}>
                            <div class="mb-7">
                              <label class="laBel justify-between flex">
                                <span>Fee name</span>

                                {index !== 0 && (
                                  <span
                                    onClick={() => {
                                      remove(index);
                                      resetField(`fieldFee.${index}`);
                                    }}
                                    class="cursor-pointer hover:text-[red]"
                                  >
                                    Clear
                                  </span>
                                )}
                              </label>
                              <input
                                type="text"
                                class={`in_Put ${
                                  errors.fieldFee?.[index]?.feeName &&
                                  "inPut_error"
                                }`}
                                placeholder="E.g Transport levy"
                                {...register(`fieldFee.${index}.feeName`, {
                                  required: true,
                                })}
                              />
                              <p class="hidden mt-1 text-sm text-red-600 dark:text-red-500">
                                Enter fee name
                              </p>
                            </div>

                            <label class="laBel mb-2">Fee amount</label>
                            <div class="flex mb-7">
                              <span class="half_label">NGN</span>
                              <input
                                type="number"
                                class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                                  errors.fieldFee?.[index]?.feeAmount &&
                                  "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[red] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[red] border-2 outline-none "
                                }`}
                                placeholder="Eg. 5,000"
                                {...register(`fieldFee.${index}.feeAmount`, {
                                  required: true,
                                })}
                              />
                            </div>

                            <div class="relative mb-7">
                              <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                                Class fee apply
                              </span>

                              <Controller
                                name={`classFeeApplied.${index}`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => {
                                  return (
                                    <Select
                                      {...field}
                                      options={selectedClassOption}
                                      placeholder="Select class"
                                      isMulti
                                      name="classes"
                                      className="basic-multi-select"
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: "#f7f7f7",
                                          borderWidth: "2px",
                                          borderRadius: "8px",
                                          minHeight: "52px",
                                        }),
                                      }}
                                    />
                                  );
                                }}
                              />
                              <p style={{ color: "red" }}>
                                {errors.hobbies?.message}
                              </p>
                            </div>

                            <div class="col-span-full mb-7">
                              <label for="about" class="laBel">
                                Fee Description ( Optional )
                              </label>
                              <div class="mt-2">
                                <textarea
                                  rows="3"
                                  class="block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff] "
                                  placeholder="Type something"
                                  {...register(
                                    `fieldFee.${index}.feeDescription`
                                  )}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <button
                        type="button"
                        onClick={() =>
                          append({
                            feeName: "",
                            feeAmount: "",
                            feeDescription: "",
                          })
                        }
                        class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                      >
                        <AddFeeIcon />
                        <span class="items-center justify-center flex text_14_400 text-[#ffffff]">
                          Add another fee
                        </span>
                      </button>
                    </div>

                    {/* FOOTER */}
                    <div class="flex py-4   border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                      <button
                        onClick={openSelectSchoolFeeModal}
                        class="white_modal_btn"
                      >
                        Back
                      </button>
                      <button
                        //onClick={openSelectSchoolFeeModal}
                        type="submit"
                        class="blue_modal_btn ml-4 "
                      >
                        {isCreatingFee ? <SpinnerWhite /> : "Add"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : null}

          {/* MODAL TO SELECT FEES */}
          {showSchoolFees5 ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <div class="flex flex-col">
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title">Create Payment link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8  overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div class="md:block mb-[45px] mt-[38px] relative ">
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#1a71ff] border border-[#1A71FF] rounded-[32px] mr-1">
                        <span class="text-[#ffffff] text-center w-full">1</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#1a71ff] flex items-center text-center w-full absolute left-[40px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#ccdfff] border border-[#1a71ff] rounded-[32px] mr-1 absolute md:left-[208px] left-[120px] top-[0px]">
                        <span class="text-[#1a71ff] text-center w-full">2</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#e1e1e1] flex items-center text-center w-full absolute md:left-[248px] left-[160px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#f3f3f3] border border-[#f3f3f3] rounded-[32px] mr-1 absolute md:left-[417px] left-[240px] top-[0px]">
                        <span class="text-[#595959] text-center w-full">3</span>
                      </div>
                    </div>

                    <div class="py-4 ">
                      <div class="col-span-full"></div>
                      <div class="w-full mb-7">
                        <div
                          class="relative"
                          ref={selectRef}
                          //onClick={showCheckBoxes}
                        >
                          <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                            Select fee
                          </span>

                          <div class=" text-sm text-[#8d8d8d] border border-[#f7f7f7] rounded-lg bg-[#f7f7f7]">
                            <div className="App">
                              <Select
                                value={selectedFeeOption}
                                onChange={setSelectedFeeOption}
                                options={feeOptions}
                                placeholder="Select fee"
                                isMulti
                                name="fees"
                                className="basic-multi-select"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "#f7f7f7",
                                    borderWidth: "2px",
                                    borderRadius: "8px",
                                    minHeight: "52px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {selectedFeeOption?.map((list, index) => (
                        <div
                          key={index}
                          class="text-[#606060] rounded-lg py-3 px-4 flex justify-between bg-[#ececec] w-full mb-2"
                        >
                          <span class="text-[#606060]">{list.label}</span>
                          <span class="flex space-x-2">
                            <span class="">NGN {list.amount}</span>
                          </span>
                        </div>
                      ))}

                      <div class="py-8">
                        <button
                          onClick={openClassFeeModal}
                          class="py-3 items-center justify-center flex space-x-2 full_blue_btn"
                        >
                          <AddFeeIcon />
                          <span class="items-center justify-center flex text_14_400 text-[#ffffff]">
                            Add new fee
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4 border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button onClick={openSchoolModal} class="white_modal_btn">
                      Back
                    </button>
                    <button
                      disabled={selectedFeeOption?.length === 0}
                      onClick={() => {
                        openSchoolEndModal();
                      }}
                      class="blue_modal_btn ml-4 "
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* MODAL TO CREATE SCHOOL LINK */}
          {showSchoolFees6 ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <form
                  onSubmit={handleSubmit(onSubmitHandler)}
                  class="flex flex-col"
                >
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title">Create payment link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8 overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div class="md:block mb-[45px] mt-[38px] relative">
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#1a71ff] border border-[#1A71FF] rounded-[32px] mr-1">
                        <span class="text-[#ffffff] text-center w-full">1</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#1a71ff] flex items-center text-center w-full absolute left-[40px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#1a71ff] border border-[#1a71ff] rounded-[32px] mr-1 absolute md:left-[208px] left-[120px] top-[0px]">
                        <span class="text-[#ffffff] text-center w-full">2</span>
                      </div>
                      <div
                        class="rounded-full md:max-w-[160px] max-w-[70px] bg-[#1a71ff] flex items-center text-center w-full absolute md:left-[248px] left-[160px] top-[14px] "
                        style={{ height: "3px", width: "50%" }}
                      ></div>
                      <div class="inline-flex items-center w-[32px] h-[32px] bg-[#ccdfff] border border-[#1a71ff] rounded-[32px] mr-1 absolute md:left-[417px] left-[240px] top-[0px]">
                        <span class="text-[#1a71ff] text-center w-full">3</span>
                      </div>
                    </div>

                    <div>
                      <div class="mb-7">
                        <label class="laBel">Page title</label>
                        <input
                          type="text"
                          class={`in_Put ${
                            errors.fieldSchoolLink?.pageTitle && "inPut_error"
                          }`}
                          placeholder="E.g Payment"
                          {...register(`fieldSchoolLink.pageTitle`, {
                            required: "Page title is required",
                          })}
                        />
                        {errors.fieldSchoolLink?.pageTitle && (
                          <p class=" mt-1 text-sm text-[red]">
                            {errors.fieldSchoolLink?.pageTitle?.message}
                          </p>
                        )}
                      </div>
                      <div class="col-span-full">
                        <label for="about" class="laBel">
                          Page Description
                        </label>
                        <div class="mt-2">
                          <textarea
                            rows="3"
                            class={`"block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff]" ${
                              errors.fieldSchoolLink?.description &&
                              "block w-full h-[87px] rounded-lg border-[red] outline-none border-[2px] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[red]"
                            }`}
                            placeholder="Give customers more details of what they are paying for"
                            {...register(`fieldSchoolLink.description`, {
                              required: "Description is required",
                            })}
                          ></textarea>
                          {errors.fieldSchoolLink?.description && (
                            <p class=" mt-1 text-sm text-[red]">
                              {errors.fieldSchoolLink?.description?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="col-span-full">
                        <label class="laBel mt-7">Add image</label>
                        <div
                          onClick={() => {
                            uploadimage();
                          }}
                          class="mt-2 flex bg-[#f7f7f7] cursor-pointer justify-center rounded-lg border border-dashed border-[#7b7b7b] px-1 py-1 h-[85px]"
                        >
                          <div class="text-center">
                            {isImageUploading ? (
                              <div class="mt-2 justify-center py-4">
                                <Spinner />
                              </div>
                            ) : (
                              <>
                                <div class="mt-4 flex text-sm leading-6 ">
                                  <label class="mx-auto relative cursor-pointer rounded-md font-medium text-[#000000]">
                                    <span>Choose file</span>
                                    <input
                                      id="upload"
                                      type="file"
                                      ref={uploadRef}
                                      onChange={handleFileUpload}
                                      class="sr-only"
                                    />
                                  </label>
                                </div>
                                <p class="text-[10px] leading-5 text-[#adadad]">
                                  {imageName === "" ? (
                                    <>(JPG, JPEG, PNG, SVG, 1MB max)</>
                                  ) : (
                                    <>{imageName}</>
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="mt-7">
                        <div class="flex items-center pt-3">
                          <input
                            type="checkbox"
                            class="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                            {...register(`fieldSchoolLink.collectPhoneNum`)}
                          />
                          <label class="block ml-2 text-sm text-[#595959]">
                            Collect customer phone number
                          </label>
                        </div>
                      </div>

                      <div class="mt-7">
                        <label class="block mb-2 text-sm font-normal text-[#595959] dark:text-[#595959]">
                          Do you want to collect any other information
                        </label>

                        {/* ADD MORE FIELDS */}
                        {fields.map((item, index) => {
                          return (
                            <div class="mt-5" key={item.id}>
                              <div class="flex relative">
                                <span class="half_label">Label Name</span>
                                <input
                                  type="text"
                                  class="rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]"
                                  placeholder="E.g Student name"
                                  {...register(
                                    `fieldSchoolLink.addInfo.${index}.labelName`
                                  )}
                                />

                                {index !== 0 && (
                                  <svg
                                    onClick={() => {
                                      remove(index);
                                      setAddInfoCount(addInfoCount - 1);
                                      resetField(
                                        `fieldSchoolLink.addInfo.${index}`
                                      );
                                    }}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5 absolute top-[30%] right-[4.5%] cursor-pointer "
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                )}
                              </div>
                              <div class="flex items-center pt-3">
                                <input
                                  type="checkbox"
                                  class="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                                  {...register(
                                    `fieldSchoolLink.addInfo.${index}.checkOptional`
                                  )}
                                />
                                <label class="block ml-2 text-sm text-[#595959]">
                                  Mark as optional
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {
                        <button
                          type="button"
                          disabled={addInfoCount >= 4}
                          onClick={() => {
                            append({ labelName: "", checkOptional: "" });
                            setAddInfoCount(addInfoCount + 1);
                          }}
                          class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                        >
                          <AddFeeIcon />
                          <span class="items-center justify-center flex text_14_400 text-[#ffffff]">
                            Add another field
                          </span>
                        </button>
                      }

                      <button
                        type="button"
                        onClick={() => {
                          setShow(!show);

                          if (show) {
                            closeMoreDetails();
                          } else {
                            openMoreDetails();
                          }
                        }}
                      >
                        {show ? (
                          <span class="flex space-x-4">
                            <h4 class="text-sm text-[#1a71ff] font-medium ">
                              Hide advanced options
                            </h4>
                            <span
                              onClick={() => {
                                closeMoreDetails();
                              }}
                            >
                              <FeeArrowUpIcon />
                            </span>
                          </span>
                        ) : (
                          <span class="flex space-x-4">
                            <h4 class="text-sm text-[#1a71ff] font-medium ">
                              Show advanced options
                            </h4>
                            <span onClick={openMoreDetails}>
                              <FeeArrowDownIcon />
                            </span>
                          </span>
                        )}
                      </button>

                      {showMoreDetails ? (
                        <>
                          <div class="mt-7">
                            <label class="block mb-2 text-sm font-normal text-[#595959] dark:text-[#595959]">
                              Custom link
                            </label>
                            <div class="flex">
                              <span class="half_label">
                                https://pay.deempay.com/
                              </span>
                              <input
                                type="text"
                                class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                                  errors.fieldSchoolLink?.customLinkCode &&
                                  "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[red] focus:border-[red] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[1a71ff] border-[red] outline-none border-[2px]"
                                }`}
                                placeholder="Your custom link"
                                {...register(`fieldSchoolLink.customLinkCode`, {
                                  minLength: {
                                    value: 6,
                                    message:
                                      "Characters should be upto 6 in numbers",
                                  },
                                })}
                              />
                            </div>
                            {errors.fieldSchoolLink?.customLinkCode && (
                              <p class=" mt-1 text-sm text-[red]">
                                {
                                  errors.fieldSchoolLink?.customLinkCode
                                    ?.message
                                }
                              </p>
                            )}
                          </div>

                          <div class="mb-7 mt-7">
                            <label class="laBel">Redirect after payment</label>
                            <input
                              type="url"
                              class="in_Put"
                              placeholder="https://redirect.link"
                              {...register(`fieldSchoolLink.redirect`)}
                            />
                          </div>
                          <div class="mb-7">
                            <label class="laBel">Message after payment</label>
                            <input
                              type="text"
                              class="in_Put"
                              placeholder="Message to show after payment"
                              {...register(`fieldSchoolLink.afterPaymentMsg`)}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button
                      onClick={openSelectSchoolFeeModal}
                      class="white_modal_btn"
                    >
                      Back
                    </button>
                    <button type="submit" class="blue_modal_btn ml-4 ">
                      {isCreatingPayLink ? <SpinnerWhite /> : "Create"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
          {/* </form> */}

          {/* MODAL FOR DONATION */}
          {showDonation ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <form
                  onSubmit={handleSubmit(onSubmitDonationleHandler)}
                  class="flex flex-col"
                >
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title">Create donation link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8 py-8 overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div class="mb-7">
                      <label class="laBel">Page title</label>
                      <input
                        type="text"
                        class={`in_Put ${
                          errors.fieldDonateLink?.pageTitle && "inPut_error"
                        }`}
                        placeholder="E.g Payment"
                        {...register(`fieldDonateLink.pageTitle`, {
                          required: "Page title is required",
                        })}
                      />
                      {errors.fieldDonateLink?.pageTitle && (
                        <p class=" mt-1 text-sm text-[red]">
                          {errors.fieldDonateLink?.pageTitle?.message}
                        </p>
                      )}
                    </div>
                    <div class="col-span-full">
                      <label for="about" class="laBel">
                        Page Description
                      </label>
                      <div class="mt-2">
                        <textarea
                          rows="3"
                          class={`"block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff]" ${
                            errors.fieldDonateLink?.description &&
                            "block w-full h-[87px] rounded-lg border-[red] outline-none border-[2px] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[red]"
                          }`}
                          placeholder="Give customers more details of what they are paying for"
                          {...register(`fieldDonateLink.description`, {
                            required: "Description is required",
                          })}
                        ></textarea>
                        {errors.fieldDonateLink?.description && (
                          <p class=" mt-1 text-sm text-[red]">
                            {errors.fieldDonateLink?.description?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div class="col-span-full">
                      <label class="laBel mt-7">Add image</label>
                      <div
                        onClick={() => {
                          uploadimage();
                        }}
                        class="mt-2 flex bg-[#f7f7f7] cursor-pointer justify-center rounded-lg border border-dashed border-[#7b7b7b] px-1 py-1 h-[85px]"
                      >
                        <div class="text-center">
                          {isImageUploading ? (
                            <div class="mt-2 justify-center py-4">
                              <Spinner />
                            </div>
                          ) : (
                            <>
                              <div class="mt-4 flex text-sm leading-6 ">
                                <label class="mx-auto relative cursor-pointer rounded-md font-medium text-[#000000]">
                                  <span>Choose file</span>
                                  <input
                                    id="upload"
                                    type="file"
                                    ref={uploadRef}
                                    onChange={handleFileUpload}
                                    class="sr-only"
                                  />
                                </label>
                              </div>
                              <p class="text-[10px] leading-5 text-[#adadad]">
                                {imageName === "" ? (
                                  <>(JPG, JPEG, PNG, SVG, 1MB max)</>
                                ) : (
                                  <>{imageName}</>
                                )}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="mt-7">
                      {showInputAmountDonate ? (
                        <>
                          <label class="laBel mb-2">Amount</label>
                          <div class="flex">
                            <span class="half_label">NGN</span>
                            <input
                              type="number"
                              class={`" block flex-1 min-w-0 w-full text-sm rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] border-[none] px-4 sm:h-[56px] h-[46px] text-gray-900 ring-1 ring-inset ring-[#f7f7f7] " ${
                                errors.fieldDonateLink?.amount &&
                                "block flex-1 min-w-0 w-full text-sm rounded-none rounded-r-lg border-[red] outline-none border-[2px] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7] "
                              }`}
                              placeholder="5,000"
                              {...register(`fieldDonateLink.amount`, {
                                required: "Amount is required",
                              })}
                            />
                          </div>
                          {errors.fieldDonateLink?.amount && (
                            <p class=" mt-1 text-sm text-[red]">
                              {errors.fieldDonateLink?.amount.message}
                            </p>
                          )}
                        </>
                      ) : null}
                      <div class="flex items-center pt-3">
                        <input
                          type="checkbox"
                          class="sm:w-4 md:h-4 w-3 h-3 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                          {...register(`fieldDonateLink.custInputAmount`)}
                        />
                        <label class="block ml-2 text_14_400 text-[#595959]">
                          Let customers input amount
                        </label>
                      </div>
                      <div class="flex items-center pt-3">
                        <input
                          type="checkbox"
                          class="sm:w-4 sm:h-4 w-3 h-3 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                          {...register(`fieldDonateLink.collectPhoneNum`)}
                        />
                        <label class="block ml-2 text-sm text-[#595959]">
                          Collect customer phone number
                        </label>
                      </div>
                    </div>
                    <div class="mt-7">
                      <label class="block mb-2 laBel text-[#595959]">
                        Do you want to collect any other information
                      </label>
                      {/* ADD MORE FIELDS */}
                      {fields.map((item, index) => {
                        return (
                          <div class="mt-5" key={item.id}>
                            <div class="flex relative">
                              <span class="half_label">Label Name</span>
                              <input
                                type="text"
                                class="rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm  px-4 sm:h-[56px] h-[46px]  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]"
                                placeholder="E.g Student name"
                                {...register(
                                  `fieldDonateLink.addInfo.${index}.labelName`
                                )}
                              />
                              {index !== 0 && (
                                <svg
                                  onClick={() => {
                                    remove(index);
                                    setAddInfoCount(addInfoCount - 1);
                                    resetField(
                                      `fieldDonateLink.addInfo.${index}`
                                    );
                                  }}
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-5 h-5 absolute top-[30%] right-[4.5%] cursor-pointer "
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              )}
                            </div>
                            <div class="flex items-center pt-3">
                              <input
                                type="checkbox"
                                class="sm:w-4 sm:h-4 w-3 h-3  text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                                {...register(
                                  `fieldDonateLink.addInfo.${index}.checkOptional`
                                )}
                              />
                              <label class="block ml-2 text_14_400 text-[#595959]">
                                Mark as optional
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      disabled={addInfoCount >= 4}
                      type="button"
                      onClick={() => {
                        append({ labelName: "", checkOptional: "" });
                        setAddInfoCount(addInfoCount + 1);
                      }}
                      class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                    >
                      <AddFeeIcon />
                      <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                        Add another field
                      </span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShow(!show);

                        if (show) {
                          closeMoreDetails();
                        } else {
                          openMoreDetails();
                        }
                      }}
                    >
                      {show ? (
                        <span class="flex space-x-4">
                          <h4 class="text-sm text-[#1a71ff] font-medium ">
                            Hide advanced options
                          </h4>
                          <span
                            onClick={() => {
                              closeMoreDetails();
                            }}
                          >
                            <FeeArrowUpIcon />
                          </span>
                        </span>
                      ) : (
                        <span class="flex space-x-4">
                          <h4 class="text-sm text-[#1a71ff] font-medium ">
                            Show advanced options
                          </h4>
                          <span onClick={openMoreDetails}>
                            <FeeArrowDownIcon />
                          </span>
                        </span>
                      )}
                    </button>
                    {showMoreDetails ? (
                      <>
                        <div class="mt-7">
                          <label class="block mb-2 text_14_400 text-[#595959]">
                            Custom link
                          </label>
                          <div class="flex">
                            <span class="half_label">
                              https://pay.deempay.com/
                            </span>
                            <input
                              type="text"
                              class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm  px-4 sm:h-[56px] h-[46px]  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                                errors.fieldDonateLink?.customLinkCode &&
                                "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[red] focus:border-[red] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[1a71ff] border-[red] outline-none border-[2px]"
                              }`}
                              placeholder="Your custom link"
                              {...register(`fieldDonateLink.customLinkCode`, {
                                minLength: {
                                  value: 6,
                                  message:
                                    "Characters should be upto 6 in numbers",
                                },
                              })}
                            />
                          </div>
                          {errors.fieldDonateLink?.customLinkCode && (
                            <p class=" mt-1 text-sm text-[red]">
                              {errors.fieldDonateLink?.customLinkCode?.message}
                            </p>
                          )}
                        </div>

                        <div class="mb-7 mt-7">
                          <label class="laBel">Redirect after payment</label>
                          <input
                            type="url"
                            class="in_Put"
                            placeholder="https://redirect.link"
                            {...register(`fieldDonateLink.redirect`)}
                          />
                        </div>
                        <div class="mb-7">
                          <label class="laBel">Message after payment</label>
                          <input
                            type="text"
                            class="in_Put"
                            placeholder="Message to show after payment"
                            {...register(`fieldDonateLink.afterPaymentMsg`)}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button onClick={startSchoolModal} class="white_modal_btn">
                      Back
                    </button>
                    <button type="submit" class="blue_modal_btn ml-4 ">
                      {isCreatingPayLink ? <SpinnerWhite /> : "Create"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
        </main>
      </header>
    </SupervisorLayout>
  );
};
