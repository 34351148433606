import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdKeyboardArrowDown } from "react-icons/md";
import Layout from "../../components/layout/Layout";
import { OnboardingList } from "../../components/onboarding-list/OnboardingList";
import { banks, bankList } from "../../utils/constants/banks";
import { useValidateBankAccount } from "../../hooks/useBank";
import storage from "../../utils/storage";
import { Notify } from "../../components/notification";
import { SpinnerMedium } from "../../components/spinner/Spinner";

import "./styles.scss";

export const BankDetailsPage = () => {
  const history = useHistory();
  const [accountDetails, setAccountDetails] = useState({});
  const [isBankChecked, setIsBankChecked] = useState(false);

  const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));
  if (onboardingDetails.onboarded) {
    history.push("/dashboard/merchant/overview");
  }

  const [bankForm, setBankForm] = useState(0);

  const filledBankslInfo = JSON.parse(storage.fetch("bankDetails"));
  useEffect(() => {
    if (filledBankslInfo) {
      banks.forEach(function (value, key) {
        if (value === filledBankslInfo.bank) {
          setBankForm(key);
          setValue("bankId", key);
          setValue("bankAccount", filledBankslInfo.accountNo);
          setValue("accountName", filledBankslInfo.accountName);
        }
      });
    }
  }, []);

  // Form Validation
  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const bankAccount = watch("bankAccount");
  const bankId = watch("bankId");

  const {
    isLoading: isValidating,
    data: validateData,
    mutate: fetchBankDetails,
  } = useValidateBankAccount({
    bankId: banks.get(bankId),
    bankAccount,
  });

  useEffect(() => {
    if (bankAccount?.length === 10) {
      fetchBankDetails();
    } else {
      setAccountDetails({});
    }
  }, [bankAccount]);

  useEffect(() => {
    resetField("accountName");
    setAccountDetails({});
  }, [bankId]);

  useEffect(() => {
    if (validateData?.code === "000000") {
      setAccountDetails(validateData?.data);
      setIsBankChecked(true);
    } else if (validateData?.code === "CH0003") {
      resetField("bankAccount");
      resetField("accountName");
      setAccountDetails({});
      Notify(
        "error",
        "Invalid bank account!",
        "Please check your bank account number and your selected bank to make sure they are both correct.",
        10
      );
    }
  }, [validateData]);

  const onSubmitHandler = (data) => {
    const payload = {
      bank: banks.get(bankId),
      accountName: accountDetails.account_name,
      accountNo: bankAccount,
    };

    if (isBankChecked && accountDetails.account_name) {
      storage.add("bankDetails", JSON.stringify(payload));
      history.push("/dashboard/merchant/onboarding/document-upload");
    } else {
      fetchBankDetails();
    }
  };

  return (
    <>
      <Layout>
        <header>
          <main className="bank-main">
            <div className="row">
              <div className="column">
                <OnboardingList />
              </div>
              <div className="column">
                <div className="account-wrap">
                  <h3>Bank Details</h3>
                  <p>
                    Please provide your bank details. This is required to settle
                    your payment to your bank account. Your account name must
                    match your first and your last name.
                  </p>
                  <form
                    onSubmit={handleSubmit(onSubmitHandler)}
                    className="form__btm"
                  >
                    <label>Bank</label>
                    <div className="business-option">
                      <select
                        className={`bankInput ${errors.bank && "invalid"}`}
                        type="text"
                        placeholder="Bank"
                        {...register("bankId", {
                          required: "Bank number is required",
                        })}
                      >
                        <option selected="Select bank">Select bank</option>
                        {bankList.map((bankId, i) => (
                          <option
                            key={i}
                            selected={bankId === bankForm && "selected"}
                          >
                            {bankId}
                          </option>
                        ))}
                      </select>
                      <MdKeyboardArrowDown className="business-down" />
                      {errors.bank && (
                        <section className="error__txtbtm">
                          {errors.bank.message}
                        </section>
                      )}
                    </div>

                    <label>Account Number</label>
                    <input
                      className={`bankInput ${errors.bankAccount && "invalid"}`}
                      type="number"
                      placeholder="Enter account number"
                      {...register("bankAccount", {
                        required: "Account number is required",
                        minLength: {
                          value: 10,
                          message: "minimum allowed number is 10",
                        },
                        maxLength: {
                          value: 10,
                          message: "maximum allowed number is 10",
                        },
                      })}
                    />
                    {errors.bankAccount && (
                      <section className="error__txtbtm">
                        {errors.bankAccount.message}
                      </section>
                    )}
                    <label>Account Name</label>
                    <input
                      className="spinner_input"
                      type="name"
                      placeholder={
                        accountDetails.account_name
                          ? accountDetails.account_name
                          : "Enter account name"
                      }
                      disabled={true}
                      {...register("accountName")}
                    />
                    {isValidating ? (
                      <>
                        <span className="acc-Name">
                          <span className="spinner_input">
                            <SpinnerMedium className="spinner_input" />
                          </span>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    <button type="submit">Next</button>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </header>
      </Layout>
    </>
  );
};
