import {
  HomeIcon,
  CollectPaymentIcon,
  SubAccountIcon,
  TransactionIcon,
  SettingsIcon,
} from "../../../assests/icons/Icons";

export const merchantLinks = [
  {
    name: "Overview",
    route: "/dashboard/manager/overview",
    icon: <HomeIcon />,
    subLinks: null,
    keyWord: "overview",
  },
  {
    name: "Receive payment",
    route: "/dashboard/manager/accept-payment",
    icon: <CollectPaymentIcon />,
    subLinks: null,
    keyWord: "accept-payment",
  },
  {
    name: "Transactions",
    route: "/dashboard/manager/transactions",
    icon: <TransactionIcon />,
    subLinks: null,
    keyWord: "transactions",
  },
  {
    name: "Retail sub-accounts",
    route: "/dashboard/manager/sub-accounts",
    icon: <SubAccountIcon />,
    subLinks: null,
    keyWord: "sub-accounts",
  },
  {
    name: "Settings",
    route: "/dashboard/manager/settings",
    icon: <SettingsIcon />,
    subLinks: null,
    keyWord: "settings",
  },
];
