import React, { useState, useEffect } from "react";
import { Notify } from "../components/notification";

const NoInternetConnection = (props) => {
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else if(!isOnline) {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    return props.children;
  }
};

export default NoInternetConnection;
