import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Close } from "../../../assests/svgs";
import { Spinner } from "../../spinner";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import storage from "../../../utils/storage";
import useRegister from "../../../hooks/useRegister";
import usePasswordReset from "../../../hooks/usePasswordReset";
import { VerifyPhoneIcon } from "../../../assests/icons/Icons";
import { formatPhoneNo } from "../../../utils/functions";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

import "./styles.scss";

function AccountModal({ showModal, setShowModal }) {
  const phoneNo = JSON.parse(storage.fetch("userDetails")).phoneNo;
  const [password, setPassword] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [isValidCodeChecked, setIsValidCodeChecked] = useState(true);
  const [apiResponseError, setApiResponseError] = useState("");
  const [show, setShow] = useState(false);
  const [isCounterDone, setIsCounterDone] = useState(true);
  const [isResendDone, setIsResendDone] = useState(false);
  var [minutes, setMinutes] = useState("1");
  var [seconds, setSeconds] = useState("00");
  var [inval, setInval] = useState("");
  const history = useHistory();

  //Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const {
    isLoading: isSendingCode,
    data: validData,
    mutate: sendCode,
  } = useRegister({
    phoneNo,
    templateId: "M1001",
  });

  const validCode = watch("validCode");

  const {
    isLoading: isReseting,
    data,
    mutate: resetPassword,
  } = usePasswordReset({
    phoneNo,
    validCode,
    password,
  });

  useEffect(() => {

    if(showModal){

      clearInterval(inval);
      setIsPassword(false);
      setIsCounterDone(true);
      setIsResendDone(false);
      startCounter();
    }
  }, [showModal]);

  const confirmValidCode = () => {
    if (isValidCodeChecked) {
      if (validCode?.length === 6) {
        setIsValidCodeChecked(false);
        setIsPassword(true);
      }
    }
  };

  const handleCode = () => {
    setShowModal(false);
    setIsPassword(false);
    clearInterval(inval)

    const role = JSON.parse(storage.fetch("role")).roleId;
      if (role === "1"){
        history.replace("/dashboard/merchant/settings")
      }else if(role === "2"){
        history.replace("/dashboard/manager/settings")
      }else if(role === "3"){
        history.replace("/dashboard/cashier/settings")
      }
  };

  useEffect(() => {
    if (validData?.code === "000000") {
    } else {
    }
  }, [validData]);

  useEffect(() => {
    if (data?.code === "000000") {
      //history.go();
      setShowModal(false);
      clearInterval(inval)
      setShowModal( false);
      Modal.success({
        title: "Congratulations!",
        content: "You have successfully reset your password",
        onOk: () => handleCode(),
      });
      reset();
    } else if (data?.code === "100002") {
      setShowModal(false);
      clearInterval(inval)
      Modal.error({
        title: "Password reset failed",
        content: `Sorry! ${data?.message}`,
        okText: "Retry",
        onOk: () => handleCode(),
      });
      reset();
    }
  }, [data]);

 

  const startCounter = () => {
    const startTiming = 1;
    let time = startTiming * 60;
    let _countIntvl = 0;
    const timerInterval = setInterval(function () {
      _countIntvl += 1;
      setInval(timerInterval);
      const minute_2 = Math.floor(time / 60);
      setMinutes(minute_2);
      let second_2 = time % 60;
      second_2 = second_2 < 10 ? "0" + second_2 : second_2;
      setSeconds(second_2);
      time--;
      if (second_2 === "00" && minute_2 === 0) {
        clearInterval(timerInterval);
        setIsCounterDone(false);
        setIsResendDone(true);
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  };

  const onSubmit = (data) => {
    setPassword(data.password);
    resetPassword();
    //setIsPassword(false);
  };

  const resendValidCode = () => {
    sendCode();
    setIsCounterDone(true);
    setIsResendDone(false);
    setIsValidCodeChecked(true);
    setIsPassword(false);
    startCounter();
    reset();
  };

  const cancleModal = () => {
    clearInterval(inval)
    setIsCounterDone(true);
    setIsResendDone(false);
    setIsValidCodeChecked(true);
    setIsPassword(false);
  };

  return (
    <>
      {showModal ? (
        <div className="backgd">
          <div className="wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              {isValidCodeChecked && (
                <>
                  <div className="header-part">
                    <span className="img">
                      <VerifyPhoneIcon />
                    </span>
                    <h2 className="h2">Verify your number</h2>
                    <h4>
                      {`Please enter the verification code sent to ${formatPhoneNo(
                        phoneNo
                      )} for
              confirmation`}
                    </h4>
                  </div>
                  <div className="body-part">
                    <label>Enter valid code</label>
                    <input
                      className={`verifyInput ${errors.validCode && "invalid"}`}
                      type="number"
                      placeholder="Enter code here"
                      onFocus={() => setApiResponseError("")}
                      {...register("validCode", {
                        required: "Verification code is required",
                        minLength: {
                          value: 6,
                          message: "minimum allowed valid code is 6",
                        },
                        maxLength: {
                          value: 6,
                          message: "minimum allowed valid code is 6",
                        },
                      })}
                    />
                    {errors.validCode && (
                      <p style={{ color: "red" }}>{errors.validCode.message}</p>
                    )}

                    <img
                      width="4%"
                      src={Close}
                      alt=""
                      onClick={() => setShowModal((prev) => !prev)}
                    />
                    {isCounterDone && (
                      <>
                        <p>
                        Your verification code will arrive within
                          <span className="timer">
                            {minutes}:{seconds} s.
                          </span>
                        </p>
                      </>
                    )}
                    <div>{apiResponseError}</div>
                    {isResendDone && (
                      <p className="para">
                        Your verification code will arrive within 0:00s
                        <span className="resend" onClick={resendValidCode}>
                          Resend.
                        </span>
                      </p>
                    )}
                    <button disabled={isSendingCode} onClick={confirmValidCode}>
                      {isSendingCode ? <Spinner /> : "Change Password"}
                    </button>
                  </div>
                </>
              )}
              {isPassword && (
                <>
                  <div className="header-part">
                    <h2>Create new password</h2>
                    <h6>
                      The password should contain upto 6 characters and a mix of
                      digit and letter.
                    </h6>
                  </div>
                  <div className="body-part">
                    <label>Password</label>
                    <div className="input">
                      <input
                        className={`passwordInput ${
                          errors.password && "invalid"
                        }`}
                        type={show ? "text" : "password"}
                        placeholder="Password"
                        {...register("password", {
                          required: "password is required",
                          pattern: {
                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z!@#\$%\^\&*\)\(+=._-\d]{6,}$/,
                            message:
                              "Your password should contain at least a number and a letter and minimum of 6 characters",
                          },
                        })}
                      />
                      {errors.password && (
                        <section>{errors.password.message}</section>
                      )}

                      <span onClick={() => setShow(!show)}>
                        {show ? (
                          <AiOutlineEyeInvisible className="input-eye" />
                        ) : (
                          <AiOutlineEye className="input-eye" />
                        )}
                      </span>
                    </div>
                    <img width="4%" src={Close} alt="" onClick={cancleModal} />
                    <button type="submit" disabled={isReseting}>
                      {isReseting ? <Spinner /> : "Reset password"}
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AccountModal;
