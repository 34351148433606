import React from "react";
import { useHistory } from "react-router-dom";

import { NotFound } from "../../assests/svgs";
import "./styles.scss";

export const NotFoundPage = () => {
  const history = useHistory();

  return (
    <div className="notfound__page">
      <h1>Oops! The page does not exist.</h1>
      <img src={NotFound} alt="" height="80%" width="80%" />
      <p onClick={() => history.goBack()} className="notfound-center">
        Visit our home page
      </p>
    </div>
  );
};
