import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import { addSpace, formatCurrency } from "../../../../utils/functions";
import storage from "../../../../utils/storage";
import { useQueryDynamicQR } from "../../../../hooks/useDynamicQR";
import { NIBSS } from "../../../../assests/svgs";
import { NQR, Logo } from "../../../../assests/images";
import { Modal } from "antd";
import { RiErrorWarningFill } from "react-icons/ri";

import "./styles.scss";
import SupervisorLayout from "../../../../components/layout/SupervisorLayout";

export const SupervisorDynamicPaymentPage = () => {
  const history = useHistory();
  const location = useLocation();
  const logoUrl = JSON.parse(storage.fetch("merchantDetails")).logoUrl;
  const virtualAccNo = JSON.parse(storage.fetch("cashierDetails")).virtualAccNo;
  const virtualBank = JSON.parse(storage.fetch("cashierDetails")).virtualBank;
  const userId = JSON.parse(storage.fetch("userDetails")).userId;
  const chlTermNo = JSON.parse(storage.fetch("role")).chlTermNo;
  const merchantName = JSON.parse(
    storage.fetch("merchantDetails")
  ).merchantName;
  const { qrCode } = useParams();
  const [amount, setAmount] = useState(0);
  let [orderNo, setOrderNo] = useState("");
  var [minutes, setMinutes] = useState("");
  var [seconds, setSeconds] = useState("");
  var [inval, setInval] = useState("");
  var [countIntvl, setCountIntvl] = useState(0);
  var [callApi, setCallApi] = useState(true);
  var [isModalShown, setIsModalShown] = useState(false);

  const handlePaymentSuccess = () => {
    history.goBack();
  };

  const { data, mutate: fetchQrStatus } = useQueryDynamicQR({
    userId,
    orderNo,
    terminalCode: chlTermNo,
  });

  useEffect(() => {
    const searchString = location.search.split("?")[1];
    const searchParams = searchString.split("&");
    const amount = searchParams[0].split("=")[1];
    orderNo = searchParams[1].split("=")[1];
    setAmount(Number(amount));
    setOrderNo(orderNo);
  }, []);

  useEffect(() => {
    setTimeout(function () {
      fetchQrStatus();
    }, 3000);
  }, []);

  useEffect(() => {
    if (data?.code === "000000") {
      if (data?.data.tradeStatus === "SUCCESS") {
        if (!isModalShown) {
          Modal.success({
            title: "Congratulations!",
            content: "Payment successful.",
            onOk: () => handlePaymentSuccess(),
          });
        }
        setIsModalShown(true);
        clearInterval(inval);
      } else {
        if (callApi) {
          fetchQrStatus();
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const startTiming = 5;
    let time = startTiming * 60;
    let _countIntvl = 0;
    const timerInterval = setInterval(function () {
      _countIntvl += 1;
      setCountIntvl(_countIntvl);
      setInval(timerInterval);
      const minute_2 = Math.floor(time / 60);
      setMinutes(minute_2);
      let second_2 = time % 60;
      second_2 = second_2 < 10 ? "0" + second_2 : second_2;
      setSeconds(second_2);

      if (_countIntvl % 10 === 0) {
        fetchQrStatus();
      }
      time--;
      if (second_2 === "00" && minute_2 === 0) {
        clearInterval(timerInterval);
        setCallApi(false);

        Modal.error({
          title: "QR code Expired!",
          content:
            "The QR code you generated have expired. You can generate a new one to accept payment",
          onOk: () => handlePaymentSuccess(),
        });
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  const qrcode = (
    <QRCode
      id="qrCodeId"
      size={230}
      value={qrCode}
      bgColor="white"
      fqColor="black"
      level="M"
      imageSettings={{
        src: logoUrl ? logoUrl : Logo,
        excavate: true,
        width: 40,
        height: 40,
      }}
    />
  );

  return (
    <SupervisorLayout>
      <header>
        <main className="main">
          <section>
            <form>
              <div className="bottom-line">
                <h3>{merchantName}</h3>
              </div>
              <h4>Amount to pay</h4>
              <h2 id="font_change">{formatCurrency(amount)}</h2>
              <card>
                <section>{qrcode}</section>
                <div className="nibss__images">
                  <img
                    src={NQR}
                    alt=""
                    className="nibss"
                    height="45%"
                    width="45%"
                  />
                  <img src={NIBSS} alt="" height="24%" width="24%" />
                </div>
              </card>
              <h5>Scan and pay with any bank app</h5>

              <span className="line--wrapper">
                <h6>OR</h6>
              </span>

              <div className="transferPart_dyns">
                <span className="payWith">
                  Pay into
                </span>
                <span className="accNo">
                {virtualAccNo === null ? "0000000000" : addSpace(virtualAccNo)}
                </span>
                <span className="wemaBank">
                {virtualBank === null ? "Wema Bank" : virtualBank}
                </span>
              </div>

              <div className="time-wrap_dyn">
                <h5>
                  <RiErrorWarningFill className="warningIcon" />
                  This QR code will expire in
                  <span className="timer">
                    <span>
                      {minutes}:{seconds}s.
                    </span>
                  </span>
                </h5>
              </div>
            </form>
          </section>
        </main>
      </header>
    </SupervisorLayout>
  );
};
