import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components/spinner";
import { SpinnerWhite, SpinnerMedium } from "../../components/spinner/Spinner";
import useRegister from "../../hooks/useRegister";
import storage from "../../utils/storage";
import useCheckPhone from "../../hooks/useCheckPhone";
import {
  ArrowDownIcon,
  CloseIcon,
  OpenIcon,
  DeemPayIcon,
} from "../../assests/icons/Icons";
import ScrollToTop from "../../helpers/ScrollToTop";
import { Modal } from "antd";

import "./styles.scss";

export const RegistrationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isPhoneNoChecked, setIsPhoneNoChecked] = useState(false);

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const phoneNo = watch("phoneNo");

  const {
    isLoading: isCheckingPhone,
    data: checkPhoneData,
    mutate: phoneCode,
  } = useCheckPhone({
    phoneNo,
  });

  const {
    isLoading: isRegistering,
    data,
    mutate: registerUser,
  } = useRegister({
    phoneNo,
    templateId: "M1001",
  });

  const existingUser = () => {
    storage.clear();
    history.push("/login");
  };

  useEffect(() => {
    if (phoneNo?.length === 11) {
      phoneCode();
    }
  }, [phoneNo]);

  useEffect(() => {
    if (checkPhoneData?.code === "000000") {
      setIsPhoneNoChecked(true);
      if (checkPhoneData?.data.registeredMerchant === "1") {
        Modal.success({
          title: "Phone number already registered!",
          content:
            "Login to access your dashboard or use a different number if you want to create a new account.",
          onOk: () => existingUser(),
        });
        reset();
      } else if (checkPhoneData?.data.registeredUser === "1") {
        Modal.success({
          title: "Phone number already registered!",
          content:
            "Login to access your dashboard and complete your onboarding process.",
          onOk: () => existingUser(),
        });
        reset();
      }
    }
  }, [checkPhoneData]);

  useEffect(() => {
    if (data?.code === "000000") {
      storage.add(
        "userRegistrationPayload",
        JSON.stringify({
          firstName,
          lastName,
          phoneNo,
          password,
        })
      );
      history.push("/verification", { from: location });
      reset();
    }
    // Handle API error
  }, [data]);

  const onSubmitHandler = (data) => {
    const { password, firstName, lastName } = data;
    setPassword(password);
    setFirstName(firstName);
    setLastName(lastName);

    if (isPhoneNoChecked) {
      registerUser();
    } else {
      phoneCode();
    }
  };

  return (
    <>
    <ScrollToTop />
      <div className="register">
        <div className="register-wrap">
          <div className="row">
            <div className="column">
              <div className="image-wrap">
                <div className="">
                  <DeemPayIcon />
                </div>
                <h2>Set up your account</h2>
                <p>Get up & running in no time</p>
              </div>
            </div>
            <div className="column">
              <div className="wrapper">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <label className="label-top">Country</label>
                  <div className="select-option">
                    <select type="text" placeholder="select" required>
                      <option>Nigeria</option>
                    </select>
                    <span className="select-down">
                      <ArrowDownIcon />
                    </span>
                  </div>
                  <div className="left-input">
                    <label>First Name</label>
                    <input
                      className={`registerInput ${
                        errors.firstName && "invalid top"
                      }`}
                      type="text"
                      placeholder="Enter First name"
                      {...register("firstName", {
                        required: "first name is required",
                      })}
                    />
                    {errors.firstName && (
                      <section className="error__txt">
                        {errors.firstName.message}
                      </section>
                    )}
                  </div>
                  <div className="right-input">
                    <label>Last Name</label>
                    <input
                      className={`registerInput ${
                        errors.lastName && "invalid top"
                      }`}
                      type="text"
                      placeholder="Enter Last name"
                      {...register("lastName", {
                        required: "last name is required",
                      })}
                    />
                    {errors.lastName && (
                      <section className="error__txt">
                        {errors.lastName.message}
                      </section>
                    )}
                  </div>

                  <label>Phone Number</label>
                  <input
                    className={`registerInput ${errors.phoneNo && "invalid"}`}
                    type="number"
                    autoComplete="new-password"
                    placeholder={
                      isCheckingPhone ? (
                        <SpinnerMedium />
                      ) : (
                        "e.g 08101010101"
                      )
                    }
                    {...register("phoneNo", {
                      required: "Phone number is required",
                      minLength: {
                        value: 11,
                        message: "minimum allowed number is 11",
                      },
                      maxLength: {
                        value: 11,
                        message: "maximum allowed number is 11",
                      },
                    })}
                  />
                  {errors.phoneNo && (
                    <section className="error__txt">
                      {errors.phoneNo.message}
                    </section>
                  )}

                  <label>Password</label>
                  <div className="input">
                    <input
                      className={`registerInput ${
                        errors.password && "invalid"
                      }`}
                      autoComplete="new-password" 
                      type={show ? "text" : "password"}
                      placeholder="Enter Password"
                      {...register("password", {
                        required: "password is required",
                        pattern: {
                          value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z!@#\$%\^\&*\)\(+=._-\d]{6,}$/,
                          message:
                            "Your password should contain at least a number and a letter and minimum of 6 characters",
                        },
                      })}
                    />
                    {errors.password && (
                      <section className="error__tt">
                        {errors.password.message}
                      </section>
                    )}

                    <span onClick={() => setShow(!show)}>
                      {show ? (
                        <span className="input-eye">
                          <OpenIcon />
                        </span>
                      ) : (
                        <span className="input-eye">
                          <CloseIcon />
                        </span>
                      )}
                    </span>
                  </div>
                  <button type="submit" disabled={isRegistering}>
                    {isRegistering ? <SpinnerWhite /> : "Create account"}
                  </button>
                  <div className="check-Box">
                    <input
                      className={`register__checkbox ${
                        errors.check && "invalid"
                      }`}
                      type="checkbox"
                      {...register("check", {
                        required:
                          "Please click the checkbox to agree to our Privacy Policies to continue",
                      })}
                    />
                    <p>
                      By clicking on the Create account, you have agreed to
                      DeemPay
                      <span className="privacy-shift">
                        <Link
                          target="_blank"
                          to="//www.deempay.com/privacy"
                          className="link"
                        >
                          privacy
                        </Link>
                      </span>
                      and
                      <span className="privacy-shift">
                        <Link
                          target="_blank"
                          to="//www.deempay.com/termsofservice"
                          className="link"
                        >
                          terms of services
                        </Link>
                      </span>
                    </p>
                  </div>
                  {errors.check && (
                    <section className="error__text">
                      {errors.check.message}
                    </section>
                  )}
                </form>
                <div className="register-create">
                  <p>
                    Existing user?
                    <span>
                      <Link to="/login" className="link">
                        Log In
                      </Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
