import React from "react";

import "./styles.scss";

export const SettlementDetailTable = ({ column, data }) => {
  return (
    <>
      <div id="table-container">
        <table className="transaction-tabs">
          <thead>
            {column.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </thead>
          <tbody>
            {data.map((transaction, i) => (
              <tr key={i}>
                <td>{transaction.customerName}</td>
                <td id="font_change" className="bolder__one">{transaction.grossAmount}</td>
                <td id="font_change" className="bolder__two">{transaction.fee}</td>
                <td id="font_change" className="bolder__one">{transaction.settledAmount}</td>
                <td>
                  <span className="transfer">
                    {transaction.paymentMethod}
                  </span>
                </td>
                <td>{transaction.date}</td>
                <td>{transaction.transactionReference}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
