import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Layout from "../../components/layout/Layout";
import { OnboardingList } from "../../components/onboarding-list/OnboardingList";
import storage from "../../utils/storage";
import { useMerchantDetail } from "../../hooks/useMerchant";
import { useUserQuery } from "../../hooks/useUser";
import { Notify } from "../../components/notification";
import file from "../../helpers/file";
import { useDocumentUpload } from "../../hooks/useFileUpload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles.scss";
import { SpinnerWhite } from "../../components/spinner/Spinner";

export const DocumentUploadPage = () => {
  const userId = JSON.parse(storage.fetch("userDetails")).userId;
  const history = useHistory();
  const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));
  if (onboardingDetails.onboarded) {
    history.push("/dashboard/merchant/overview");
  }
  const [payload, setPayload] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isRegisteredBusiness, setIsRegisteredBusiness] = useState(false);
  const [fileSuffix, setFileSuffix] = useState("");
  const [base64Avatar, setBase64Avatar] = useState("");

  const notify = () =>
    toast.success("Uploading File!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // Form Validation
  const { handleSubmit } = useForm();
  const {
    data,
    isLoading: isSubmitting,
    mutate: sendMerchantDetails,
  } = useMerchantDetail(payload);

  const {
    data: documentUploadData,
    isLoading: isUploadingDocument,
    mutate: uploadDocument,
  } = useDocumentUpload({
    userId,
    fileSuffix,
    base64Avatar,
  });

  const {
    isLoading: isFetching,
    data: userQuery,
    mutate: fetchUser,
  } = useUserQuery({});

  useEffect(() => {
    const cusType = JSON.parse(storage.fetch("businessDetails")).cusType;
    if (cusType === "2") {
      setIsRegisteredBusiness(true);
    }
  }, []);

  useEffect(() => {
    if (
      !!Object.keys(payload).length &&
      documentUploadData?.code === "000000" &&
      isRegisteredBusiness
    ) {
      sendMerchantDetails();
    } else if (!!Object.keys(payload).length && !isRegisteredBusiness) {
      sendMerchantDetails();
    }
  }, [payload, documentUploadData]);

  useEffect(() => {
    if (documentUploadData?.code === "000000") {
    } else if (documentUploadData?.code === "US0049") {
      Notify(
        "error",
        "Upload a file!",
        "Please upload your business registration certificate."
      );
    }
  }, [documentUploadData]);

  useEffect(() => {
    if (userQuery?.code === "000000") {
      history.push("/dashboard/merchant/overview");
      Notify(
        "success",
        "Onboarding completed!",
        "You have successfully completed your DeemPay onboarding"
      );

      storage.add(
        "userDetails",
        JSON.stringify({
          userId: userQuery?.data.userId,
          firstName: userQuery?.data.firstName,
          surname: userQuery?.data.surname,
          avatar: userQuery?.data.userHeader,
          phoneNo: userQuery?.data.phoneNo,
        })
      );
    }
  }, [userQuery]);

  useEffect(() => {
    if (data?.code === "000000") {
      fetchUser();
      storage.add(
        "onboardingDetails",
        JSON.stringify({
          merchant: true,
          cusId: userId,
          onboarded: true,
          singleRole: true,
        })
      );
    }
  }, [data]);

  const onSubmitHandler = (data) => {
    const businessDetails = JSON.parse(storage.fetch("businessDetails"));
    const personalInformation = JSON.parse(
      storage.fetch("personalInformation")
    );
    const bankDetails = JSON.parse(storage.fetch("bankDetails"));
    const userDetails = JSON.parse(storage.fetch("userDetails"));
    const payload = Object.assign(
      {
        userId: userDetails.userId,
        phoneNo: userDetails.phoneNo,
        contactName: personalInformation.legalName,
        bizAddress: businessDetails.regAddress,
        ...businessDetails,
      },
      personalInformation,
      bankDetails
    );
    setPayload(payload);
    if (base64Avatar && isRegisteredBusiness) {
      uploadDocument();
      document.getElementById("uploadText").style.color = "black";
    } else if (isRegisteredBusiness) {
      document.getElementById("uploadText").style.color = "red";
    }
  };

  const handleDocumentUpload = async (e) => {
    const document = e.target.files[0];
    const fileType = document.type.split("/");
    setFileSuffix(fileType[1]);
    const base64Avatar = await file.convertToBase64(document);
    setBase64Avatar(base64Avatar);

    setIsUploading(true);
  };

  useEffect(() => {
    if (isUploading === true) {
      notify();
    }
  }, [isUploading]);

  return (
    <>
      <Layout>
        <header>
          <main className="document-main">
            <div className="row">
              <div className="column">
                <OnboardingList />
              </div>

              <div className="column">
                <div className="account-wrap">
                  <h3>Document Upload</h3>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    {isUploading ? (
                      <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    ) : (
                      ""
                    )}

                    {isRegisteredBusiness && (
                      <>
                        <label id="uploadText" className="document-label">
                          Upload Business Registration Certificate
                        </label>
                        <div className="btn-Wrap">
                          <label className="btn" for="upload">
                            Choose file
                          </label>
                          <input
                            type="file"
                            id="upload"
                            placeholder="Choose file"
                            onChange={handleDocumentUpload}
                          />
                        </div>
                      </>
                    )}
                    {!isRegisteredBusiness && (
                      <label className="sole_text">
                        You have provided all onboarding details. Kindly click
                        submit to complete this process.
                      </label>
                    )}
                    <button
                      type="submit"
                      disabled={
                        isSubmitting || isFetching || isUploadingDocument
                      }
                    >
                      {isSubmitting || isFetching || isUploadingDocument ? (
                        <SpinnerWhite />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </header>
      </Layout>
    </>
  );
};
