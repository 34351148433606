import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu, Dropdown, List } from "antd";
//import { GoPrimitiveDot } from "react-icons/go";
import LogoutModal from "../modals/logoutmodal/LogoutModal";
import user from "../../helpers/user";
import { Avatar } from "../avatar";
//import { Notify2 } from "../../assests/svgs";
import storage from "../../utils/storage";
import { LogOutIcon } from "../../assests/icons/Icons";
import { onMessageListener } from "../../firebase";
import { Notify } from "../../components/notification";

import "./styles.scss";

const menu = (
  <Menu>
    <Menu.Item>
      <Link to={"/dashboard/merchant/transactions/20220508708014662792953856"}>
        <h3>Payment 1 received</h3>
        <p>You have received a payment...</p>
      </Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item>
      <Link to={"/dashboard/merchant/transactions/20220508708014662792953856"}>
        <h3>Payment 2 received</h3>
        <p>You have received a payment...</p>
      </Link>
    </Menu.Item>
  </Menu>
);

export const HeaderPage = () => {
  const [logoutModal, setLogoutModal] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const history = useHistory();

  const toggleLogoutModal = () => {
    setLogoutModal(!logoutModal);
  };

  onMessageListener().then(() => setIsNewNotification(true));

  const avatarUrl = JSON.parse(storage.fetch("userDetails")).avatar;
  
  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];

  const toSettingPage = () => {
    if (navigator.onLine) {
     history.push("/dashboard/merchant/settings");
     } else {
       Notify(
         "error",
         "Network Error!",
         "Please check your internet connection!",
         10
       );
     }
  }

  const profile = (
    <Menu className="top-menu">
      <Menu.Item className="top-items">
        <aside>
          {user.fullName()
            ? user.fullName()[0].toUpperCase() + user.fullName().substring(1)
            : "Hello"}
        </aside>
      </Menu.Item>
      <Menu.Divider className="divider" />
      <Menu.Item key="0" className="top-settings"
      onClick={(node, event) =>toSettingPage()}
      >
        <a>Profile</a>
      </Menu.Item>
      <Menu.Item key="1" className="top-support">
        <Link to={"//www.deempay.com/supportcenter"} target="_blank">
          Support
        </Link>
      </Menu.Item>
      <Menu.Divider className="divider" />
      <Menu.Item key="3" className="logout-hover">
        <section onClick={toggleLogoutModal}>
          <LogOutIcon className="top-Iconr" />
          <span>Sign out</span>
        </section>
      </Menu.Item>
    </Menu>
  );

  const notification = (
    <Menu className="top-menu">
      <Menu.Item className="top-items">
        <aside>{"Notifications"}</aside>
      </Menu.Item>
      <Menu.Divider className="divider" />
      <List
        size="large"
        dataSource={data}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
      <Menu.Divider className="divider" />
    </Menu>
  );

  return (
    <div className="header-top">
      <div className="top-bar">
        <h2 className="top-head">
          Welcome,{" "}
          <span className="userName">
            {user.firstName()
              ? user.firstName()[0].toUpperCase() +
                user.firstName().substring(1)
              : ""}
          </span>
        </h2>
        <div className="topbar">
          {/* <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
              <span>
                <span className="top-notify">
                  <img
                    src={Notify2}
                    alt=""
                    className="bell"
                    width="20"
                    height="20"
                  />
                  {isNewNotification && <GoPrimitiveDot className="dot" />}
                </span>
              </span>
            </a>
          </Dropdown> */}
          <Dropdown overlay={profile} trigger={["click"]}>
            <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Avatar imageUrl={avatarUrl} asLink />
            </span>
          </Dropdown>
        </div>
      </div>
      {logoutModal && <LogoutModal closeModal={toggleLogoutModal} />}
    </div>
  );
};


            
              