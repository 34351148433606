import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { feePaymentStatus } from "../../utils/functions";
import { Notify } from "../../components/notification";
import { CopyIcon, CopiedIcon } from "../../assests/icons/Icons";

import "./styles.scss";

export const FeeTable = ({ column, data, link }) => {
  const history = useHistory();
  const [copied, setCopied] = useState(true);
  const [copiedIndex, setCopiedIndex] = useState(0);

  async function handleCopy(linkUrl, index) {
    try {
      await navigator.clipboard.writeText(linkUrl);
      /* Resolved - text copied to clipboard successfully */    
      setCopied(false);
      setCopiedIndex(index)
      setTimeout(() => {
        setCopied(true);
        setCopiedIndex(null)
      }, 3000);
    } catch (err) {
      console.error("Failed to copy: ", err);
      /* Rejected - text failed to copy to the clipboard */
    }
  }

  function linkPreview(linkUrl) {
    if (linkUrl) {
      window.open(linkUrl);
    }
  }

  const handleRowClick = (trans) => {
    if (navigator.onLine) {
      history.push(`${link}${trans.linkId}/${trans.linkCode}`);
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <div id="table-container">
      <table className="payment-link-tabs">
        <thead>
          {column.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </thead>
        <tbody>
          {data.map((fees, i) => (
            <tr key={i} onClick={(node, event) => handleRowClick(fees)}>
              <td
                id="font_change"
                className="bolder__one"
                onClick={(node, event) => handleRowClick(fees)}
              >
                <a className="view bolder__one">{fees.pageTitle}</a>
              </td>

              <td onClick={(node, event) => handleRowClick(fees)}>
                <a className="view">
                  <span className={feePaymentStatus(fees.status)}>
                    {fees.status}
                  </span>
                </a>
              </td>

              <td onClick={(i) => handleRowClick}>
                <a className="view">{fees.linkType}</a>
              </td>

              <td onClick={(i) => handleRowClick}>
                <a className="view">{fees.createTime}</a>
              </td>

              <td className="flex_copy">
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    linkPreview(`https://pay.deempay.com/${fees.linkCode}`);
                  }}
                >
                  Preview
                </a>
                <button
                >
                  <p
                  >
                    {copied ? (
                      <span
                        className="copy__icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopy(`https://pay.deempay.com/${fees.linkCode}`, i);
                        }}
                      >
                        <CopyIcon />
                      </span>
                    ) : ( 
                      (copiedIndex === i ) ? ( <span className="copy__icon"><CopiedIcon />  </span>
                      ) : ( <span 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopy(`https://pay.deempay.com/${fees.linkCode}`, i);
                        }}
                        className="copy__icon"><CopyIcon />  </span>)
                    )}
                  </p>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
