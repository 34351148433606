import React from "react";
import { SidebarPage } from "../sidebar";
import { NavbarPage } from "../navbar";
import { HeaderPage } from "../header";
import { MiniSidebarPage } from "../minisidebar/MiniSidebar";

function Layout({ children }) {
  return (
    <>
      <div>
        <main>
          <SidebarPage />
          <MiniSidebarPage />
          <NavbarPage />
          <HeaderPage />
          {children}
        </main>
      </div>
    </>
  );
}

export default Layout;
