import React, { useState, useEffect } from "react";
import CashierLayout from "../../../../components/layout/CashierLayout";
import storage from "../../../../utils/storage";
import { useUpdateMerchantLogo } from "../../../../hooks/useMerchant";
import { Spinner } from "../../../../components/spinner";
import ImgCrop from "antd-img-crop";
import { Notify } from "../../../../components/notification";
import { useMerchantQuery } from "../../../../hooks/useMerchant";
import file from "../../../../helpers/file";
import { Upload } from "antd";
import { EditIcon } from "../../../../assests/icons/Icons";
import { reverseCustomers } from "../../../../utils/constants/customers";
import { SpinnerMedium } from "../../../../components/spinner/Spinner";
import { reversebusinessCategories } from "../../../../utils/constants/businessCategories";
import { reverseBusinessSection } from "../../../../utils/constants/reverseBusinessSection";
import { useHistory } from "react-router-dom";

import "./styles.scss";
import SupervisorLayout from "../../../../components/layout/SupervisorLayout";

export const SupervisorBusinessProfilePage = () => {
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const roleName = JSON.parse(storage.fetch("role")).roleName;
  const logoUrl = JSON.parse(storage.fetch("merchantDetails")).logoUrl;
  const [phoneNo, setPhoneNo] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [cusType, setCusType] = useState("");
  const [city, setCity] = useState("");
  const [industry1, setIndustry1] = useState("none");
  const [industry2, setIndustry2] = useState("n");
  const [province, setProvince] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [fileSuffix, setFileSuffix] = useState("");
  const [base64Avatar, setBase64Avatar] = useState("");
  const history = useHistory();
  const [fileList, setFileList] = useState([
    {
      uid: "",
      name: "",
      status: "",
      url: logoUrl,
    },
  ]);

  const onChange = async ({ fileList: newFileList }) => {
    newFileList = newFileList.slice(-1);
    setFileList(newFileList);
    const imageFile = newFileList[0].originFileObj;
    const fileType = imageFile.type.split("/")[1];

    if (newFileList[0].status === "error" || newFileList[0].status === "done") {
      setFileSuffix(fileType);
      setSelectedFile(imageFile);
      let base64URL = await file.convertToBase64(newFileList[0].originFileObj);
      setBase64Avatar(base64URL);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const {
    isLoading: isFetchingMerchant,
    data: merchantData,
    mutate: fetchMerchantDetails,
  } = useMerchantQuery({ cusId });

  const {
    isLoading: isUploadingLogo,
    data,
    mutate: uploadMerchantLogo,
  } = useUpdateMerchantLogo({
    fileSuffix,
    base64Avatar,
  });

  useEffect(() => {
    fetchMerchantDetails();
  }, []);

  useEffect(() => {
    if (merchantData?.code === "000000") {
      setPhoneNo(merchantData?.data.merchantInfo.contactPhone);
      setMerchantName(merchantData?.data.merchantInfo.cusFullName);
      setAddress(merchantData?.data.merchantInfo.bizAddress);
      setEmail(merchantData?.data.merchantInfo.contactEmail);
      setCusType(merchantData?.data.merchantInfo.cusType);
      setCity(merchantData?.data.merchantInfo.city);
      setProvince(merchantData?.data.merchantInfo.province);
      setIndustry1(merchantData?.data.merchantInfo.industry1);
      setIndustry2(merchantData?.data.merchantInfo.industry2);
    }
  }, [merchantData]);

  useEffect(() => {
    if (data?.code === "000000") {
      const merchantDetails = JSON.parse(storage.fetch("merchantDetails"));
      const updatedmerchantDetails = {
        ...merchantDetails,
        logoUrl: data?.data,
      };
      storage.add("merchantDetails", JSON.stringify(updatedmerchantDetails));

      Notify(
        "success",
        "Successfully completed!",
        "Logo updated successfully."
      );
    }
  }, [data]);

  // Upload and preview image
  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (fileSuffix && base64Avatar) {
      uploadMerchantLogo();
    }
  }, [fileSuffix, base64Avatar]);

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {
      switch (profileType) {
        case "businessProfile":
          history.replace("/dashboard/supervisor/settings/business");
          break;
        case "personalProfile":
          history.replace("/dashboard/supervisor/settings");
          break;
        case "bankProfile":
          history.replace("/dashboard/supervisor/settings/bank");
          break;
        case "accountSettings":
          history.replace("/dashboard/supervisor/settings/accounts");
          break;
        case "paymentStand":
          history.replace("/dashboard/supervisor/settings/payment-stand");
          break;
        default:
          history.replace("/dashboard/supervisor/settings");
      }
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <>
      <SupervisorLayout>
        <header>
          <main className="business___Settings">
            <section id="sect">
              <a>
                <span
                  className="personal-info"
                  onClick={(node, event) => handleRowClick("personalProfile")}
                >
                  Personal Details
                </span>
              </a>
              <a>
                <span
                  className="activee"
                  onClick={(node, event) => handleRowClick("businessProfile")}
                >
                  Business Details
                </span>
              </a>
              <a>
                <span onClick={(node, event) => handleRowClick("bankProfile")}>
                  Bank Details
                </span>
              </a>
              <a>
                <span
                  className="accSet"
                  onClick={(node, event) => handleRowClick("accountSettings")}
                >
                  Account Settings
                </span>
              </a>
              <a>
                <span
                  className="payStand"
                  onClick={(node, event) => handleRowClick("paymentStand")}
                >
                  Payment Sticker
                </span>
              </a>
            </section>
            <div className="intro-txt">
              <h2>Business Information</h2>
              <h4>View and update your business information.</h4>
            </div>
            <div className="line-border">
              <div className="row">
                <div className="column">
                  <form>
                    <div className="name_row">
                      <div className="first-row">
                        <label>Business Name</label>
                        <input
                          type="text"
                          placeholder={merchantName}
                          disabled={true}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="first-row">
                        <label>Business Type</label>
                        <input
                          type="name"
                          placeholder={reverseCustomers.get(cusType)}
                          disabled={true}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="name_row">
                      <div className="first-row">
                        <label>Business Email</label>
                        <input
                          type="name"
                          placeholder={email}
                          disabled={true}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="first-row">
                        <label>Business Phone</label>
                        <input
                          type="name"
                          disabled={true}
                          placeholder={phoneNo}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="name_row">
                      <div className="first-row">
                        <label>Account Type</label>
                        <input
                          type="number"
                          disabled={true}
                          placeholder={roleName === null ? "" : roleName}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="first-row">
                        <label>Business Address</label>
                        <input
                          type="number"
                          disabled={true}
                          placeholder={address}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="name_row">
                      <div className="first-row">
                        <label>Business Industry</label>
                        <input
                          type="number"
                          disabled={true}
                          placeholder={reversebusinessCategories.get(industry1)}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="first-row">
                        <label>Business Category</label>
                        <input
                          type="number"
                          disabled={true}
                          placeholder={reverseBusinessSection.get(industry2)}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="name_row">
                      <div className="first-row">
                        <label>City</label>
                        <input
                          type="number"
                          disabled={true}
                          placeholder={city}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="first-row">
                        <label>State</label>
                        <input
                          type="number"
                          disabled={true}
                          placeholder={province}
                        />
                        {isFetchingMerchant ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <button 
                      type="submit"
                      class="text-[#ffffff] hover:bg-[#d9d9d9] rounded-lg py-3 items-center justify-center flex space-x-2 bg-[#ffffff] border border-[#d9d9d9] max-w-[150px] mt-4"
                      disabled
                      >
                        <EditIcon />
                        <span class="items-center justify-center flex text_16_500 text-[#595959]">
                          Edith details
                        </span>
                      </button>
                  </form>
                </div>

                <div className="column">
                  <div className="image-update">
                    <form className="board-wrap">
                      <div className="btm__line">
                        <p>Business logo</p>
                      </div>
                      <ImgCrop rotate>
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreview}
                          disabled={true}
                        >
                          {isUploadingLogo ? (
                            <Spinner color={"#1a71ff"} />
                          ) : (
                            fileList.length < 5 && "+ Upload"
                          )}
                        </Upload>
                      </ImgCrop>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </header>
      </SupervisorLayout>
    </>
  );
};
