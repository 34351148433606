export const roleIds = new Map([
  ["Manager", "2"],
  ["Cashier", "3"],
  ["Supervisor", "4"],
]);

export const reverseRoles = new Map([
  ["1", "Admin"],
  ["2", "Manager"],
  ["3", "Cashier"],
  ["4", "Supervisor"],
]);
  
  export const roleIdList = Array.from(roleIds.keys());
  