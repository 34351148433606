import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Close } from "../../../assests/svgs";
import { Spinner } from "../../spinner";
import storage from "../../../utils/storage";
import useRegister from "../../../hooks/useRegister";
import { banks } from "../../../utils/constants/banks";
import { useChangeBankAccount } from "../../../hooks/useBank";
import { Modal } from "antd";
import { VerifyPhoneIcon } from "../../../assests/icons/Icons";
import { formatPhoneNo } from "../../../utils/functions";

import "./styles.scss";

function BankModal({ showModal, setShowModal }) {
  const history = useHistory();
  const contactPhone = JSON.parse(storage.fetch("merchantDetails")).contactPhone;
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [bankCode, setBankCode] = useState("");
  const [cardName, setCardName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [apiResponseError, setApiResponseError] = useState("");
  const [validCode, setValidCode] = useState("");
  const [isCounterDone, setIsCounterDone] = useState(true);
  const [isResendDone, setIsResendDone] = useState(false);
  var [minutes, setMinutes] = useState("");
  var [seconds, setSeconds] = useState("");
  var [inval, setInval] = useState("");

  
  const {
    isLoading: isVerifying,
    data,
    mutate: fetchingBankAccount,
  } = useChangeBankAccount({
    cusId,
    cardName,
    cardNo,
    bankCode,
    validCode,
    phoneNo: contactPhone,
  });

  const {
    isLoading: isSendingCode,
    data: resendCodeData,
    mutate: sendCode,
  } = useRegister({
    phoneNo: contactPhone,
    templateId: "M1003",
  });

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    startCounter();
  }, []);

  const startCounter = () => {
    const startTiming = 1;
    let time = startTiming * 60;
    let _countIntvl = 0;
    const timerInterval = setInterval(function () {
      _countIntvl += 1;
      setInval(timerInterval);
      const minute_2 = Math.floor(time / 60);
      setMinutes(minute_2);
      let second_2 = time % 60;
      second_2 = second_2 < 10 ? "0" + second_2 : second_2;
      setSeconds(second_2);
      time--;
      if (second_2 === "00" && minute_2 === 0) {
        clearInterval(timerInterval);
        setIsCounterDone(false);
        setIsResendDone(true);
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  };

  useEffect(() => {
    const  handleBankSuccess = () => {
      history.push("/dashboard/merchant/settings/bank"); 
    };
    if(data?.code === "000000") {
      storage.add(
        "userBankPayload",
        JSON.stringify({
          bankCode: banks.get(bankCode),
          cardNo,
          cardName,
        })
      );
      setShowModal((prev) => !prev)
      Modal.success({
        title: "Congratulations!",
        content: "Bank account updated successfully.",
        onOk: () => handleBankSuccess(),
      });
      reset();
    } else if (data?.code === "100002") {
      setApiResponseError(<p style={{color: "red"}}>Sorry! The verification code is invalid</p>);
      reset();
    }
  }, [data]);

  const onSubmitHandler = (data) => {
   storage.add("validCode", data.validCode);
   setValidCode(data.validCode);
   const bankPayload = JSON.parse(
    storage.fetch("userBankPayload")
  );

  if (bankPayload) {
    setCardName(bankPayload.cardName);
    setCardNo(bankPayload.cardNo);
    setBankCode(bankPayload.bankCode);
    setPhoneNo(bankPayload.phoneNo);
  }
   fetchingBankAccount();
  };

  const resendValidCode = () => {
    const bankPayload = JSON.parse(
      storage.fetch("userBankPayload")
    );

    if (bankPayload) {
     setPhoneNo(bankPayload.phoneNo);
      sendCode();
      setIsCounterDone(true);
      setIsResendDone(false);
      startCounter();
    }
  };

  return (
    <>
      {showModal ? (
        <div className="backgd--bank">
          <div className="bank-wrap">
            <form onSubmit={handleSubmit(onSubmitHandler)} >
            <div className="header-part">
                <span className="img">
                  <VerifyPhoneIcon />
                </span>
                <h2>Verify your number</h2>
                <h4>
                  {/* {`Please enter the verification code sent to ${formatPhoneNo(
                   setPhoneNo(phoneNo)
                  )} for
              confirmation`} */}
                Please enter the verification code sent to you for
              confirmation
                </h4>
              </div>
              <div className="body-part">
                <label>Enter valid code</label>
                <input
                  className={`verifyInput ${errors.validCode && "invalid"}`}
                  type="number"
                  placeholder="Enter code here"
                  onFocus={() => setApiResponseError("")}
                  {...register("validCode", {
                    required: "Verification code is required",
                    minLength: {
                      value: 6,
                      message: "minimum allowed valid code is 6",
                    },
                    maxLength: {
                      value: 6,
                      message: "minimum allowed valid code is 6",
                    },
                  })}
                />
                {errors.validCode && <p>{errors.validCode.message}</p>}

                <img
                  width="4%"
                  src={Close}
                  alt=""
                  onClick={() => setShowModal((prev) => !prev)}
                />
                {isCounterDone && (
                  <>
                    <p>
                      Your verification code will expire in
                      <span className="timer">
                        {minutes}:{seconds} s.
                      </span>
                    </p>
                    
                  </>
                )}
                <div>{apiResponseError}</div>
                {isResendDone && (
                  <p className="para">
                    Your verification code has expired
                    <span className="resend" onClick={resendValidCode}>
                      Resend.
                    </span>
                  </p>
                )}
                <button type="submit" disabled={isVerifying || isSendingCode}>
                  {isVerifying || isSendingCode ? (
                    <Spinner />
                  ) : (
                    "Change Account"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default BankModal;

