import {
  HomeIcon,
  CollectPaymentIcon,
  TransactionIcon,
  SettingsIcon,
  FeePaymentIcon,
} from "../../../assests/icons/Icons";

export const merchantLinks = [
  {
    name: "Overview",
    route: "/dashboard/supervisor/overview",
    icon: <HomeIcon />,
    subLinks: null,
    keyWord: "overview",
  },
  {
    name: "Receive payment",
    route: "/dashboard/supervisor/accept-payment",
    icon: <CollectPaymentIcon />,
    subLinks: null,
    keyWord: "accept-payment",
  },
  {
    name: "Transactions",
    route: "/dashboard/supervisor/transactions",
    icon: <TransactionIcon />,
    subLinks: null,
    keyWord: "transactions",
  },
  {
    name: "Payment links",
    route: "/dashboard/supervisor/payment-link",
    icon: <FeePaymentIcon />,
    subLinks: null,
    keyWord: "payment-link",
  },
  {
    name: "Settings",
    route: "/dashboard/supervisor/settings",
    icon: <SettingsIcon />,
    subLinks: null,
    keyWord: "settings",
  },
];
