import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import storage from "../../utils/storage";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components/spinner";
import { genderList, genders } from "../../utils/constants/gender";
import ImgCrop from "antd-img-crop";
import { Notify } from "../../components/notification";
import { useUserQuery } from "../../hooks/useUser";
import file from "../../helpers/file";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useAvatarUpload, useUpdateUser } from "../../hooks/useUser";
import { Upload } from "antd";
import { EditIcon } from "../../assests/icons/Icons";
import { documentList, documents } from "../../utils/constants/documents";
import { SpinnerMedium } from "../../components/spinner/Spinner";
import { useFileUpload } from "../../hooks/useFileUpload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles.scss";

export const PersonalProfilePage = () => {
  const history = useHistory();
  const avatarUrl = JSON.parse(storage.fetch("userDetails")).avatar;
  const userId = JSON.parse(storage.fetch("userDetails")).userId;
  const [surName, setSurName] = useState("");
  const [idNo, setIdNo] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [firstName, setFirstName] = useState("");
  const [bvn, setBvn] = useState("");
  const [certType, setCertType] = useState(
    "National Identification Number (NIN)"
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [fileSuffix, setFileSuffix] = useState("");
  const [base64Avatar, setBase64Avatar] = useState("");
  const [isBvn, setIsBvn] = useState(false);
  const [isNin, setIsNin] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [certificateType, setCertificateType] = useState("");
  const [base64Certificate, setBase64Certificate] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [fileList, setFileList] = useState([
    {
      uid: "",
      name: "",
      status: "",
      url: avatarUrl,
    },
  ]);

  const changeForm = () => {
    setIsEdit(false);
    setIsSubmit(true);
  };

  const notify = () =>
    toast.success("Uploading File!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const {
    isLoading: isFetchingUser,
    data: userQuery,
    mutate: fetchUser,
  } = useUserQuery({});

  const legalType = watch("legalType");

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (userQuery?.code === "000000") {
      setBirthday(userQuery?.data.birthday);
      setFirstName(userQuery?.data.firstName);
      setSurName(userQuery?.data.surname);
      setIdNo(userQuery?.data.idNo);
      setBvn(userQuery?.data.bvn);
      setValue("idNo", idNo);
      setValue("bvn", bvn);

      genders.forEach(function (value, key) {
        if (value === userQuery?.data.gender) {
          setGender(key);
          setValue("gender", key);
        }
      });

      const userDetails = JSON.parse(storage.fetch("userDetails"));

      if (userDetails.certificateType) {
        documents.forEach(function (value, key) {
          if (value === userDetails.certificateType) {
            setCertType(key);
            setValue("legalType", key);
          }
        });
      } else if (idNo !== "-") {
        setCertType("National Identification Number (NIN)");
        setValue("legalType", "National Identification Number (NIN)");
      } else if (bvn !== "-") {
        setCertType("Bank Verification Number (BVN)");
        setValue("legalType", "Bank Verification Number (BVN)");

        const updatedUserDetails = {
          ...userDetails,
          firstName: userQuery?.data.firstName,
          surname: userQuery?.data.surname,
        };
        storage.add("userDetails", JSON.stringify(updatedUserDetails));
      }
    }
  }, [userQuery]);

  useEffect(() => {
    const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));

    if (!onboardingDetails.onboarded) {
      history.push("/dashboard/merchant/onboarding");
      Notify(
        "info",
        "Onboarding pending",
        "Please complete the onboarding process to start using your DeemPay account!",
        10
      );
    }
  }, []);

  const onChange = async ({ fileList: newFileList }) => {
    newFileList = newFileList.slice(-1);
    setFileList(newFileList);
    const imageFile = newFileList[0].originFileObj;
    const fileType = imageFile.type.split("/")[1];

    if (newFileList[0].status === "error" || newFileList[0].status === "done") {
      setFileSuffix(fileType);
      setSelectedFile(imageFile);
      let base64URL = await file.convertToBase64(newFileList[0].originFileObj);
      setBase64Avatar(base64URL);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const {
    isLoading: isFetching,
    data,
    mutate: updateProfile,
  } = useUpdateUser({
    firstName,
    surName,
    birthday,
    idNo,
    bvn,
    gender: genders.get(gender),
  });

  const {
    isLoading: isUploadingImage,
    data: userAvatar,
    mutate: uploadUserAvatar,
  } = useAvatarUpload({
    fileSuffix,
    base64Avatar,
  });

  const { data: certData, mutate: uploadFile } = useFileUpload({
    userId,
    certificateType,
    fileSuffix,
    base64Certificate,
  });

  useEffect(() => {
    if (!!isUploadFile && certData?.code === "US0052") {
      Notify(
        "error",
        "Upload a file!",
        "Please upload the required file to continue."
      );
    }
  }, [certData]);

  useEffect(() => {
    if (userAvatar?.code === "000000") {
      const userDetails = JSON.parse(storage.fetch("userDetails"));
      const updatedUserDetails = { ...userDetails, avatar: userAvatar?.data };
      storage.add("userDetails", JSON.stringify(updatedUserDetails));
      Notify(
        "success",
        "Successfully completed!",
        "Image updated successfully."
      );
    }
  }, [userAvatar]);

  // Upload and preview image
  useEffect(() => {
    if (!selectedFile) {
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (legalType === "Bank Verification Number (BVN)") {
      setIsNin(false);
      setIsBvn(true);
      setIsUploadFile(false);
      setCertificateType(documents.get(legalType));
    } else if (legalType === "International Passport") {
      setIsNin(false);
      setIsBvn(false);
      setIsUploadFile(true);
      setCertificateType(documents.get(legalType));
    } else if (legalType === "National Identification Number (NIN)") {
      setIsNin(true);
      setIsBvn(false);
      setIsUploadFile(false);
      setCertificateType(documents.get(legalType));
    } else if (legalType === "Voter's Card") {
      setIsNin(false);
      setIsBvn(false);
      setIsUploadFile(true);
      setCertificateType(documents.get(legalType));
    } else if (legalType === "Driver's License") {
      setIsNin(false);
      setIsBvn(false);
      setIsUploadFile(true);
      setCertificateType(documents.get(legalType));
    } else {
      setIsNin(true);
      setIsBvn(false);
      setIsUploadFile(false);
      setCertificateType(documents.get(legalType));
    }
  }, [legalType]);

  useEffect(() => {
    resetField("legalNo");
  }, [legalType]);

  useEffect(() => {
    if (data?.code === "000000") {
      fetchUser();
      Notify(
        "success",
        "Successfully completed!",
        "Profile details updated successfully."
      );
      setIsEdit(true);
      setIsSubmit(false);
    }
  }, [data]);

  useEffect(() => {
    if (fileSuffix && base64Avatar) {
      uploadUserAvatar();
    }
  }, [fileSuffix, base64Avatar]);

  const onSubmit = (data) => {
    const { firstName, surName, idNo, bvn, birthday, gender } = data;

    if (firstName) {
      setFirstName(firstName);
    }
    if (surName) {
      setSurName(surName);
    }
    if (idNo) {
      setIdNo(idNo);
    }
    if (bvn) {
      setBvn(bvn);
    }
    if (birthday) {
      setBirthday(birthday);
    }
    if (gender) {
      setGender(gender);
    }

    updateProfile();

    if (isUploadFile) {
      uploadFile();
    }
  };

  const handleFileUpload = async (e) => {
    const certificate = e.target.files[0];
    const fileType = certificate.type.split("/");
    setFileSuffix(fileType[1]);
    const base64Certificate = await file.convertToBase64(certificate);
    setBase64Certificate(base64Certificate);

    setIsUploading(true);
  };

  useEffect(() => {
    if (isUploading === true) {
      notify();
    }
  }, [isUploading]);

  useEffect(() => {
    if (isSubmit) {
      setValue("firstName", firstName);
      setValue("surName", surName);
      setValue("birthday", birthday);
      setValue("idNo", idNo);
      setValue("bvn", bvn);
    }
  }, [isSubmit]);

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {
      switch (profileType) {
        case "businessProfile":
          history.replace("/dashboard/merchant/settings/business");
          break;
        case "personalProfile":
          history.replace("/dashboard/merchant/settings");
          break;
        case "bankProfile":
          history.replace("/dashboard/merchant/settings/bank");
          break;
        case "accountSettings":
          history.replace("/dashboard/merchant/settings/accounts");
          break;
        case "paymentStand":
          //history.push("/dashboard/merchant/settings/payment-stand")
          history.replace("/dashboard/merchant/settings/payment-stand");
          break;
        default:
          history.replace("/dashboard/merchant/settings");
      }
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <>
      <Layout>
        <header>
          <main className="personal__Setting">
            <section id="sect">
              <a>
                <span
                  className="active"
                  onClick={(node, event) => handleRowClick("personalProfile")}
                >
                  Personal Details
                </span>
              </a>
              <a>
                <span
                  onClick={(node, event) => handleRowClick("businessProfile")}
                >
                  Business Details
                </span>
              </a>
              <a>
                <span onClick={(node, event) => handleRowClick("bankProfile")}>
                  Bank Details
                </span>
              </a>
              <a>
                <span
                  onClick={(node, event) => handleRowClick("accountSettings")}
                >
                  Account Settings
                </span>
              </a>
              <a>
                <span onClick={(node, event) => handleRowClick("paymentStand")}>
                  Payment Sticker
                </span>
              </a>
            </section>
            <div className="intro-txt">
              <h2>Personal Information</h2>
              <h4>View and update your personal information.</h4>
            </div>
            <div className="line-border">
              <div className="row">
                <div className="column">
                  {isEdit && (
                    <form>
                      <div className="name_row">
                        <div className="first-row">
                          <label>First Name</label>
                          <input
                            className={`personalInput ${
                              errors.firstName && "invalid"
                            }`}
                            type="name"
                            disabled={true}
                            placeholder={
                              firstName
                                ? firstName[0].toUpperCase() +
                                  firstName.substring(1)
                                : "Enter first name"
                            }
                          />
                          {isFetchingUser ? (
                            <>
                              <span className="acc-Name">
                                <span className="spinner_input">
                                  <SpinnerMedium className="spinner_input" />
                                </span>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="first-row">
                          <label>Last Name</label>
                          <input
                            className={`personalInput ${
                              errors.surName && "invalid"
                            }`}
                            type="name"
                            placeholder={
                              surName
                                ? surName[0].toUpperCase() +
                                  surName.substring(1)
                                : "Enter surname"
                            }
                            disabled={true}
                          />
                          {isFetchingUser ? (
                            <>
                              <span className="acc-Name">
                                <span className="spinner_input">
                                  <SpinnerMedium className="spinner_input" />
                                </span>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="name_row">
                        <div className="first-row">
                          <label>Date of birth</label>
                          <input
                            type="text"
                            disabled={true}
                            placeholder={
                              birthday
                                ? new Date(birthday).toDateString()
                                : "Enter your birthday"
                            }
                          />
                          {isFetchingUser ? (
                            <>
                              <span className="acc-Name">
                                <span className="spinner_input">
                                  <SpinnerMedium className="spinner_input" />
                                </span>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="first-row">
                          <label>Gender</label>
                          <input
                            type="text"
                            disabled={true}
                            placeholder={gender ? gender : "Select your gender"}
                          />
                          {isFetchingUser ? (
                            <>
                              <span className="acc-Name">
                                <span className="spinner_input">
                                  <SpinnerMedium className="spinner_input" />
                                </span>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <label>Identification Type</label>
                      <div>
                        <input
                          className="business-option"
                          type="text"
                          disabled={true}
                          placeholder={
                            certType ? certType : "Add your identification type"
                          }
                        />
                        {isFetchingUser ? (
                          <>
                            <span className="acc-Type">
                              <span className="spinner_inputT">
                                <SpinnerMedium className="spinner_inputT" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <label>National Identification Number (NIN)</label>
                      <input
                        className="personalInput"
                        type="text"
                        placeholder={
                          idNo !== "-"
                            ? idNo
                            : "Enter your national identification number"
                        }
                        disabled={true}
                      />
                      {isFetchingUser ? (
                        <>
                          <span className="acc-Name">
                            <span className="spinner_input">
                              <SpinnerMedium className="spinner_input" />
                            </span>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <button
                        type="submit"
                        class="text-[#ffffff] hover:bg-[#d9d9d9] rounded-lg py-3 items-center justify-center flex space-x-2 bg-[#ffffff] border border-[#d9d9d9] max-w-[150px] mt-4"
                        onClick={changeForm}
                      >
                        <EditIcon />
                        <span class="items-center justify-center flex text_16_500 text-[#595959]">
                          Edith details
                        </span>
                      </button>
                    </form>
                  )}

                  {isSubmit && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="name_row">
                        <div className="first-row">
                          <label>First Name</label>
                          <input
                            className={`personalInput ${
                              errors.firstName && "invalid"
                            }`}
                            type="name"
                            placeholder="Enter first name"
                            {...register("firstName", {
                              required: "First name is required",
                            })}
                          />
                        </div>
                        <div className="first-row">
                          <label>Last Name</label>
                          <input
                            className={`personalInput ${
                              errors.surName && "invalid"
                            }`}
                            type="name"
                            placeholder="Enter last name"
                            {...register("surName", {
                              required: "Last name is required",
                            })}
                          />
                        </div>
                      </div>

                      <div className="name_row">
                        <div className="first-row">
                          <label>Date of birth</label>
                          <input
                            type="date"
                            placeholder={"Enter your birthday"}
                            {...register("birthday", {
                              required: "birthday is required",
                            })}
                          />
                          {isFetchingUser ? (
                            <>
                              <span className="acc-Name">
                                <span className="spinner_input">
                                  <SpinnerMedium className="spinner_input" />
                                </span>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="biz_option">
                          <label>Gender</label>
                          <select
                            type="text"
                            placeholder="Select your gender"
                            {...register("gender")}
                          >
                            {genderList.map((doc, i) => (
                              <option
                                key={i}
                                selected={i === gender && "selected"}
                              >
                                {doc}
                              </option>
                            ))}
                          </select>
                          <MdKeyboardArrowDown className="biz_down" />
                        </div>
                      </div>

                      <label>Identification Type</label>
                      <div className="business-option">
                        <select
                          type="text"
                          placeholder="Identifications"
                          {...register("legalType")}
                        >
                          {documentList.map((doc, i) => (
                            <option
                              key={i}
                              selected={i === certType && "selected"}
                            >
                              {doc}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="business-down" />
                      </div>

                      {isUploading ? (
                        <ToastContainer
                          position="bottom-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      ) : (
                        ""
                      )}

                      {isNin && (
                        <>
                          <label>National Identification Number (NIN)</label>
                          <input
                            className={`personalInput ${
                              errors.idNo && "invalid"
                            }`}
                            type="text"
                            placeholder="Enter NIN Number"
                            {...register("idNo", {
                              required:
                                "National identification number(NIN) is required",
                            })}
                          />
                        </>
                      )}

                      {isBvn && (
                        <>
                          <label>Bank Verification Number (BVN)</label>
                          <input
                            className={`personalInput ${
                              errors.bvn && "invalid"
                            }`}
                            type="text"
                            placeholder="Enter BVN"
                            {...register("bvn", {
                              required:
                                "Bank verification number (BVN) is required",
                            })}
                          />
                        </>
                      )}

                      {isUploadFile && (
                        <>
                          <p className="upload-doc">Upload Document</p>
                          <div className="btn-Wrap">
                            <label className="btn" for="upload">
                              Choose file
                            </label>
                            <input
                              type="file"
                              id="upload"
                              placeholder="Choose file"
                              onChange={handleFileUpload}
                            />
                          </div>
                        </>
                      )}

                      <button type="submit" className="btn__submit">
                        {isFetching ? <Spinner /> : "Save Changes"}
                      </button>
                    </form>
                  )}
                </div>

                <div className="column">
                  <div className="image-update">
                    <form className="board-wrap">
                      <div className="btm__line">
                        <p>Profile photo</p>
                      </div>
                      <ImgCrop rotate>
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreview}
                        >
                          {isUploadingImage ? (
                            <Spinner color={"#1a71ff"} />
                          ) : (
                            fileList.length < 5 && "+ Upload"
                          )}
                        </Upload>
                      </ImgCrop>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </header>
      </Layout>
    </>
  );
};
