export const customers = new Map([
  ["Sole trader / Individual business", "1"],
  ["Registered business / Company", "2"],
]);

export const reverseCustomers = new Map([
  ["1", "Sole trader / Individual business"],
  ["2", "Registered business / Company"],
  ["3", "Personal user"],

]);

export const customerList = Array.from(customers.keys());
