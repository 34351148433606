import React, { useState, useEffect, useRef } from "react";
import ManagerLayout from "../../../../components/layout/ManagerLayout";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { formatCurrency, formatDateTime } from "../../../../utils/functions";
import { useQueryTransaction } from "../../../../hooks/useTransaction";
import storage from "../../../../utils/storage";
import { Spinner } from "../../../../components/spinner";
import { truncateLongName } from "../../../../utils/functions";
import { reversePaymentMethods } from "../../../../utils/constants/paymentMethods";

import "./styles.scss";

export const ManagerTransactionStatusPage = () => {
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const { orderNo } = useParams();
  const [transaction, setTransaction] = useState({});
  const [metaData, setMetaData] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});
  const [additionInfo, setAdditionInfo] = useState({});
  const [studentInfo, setStudentInfo] = useState({});
  const [feeInfo, setFeeInfo] = useState({});
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 400px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 400px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const {
    isLoading: isFetching,
    data,
    mutate: fetchTransactionDetails,
  } = useQueryTransaction({
    cusId,
    orderNo,
  });

  useEffect(() => {
    fetchTransactionDetails();
  }, []);

  useEffect(() => {
    if (data?.code === "000000") {
      setTransaction(data?.data?.result[0]);
      var metaData = data?.data?.result[0].metaData;
      if (typeof metaData === "string" || metaData instanceof String) {
        if (metaData !== "null" && metaData !== null) {
          try {
            var metaDataJson = JSON.parse(metaData);
            setMetaData(metaDataJson);
            if (metaDataJson.additionInformation) {
              setAdditionInfo(metaDataJson.additionInformation);
            }
            if (metaDataJson.customerInformation) {
              setCustomerInfo(metaDataJson.customerInformation);
            }
            if (metaDataJson.studentInformation) {
              setStudentInfo(metaDataJson.studentInformation);
            }
            if (metaDataJson.feeInformation) {
              setFeeInfo(metaDataJson.feeInformation);
            }
          } catch (err) {
            //console.log(err);
          }
        }
      }
    }
  }, [data]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Payment Details ${formatDateTime(transaction.finishTime)}`,
    copyStyles: true,
  });

  return (
    <ManagerLayout>
      <header>
      <main className="main-trans">
          <div class="mb-7 flex justify-between">
            <h1 class="text_22_600">Transaction details</h1>
            <span>
              {/* <button onClick={handlePrint} class="text-[#ffffff] rounded-lg md:text-base text-xs font-medium bg-[#1a71ff] hover:bg-[##2491f0] md:px-4 px-3 md:py-3 py-2 flex space-x-2 ">
                <PrintIcon />
                <span class="hidden md:block">Print</span>
              </button> */}
            </span>
          </div>
          {isFetching ? (
            <div style={{ marginTop: "10rem" }}>
              <Spinner color={"#1a71ff"} />
            </div>
          ) : (
            <>
              <section
                ref={componentRef}
                class="grid h-auto bg-[#F0F6FF] mt-2 mx-auto max-w-[560px] rounded-lg md:px-[35px] px-[20px] py-[40px] mb-7 "
              >
                <div class="bg-[#ffffff] md:h-[72px] h-[60px] justify-center rounded">
                  <h2 class=" items-center justify-center flex text-[#000000] md:text-[35px] text-[25px] font-bold  my-4 mt-[12px]">
                    {formatCurrency(transaction.tradeAmt)}
                  </h2>
                </div>

                <div class="flex  mb-[25px]  mt-[35px] ">
                  <h2 class="text-[#114185] text_18_500 ">Payment details</h2>
                </div>
                <div class="flex  justify-between mb-1">
                  <p class="text_16_400 ">Customer name</p>
                  <p class="text_16_500">
                    {matches
                      ? (transaction.payAccountName === null ? "-" : truncateLongName(transaction.payAccountName))
                      : (transaction.payAccountName === null ? "-" : transaction.payAccountName)}
                  </p>
                </div>
                <div class="flex  justify-between mb-1">
                  <p class="text_16_400 ">Status</p>
                  <p class="text_16_500 capitalize">
                    {transaction.tradeStatus}
                  </p>
                </div>
                <div class="flex  justify-between mb-1">
                  <p class="text_16_400">Amount Credited</p>
                  <p class="text_16_500">
                    {transaction.residualAmount === null ? "-" : formatCurrency(transaction.residualAmount)}
                  </p>
                </div>
                <div class="flex  justify-between mb-1">
                  <p class="text_16_400">Fee</p>
                  <p class="text_16_500">
                  {transaction.feeAmt === null ? "-" : formatCurrency(transaction.feeAmt)}
                  </p>
                </div>
                <div class="flex  justify-between mb-1">
                  <p class="text_16_400">Transaction reference</p>
                  <p class="text_16_500">
                    {matches
                      ? truncateLongName(transaction.orderNo)
                      : transaction.orderNo}
                  </p>
                </div>
                <div class="flex  justify-between mb-1">
                  <p class="text_16_400 ">Payment Method</p>
                  <p class="text_16_500">
                    {reversePaymentMethods.get(transaction.productCode)}
                  </p>
                </div>

                <div class="flex  justify-between mb-1">
                  <p class="text_16_400 ">Station name</p>
                  <p class="text_16_500">Main account</p>
                </div>
                <div class="flex  justify-between mb-1">
                  <p class="text_16_400 ">Date</p>
                  <p class="text_16_500 ">
                    {formatDateTime(transaction.finishTime)}
                  </p>
                </div>

                {Object.keys(customerInfo).length !== 0 ? (
                  <>
                    <div class="flex  mb-[25px]  mt-[35px] ">
                      <h2 class="text-[#114185] text_18_500 ">
                        Customer information
                      </h2>
                    </div>

                    {Object.keys(customerInfo).map((key, i) => (
                      <div class="flex  justify-between mb-2">
                        <p class="text_16_400">{key}</p>
                        <p class="text_16_500">{customerInfo[key]}</p>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                {Object.keys(additionInfo).length !== 0 ? (
                  <>
                    <div class="flex  mb-[25px]  mt-[35px] ">
                      <h2 class="text-[#114185] text_18_500 ">
                        Additional information
                      </h2>
                    </div>

                    {Object.keys(additionInfo).map((key, i) => (
                      <div class="flex  justify-between mb-2">
                        <p class="text_16_400">{key}</p>
                        <p class="text_16_500">{additionInfo[key]}</p>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                {Object.keys(studentInfo).length !== 0 ? (
                  <>
                    <div class="flex  mb-[25px]  mt-[35px] ">
                      <h2 class="text-[#114185] text_18_500 ">
                        Student details
                      </h2>
                    </div>

                    {Object.keys(studentInfo).map((key, i) => (
                      <div class="flex  justify-between mb-2">
                        <p class="text_16_400">{key}</p>
                        <p class="text_16_500">{studentInfo[key]}</p>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                {Object.keys(feeInfo).length !== 0 ? (
                  <>
                    <div class="flex  mb-[25px]  mt-[35px] ">
                      <h2 class="text-[#114185] text_18_500 ">
                      Fee details
                      </h2>
                    </div>

                    {Object.keys(feeInfo).map((key, i) => (
                      <div class="flex  justify-between mb-2">
                        <p class="text_16_400">{key}</p>
                        <p class="text_16_500">{feeInfo[key]}</p>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </section>
            </>
          )}
        </main>
      </header>
    </ManagerLayout>
  );
};
