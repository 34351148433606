import React, { useState, useEffect } from "react";
import ManagerLayout from "../../../../components/layout/ManagerLayout";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Table } from "../../../../components/table/Table";
import { Spinner } from "../../../../components/spinner";
import { useGetDynamicQR } from "../../../../hooks/useDynamicQR";
import { useQueryTransaction } from "../../../../hooks/useTransaction";
import { reversePaymentMethods } from "../../../../utils/constants/paymentMethods";
import { formatCurrency, formatDateTime, truncateName } from "../../../../utils/functions";
import storage from "../../../../utils/storage";
import { Notify } from "../../../../components/notification";

import "./styles.scss";

const column = [
  "Amount",
  "Payment method",
  "Station name",
  "Customer name",
  "Date",
];

export const ManagerAcceptPayment = () => {
  const history = useHistory();
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  // const subMchNo = JSON.parse(
  //   storage.fetch("merchantDetails")
  // ).subMerchantNumber;
  const subMchNo = JSON.parse(storage.fetch("role")).chlTermNo;
  const merchantName = JSON.parse(
    storage.fetch("merchantDetails")
  ).merchantName;
  const merchantNumber = JSON.parse(
    storage.fetch("merchantDetails")
  ).merchantNumber;

  const [amount, setAmount] = useState(0);
  const [transactions, setTransactions] = useState([]);

  const {
    isLoading: isFetchingTransaction,
    data: transactionData,
    mutate: fetchTransactions,
  } = useQueryTransaction({
    cusId,
    pageSize: 5,
  });

  const {
    isLoading: isFetching,
    data: qrCodeData,
    mutate: fetchQRCodeDetails,
  } = useGetDynamicQR({
    merchantName,
    merchantNumber,
    subMchNo,
    cusId,
    amount,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const resetState = () => {
    setAmount("");
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (transactionData?.code === "000000") {
      const formattedTransactions = transactionData.data.result.map((t) => {
        return {
          payAccountName: truncateName(t.payAccountName),
          tradeAmt: formatCurrency(t.tradeAmt),
          terminalName:
            t.terminalName === null ? "Main account" : t.terminalName,
          productCode: reversePaymentMethods.get(t.productCode),
          finishTime: formatDateTime(t.finishTime),
          orderNo: t.orderNo,
        };
      });
      setTransactions(formattedTransactions);
    }
  }, [transactionData]);

  useEffect(() => {
    if (qrCodeData?.code === "000000") {
      const qrCode = qrCodeData?.data?.qrInfo;
      const orderNo = qrCodeData?.data?.orderNo;
      if (orderNo !== null) {
        history.push(
          `/dashboard/manager/accept-payment/dynamic/${qrCode}?amount=${amount}&orderNo=${orderNo}`
        );
        resetState();
      }
    } else if (qrCodeData?.code === "999999") {
      Notify(
        "error",
        "Charge error",
        "Sorry! there was a error, please try again.",
        10
      );
    }
  }, [qrCodeData]);

  useEffect(() => {
    // if (subMchNo && merchantName && merchantNumber && amount) {
    //   fetchQRCodeDetails();
    // }
  }, [subMchNo, merchantName, merchantNumber, amount]);

  const onSubmit = (data) => {
    setAmount(Number(data.amount));
    if (subMchNo && merchantName && merchantNumber) {
      fetchQRCodeDetails();
    }
    //reset();
  };

  const checkTransaction = () => {

    if (navigator.onLine) {
      setTransactions([]);
      fetchTransactions();
    } else {
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
    }
  };

  return (
    <ManagerLayout>
      <header>
        <main className="main__collect">
          <h1 className="collect--pay">Receive Payment</h1>
          <section>
            <form className="charge__form" onSubmit={handleSubmit(onSubmit)}>
              <div className="collect-line">
                <h4>Receive</h4>
              </div>
              <div className="amount--card">
                <label>Amount</label>
                <input
                  className={`input ${errors.amount && "invalid"}`}
                  type="number"
                  placeholder="e.g 100"
                  {...register("amount", {
                    required: "Enter an amount",
                    required: "charge amount is required",
                    minLength: {
                      value: 2,
                      message: "minimum allowed amount is NGN 10",
                    },
                    maxLength: {
                      value: 8,
                      message: "maximum allowed amonut is NGN 50,000,000",
                    },
                  })}
                />
                {errors.amount && (
                  <p className="error__mssg">{errors.amount.message}</p>
                )}
                <h5 className="enter__amount">
                  Enter amount you want to be paid
                </h5>
                <button type="submit" disabled={isFetching} className="chargE">
                  {isFetching ? <Spinner /> : "Charge"}
                </button>
              </div>
            </form>
          </section>
          <div className="collect--payment">
            <h3>Recent Transactions</h3>
            <div className="topbar">
              <span className="top-notify">
                <div className="mini-pay" onClick={checkTransaction}>
                  <span className="check__trans">Check Transactions</span>
                </div>
              </span>
            </div>
          </div>
          <div className="transactionTable">
            <Table
              column={column}
              data={transactions}
              link={"/dashboard/manager/transactions/"}
            />
            {isFetchingTransaction ? (
              <>
                <div style={{ marginTop: "40px" }}>
                  <Spinner color={"#1a71ff"} />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </main>
      </header>
    </ManagerLayout>
  );
};
