import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import storage from "../../../utils/storage";

import "./styles.scss";

function LogoutModal({ closeModal }) {
  const history = useHistory();

  const {
    data,
    mutate: logOutUser,
  } = useLogout({});

  useEffect(() => {
    if (data?.code === "000000") {
    }
    // Handle API error
  }, [data]);

  const handleLogout = () => {
    logOutUser();
    storage.clear();
    // history.go();
    history.push("/login");
  };

  return (
    <div className="logout__modal">
      <div id="myModal" class="logout-modal">
        <div class="logout-content">
          <div className="logout-container">
            <p className="logout">Log out</p>
          </div>
          <h6 className="modalDropDown">Are you sure you want to log out?</h6>
          <div className="log__btns" onClick={closeModal}>
            <button className="stay-btn">Stay</button>
            <button className="logout-btn" onClick={handleLogout}>
              <span className="export-text">Log out</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;
