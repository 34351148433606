import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Notify } from "../../components/notification";

import "./styles.scss";

export const SubAccountTable = ({ column, data, link }) => {
  const history = useHistory();

  // const formatTerminalCode = (_terminalCode) => {
  //   return _terminalCode.replace(/\s/g, "_");
  // };

  const handleRowClick = (role) => {
    if (navigator.onLine) {
      history.push(`${link}${role.chlTermNo}/${role.phoneNo}=${role.terminalCode}`);
      } else {
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
      }
  };

  return (
    <>
      <div id="table-container">
        <table className="transaction-tabs">
          <thead>
            {column.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </thead>

          <tbody>
            {data.map((role, i) => (
              <tr key={i} onClick={(node, event) => handleRowClick(role)}>
                <td className="bolder__one">
                  <a
                    className="view bolder__one"
                  >
                    {role.terminalName}
                  </a>
                </td>
                <td>
                  <a
                    className="view"
                  >
                    {role.roleId}
                  </a>
                </td>
                <td>
                  <a
                    className="view"
                  >
                    {`${role.firstName} ${role.surname}`}
                  </a>
                </td>
                <td>
                  <a
                    className="view"
                  >
                    {role.phoneNo}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

{/* <Link
  to={`${link}${role.phoneNo}/${formatTerminalCode(role.terminalCode)}/${
    role.chlTermNo
  }`}
  className="view"
></Link>; */}
