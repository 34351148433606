export const banks = new Map([
  ["Access bank", "000014"],
  ["Access bank (Diamond)", "000005"],
  ["Citi bank", "000009"],
  ["Ecobank", "000010"],
  ["Fidelity bank", "000007"],
  ["First bank", "000016"],
  ["First city monument bank", "000003"],
  ["Globus bank", "000027"],
  ["Guaranty trust bank", "000013"],
  ["Heritage bank", "000020"],
  ["Jaiz bank", "000006"],
  ["Keystone bank", "000002"],
  ["Polaris bank", "000008"],
  ["Providus bank", "000023"],
  ["Stanbic IBTC bank", "000012"],
  ["Standard chartered bank", "000021"],
  ["Sterling bank", "000001"],
  ["SunTrust bank", "000022"],
  ["Taj bank", "000026"],
  ["Titan trust bank", "000025"],
  ["Union bank", "000018"],
  ["United bank for Africa", "000004"],
  ["Unity bank", "000011"],
  ["Wema bank", "000017"],
  ["Zenith bank", "000015"],
]);

export const bankList = Array.from(banks.keys());
