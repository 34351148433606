import React, { useState } from "react";
import { Link , useHistory } from "react-router-dom";
import { Menu, Dropdown } from "antd";
//import { GoPrimitiveDot } from "react-icons/go";
import LogoutModal from "../modals/logoutmodal/LogoutModal";
import user from "../../helpers/user";
import { Avatar } from "../avatar";
//import { Notify2 } from "../../assests/svgs";
import storage from "../../utils/storage";
import { LogOutIcon } from "../../assests/icons/Icons";
import { Notify } from "../../components/notification";

import "./styles.scss";

export const HeaderPage = () => {
  const [logoutModal, setLogoutModal] = useState(false);
  const history = useHistory();

  const toggleLogoutModal = () => {
    setLogoutModal(!logoutModal);
  };

  const avatarUrl = JSON.parse(storage.fetch("userDetails")).avatar;

  const toSettingPage = () => {
    if (navigator.onLine) {
     history.push("/dashboard/supervisor/settings");
     } else {
       Notify(
         "error",
         "Network Error!",
         "Please check your internet connection!",
         10
       );
     }
  }

  const profile = (
    <Menu className="top-menu">
      <Menu.Item className="top-items">
      <aside>
          {user.fullName()
            ? user.fullName()[0].toUpperCase() + user.fullName().substring(1)
            : "Hello"}
        </aside>
      </Menu.Item>
      <Menu.Divider className="divider" />
      <Menu.Item key="0" className="top-settings"
      onClick={(node, event) =>toSettingPage()}
      >
        <a>Profile</a>
      </Menu.Item>
      <Menu.Item key="1" className="top-support">
        <Link to={"//www.deempay.com/supportcenter"} target="_blank">Support</Link>
      </Menu.Item>
      <Menu.Divider className="divider" />
      <Menu.Item key="3" className="logout-hover">
        <section onClick={toggleLogoutModal}>
          <LogOutIcon className="top-Iconr" />
          <span >Sign out</span>
        </section>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header-top">
      <div className="top-bar">
        <h2 className="top-head">
          Welcome,{" "} 
          <span className="userName">
            {user.firstName()
              ? user.firstName()[0].toUpperCase() +
                user.firstName().substring(1)
              : ""}
          </span>
        </h2>
        <div className="topbar">
            <span>
              {/* <span className="top-notify">
                <img src={Notify2} alt="" className="bell" width="20" height="20" />
                <GoPrimitiveDot className="dot" />
              </span> */}
            </span>
          <Dropdown overlay={profile} trigger={["click"]}>
          <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <Avatar 
              imageUrl={ avatarUrl }
              asLink />

            </span>
          </Dropdown>
        </div>
      </div>
      {logoutModal && <LogoutModal closeModal={toggleLogoutModal} />}
    </div>
  );
};
