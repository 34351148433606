import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { FiEdit } from "react-icons/fi";
import {
  SunTrustIcon,
  ProvidusIcon,
  TitanIcon,
  TajIcon,
} from "../../assests/images";
import { useQuerySettlement } from "../../hooks/useSettlement";
import { Spinner } from "../../components/spinner";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import storage from "../../utils/storage";
import { useMerchantQuery } from "../../hooks/useMerchant";
import { SettlementTable } from "../../components/table/SettlementTable";
import {
  formatCurrency,
  formatDateTime,
  formatCount,
  formatBankNo,
} from "../../utils/functions";
import {
  NoSettlementIcon,
  ZenithIcon,
  UnionIcon,
  GTIcon,
  WemaIcon,
  AccessIcon,
  CitiIcon,
  EcoIcon,
  FidelityIcon,
  FCMBIcon,
  HeritageIcon,
  GlobusIcon,
  JaizIcon,
  KeyStoneIcon,
  PolarisIcon,
  StandardChartedIcon,
  UbaIcon,
  UnityIcon,
  StanbicIcon,
  SterlingIcon,
  FirstIcon,
} from "../../assests/icons/Icons";
import { Notify } from "../../components/notification";
import { pageList, pages } from "../../utils/constants/pages";
import { banks } from "../../utils/constants/banks";

import "./styles.scss";

const column = ["Bank", "Collected Amount", "Settled Amount", "Settlemt reference", "Date"];

export const SettlementPage = () => {
  const history = useHistory();
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [settlements, setSettlements] = useState([]);
  const [settlementCount, setSettlementCount] = useState(0);
  const [totalAmountSettled, setTotalAmountSettled] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState("1");
  const [currentSize, setCurrentSize] = useState(25);
  const [cardNo, setCardNo] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankIcon, setBankIcon] = useState([]);
  const [bankImg, setBankImg] = useState([]);

  const {
    data: merchantData,
    mutate: fetchMerchantDetails,
  } = useMerchantQuery({ cusId });

  const {
    isLoading: isFetchingSettlement,
    data: settleData,
    mutate: fetchSettlements,
  } = useQuerySettlement({
    cusId,
    page: currentPage,
    pageSize: currentSize,
  });

  useEffect(() => {
    fetchMerchantDetails();
  }, []);

  useEffect(() => {
    const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));

    if (!onboardingDetails.onboarded) {
      history.push("/dashboard/merchant/onboarding");
      Notify(
        "info",
        "Onboarding pending",
        "Please complete the onboarding process to start using your DeemPay account!",
        10
      );
    }
  }, []);

  useEffect(() => {
    fetchSettlements();
  }, [currentPage]);

  useEffect(() => {
    if (merchantData?.code === "000000") {
      setCardNo(merchantData?.data.cardInfo.cardNo);
      setBankName(merchantData?.data.cardInfo.bankName);

      switch (merchantData?.data.cardInfo.bankCode) {
        case "000014":
          //for access bank
          setBankIcon(AccessIcon);
          break;
        case "000005":
          // for Access bank (Diamond)
          setBankIcon(AccessIcon);
          break;
        case "000009":
          // for Citi bank
          setBankIcon(CitiIcon);
          break;
        case "000010":
          // for Ecobank
          setBankIcon(EcoIcon);
          break;
        case "000007":
          // for Fidelity bank
          setBankIcon(FidelityIcon);
          break;
        case "000016":
          // for First bank
          setBankIcon(FirstIcon);
          break;
        case "000003":
          // for First city monument bank
          setBankIcon(FCMBIcon);
          break;
        case "000027":
          // for Globus bank
          setBankIcon(GlobusIcon);
          break;
        case "000013":
          // for Guaranty trust bank
          setBankIcon(GTIcon);
          break;
        case "000020":
          // for Heritage bank
          setBankIcon(HeritageIcon);
          break;
        case "000006":
          // for Jaiz bank
          setBankIcon(JaizIcon);
          break;
        case "000002":
          // for Keystone bank
          setBankIcon(KeyStoneIcon);
          break;
        case "000008":
          // for Polaris bank
          setBankIcon(PolarisIcon);
          break;
        case "000023":
          // for Providus bank
          setBankImg(ProvidusIcon);
          break;
        case "000012":
          // for Stanbic IBTC bank
          setBankIcon(StanbicIcon);
          break;
        case "000021":
          // for Standard chartered bank
          setBankIcon(StandardChartedIcon);
          break;
        case "000001":
          // for Sterling bank
          setBankIcon(SterlingIcon);
          break;
        case "000022":
          // for SunTrust bank
          setBankImg(SunTrustIcon);
          break;
        case "000026":
          // for Taj bank
          setBankImg(TajIcon);
          break;
        case "000025":
          // for Titan trust bank
          setBankImg(TitanIcon);
          break;
        case "000018":
          // for Union bank
          setBankIcon(UnionIcon);
          break;
        case "000004":
          // for United bank for Africa
          setBankIcon(UbaIcon);
          break;
        case "000011":
          // for Unity bank
          setBankIcon(UnityIcon);
          break;
        case "000017":
          // for Wema bank
          setBankIcon(WemaIcon);
          break;
        case "000015":
          // for Zenith bank
          setBankIcon(ZenithIcon);
          break;
        default:
          setBankIcon(AccessIcon);
          break;
      }
    }
  }, [merchantData]);

  useEffect(() => {
    if (settleData?.code === "000000") {
      const formattedSettlements = settleData.data.list.map((t) => {
        return {
          bankCode: t.bankCode,
          bankName: getBankName(t.bankCode),
          settleAccountNo: t.settleAccountNo,
          dateDeposited: formatDateTime(t.createTime),
          settleAccountName: t.settleAccountName,
          settleAmount: formatCurrency(t.settleAmount),
          collectedAmount: formatCurrency(t.collectedAmount),
          settleBatchNo: t.settleBatchNo,
          currency: t.currency,
        };
      });
      setTotalAmountSettled(settleData.data.totalAmountSettled);
      setSettlementCount(settleData.data.totalCountSettled);
      setSettlements(formattedSettlements);
      setPageCount(Math.ceil(settleData.data.totalCountSettled / currentSize));
    }
  }, [settleData]);

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleChange = (e) => {
    setCurrentSize(pages.get(e.target.value));
    setCurrentPage(1);
  };

  const getBankName = (bankCode) => {
    let bankNam = ''
    banks.forEach(function(value, key) {
      if (bankCode === value){
        bankNam = key
      }
    })
    return bankNam 
  };

  const goBankSetting = () => {
    if (navigator.onLine) {
      history.push("/dashboard/merchant/settings/bank");
      } else {
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
      }
  };

  return (
    <Layout>
      <header>
        <main className="settlement">
          <div className="main-settle">
            <section className="header-container">
              <div>
                <h1>
                  Settlement
                  <span className="small__card">
                    {formatCount(settlementCount)}
                  </span>
                </h1>
              </div>
            </section>
            {isFetchingSettlement || settlements.length > 0 ? (
              <>
                {isFetchingSettlement ? (
                  <div style={{ marginLeft: "40px", marginTop: "20%" }}>
                    <Spinner color={"#1a71ff"} />
                  </div>
                ) : (
                  <>
                    <div className="settlement-card">
                      <div className="total-card">
                        <div className="main-title">
                          <span className="main-descr">
                            Total amount settled
                          </span>
                        </div>
                        <div className="card-dsicrption">
                          <div className="description">
                            <p id="font_change">{formatCurrency(totalAmountSettled)}</p>
                          </div>
                        </div>
                      </div>
                      <div className="bank-cards">
                        <div className="card-dsicrption">
                          <div className="description2">
                            <span className="bank__icons">{bankIcon}</span>
                            <img src={bankImg} alt="" />
                            <span>
                              <p className="bank">{bankName}</p>
                              <p className="accNo">{formatBankNo(cardNo)}</p>
                            </span>
                            <div className="editAcc"
                            onClick={(node, event) => goBankSetting()}
                            >
                              <a>
                                <button>
                                <FiEdit />
                                  <span className="edit">Edit</span>
                                </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transactionTable">
                      <SettlementTable column={column} data={settlements} />
                      {isFetchingSettlement ? (
                        <>
                          <div
                            style={{ marginTop: "40px", marginBottom: "8rem" }}
                          >
                            <Spinner color={"#1a71ff"} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="pagination--D">
                            <div className="items-to-show">
                              <p>Items to show: {currentSize} </p>
                              <select onChange={handleChange}>
                                {pageList.map((page, i) => (
                                  <option
                                    key={i}
                                    selected={page === currentSize}
                                  >
                                    {page}
                                  </option>
                                ))}
                              </select>
                              <MdKeyboardArrowDown className="business-down" />
                            </div>
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel={<IoIosArrowRoundForward />}
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={1}
                              pageCount={pageCount}
                              previousLabel={<IoIosArrowRoundBack />}
                              renderOnZeroPageCount={null}
                              containerClassName={"pagination__container"}
                              activeClassName={"active"}
                              previousLinkClassName={"pages-p"}
                              nextLinkClassName={"pages-p"}
                              forcePage={currentPage - 1}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : !!isFetchingSettlement || settlements.length === 0 ? (
              <>
                <section className="noData">
                  <NoSettlementIcon />
                  <h2>No Settlement!</h2>
                  <h4>
                    You do not have any settlement made to your account. You may
                    need to clear the query or clear the filter.
                  </h4>
                </section>
              </>
            ) : (
              ""
            )}
          </div>
        </main>
      </header>
    </Layout>
  );
};
