import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import storage from "../../../../utils/storage";
import {
  formatCount,
  formatCurrency,
  formatDateTime,
  truncateName,
} from "../../../../utils/functions";
import { Spinner } from "../../../../components/spinner";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowDown } from "react-icons/md";
import { pageList, pages } from "../../../../utils/constants/pages";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { reversePaymentMethods } from "../../../../utils/constants/paymentMethods";
import { periodList, periods } from "../../../../utils/constants/periods";
import { useQueryPayLink, useCreatePayLink } from "../../../../hooks/usePaymentLink";
import { CopiedIcon, CopyIcon } from "../../../../assests/icons/Icons";
import { reverseLinkTypes } from "../../../../utils/constants/linkType";
import { reverseLinkStatus } from "../../../../utils/constants/linkStatus";
import { Notify } from "../../../../components/notification";
import { Table } from "../../../../components/table/Table";
import { PayLkTable } from "../../../../components/table/PayLkTable";
import { CSVLink } from "react-csv";
import {
  useQueryTransaction,
  useTradeSummary,
} from "../../../../hooks/useTransaction";

import "./styles.scss";
import "./deactivatestyles.scss";
import { SpinnerWhite } from "../../../../components/spinner/Spinner";
import SupervisorLayout from "../../../../components/layout/SupervisorLayout";

const column = [
  "Amount",
  "Payment method",
  "Station Name",
  "Payer's name",
  "Date",
];
const columnSchool = [
  "Amount",
  "Payment method",
  "Student name",
  "Class",
  "Date",
];

export const SupervisorFeePaymentDetailsPage = () => {
  const currentDate = new Date().toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const { linkId, linkCode } = useParams();
  const exportTransactionRef = useRef(null);
  const [payLink, setpayLink] = useState({});
  const [isShowTransaction, setIsShowTransaction] = useState(true);
  const [isShowPage, setIsShowPage] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isMoreFees, setMoreIsFees] = useState(false);
  const [isClasses, setIsClasses] = useState(false);
  const [isIputAmount, setIsInputAmount] = useState(false);
  const [copied, setCopied] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [exporttransactions, setExportTransactions] = useState([]);
  const [transactionSchool, setTransactionSchool] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [summaryType, setSummaryType] = useState("CURRENT_DAY");
  const [sumTypeDisplay, setSumTypeDisplay] = useState("1 Day");
  const [totalAmount, setTotalAmount] = useState(0);
  const [tradeCount, setTradeCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [currentSize, setCurrentSize] = useState(10);
  const [displayDate, setDisplayDate] = useState(currentDate);
  const [statusPl, setStatusPl] = useState(1);
  const [deactivateModal, setDeactivatetModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [linkType, setLinkType] = useState(1);
  const [labels, setLabels] = useState({});
  let history = useHistory();

  const {
    isLoading: isFetchingPayLink,
    data: queryPayLinkData,
    mutate: fetchQueryPayLink,
  } = useQueryPayLink({
    cusId,
    linkId,
  });

  const {
    isLoading: isCreatingPayLink,
    data: payLinkData,
    mutate: fetchCreatePaylink,
  } = useCreatePayLink({
    cusId,
    linkId,
    status: statusPl,
  });

  const {
    isLoading: isFetchingSummary,
    data: summaryData,
    mutate: fetchTradeSummary,
  } = useTradeSummary({
    cusId,
    summaryType,
    //terminalCode: chlTermNo, //TerminalCode here reqiure to pass the cashiersub merchantNo(chlTermNo)
    linkCode: linkCode,
  });

  const {
    isLoading: isFetchingTransaction,
    data: transactionData,
    mutate: fetchTransactions,
  } = useQueryTransaction({
    cusId,
    page: currentPage,
    pageSize: currentSize,
    //terminalCode: chlTermNo, //TerminalCode here reqiure to pass the cashiersub merchantNo(chlTermNo)
    linkCode: linkCode,
  });

  const {
    isLoading: isFetchingTransactionExport,
    data: transactionDataExport,
    mutate: fetchTransactionsExport,
  } = useQueryTransaction({
    cusId,
    page: "1",
    pageSize: transactionCount,
    linkCode: linkCode,
  });

  useEffect(() => {
    fetchTradeSummary();
  }, [summaryType]);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, currentSize]);

  useEffect(() => {
    if (summaryData?.code === "000000") {
      setTotalAmount(summaryData?.data?.sumTradeAmt);
      setTradeCount(summaryData?.data?.sumCnt);
      if (summaryType !== "ALL") {
        let startDate = new Date(summaryData?.data?.startDate);
        let endDate = new Date(summaryData?.data?.endDate);
        if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth() &&
          startDate.getDate() === endDate.getDate()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr);
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() !== endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr + " - " + endDateStr);
        }
      } else {
        let startDate = new Date(summaryData?.data?.minDate);
        let endDate = new Date(summaryData?.data?.maxDate);

        if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth() &&
          startDate.getDate() === endDate.getDate()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr);
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() !== endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr + " - " + endDateStr);
        }
      }
    }
  }, [summaryData]);

  useEffect(() => {
    if (transactionData?.code === "000000") {
      if (linkType === 2) {
        const formattedTransactions = transactionData.data.result.map((t) => {
          var metaData = {};
          if (typeof t.metaData === "string" || t.metaData instanceof String) {
            if (t.metaData !== "null" && t.metaData !== null) {
              try {
                metaData = JSON.parse(t.metaData);
              } catch (err) {
                console.log(err);
              }
            }
          }

          return {
            linkType: linkType,
            payAccountName: truncateName(t.payAccountName),
            tradeAmt: formatCurrency(t.tradeAmt),
            terminalName:
              t.terminalName === null ? "Main account" : t.terminalName,
            productCode: reversePaymentMethods.get(t.productCode),
            finishTime: formatDateTime(t.finishTime),
            orderNo: t.orderNo,
            classId: t.classId,
            studentName: truncateName(metaData.studentInformation["Full name"]),
            classNm: metaData.studentInformation["Class"],
          };
        });
        setTransactionCount(transactionData.data.total);
        setTransactionSchool(formattedTransactions);
        setPageCount(Math.ceil(transactionData.data.total / currentSize));
        if (isExportLoading) {
          fetchTransactionsExport();
        }
      } else {
        const formattedTransactions = transactionData.data.result.map((t) => {
          return {
            linkType: linkType,
            payAccountName: truncateName(t.payAccountName),
            tradeAmt: formatCurrency(t.tradeAmt),
            terminalName:
              t.terminalName === null ? "Main account" : t.terminalName,
            productCode: reversePaymentMethods.get(t.productCode),
            finishTime: formatDateTime(t.finishTime),
            orderNo: t.orderNo,
            classId: t.classId,
          };
        });
        setTransactionCount(transactionData.data.total);
        setTransactions(formattedTransactions);
        setPageCount(Math.ceil(transactionData.data.total / currentSize));
        if (isExportLoading) {
          fetchTransactionsExport();
        }
      }
    }
  }, [transactionData]);

  useEffect(() => {
    if (transactionDataExport?.code === "000000") {
      if (linkType === 2) {
        const unFormattedTransactions = transactionDataExport.data.result.map(
          (t) => {
            var metaData = {};
            if (
              typeof t.metaData === "string" ||
              t.metaData instanceof String
            ) {
              if (t.metaData !== "null" && t.metaData !== null) {
                try {
                  metaData = JSON.parse(t.metaData);
                } catch (err) {
                  console.log(err);
                }
              }
            }
            return {
              linkType: linkType,
              payAccountName: t.payAccountName,
              tradeAmt: t.tradeAmt,
              terminalName:
                t.terminalName === null ? "Main account" : t.terminalName,
              productCode: reversePaymentMethods.get(t.productCode),
              finishTime: formatDateTime(t.finishTime),
              orderNo: t.orderNo,
              classId: t.classId,
              studentName: metaData.studentInformation["Full name"],
              classNm: metaData.studentInformation["Class"],
            };
          }
        );
        setExportTransactions(unFormattedTransactions);
        setTimeout(() => {
          exportTransactionRef.current.link.click();
        }, 500);
        setIsExportLoading(false);
      } else {
        const unFormattedTransactions = transactionDataExport.data.result.map(
          (t) => {
            return {
              linkType: linkType,
              payAccountName: t.payAccountName,
              tradeAmt: t.tradeAmt,
              terminalName:
                t.terminalName === null ? "Main account" : t.terminalName,
              productCode: reversePaymentMethods.get(t.productCode),
              finishTime: formatDateTime(t.finishTime),
              orderNo: t.orderNo,
              classId: t.classId,
            };
          }
        );
        setExportTransactions(unFormattedTransactions);
        setTimeout(() => {
          exportTransactionRef.current.link.click();
        }, 500);
        setIsExportLoading(false);
      }
    }
  }, [transactionDataExport]);

  useEffect(() => {
    fetchCreatePaylink();
  }, [statusPl]);

  useEffect(() => {
    if (payLinkData?.code === "000000") {
      if (statusPl === 0) {
        history.push("/dashboard/supervisor/payment-link");
        closeDeactive();
        Notify(
          "success",
          "Link deleted successful!",
          "Your payment link is deleted successfully"
        );
      }
    }
  }, [payLinkData]);

  const handleChangeSummary = (e) => {
    setSummaryType(periods.get(e.target.value));
    setSumTypeDisplay(e.target.value);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleChange = (e) => {
    setCurrentSize(pages.get(e.target.value));
    setCurrentPage(1);
  };

  const deActivate = () => {
    setStatusPl(0);
  };

  useEffect(() => {
    if (queryPayLinkData?.code === "000000") {
      if (queryPayLinkData?.data[0]?.linkType === 2) {
        setIsFilter(true);
        setMoreIsFees(true);
        setIsClasses(true);
      } else if (queryPayLinkData?.data[0]?.linkType === 1) {
        if (queryPayLinkData?.data[0]?.fees?.length > 0) {
          setIsFilter(false);
          setMoreIsFees(true);
        } else {
          setIsFilter(false);
          setIsInputAmount(true);
        }
      } else if (queryPayLinkData?.data[0]?.linkType === 3) {
        setIsFilter(false);
        setIsInputAmount(true);
      }

      setpayLink(queryPayLinkData?.data[0]);
      setLinkType(queryPayLinkData?.data[0]?.linkType);
      const labelInner = [];
      queryPayLinkData?.data[0]?.labels?.forEach((item, index, arr) => {
        if (index === queryPayLinkData?.data[0]?.labels?.length - 1) {
          labelInner.push(item.labelName);
        } else {
          labelInner.push(item.labelName);
          labelInner.push(", ");
        }
      });
      setImageUrl(queryPayLinkData?.data[0]?.image?.imageUrl);
      setLabels(labelInner);
    }
  }, [queryPayLinkData]);

  useEffect(() => {
    fetchQueryPayLink();
  }, []);

  const showTransaction = () => {
    setIsShowPage(false);
    setIsShowTransaction(true);
  };

  const showPage = () => {
    setIsShowTransaction(false);
    setIsShowPage(true);
  };

  //COPY TEXT TO CLIPBOARD
  const handleCopy = () => {
    navigator.clipboard
      .writeText(`https://pay.deempay.com/${payLink.linkCode}`)
      .then(() => {
        setCopied(false);
        setTimeout(() => {
          setCopied(true);
        }, 2000);
      })
      .catch((error) => console.log(error));
  };

  const showDeactive = () => {
    setDeactivatetModal(true);
  };

  const closeDeactive = () => {
    setDeactivatetModal(false);
  };

  return (
    <SupervisorLayout>
      <header>
        <main className="main__main">
          <div class="mb-7 flex justify-between">
            <h1 class="text_22_600">Payment page</h1>
            <span>
              {/* <button class="white___btn mr-4">Edit Link</button> */}
              <button
                onClick={showDeactive}
                class="text-[#ffffff] rounded-lg sm:text-base text-xs font-medium bg-[red] hover:bg-[red] px-4 sm:h-[48px] h-[38px]"
              >
                {isCreatingPayLink ? <SpinnerWhite /> : <>Delete</>}
              </button>
            </span>
          </div>

          {deactivateModal ? (
            <div className="logout__modal">
              <div id="myModal" class="logout-modal">
                <div class="logout-content">
                  <div className="logout-container">
                    <p className="logout">Delete</p>
                  </div>
                  <h6 className="modalDropDown">
                    Are you sure you want to delete this payment link? This
                    action is irreversible
                  </h6>
                  <div className="log__btns">
                    <button className="stay-btn" onClick={closeDeactive}>
                      Cancel
                    </button>
                    <button className="logout-btn" onClick={deActivate}>
                      <span className="export-text">
                        {isCreatingPayLink ? <SpinnerWhite /> : <>Continue</>}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <section class="grid  gap-6 xl:grid-cols-2 grid-cols-1 ">
            <div class="bg-[#F0F6FF] rounded-lg md:px-8 px-3.5 h-[286px]">
              {isFetchingPayLink ? (
                <div style={{ marginTop: "120px" }}>
                  <Spinner />
                </div>
              ) : (
                <>
                  <h2 class="text-[#002766] text_16_500 mb-7 mt-6">
                    Payment page details
                  </h2>

                  <div class="flex  justify-between  mb-2">
                    <p class="text_16_400 ">Page name</p>
                    <p class="text_16_500">{payLink.pageTitle}</p>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text_16_400">Link</p>
                    <span class="flex  space-x-2 items-center">
                      <p class="text_16_500 flex">{`https://pay.deempay.com/${payLink.linkCode}`}</p>
                      {copied ? (
                        <span
                          class="mt-[-17px] cursor-pointer"
                          onClick={handleCopy}
                        >
                          <CopyIcon />
                        </span>
                      ) : (
                        <span
                          class="mt-[-17px] cursor-pointer"
                          onClick={handleCopy}
                        >
                          <CopiedIcon />
                        </span>
                      )}
                    </span>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text_16_400">Status</p>
                    <button class="bg-[#ccffe6] text_16_500  h-[32px] px-6 rounded">
                      {reverseLinkStatus.get(payLink.status)}
                    </button>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text_16_400">Date Created</p>
                    <p class="text_16_500 ">
                      {formatDateTime(payLink.createTime)}
                    </p>
                  </div>
                </>
              )}
            </div>

            <div class="bg-[#ffffff] border border-[#d7d7d7] rounded-lg md:px-8 px-3.5 h-[286px]">
              {isFetchingPayLink || isFetchingSummary ? (
                <div style={{ marginTop: "120px" }}>
                  <Spinner />
                </div>
              ) : (
                <>
                  <h2 class="text-[#002766] text-base font-medium mb-2 mt-6">
                    Transaction summary
                  </h2>
                  <div class="border-b border-dashed border-[#dfdfdf] mb-5 "></div>

                  <div class="flex  justify-between mb-2">
                    <p class="text-[#595959] text_16_400">Summary type</p>

                    <span className="home3-sub">
                      <select onChange={handleChangeSummary}>
                        {periodList.map((period, i) => (
                          <option key={i} selected={period === sumTypeDisplay}>
                            {period}
                          </option>
                        ))}
                      </select>
                      <MdKeyboardArrowDown className="date-down" />
                    </span>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text-[#595959] text_16_400">Transaction count</p>
                    <p class="text_16_500">{formatCount(tradeCount)}</p>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text-[#595959] text_16_400">Total amount</p>
                    <p class="text_16_500">{formatCurrency(totalAmount)}</p>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text-[#595959] text_16_400">
                      Transaction timeline
                    </p>
                    <p class="text_16_500">{displayDate}</p>
                  </div>
                </>
              )}
            </div>
          </section>

          {isShowTransaction ? (
            <>
              <div class="mt-[73px] flex   justify-between">
                <span class="flex">
                  <div>
                    <h1 class="text-[#000000] text-[16px] font-medium cursor-pointer ">
                      Transactions
                    </h1>
                    <div class="bg-[#65bf73] h-[3px] w-[40px] mt-[-8px]"></div>
                  </div>
                  <h1
                    onClick={showPage}
                    class="text-[#767676] text-[16px] font-medium pl-[40px] cursor-pointer"
                  >
                    Page additional information
                  </h1>
                </span>

                <span class=" flex justify-end md:mt-0 mt-5">
                  {/* {isFilter ? (
                    <button class="white___btn mr-4">Filter</button>
                  ) : null} */}
                  <CSVLink
                    data={exporttransactions}
                    filename={`Transaction_report_${payLink.createTime}`}
                    ref={exportTransactionRef}
                  ></CSVLink>
                  <button onClick={fetchTransactionsExport} class="blue___btn">
                    {isFetchingTransactionExport ? <SpinnerWhite /> : "Export"}
                  </button>
                </span>
              </div>
              <div className="transactionTable">
                {linkType === 2 ? (
                  <PayLkTable
                    column={columnSchool}
                    data={transactionSchool}
                    link={"/dashboard/merchant/transactions/"}
                  />
                ) : (
                  <Table
                    column={column}
                    data={transactions}
                    link={"/dashboard/merchant/transactions/"}
                  />
                )}

                {isFetchingTransaction ? (
                  <>
                    <div style={{ marginTop: "40px", marginBottom: "8rem" }}>
                      <Spinner color={"#1a71ff"} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pagination--Details">
                      <div className="items-to-show">
                        <p>Items to show: {currentSize} </p>
                        <select onChange={handleChange}>
                          {pageList.map((page, i) => (
                            <option key={i} selected={page === currentSize}>
                              {page}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="business-down" />
                      </div>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IoIosArrowRoundForward />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel={<IoIosArrowRoundBack />}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination__container"}
                        activeClassName={"active"}
                        previousLinkClassName={"pages-p"}
                        nextLinkClassName={"pages-p"}
                        forcePage={currentPage - 1}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}

          {isShowPage ? (
            <>
              <div class="mt-[73px] md:flex grid justify-between">
                <span class="flex">
                  <h1
                    onClick={showTransaction}
                    class="text-[#767676] text-[16px] font-medium cursor-pointer "
                  >
                    Transactions
                  </h1>
                  <div class="">
                    <h1 class="text-[#000000] text-[16px] font-medium pl-[40px] cursor-pointer">
                      Page additional information
                    </h1>
                    <div class="bg-[#65bf73] h-[3px] w-[40px] ml-[40px] mt-[-8px]"></div>
                  </div>
                </span>
              </div>
              <section class="grid  xl:gap-[62px] gap-[0px] xl:grid-cols-2 grid-cols-1 md:px-[32px] px-[18px] mt-[53px] bg-[#F0F6FF] rounded-[12px] py-[40px]  h-[auto] mb-7">
                {isFetchingPayLink ? (
                  <Spinner />
                ) : (
                  <>
                    <div>
                      <h2 class="text-[#002766] text_16_500 ">Description</h2>
                      <p class="text_16_400 mb-5">{payLink.pageDescription}</p>
                      <h2 class="text-[#002766] text_16_500 ">Payment type</h2>
                      <p class="text_16_400 mb-5">
                        {reverseLinkTypes.get(payLink.linkType)}
                      </p>
                      {isIputAmount ? (
                        <>
                          <h2 class="text-[#002766] text_16_500 ">
                            Customer Input amount
                          </h2>
                          <p class="text_16_400 mb-5">
                            {payLink.customerInputAmount === 0
                              ? "False"
                              : "True"}
                          </p>
                        </>
                      ) : null}
                      {/* {isClasses ? (
                        <>
                        <h2 class="text-[#002766] text_16_500 ">
                            Class name
                          </h2>
                          <p class="text_16_400 mb-5">
                            Primary 1-B
                          </p>
                        </>
                      ) : null} */}
                      {isMoreFees ? (
                        <h2 class="text-[#002766] text_16_500 mb-4">
                          Fee details
                          {payLink.fees.map((item, index) => (
                            <span
                              class="flex justify-between pt-1.5"
                              key={index}
                            >
                              <p class="text_16_400 mb-1.5">{item.feeName}</p>
                              <p class="text_16_400 mb-1.5">
                                {formatCurrency(item.amount)}
                              </p>
                            </span>
                          ))}
                        </h2>
                      ) : null}

                      <h2 class="text-[#002766] text_16_500 ">Redirect link</h2>
                      <p class="text_16_400 mb-5">
                        {payLink.redirectUrl === "" ? "-" : payLink.redirectUrl}
                      </p>

                      <h2 class="text-[#002766] text_16_500 ">Custom field</h2>
                      <p class="text_16_400 ">{labels === "" ? labels : "-"}</p>
                      <h2 class="text-[#002766] text_16_500 ">
                        Return message
                      </h2>
                      <p class="text_16_400 mb-5">
                        {payLink.message === "" ? "-" : payLink.message}
                      </p>
                    </div>

                    <div>
                      <h2 class="text-[#002766] text_16_500 mb-2.5 ">Image</h2>
                      <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg h-[165px] md:w-[272px] md-full">
                        <img
                          src={imageUrl}
                          alt=""
                          class="h-full w-full object-cover object-center"
                        />
                      </div>
                    </div>
                  </>
                )}
              </section>
            </>
          ) : null}
        </main>
      </header>
    </SupervisorLayout>
  );
};
