import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout/Layout";
import { useParams, useHistory } from "react-router-dom";
import storage from "../../utils/storage";
import file from "../../helpers/file";
import {
  AddFeeIcon,
  FeeArrowUpIcon,
  CloseModalIcon,
  FeeArrowDownIcon,
} from "../../assests/icons/Icons";
import { CSVLink } from "react-csv";
import {
  formatCount,
  formatCurrency,
  formatDateTime,
  truncateName,
} from "../../utils/functions";
import { Spinner } from "../../components/spinner";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowDown } from "react-icons/md";
import { pageList, pages } from "../../utils/constants/pages";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { reversePaymentMethods } from "../../utils/constants/paymentMethods";
import { periodList, periods } from "../../utils/constants/periods";
import { useQueryPayLink, useCreatePayLink } from "../../hooks/usePaymentLink";
import { CopiedIcon, CopyIcon } from "../../assests/icons/Icons";
import { reverseLinkTypes } from "../../utils/constants/linkType";
import { reverseLinkStatus } from "../../utils/constants/linkStatus";
import { Notify } from "../../components/notification";
import { Table } from "../../components/table/Table";
import { PayLkTable } from "../../components/table/PayLkTable";
import Select from "react-select";
import { useImageUploadPayLink } from "../../hooks/useFileUpload";
import {
  useQueryTransaction,
  useTradeSummary,
} from "../../hooks/useTransaction";
import { useQueryFee } from "../../hooks/useFee";
import { useFieldArray, useForm } from "react-hook-form";

import "./styles.scss";
import "./deactivatestyles.scss";
import { SpinnerWhite } from "../../components/spinner/Spinner";

const column = [
  "Amount",
  "Payment method",
  "Station Name",
  "Payer's name",
  "Date",
];
const columnSchool = [
  "Amount",
  "Payment method",
  "Student name",
  "Class",
  "Date",
];

export const FeePaymentDetailsPage = () => {
  const currentDate = new Date().toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const selectRef = useRef(null);
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const { linkId, linkCode } = useParams();
  const exportTransactionRef = useRef(null);
  const [payLink, setpayLink] = useState({});
  const [isShowTransaction, setIsShowTransaction] = useState(true);
  const [isShowPage, setIsShowPage] = useState(false);
  const [copied, setCopied] = useState(true);
  const [feeOptions, setFeeOptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionSchool, setTransactionSchool] = useState([]);
  const [summaryType, setSummaryType] = useState("CURRENT_DAY");
  const [sumTypeDisplay, setSumTypeDisplay] = useState("1 Day");
  const [totalAmount, setTotalAmount] = useState(0);
  const [tradeCount, setTradeCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [exporttransactions, setExportTransactions] = useState([]);
  const [currentSize, setCurrentSize] = useState(10);
  const [displayDate, setDisplayDate] = useState(currentDate);
  const [statusPl, setStatusPl] = useState(1);
  const [deactivateModal, setDeactivatetModal] = useState(false);
  const [selectedFeeOption, setSelectedFeeOption] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isMoreFees, setMoreIsFees] = useState(false);
  const [isClasses, setIsClasses] = useState(false);
  const [isIputAmount, setIsInputAmount] = useState(false);
  const [showSchoolFees, setShowSchoolFees] = useState(false);
  const [transactionCount, setTransactionCount] = useState(0);
  const [showSchoolFees2, setShowSchoolFees2] = useState(false);
  const [showSchoolFees3, setShowSchoolFees3] = useState(false);
  const [showSchoolFees4, setShowSchoolFees4] = useState(false);
  const [showSchoolFees5, setShowSchoolFees5] = useState(false);
  const [showSchoolFees6, setShowSchoolFees6] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showAddClass, setShowAddClass] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSinglePaymentModal, setShowSinglePaymentModal] = useState(false);
  const [showMutiplePaymentModal, setShowMultiplePaymentModal] =
    useState(false);
  const [showMutiplePaymentModal2, setShowMultiplePaymentModal2] =
    useState(false);
  const [showMutiplePaymentModal3, setShowMultiplePaymentModal3] =
    useState(false);
  const [showMutiplePaymentModal4, setShowMultiplePaymentModal4] =
    useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const [show, setShow] = useState(false);
  const [classCategory, setClassCategory] = useState("");
  const [fileSuffix, setFileSuffix] = useState("");
  const [base64Certificate, setBase64Certificate] = useState("");
  const [imageNameTemp, setImageNameTemp] = useState("");
  const [imageName, setImageName] = useState("");
  const [addInfoCount, setAddInfoCount] = useState(0);
  const [payLinkDetails, setPayLinkDetails] = useState(null);
  const [selectedClassOption, setSelectedClassOption] = useState([]);
  const [classOptions, setClassOptions] = useState(null);
  const [imageId, setImageId] = useState();
  const uploadRef = useRef();
  const [imageUrl, setImageUrl] = useState("");
  const [linkType, setLinkType] = useState(1);
  const [labels, setLabels] = useState({});
  const [showImg, setShowImg] = useState(true);
  const [showEditImg, setShowEditImg] = useState(false);
  let history = useHistory();

  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      field: [{ className: "", classCategory: "" }],
      fieldFee: [{ feeName: "", feeAmount: "", feeDescription: "" }],
      fieldClass: [{ className: "", classCategory: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "field",
  });

  const {
    isLoading: isFetchingPayLink,
    data: queryPayLinkData,
    mutate: fetchQueryPayLink,
  } = useQueryPayLink({
    cusId,
    linkId,
  });

  const {
    isLoading: isCreatingPayLink,
    data: payLinkData,
    mutate: fetchCreatePaylink,
  } = useCreatePayLink({
    linkId,
    status: statusPl,
    cusId,
    linkType: linkType,
    ...payLinkDetails,
  });

  const {
    isLoading: isFetchingSummary,
    data: summaryData,
    mutate: fetchTradeSummary,
  } = useTradeSummary({
    cusId,
    summaryType,
    //terminalCode: chlTermNo, //TerminalCode here reqiure to pass the cashiersub merchantNo(chlTermNo)
    linkCode: linkCode,
  });

  const {
    isLoading: isFetchingTransaction,
    data: transactionData,
    mutate: fetchTransactions,
  } = useQueryTransaction({
    cusId,
    page: currentPage,
    pageSize: currentSize,
    //terminalCode: chlTermNo, //TerminalCode here reqiure to pass the cashiersub merchantNo(chlTermNo)
    linkCode: linkCode,
  });

  const {
    isLoading: isFetchingTransactionExport,
    data: transactionDataExport,
    mutate: fetchTransactionsExport,
  } = useQueryTransaction({
    cusId,
    page: "1",
    pageSize: transactionCount,
    linkCode: linkCode,
  });

  const {
    isLoading: isQueryingFee,
    data: feeQueryData,
    mutate: fetchQueryFee,
  } = useQueryFee({
    cusId,
  });

  const {
    isLoading: isImageUploading,
    data: imageUploadData,
    mutate: fetchImageUpload,
  } = useImageUploadPayLink({
    cusId,
    fileSuffix: fileSuffix,
    base64: base64Certificate,
  });

  useEffect(() => {
    fetchTradeSummary();
  }, [summaryType]);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, currentSize]);

  useEffect(() => {
    if (summaryData?.code === "000000") {
      setTotalAmount(summaryData?.data?.sumTradeAmt);
      setTradeCount(summaryData?.data?.sumCnt);

      if (summaryType !== "ALL") {
        let startDate = new Date(summaryData?.data?.startDate);
        let endDate = new Date(summaryData?.data?.endDate);

        if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth() &&
          startDate.getDate() === endDate.getDate()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr);
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() !== endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr + " - " + endDateStr);
        }
      } else {
        let startDate = new Date(summaryData?.data?.minDate);
        let endDate = new Date(summaryData?.data?.maxDate);

        if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth() &&
          startDate.getDate() === endDate.getDate()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr);
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() !== endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr + " - " + endDateStr);
        }
      }
    }
  }, [summaryData]);

  useEffect(() => {
    if (transactionData?.code === "000000") {
      if (linkType === 2) {
        const formattedTransactions = transactionData.data.result.map((t) => {
          var metaData = {};
          if (typeof t.metaData === "string" || t.metaData instanceof String) {
            if (t.metaData !== "null" && t.metaData !== null) {
              try {
                metaData = JSON.parse(t.metaData);
              } catch (err) {
                console.log(err);
              }
            }
          }

          return {
            linkType: linkType,
            payAccountName: truncateName(t.payAccountName),
            tradeAmt: formatCurrency(t.tradeAmt),
            terminalName:
              t.terminalName === null ? "Main account" : t.terminalName,
            productCode: reversePaymentMethods.get(t.productCode),
            finishTime: formatDateTime(t.finishTime),
            orderNo: t.orderNo,
            classId: t.classId,
            studentName: truncateName(metaData.studentInformation["Full name"]),
            classNm: metaData.studentInformation["Class"],
          };
        });
        setTransactionCount(transactionData.data.total);
        setTransactionSchool(formattedTransactions);
        setPageCount(Math.ceil(transactionData.data.total / currentSize));
        if (isExportLoading) {
          fetchTransactionsExport();
        }
      } else {
        const formattedTransactions = transactionData.data.result.map((t) => {
          return {
            linkType: linkType,
            payAccountName: truncateName(t.payAccountName),
            tradeAmt: formatCurrency(t.tradeAmt),
            terminalName:
              t.terminalName === null ? "Main account" : t.terminalName,
            productCode: reversePaymentMethods.get(t.productCode),
            finishTime: formatDateTime(t.finishTime),
            orderNo: t.orderNo,
            classId: t.classId,
          };
        });
        setTransactionCount(transactionData.data.total);
        setTransactions(formattedTransactions);
        setPageCount(Math.ceil(transactionData.data.total / currentSize));
        if (isExportLoading) {
          fetchTransactionsExport();
        }
      }
    }
  }, [transactionData]);

  useEffect(() => {
    if (transactionDataExport?.code === "000000") {
      if (linkType === 2) {
        const unFormattedTransactions = transactionDataExport.data.result.map(
          (t) => {
            var metaData = {};
            if (
              typeof t.metaData === "string" ||
              t.metaData instanceof String
            ) {
              if (t.metaData !== "null" && t.metaData !== null) {
                try {
                  metaData = JSON.parse(t.metaData);
                } catch (err) {
                  console.log(err);
                }
              }
            }
            return {
              linkType: linkType,
              payAccountName: t.payAccountName,
              tradeAmt: t.tradeAmt,
              terminalName:
                t.terminalName === null ? "Main account" : t.terminalName,
              productCode: reversePaymentMethods.get(t.productCode),
              finishTime: formatDateTime(t.finishTime),
              orderNo: t.orderNo,
              classId: t.classId,
              studentName: metaData.studentInformation["Full name"],
              classNm: metaData.studentInformation["Class"],
            };
          }
        );
        setExportTransactions(unFormattedTransactions);
        setTimeout(() => {
          exportTransactionRef.current.link.click();
        }, 500);
        setIsExportLoading(false);
      } else {
        const unFormattedTransactions = transactionDataExport.data.result.map(
          (t) => {
            return {
              linkType: linkType,
              payAccountName: t.payAccountName,
              tradeAmt: t.tradeAmt,
              terminalName:
                t.terminalName === null ? "Main account" : t.terminalName,
              productCode: reversePaymentMethods.get(t.productCode),
              finishTime: formatDateTime(t.finishTime),
              orderNo: t.orderNo,
              classId: t.classId,
            };
          }
        );
        setExportTransactions(unFormattedTransactions);
        setTimeout(() => {
          exportTransactionRef.current.link.click();
        }, 500);
        setIsExportLoading(false);
      }
    }
  }, [transactionDataExport]);

  useEffect(() => {
    //fetchCreatePaylink();
  }, [statusPl]);

  useEffect(() => {
    if (payLinkData?.code === "000000") {

      if (statusPl === "0") {
        history.push("/dashboard/merchant/payment-link");
        closeDeactive();
        //history(-1)
        Notify(
          "success",
          "Link deleted successful!",
          "Your payment link is deleted successfully"
        );
      }else{
        Notify(
            "success",
            "Link edited successful!",
            "Your payment link was edited successfully"
          );
    
          closeModal();
          fetchQueryPayLink();
          //resetField("fieldFee");
      }
    }else if (payLinkData?.code === "US0066"){
        Notify(
            "error",
            "Custom link error",
            "Sorry! this custom link already exists, please input a new custom link or leave field empty.",
            10
          );
    }
  }, [payLinkData]);

  const handleChangeSummary = (e) => {
    setSummaryType(periods.get(e.target.value));
    setSumTypeDisplay(e.target.value);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleChange = (e) => {
    setCurrentSize(pages.get(e.target.value));
    setCurrentPage(1);
  };

  const deActivate = () => {
    setStatusPl(0);
    fetchCreatePaylink();
  };

  useEffect(() => {
    if (queryPayLinkData?.code === "000000") {
      if (queryPayLinkData?.data[0]?.linkType === 2) {
        setIsFilter(true);
        setMoreIsFees(true);
        setIsClasses(true);
      } else if (queryPayLinkData?.data[0]?.linkType === 1) {
        if (queryPayLinkData?.data[0]?.fees?.length > 0) {
          setIsFilter(false);
          setMoreIsFees(true);
        } else {
          setIsFilter(false);
          setIsInputAmount(true);
        }
      } else if (queryPayLinkData?.data[0]?.linkType === 3) {
        setIsFilter(false);
        setIsInputAmount(true);
      }
      setpayLink(queryPayLinkData?.data[0]);
      setLinkType(queryPayLinkData?.data[0]?.linkType);
      const labelInner = [];
      queryPayLinkData?.data[0]?.labels?.forEach((item, index, arr) => {
        if (index === queryPayLinkData?.data[0]?.labels?.length - 1) {
          labelInner.push(item.labelName);
        } else {
          labelInner.push(item.labelName);
          labelInner.push(", ");
        }
      });
      setImageUrl(queryPayLinkData?.data[0]?.image?.imageUrl);
      setLabels(labelInner);
    }
  }, [queryPayLinkData]);

  useEffect(() => {
    fetchQueryPayLink();
  }, []);

  const showTransaction = () => {
    setIsShowPage(false);
    setIsShowTransaction(true);
  };

  const showPage = () => {
    setIsShowTransaction(false);
    setIsShowPage(true);
  };

  //COPY TEXT TO CLIPBOARD
  const handleCopy = () => {
    navigator.clipboard
      .writeText(`https://pay.deempay.com/${payLink.linkCode}`)
      .then(() => {
        setCopied(false);
        setTimeout(() => {
          setCopied(true);
        }, 2000);
      })
      .catch((error) => console.log(error));
  };

  //CLOSE MODALS
  const closeModal = () => {
    setShowModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowPaymentModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal4(false);
    setShowDonation(false);
    resetField("fieldFee");
    resetField("field");
    resetField("fieldCreateFee");
    resetField(`fieldCreateFee.addInfo`);
    resetField("fieldSingleLink");
    resetField(`fieldSingleLink.addInfo`);
    resetField("fieldClass");
    resetField("classFeeApplied");
    setSelectedClassOption([]);
    setClassOptions([]);
    setAddInfoCount(0);
    setImageName("");
    setImageId("");

    setSelectedFeeOption([]);
    setAddInfoCount(0);
    remove();
    append({ labelName: "", checkOptional: "" });

    setPayLinkDetails(null);
  };

  const openSelectSchoolFeeModal = () => {
    setShowModal(false);
    setShowSchoolFees(false);
    setShowSchoolFees3(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees4(false);
    setShowSchoolFees6(false);
    setShowSchoolFees5(true);
    fetchQueryFee();

    var feeOptions = [];

    payLink?.fees?.forEach(function (entry) {
      var signFee = {};
      signFee["value"] = entry.feeId;
      signFee["label"] = entry.feeName;
      signFee["amount"] = entry.amount;
      feeOptions.push(signFee);
    });

    setSelectedFeeOption(feeOptions);
  };

  const showDeactive = () => {
    setDeactivatetModal(true);
  };

  const closeDeactive = () => {
    setDeactivatetModal(false);
  };

  const openSchoolEndModal = () => {
    setShowModal(false);
    setShowSchoolFees(false);
    setShowSchoolFees3(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees5(false);
    setShowSchoolFees4(false);
    setShowSchoolFees6(true);
    setImageId(payLink.imageId);
    setLinkType(payLink.linkType);
    setStatusPl(payLink.status);
    setValue(`fieldSchoolLink.pageTitle`, payLink.pageTitle);
    setValue(`fieldSchoolLink.description`, payLink.pageDescription);
    setValue(`fieldSchoolLink.collectPhoneNum`, payLink.collectPhone);
    setValue(`fieldSchoolLink.customLinkCode`, payLink.linkCode);
    setValue(`fieldSchoolLink.redirect`, payLink.redirectUrl);
    setValue(`fieldSchoolLink.afterPaymentMsg`, payLink.message);
  };

  const openMoreDetails = () => {
    setShowMoreDetails(true);
  };

  const closeMoreDetails = () => {
    setShowMoreDetails(false);
  };

  //SUBMIT SCHOOL DATA
  const onSubmitHandler = (data) => {
    var createForm = {};
    createForm["pageTitle"] = data?.fieldSchoolLink?.pageTitle;
    createForm["pageDescription"] = data?.fieldSchoolLink?.description;
    createForm["redirectUrl"] = data?.fieldSchoolLink?.redirect;
    createForm["message"] = data?.fieldSchoolLink?.afterPaymentMsg;
    createForm["imageId"] = imageId;
    if (data?.fieldSchoolLink?.collectPhoneNum) {
      createForm["collectPhone"] = 1;
    } else {
      createForm["collectPhone"] = 0;
    }

    var labels = [];

    for (let i in data?.fieldSchoolLink?.addInfo) {
      var singleInfo = {};
      var adInfo = data?.fieldSchoolLink?.addInfo[i];
      if (adInfo?.labelName) {
        singleInfo["labelName"] = adInfo.labelName;
        singleInfo["optional"] = adInfo.checkOptional === true ? 1 : 0;
        labels.push(singleInfo);
      }
    }
    createForm["labels"] = labels;

    var fees = [];
    for (let i in selectedFeeOption) {
      var singleFee = {};
      var fee = selectedFeeOption[i];
      if (fee?.value) {
        singleFee["feeId"] = fee.value;
        fees.push(singleFee);
      }
    }
    createForm["fees"] = fees;

    if (data?.fieldSchoolLink?.pageTitle) {
      setPayLinkDetails(createForm);
      fetchCreatePaylink();
    }
  };

  const handleFileUpload = async (e) => {
    const document = e.target.files[0];
    const fileType = document.type.split("/");
    setImageNameTemp(document?.name);
    setFileSuffix(fileType[1]);
    const base64Certificate = await file.convertToBase64(document);
    setBase64Certificate(base64Certificate);

    // call image upload
    fetchImageUpload();
  };

  const uploadimage = () => {
    if (!isImageUploading) {
      uploadRef.current.click();
    }
    //console.log(uploadRef);
  };

  const handleEditImage = () => {
    setShowImg(false);
   setShowEditImg(true);
  };

  useEffect(() => {
    if (feeQueryData?.code === "000000") {
      var feeOptions = [];
      feeQueryData.data.forEach(function (entry) {
        var signFee = {};
        signFee["value"] = entry.feeId;
        signFee["label"] = entry.feeName;
        signFee["amount"] = entry.amount;
        feeOptions.push(signFee);
      });
      setFeeOptions(feeOptions);
    }
  }, [feeQueryData]);

  useEffect(() => {
    if (imageUploadData?.code === "000000") {
      //console.log("ImageUpload INFO", imageUploadData?.data);

      setImageId(imageUploadData?.data.imageId);
      setImageUrl(imageUploadData?.data.imageUrl);
      setImageName(imageNameTemp);

      Notify(
        "success",
        "Image upload successful!",
        "Your image was uploaded successfully"
      );
    setShowEditImg(false);
    setShowImg(true);
    } else if (imageUploadData?.code === "US0068") {
      Notify(
        "error",
        "Image upload failed",
        "Sorry! your image upload failed. Format not supported",
        10
      );
    setShowEditImg(true);
    setShowImg(false);
    }
  }, [imageUploadData]);

  return (
    <Layout>
      <header>
        <main className="main__main">
          <div class="mb-7 flex justify-between">
            <h1 class="text_22_600">Payment page</h1>
            <span>
              <button
                onClick={openSelectSchoolFeeModal}
                class="white___btn mr-4"
              >
                Edit
              </button>
              <button
                onClick={showDeactive}
                class="text-[#ffffff] rounded-lg sm:text-base text-xs font-medium bg-[red] hover:bg-[red]  sm:px-4 px-3 sm:h-[48px] h-[38px]"
              >
                {isCreatingPayLink ? <SpinnerWhite /> : <>Delete</>}
              </button>
            </span>
          </div>

          {deactivateModal ? (
            <div className="logout__modal">
              <div id="myModal" class="logout-modal">
                <div class="logout-content">
                  <div className="logout-container">
                    <p className="logout">Delete</p>
                  </div>
                  <h6 className="modalDropDown">
                    Are you sure you want to delete this payment link? This
                    action is irreversible
                  </h6>
                  <div className="log__btns">
                    <button className="stay-btn" onClick={closeDeactive}>
                      Cancel
                    </button>
                    <button className="logout-btn" onClick={deActivate}>
                      <span className="export-text">
                        {isCreatingPayLink ? <SpinnerWhite /> : <>Continue</>}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <section class="grid  gap-6 xl:grid-cols-2 grid-cols-1 ">
            <div class="bg-[#F0F6FF] rounded-lg px-8 h-[286px]">
              {isFetchingPayLink ? (
                <div style={{ marginTop: "120px" }}>
                  <Spinner />
                </div>
              ) : (
                <>
                  <h2 class="text-[#002766] text_16_500 mb-7 mt-6">
                    Payment page details
                  </h2>

                  <div class="flex  justify-between  mb-2">
                    <p class="text_16_400 ">Page name</p>
                    <p class="text_16_500">{payLink.pageTitle}</p>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text_16_400">Link</p>
                    <span class="flex  space-x-2 items-center">
                      <p class="text_16_500 flex">{`https://pay.deempay.com/${payLink.linkCode}`}</p>
                      {copied ? (
                        <span
                          class="mt-[-17px] cursor-pointer"
                          onClick={handleCopy}
                        >
                          <CopyIcon />
                        </span>
                      ) : (
                        <span
                          class="mt-[-17px] cursor-pointer"
                          onClick={handleCopy}
                        >
                          <CopiedIcon />
                        </span>
                      )}
                    </span>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text_16_400">Status</p>
                    <button class="bg-[#ccffe6] text_16_500  h-[32px] px-6 rounded">
                      {reverseLinkStatus.get(payLink.status)}
                    </button>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text_16_400">Date Created</p>
                    <p class="text_16_500 ">
                      {formatDateTime(payLink.createTime)}
                    </p>
                  </div>
                </>
              )}
            </div>

            <div class="bg-[#ffffff] border border-[#d7d7d7] rounded-lg px-8 h-[286px]">
              {isFetchingPayLink || isFetchingSummary ? (
                <div style={{ marginTop: "120px" }}>
                  <Spinner />
                </div>
              ) : (
                <>
                  <h2 class="text-[#002766] text-base font-medium mb-2 mt-6">
                    Transaction summary
                  </h2>
                  <div class="border-b border-dashed border-[#dfdfdf] mb-5 "></div>

                  <div class="flex  justify-between mb-2">
                    <p class="text-[#595959] text_16_400">Summary type</p>

                    <span className="home3-sub">
                      <select onChange={handleChangeSummary}>
                        {periodList.map((period, i) => (
                          <option key={i} selected={period === sumTypeDisplay}>
                            {period}
                          </option>
                        ))}
                      </select>
                      <MdKeyboardArrowDown className="date-down" />
                    </span>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text-[#595959] text_16_400">Transaction count</p>
                    <p class="text_16_500">{formatCount(tradeCount)}</p>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text-[#595959] text_16_400">Total amount</p>
                    <p class="text_16_500">{formatCurrency(totalAmount)}</p>
                  </div>
                  <div class="flex  justify-between mb-2">
                    <p class="text-[#595959] text_16_400">
                      Transaction timeline
                    </p>
                    <p class="text_16_500">{displayDate}</p>
                  </div>
                </>
              )}
            </div>
          </section>

          {isShowTransaction ? (
            <>
              <div class="mt-[73px] flex   justify-between">
                <span class="flex">
                  <div>
                    <h1 class="text-[#000000] text-[16px] font-medium cursor-pointer ">
                      Transactions
                    </h1>
                    <div class="bg-[#65bf73] h-[3px] w-[40px] mt-[-8px]"></div>
                  </div>
                  <h1
                    onClick={showPage}
                    class="text-[#767676] text-[16px] font-medium pl-[40px] cursor-pointer"
                  >
                    Page additional information
                  </h1>
                </span>

                <span class=" flex justify-end md:mt-0 mt-5">
                  {/* {isFilter ? (
                    <button class="white___btn mr-4">Filter</button>
                  ) : null} */}
                  <CSVLink
                    data={exporttransactions}
                    filename={`Transaction_report_${payLink.createTime}`}
                    ref={exportTransactionRef}
                  ></CSVLink>
                  <button onClick={fetchTransactionsExport} class="blue___btn">
                    {isFetchingTransactionExport ? <SpinnerWhite /> : "Export"}
                  </button>
                </span>
              </div>
              <div className="transactionTable">
                {linkType === 2 ? (
                  <PayLkTable
                    column={columnSchool}
                    data={transactionSchool}
                    link={"/dashboard/merchant/transactions/"}
                  />
                ) : (
                  <Table
                    column={column}
                    data={transactions}
                    link={"/dashboard/merchant/transactions/"}
                  />
                )}

                {isFetchingTransaction ? (
                  <>
                    <div style={{ marginTop: "40px", marginBottom: "8rem" }}>
                      <Spinner color={"#1a71ff"} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pagination--Details">
                      <div className="items-to-show">
                        <p>Items to show: {currentSize} </p>
                        <select onChange={handleChange}>
                          {pageList.map((page, i) => (
                            <option key={i} selected={page === currentSize}>
                              {page}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="business-down" />
                      </div>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IoIosArrowRoundForward />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel={<IoIosArrowRoundBack />}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination__container"}
                        activeClassName={"active"}
                        previousLinkClassName={"pages-p"}
                        nextLinkClassName={"pages-p"}
                        forcePage={currentPage - 1}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}

          {isShowPage ? (
            <>
              <div class="mt-[73px] md:flex grid justify-between">
                <span class="flex">
                  <h1
                    onClick={showTransaction}
                    class="text-[#767676] text-[16px] font-medium cursor-pointer "
                  >
                    Transactions
                  </h1>
                  <div class="">
                    <h1 class="text-[#000000] text-[16px] font-medium pl-[40px] cursor-pointer">
                      Page additional information
                    </h1>
                    <div class="bg-[#65bf73] h-[3px] w-[40px] ml-[40px] mt-[-8px]"></div>
                  </div>
                </span>
              </div>
              <section class="grid  xl:gap-[62px] gap-[0px] xl:grid-cols-2 grid-cols-1 md:px-[32px] px-[18px] mt-[53px] bg-[#F0F6FF] rounded-[12px] py-[40px]  h-[auto] mb-7">
                {isFetchingPayLink ? (
                   <div class="justify-center ">
                   <Spinner />
                 </div>
                ) : (
                  <>
                    <div>
                      <h2 class="text-[#002766] text_16_500 ">Description</h2>
                      <p class="text_16_400 mb-5">{payLink.pageDescription}</p>
                      <h2 class="text-[#002766] text_16_500 ">Payment type</h2>
                      <p class="text_16_400 mb-5">
                        {reverseLinkTypes.get(payLink.linkType)}
                      </p>
                      {isIputAmount ? (
                        <>
                          <h2 class="text-[#002766] text_16_500 ">
                            Customer Input amount
                          </h2>
                          <p class="text_16_400 mb-5">
                            {payLink.customerInputAmount === 0
                              ? "False"
                              : "True"}
                          </p>
                        </>
                      ) : null}
                      {/* {isClasses ? (
                        <>
                        <h2 class="text-[#002766] text_16_500 ">
                            Class name
                          </h2>
                          <p class="text_16_400 mb-5">
                            Primary 1-B
                          </p>
                        </>
                      ) : null} */}
                      {isMoreFees ? (
                        <h2 class="text-[#002766] text_16_500 mb-4">
                          Fee details
                          {payLink.fees.map((item, index) => (
                            <span
                              class="flex justify-between pt-1.5"
                              key={index}
                            >
                              <p class="text_16_400 mb-1.5">{item.feeName}</p>
                              <p class="text_16_400 mb-1.5">
                                {formatCurrency(item.amount)}
                              </p>
                            </span>
                          ))}
                        </h2>
                      ) : null}

                      <h2 class="text-[#002766] text_16_500 ">Redirect link</h2>
                      <p class="text_16_400 mb-5">
                        {payLink.redirectUrl === "" ? "-" : payLink.redirectUrl}
                      </p>

                      <h2 class="text-[#002766] text_16_500 ">Custom field</h2>
                      <p class="text_16_400 ">{labels === "" ? "-" : labels}</p>
                      <h2 class="text-[#002766] text_16_500 ">
                        Return message
                      </h2>
                      <p class="text_16_400 mb-5">
                        {payLink.message === "" ? "-" : payLink.message}
                      </p>
                    </div>

                    <div>
                      <h2 class="text-[#002766] text_16_500 mb-2.5 ">Image</h2>

                      {showImg ? (
                        <div class=" relative group aspect-h-2 aspect-w-3 overflow-hidden rounded-lg h-[165px] md:w-[272px] md-full">
                          {/* <a
                            href="javascript:void(0)"
                            onClick={handleEditImage}
                          > */}
                            <img
                              src={imageUrl}
                              alt=""
                              class="h-full w-full object-cover object-center"
                            />
                             {/* <span class="opacity-0 cursor-pointer group-hover:opacity-100 absolute left-0 bottom-[45%] right-0 z-10 flex justify-center items-end text-base text-[#000] font-medium">
                              Edit
                            </span> 
                           </a>  */}
                        </div>
                      ) : null}

                      {/* {showEditImg ? (
                         <div class="col-span-full">
                         <label class="laBel mt-7">Add image</label>
                         <div
                           onClick={() => {
                             uploadimage();
                           }}
                           class="mt-2 flex bg-[#f7f7f7] cursor-pointer justify-center rounded-lg border border-dashed border-[#7b7b7b] px-1 py-1 h-[85px]"
                         >
                           <div class="text-center">
                             {isImageUploading ? (
                               <div class="mt-2 justify-center py-4">
                                 <Spinner />
                               </div>
                             ) : (
                               <>
                                 <div class="mt-4 flex text-sm leading-6 ">
                                   <label class="mx-auto relative cursor-pointer rounded-md font-medium text-[#000000]">
                                     <span>Choose file</span>
                                     <input
                                       id="upload"
                                       type="file"
                                       ref={uploadRef}
                                       onChange={handleFileUpload}
                                       class="sr-only"
                                     />
                                   </label>
                                 </div>
                                 <p class="text-[10px] leading-5 text-[#adadad]">
                                   {imageName === "" ? (
                                     <>(JPG, JPEG, PNG, SVG, 1MB max)</>
                                   ) : (
                                     <>{imageName}</>
                                   )}
                                 </p>
                               </>
                             )}
                           </div>
                         </div>
                       </div>
                      ) : null} */}

                    </div>
                  </>
                )}
              </section>
            </>
          ) : null}

          {/* MODAL TO SELECT FEES */}
          {showSchoolFees5 ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <div class="flex flex-col">
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title">Edit Payment link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8  overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div class="md:block mb-[40px] mt-[30px] relative "></div>

                    <div class="py-4 ">
                      <div class="col-span-full"></div>
                      <div class="w-full mb-7">
                        <div
                          class="relative"
                          ref={selectRef}
                          //onClick={showCheckBoxes}
                        >
                          <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                            Select fee
                          </span>

                          <div class=" text-sm text-[#8d8d8d] border border-[#f7f7f7] rounded-lg bg-[#f7f7f7]">
                            <div className="App">
                              <Select
                                //defaultValue={selectedFeeOption}
                                value={selectedFeeOption}
                                onChange={setSelectedFeeOption}
                                options={feeOptions}
                                placeholder="Select fee"
                                isMulti
                                name="fees"
                                //closeMenuOnSelect={false}
                                className="basic-multi-select"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    // borderColor: state.isFocused
                                    //   ? "#1a71ff"
                                    //   : "#8d8d8d",
                                    backgroundColor: "#f7f7f7",
                                    //height: '52px',
                                    borderWidth: "2px",
                                    borderRadius: "8px",
                                    minHeight: "52px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {selectedFeeOption?.map((list, index) => (
                        <div
                          key={index}
                          class="text-[#606060] rounded-lg py-3 px-4 flex justify-between bg-[#ececec] w-full mb-2"
                        >
                          <span class="text-[#606060]">{list.label}</span>
                          <span class="flex space-x-2">
                            <span class="">NGN {list.amount}</span>
                          </span>
                        </div>
                      ))}

                      <div class="py-8">
                        {/* <button
                            onClick={openClassFeeModal}
                            class="py-3 items-center justify-center flex space-x-2 full_blue_btn"
                          >
                            <AddFeeIcon />
                            <span class="items-center justify-center flex text_14_400 text-[#ffffff]">
                              Add new fee
                            </span>
                          </button> */}
                      </div>
                    </div>
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4 border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    {/* <button onClick={openSchoolModal} class="white_modal_btn">
                        Back
                      </button> */}
                    <button
                      disabled={selectedFeeOption?.length === 0}
                      onClick={() => {
                        openSchoolEndModal();
                      }}
                      class="blue_modal_btn ml-4 "
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* MODAL TO CREATE SCHOOL LINK */}
          {showSchoolFees6 ? (
            <div className="fee__modal">
              <div className="fee__wrap">
                <form
                  onSubmit={handleSubmit(onSubmitHandler)}
                  class="flex flex-col"
                >
                  {/* HEADER */}
                  <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                    <h4 class="modal_title">Edit payment link</h4>
                    <span
                      onClick={closeModal}
                      class="absolute top-[38%] right-[9.8%] cursor-pointer"
                    >
                      <CloseModalIcon />
                    </span>
                  </div>
                  {/* BODY */}
                  <div class="sm:px-14 px-8 overflow-y-auto max-h-[660px] min-h-[450px]">
                    <div class="md:block mb-[45px] mt-[38px] relative"></div>

                    <div>
                      <div class="mb-7">
                        <label class="laBel">Page title</label>
                        <input
                          type="text"
                          class={`in_Put ${
                            errors.fieldSchoolLink?.pageTitle && "inPut_error"
                          }`}
                          placeholder="E.g Payment"
                          {...register(`fieldSchoolLink.pageTitle`, {
                            required: "Page title is required",
                          })}
                        />
                        {errors.fieldSchoolLink?.pageTitle && (
                          <p class=" mt-1 text-sm text-[red]">
                            {errors.fieldSchoolLink?.pageTitle?.message}
                          </p>
                        )}
                      </div>
                      <div class="col-span-full">
                        <label for="about" class="laBel">
                          Page Description
                        </label>
                        <div class="mt-2">
                          <textarea
                            rows="3"
                            class={`"block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff]" ${
                              errors.fieldSchoolLink?.description &&
                              "block w-full h-[87px] rounded-lg border-[red] outline-none border-[2px] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[red]"
                            }`}
                            placeholder="Give customers more details of what they are paying for"
                            {...register(`fieldSchoolLink.description`, {
                              required: "Description is required",
                            })}
                          ></textarea>
                          {errors.fieldSchoolLink?.description && (
                            <p class=" mt-1 text-sm text-[red]">
                              {errors.fieldSchoolLink?.description?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="col-span-full">
                        <label class="laBel mt-7">Add image</label>
                        <div
                          onClick={() => {
                            uploadimage();
                          }}
                          class="mt-2 flex bg-[#f7f7f7] cursor-pointer justify-center rounded-lg border border-dashed border-[#7b7b7b] px-1 py-1 h-[85px]"
                        >
                          <div class="text-center">
                            {isImageUploading ? (
                              <div class="mt-2 justify-center py-4">
                                <Spinner />
                              </div>
                            ) : (
                              <>
                                <div class="mt-4 flex text-sm leading-6 ">
                                  <label class="mx-auto relative cursor-pointer rounded-md font-medium text-[#000000]">
                                    <span>Choose file</span>
                                    <input
                                      id="upload"
                                      type="file"
                                      ref={uploadRef}
                                      onChange={handleFileUpload}
                                      class="sr-only"
                                    />
                                  </label>
                                </div>
                                <p class="text-[10px] leading-5 text-[#adadad]">
                                  {imageName === "" ? (
                                    <>(JPG, JPEG, PNG, SVG, 1MB max)</>
                                  ) : (
                                    <>{imageName}</>
                                  )}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="mt-7">
                        <div class="flex items-center pt-3">
                          <input
                            type="checkbox"
                            class="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                            {...register(`fieldSchoolLink.collectPhoneNum`)}
                          />
                          <label class="block ml-2 text-sm text-[#595959]">
                            Collect customer phone number
                          </label>
                        </div>
                      </div>

                      <div class="mt-7">
                        <label class="block mb-2 text-sm font-normal text-[#595959] dark:text-[#595959]">
                          Do you want to collect any other information
                        </label>

                        {/* ADD MORE FIELDS */}
                        {fields.map((item, index) => {
                          return (
                            <div class="mt-5" key={item.id}>
                              <div class="flex relative">
                                <span class="half_label">Label Name</span>
                                <input
                                  type="text"
                                  class="rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]"
                                  placeholder="E.g Student name"
                                  {...register(
                                    `fieldSchoolLink.addInfo.${index}.labelName`
                                  )}
                                />

                                {index !== 0 && (
                                  <svg
                                    onClick={() => {
                                      remove(index);
                                      setAddInfoCount(addInfoCount - 1);
                                      resetField(
                                        `fieldSchoolLink.addInfo.${index}`
                                      );
                                    }}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5 absolute top-[30%] right-[4.5%] cursor-pointer "
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                )}
                              </div>
                              <div class="flex items-center pt-3">
                                <input
                                  type="checkbox"
                                  class="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
                                  {...register(
                                    `fieldSchoolLink.addInfo.${index}.checkOptional`
                                  )}
                                />
                                <label class="block ml-2 text-sm text-[#595959]">
                                  Mark as optional
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {
                        <button
                          type="button"
                          disabled={addInfoCount >= 4}
                          onClick={() => {
                            append({ labelName: "", checkOptional: "" });
                            setAddInfoCount(addInfoCount + 1);
                          }}
                          class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                        >
                          <AddFeeIcon />
                          <span class="items-center justify-center flex text_14_400 text-[#ffffff]">
                            Add another field
                          </span>
                        </button>
                      }

                      <button
                        type="button"
                        onClick={() => {
                          setShow(!show);

                          if (show) {
                            closeMoreDetails();
                          } else {
                            openMoreDetails();
                          }
                        }}
                      >
                        {show ? (
                          <span class="flex space-x-4">
                            <h4 class="text-sm text-[#1a71ff] font-medium ">
                              Hide advanced options
                            </h4>
                            <span
                              onClick={() => {
                                closeMoreDetails();
                              }}
                            >
                              <FeeArrowUpIcon />
                            </span>
                          </span>
                        ) : (
                          <span class="flex space-x-4">
                            <h4 class="text-sm text-[#1a71ff] font-medium ">
                              Show advanced options
                            </h4>
                            <span onClick={openMoreDetails}>
                              <FeeArrowDownIcon />
                            </span>
                          </span>
                        )}
                      </button>

                      {showMoreDetails ? (
                        <>
                          <div class="mt-7">
                            <label class="block mb-2 text-sm font-normal text-[#595959] dark:text-[#595959]">
                              Custom link
                            </label>
                            <div class="flex">
                              <span class="half_label">
                                https://pay.deempay.com/
                              </span>
                              <input
                                type="text"
                                class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                                  errors.fieldSchoolLink?.customLinkCode &&
                                  "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[red] focus:border-[red] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[1a71ff] border-[red] outline-none border-[2px]"
                                }`}
                                placeholder="Your custom link"
                                {...register(`fieldSchoolLink.customLinkCode`, {
                                  minLength: {
                                    value: 6,
                                    message:
                                      "Characters should be upto 6 in numbers",
                                  },
                                })}
                              />
                            </div>
                            {errors.fieldSchoolLink?.customLinkCode && (
                              <p class=" mt-1 text-sm text-[red]">
                                {
                                  errors.fieldSchoolLink?.customLinkCode
                                    ?.message
                                }
                              </p>
                            )}
                          </div>

                          <div class="mb-7 mt-7">
                            <label class="laBel">Redirect after payment</label>
                            <input
                              type="url"
                              class="in_Put"
                              placeholder="https://redirect.link"
                              {...register(`fieldSchoolLink.redirect`)}
                            />
                          </div>
                          <div class="mb-7">
                            <label class="laBel">Message after payment</label>
                            <input
                              type="text"
                              class="in_Put"
                              placeholder="Message to show after payment"
                              {...register(`fieldSchoolLink.afterPaymentMsg`)}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  {/* FOOTER */}
                  <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                    <button
                      onClick={openSelectSchoolFeeModal}
                      class="white_modal_btn"
                    >
                      Back
                    </button>
                    <button type="submit" class="blue_modal_btn ml-4 ">
                      {isCreatingPayLink ? <SpinnerWhite /> : "Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
        </main>
      </header>
    </Layout>
  );
};
