import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components/spinner";
import { ArrowDownIcon, DeemPayIcon } from "../../assests/icons/Icons";
import storage from "../../utils/storage";
import { useUserRole } from "../../hooks/useUser";
import { useMerchantQuery } from "../../hooks/useMerchant";
import { redirectTo } from "../../utils/functions";

import "./styles.scss";
import { SpinnerWhite } from "../../components/spinner/Spinner";

export const SelectBusinessAccountPage = () => {
  const history = useHistory();
  const [userId, setUserId] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [payload, setPayload] = useState({});

  const {
    isLoading: isFetching,
    data,
    mutate: fetchRole,
  } = useUserRole({ userId });

  const {
    isLoading: fetchingAccount,
    data: accountDetails,
    mutate: fetchAccountDetails,
  } = useMerchantQuery(payload);

  // Form Validation
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    const userDetails = JSON.parse(storage.fetch("userDetails"));
    setUserId(userDetails?.userId);
  }, []);

  useEffect(() => {
    if (userId) {
      fetchRole();
    }
  }, [userId]);

  useEffect(() => {
    if (data?.code === "000000") {
      setAccounts(data?.data);
    }
  }, [data]);

  useEffect(() => {
    if (!!Object.keys(payload).length) {
      fetchAccountDetails();
    }
  }, [payload]);

  useEffect(() => {
    if (accountDetails?.code === "000000") {
      const role = JSON.parse(storage.fetch("role"));
      const onboardingDetails =
        role?.roleName === "Admin"
          ? { merchant: true, cusId: role?.cusId, onboarded: true, singleRole: false }
          : { merchant: false, cusId: role?.cusId, onboarded: true, singleRole: false };
      storage.add("onboardingDetails", JSON.stringify(onboardingDetails));
      const url = redirectTo(role.roleName);
      history.push(url);
      storage.add(
        "merchantDetails",
        JSON.stringify({
          merchantName: accountDetails?.data.merchantInfo.cusFullName,
          merchantNumber: accountDetails?.data.merchantInfo.merchantNumber,
          qrCode: accountDetails?.data.merchantInfo.qrCode,
          logoUrl: accountDetails?.data.merchantInfo.logoUrl,
          subMerchantName: accountDetails?.data.merchantInfo.subMerchantName,
          subMerchantNumber:
            accountDetails?.data.merchantInfo.subMerchantNumber,
          contactPhone: accountDetails?.data.merchantInfo.contactPhone,
          contactEmail: accountDetails?.data.merchantInfo.contactEmail,
          virtualBank: accountDetails?.data.merchantInfo.virtualAccountBankName,
          virtualAccNo: accountDetails?.data.merchantInfo.virtualAccountNumber,
          virtualAccName: accountDetails?.data.merchantInfo.virtualAccountName,
        })
      );
    }
  }, [accountDetails]);

  const onSubmitHandler = (data) => {
    const account = accounts.find(
      (account) => account.roleId === "1" ? account.cusFullName === data?.account:
      `${account.cusFullName} (${account.terminalCode})` === data?.account
     );
    storage.add(
      "role",
      JSON.stringify({
        roleId: account.roleId,
        roleName: account.roleName,
        cusId: account.cusId,
        terminalCode: account?.terminalCode,
        chlTermNo: account?.chlTermNo,
      })
    );
    const cusId = account.cusId;
    const userId = JSON.parse(storage.fetch("userDetails")).userId;
    setPayload({ cusId, userId });
  };

  return (
    <div className="select">
      <div className="select-wrap">
        <div className="image-wrap">
        <span className="img"><DeemPayIcon /></span>
        </div>
        <div className="select-content">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <h4>Select business account</h4>
            {isFetching ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spinner />
              </div>
            ) : (
              <>
                <div className="select-option">
                  <select
                    autoFocus
                    type="text"
                    {...register("account", {
                      required: "Business account is required",
                    })}
                  >
                    {accounts.map((account, i) => (
                      <>
                      <option key={i}>{
                      account.roleId === "1" ? account.cusFullName : `${account.cusFullName} (${account.terminalCode})`
                      }</option>
                      </>
                    ))}
                  </select>
                  <span className="select-down1" ><ArrowDownIcon/></span>
                </div>
                <button type="submit" disabled={fetchingAccount}>
                  {fetchingAccount ? <SpinnerWhite /> : "Continue"}
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
