import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import CashierLayout from "../../../../components/layout/CashierLayout";
import {
  useQueryTransaction,
  useTradeSummary,
} from "../../../../hooks/useTransaction";
import { Spinner } from "../../../../components/spinner";
import storage from "../../../../utils/storage";
import { reversePaymentMethods } from "../../../../utils/constants/paymentMethods";
import { Table } from "../../../../components/table/Table";
import {
  formatCurrency,
  formatDateTime,
  formatCount,
  truncateName,
} from "../../../../utils/functions";
import { pageList, pages } from "./pages";
import { CSVLink } from "react-csv";
import { ArrowDownIcon } from "../../../../assests/icons/Icons";
import { useQuerySubAccount } from "../../../../hooks/useSubAccount";
import { Filter, Calender } from "../../../../assests/svgs";
import { useForm } from "react-hook-form";
import { NoTransactionIcon } from "../../../../assests/icons/Icons";
import { Notify } from "../../../../components/notification";


import "./styles.scss";
import "./modal.scss";

const column = [
  "Amount",
  "Payment method",
  "Station name",
  "Customer name",
  "Date",
];

export const CashierTransactionPage = () => {
  const modalRef = useRef();
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const roleFetch = JSON.parse(storage.fetch("role"))
  const chlTermNo = roleFetch.chlTermNo;
  const terminalName = roleFetch.terminalCode;
  const [transactions, setTransactions] = useState([]);
  const [exporttransactions, setExportTransactions] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(25);
  const [totalAmount, setTotalAmount] = useState(0);
  const [summaryType, setSummaryType] = useState("ALL");
  const [showDate, setShowDate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [terminalCode, setTerminalCode] = useState(chlTermNo);
  const [productCode, setProductCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [createTime, setCreateTime] = useState("");
  const [subAccount, setSubAccount] = useState([]);
  const [subAccountList, setSubAccountList] = useState([]);
  const exportTransactionRef = useRef(null);

  const dayjs = require("dayjs");

  const handleClick = (event) => {
    setIsActive(true);
    setIsActive1(false);
    setIsActive2(false);
    setIsActive3(false);
    setValue("startDate", "");
    setValue("endDate", "");
  };

  const handleClickToday = (event) => {
    setIsActive1(true);
    setIsActive(false);
    setIsActive2(false);
    setIsActive3(false);

    var todayDate = dayjs().format("YYYY-MM-DD");
    setValue("startDate", todayDate);
    setValue("endDate", todayDate);
  };

  const handleClick2 = (event) => {
    setIsActive2(true);
    setIsActive1(false);
    setIsActive(false);
    setIsActive3(false);

    var todayDate = dayjs().format("YYYY-MM-DD");
    setValue("endDate", todayDate);
    var sevenDays = dayjs().subtract(7, "day").format("YYYY-MM-DD");
    setValue("startDate", sevenDays);
  };

  const handleClick3 = (event) => {
    setIsActive3(true);
    setIsActive1(false);
    setIsActive2(false);
    setIsActive(false);

    var todayDate = dayjs().format("YYYY-MM-DD");
    setValue("endDate", todayDate);

    var thirtyDays = dayjs().subtract(30, "day").format("YYYY-MM-DD");
    setValue("startDate", thirtyDays);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    isLoading: isFetchingTransaction,
    data,
    mutate: fetchTransactions,
  } = useQueryTransaction({
    cusId,
    terminalCode: terminalCode,
    productCode: productCode,
    startDate: startDate,
    endDate: endDate,
    page: currentPage,
    pageSize: currentSize,
  });

  const {
    isLoading: isFetchingTransactionExport,
    data: transactionDataExport,
    mutate: fetchTransactionsExport,
  } = useQueryTransaction({
    cusId,
    terminalCode: terminalCode,
    productCode: productCode,
    startDate: startDate,
    endDate: endDate,
    page: "1",
    pageSize: transactionCount,
  });

  const {
    isLoading: isFetchingSummary,
    data: summaryData,
    mutate: fetchTradeSummary,
  } = useTradeSummary({
    cusId,
    summaryType,
    terminalCode: chlTermNo, //TerminalCode here reqiure to pass the cashiersub merchantNo(chlTermNo)
    productCode: productCode,
    startDate: startDate,
    endDate: endDate,
  });

  const { data: subAccountData, mutate: fetchSubAccount } = useQuerySubAccount({
    cusId,
    terminalCode: terminalName,
  });

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, currentSize]);

  useEffect(() => {

    if (navigator.onLine) {
      setCurrentPage(1);
      fetchTransactions();
      fetchTradeSummary();
      setTransactions([]);
      } else {
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
    }

  }, [terminalCode, productCode, startDate, endDate]);

  useEffect(() => {
    fetchTradeSummary();
  }, [summaryType]);

  useEffect(() => {
    if (summaryData?.code === "000000") {
      setCreateTime(summaryData?.data.maxDate)
      setTotalAmount(summaryData?.data?.sumTradeAmt);
    }
  }, [summaryData]);

  useEffect(() => {
    fetchSubAccount();
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, currentSize]);

  useEffect(() => {
    if (data?.code === "000000") {
      const formattedTransactions = data.data.result.map((t) => {
        return {
          payAccountName: truncateName(t.payAccountName),
          tradeAmt: formatCurrency(t.tradeAmt),
          terminalName:
            t.terminalName === null ? "Main account" : t.terminalName,
          productCode: reversePaymentMethods.get(t.productCode),
          finishTime: formatDateTime(t.finishTime),
          orderNo: t.orderNo,
        };
      });
      setTransactionCount(data.data.total);
      setTransactions(formattedTransactions);
      setPageCount(Math.ceil(data.data.total / currentSize));
      if (isExportLoading) {
        fetchTransactionsExport();
      }
    }
  }, [data]);

  useEffect(() => {
    if (transactionDataExport?.code === "000000") {
      const unFormattedTransactions = transactionDataExport.data.result.map(
        (t) => {
          return {
            payAccountName: t.payAccountName,
            tradeAmt: t.tradeAmt,
            terminalName:
              t.terminalName === null ? "Main account" : t.terminalName,
            productCode: reversePaymentMethods.get(t.productCode),
            finishTime: formatDateTime(t.finishTime),
            orderNo: t.orderNo,
          };
        }
      );
      setExportTransactions(unFormattedTransactions);
      setTimeout(() => {
        exportTransactionRef.current.link.click();
      }, 500);
      setIsExportLoading(false);
    }
  }, [transactionDataExport]);

  useEffect(() => {
    if (subAccountData?.code === "000000") {
      const subaccList = [];
      for (const subAcc of subAccountData.data){
        if(!subaccList.includes(subAcc.terminalName)){
          subaccList.push(subAcc.terminalName);
        }
      }
      setSubAccountList(subaccList)
      setSubAccount(subAccountData.data);

    }
  }, [subAccountData]);

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleChange = (e) => {
    setCurrentSize(pages.get(e.target.value));
    setCurrentPage(1);
  };

  const onSubmitHandler = (data) => {
    if (data.startDate && data.startDate !== "") {
      setStartDate(data.startDate);
    } else {
      setStartDate("");
    }
    if (data.endDate && data.endDate !== "") {
      setEndDate(data.endDate);
    } else {
      setEndDate("");
    }

    switch (data.transactionType) {
      case "All transaction types":
        setProductCode("");
        break;
      case "Dynamic QR code":
        // code block
        setProductCode("T1002");
        break;
      case "Fixed QR code":
        // code block
        setProductCode("T1003");
        break;
      case "Bank Transfer":
        setProductCode("T1004");
        break;
      default:
        setProductCode("");
    }

    const account = subAccount.find(
      (account) => account.terminalName === data?.retailAcc
    );

    if (data.retailAcc && data.retailAcc === "All subaccounts") {
      setTerminalCode("");
    } else if (data.retailAcc) {
      setTerminalCode(account.chlTermNo);
    }
    setShowModal(false);
    setShowDate(false);
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowDate(false);
      setShowModal(false);
    }
  };

  return (
    <CashierLayout>
      <header>
        <main className="trans-main">
          <div className="home3-bar">
            <h3>
              Transactions
              <span className="small__card">{formatCount(transactionCount)}</span>
            </h3>
            <div className="topbar">
              <span className="top-notify">
                <button className="mini-card" onClick={() => setShowDate(true)}>
                  <span className="date-icon">
                    <img src={Calender} alt="" />
                  </span>
                </button>
                <button
                  className="filter-card"
                  onClick={() => setShowModal(true)}
                >
                  <img src={Filter} alt="" />
                  <span className="filter">Filter</span>
                </button>
                <CSVLink
                  data={exporttransactions}
                  filename={`Transaction_report_${createTime}`}
                  ref={exportTransactionRef}
                ></CSVLink>
                <button className="export-card" id="export-btn" onClick={fetchTransactionsExport}>
                {isFetchingTransactionExport ? <Spinner /> : "Export"}
                </button>
              </span>
            </div>
          </div>

          {isFetchingTransaction || transactions.length > 0 ? (
            <>
              <div className="transaction-page">
                <div className="card">
                  <h3 className="card-title">Total gross amount</h3>
                  <hr />
                  {isFetchingSummary ? (
                    <div style={{ marginTop: "40px" }}>
                      <Spinner color={"#1a71ff"} />
                    </div>
                  ) : (
                    <h1 id="font_change">{formatCurrency(totalAmount)}</h1>
                  )}
                </div>
              </div>
              <div className="transactionTable">
                <Table
                  column={column}
                  data={transactions}
                  link={"/dashboard/cashier/transactions/"}
                />
                {isFetchingTransaction ? (
                  <>
                    <div style={{ marginTop: "40px", marginBottom: "8rem" }}>
                      <Spinner color={"#1a71ff"} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pagination--Details">
                      <div className="items-to-show">
                        <p>Items to show: {currentSize} </p>
                        <select onChange={handleChange}>
                          {pageList.map((page, i) => (
                            <option key={i} selected={page === currentSize}>
                              {page}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="business-down" />
                      </div>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IoIosArrowRoundForward />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel={<IoIosArrowRoundBack />}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination__container"}
                        activeClassName={"active"}
                        previousLinkClassName={"pages-p"}
                        nextLinkClassName={"pages-p"}
                        forcePage={currentPage - 1}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : !!isFetchingTransaction || transactions.length === 0 ? (
            <>
              <section className="noData">
                <NoTransactionIcon />
                <h2>No Transactions!</h2>
                <h4>
                  You do not have any transactions yet. You may need to clear
                  the query or clear the filter.
                </h4>
              </section>
            </>
          ) : (
            ""
          )}

          {showDate ? (
            <div className="backgd--filter" ref={modalRef} onClick={closeModal}>
              <div className="filter-wrap">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="header-part">
                    <div className="add--line--bottom">
                      <h2>Select Date Range</h2>
                    </div>
                  </div>
                  <div className="body-part">
                    <div className="list-items">
                      <span
                        className={isActive ? "active-class" : ""}
                        onClick={handleClick}
                      >
                        Custom
                      </span>
                      <span
                        className={isActive1 ? "active-class" : ""}
                        onClick={handleClickToday}
                      >
                        Today
                      </span>
                      <span
                        className={isActive2 ? "active-class" : ""}
                        onClick={handleClick2}
                      >
                        7 days
                      </span>
                      <span
                        className={isActive3 ? "active-class" : ""}
                        onClick={handleClick3}
                      >
                        30 days
                      </span>
                    </div>

                    <span className="align-Name">
                      <div className="left-inputt">
                        <label>Start date</label>
                        <input type="date" {...register("startDate")} />
                      </div>
                      <div className="right-inputt">
                        <label>End date</label>
                        <input type="date" {...register("endDate")} />
                      </div>
                    </span>

                    <div className="log__btns">
                      <button
                        className="stay-btn"
                        onClick={() => setShowDate((prev) => !prev)}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="logout-btn">
                        <span className="export-text">Apply</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : null}

          {showModal ? (
            <div className="backgd--filter" ref={modalRef} onClick={closeModal}>
              <div className="filter-wrap">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="header-part">
                    <div className="add--line--bottom">
                      <h2>Transaction Filter</h2>
                    </div>
                  </div>
                  <div className="body-part">
                    <label>Transaction type</label>
                    <div className="select-option">
                      <select
                        type="text"
                        placeholder="select"
                        {...register("transactionType")}
                      >
                        <option>All transaction types</option>
                        <option>Fixed QR code</option>
                        <option>Dynamic QR code</option>
                        <option>Bank Transfer</option>
                      </select>
                      <span className="select-down">
                        <ArrowDownIcon />
                      </span>
                    </div>

                    <label className="label-top">Retail Sub-accounts</label>
                    <div className="select-option">
                      <select {...register("retailAcc")}>
                        <option>All subaccounts</option>
                        {subAccountList.map((subAccount, i) => (
                          <>
                            <option key={i}>{subAccount}</option>
                          </>
                        ))}
                      </select>
                      <span className="select-down">
                        <ArrowDownIcon />
                      </span>
                    </div>

                    <div className="log__btns">
                      <button
                        className="stay-btn"
                        onClick={() => setShowModal((prev) => !prev)}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="logout-btn">
                        <span className="export-text">Apply</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
        </main>
      </header>
    </CashierLayout>
  );
};
