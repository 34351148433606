import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner } from "../../components/spinner";
import { DeemPayIcon } from "../../assests/icons/Icons";
import useRegister from "../../hooks/useRegister";
import storage from "../../utils/storage";

import "./styles.scss";
import { SpinnerWhite } from "../../components/spinner/Spinner";

export const ForgotPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [phoneNo, setPhoneNo] = useState("");
  const {
    isLoading: isSendingCode,
    data,
    mutate: sendCode,
  } = useRegister({
    phoneNo,
    templateId: "M1001",
  });

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (data?.code === "000000") {
      storage.add("userPhoneNo", phoneNo);
      history.push("/verification", { from: location });
      reset();
    }
  }, [data]);

  const onSubmitHandler = (data) => {
    const { phoneNo } = data;
    setPhoneNo(phoneNo);
    sendCode();
  };

  return (
    <>
      <div className="forgot">
        <div className="forgot-wrap">
          <div className="image-wrap">
            <Link to="/login">
             <span className="img"><DeemPayIcon /></span>
            </Link>
          </div>
          <div className="forgot-content">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <h2>Reset your password</h2>
              <h4>
                Please enter the mobile number used to create your DeemPay
                account
              </h4>
              <label>Phone number</label>
              <input
                className={`forgotInput ${errors.phoneNo && "invalid"}`}
                type="number"
                placeholder="Enter phone number"
                {...register("phoneNo", {
                  required: "Phone number is required",
                  minLength: {
                    value: 11,
                    message: "minimum allowed number is 11",
                  },
                  maxLength: {
                    value: 11,
                    message: "maximum allowed number is 11",
                  },
                })}
              />
              {errors.phoneNo && <section className="error__text">{errors.phoneNo.message}</section>}
              <button type="submit" disabled={isSendingCode}>
                {isSendingCode ? <SpinnerWhite /> : "Continue"}
              </button>
            </form>
            <div className="verify-create">
              <p>
                Need help?
                <span>
                  <Link
                    target="_blank"
                    to="//www.deempay.com/supportcenter"
                    className="link2"
                  >
                    Contact support
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
