const health = new Map([
  ["Hospitals", "8062"],
  ["Phmarmacy and drug stores", "5912"],
  ["Dental and medicine laboratories", "8071"],
  ["Other medical services", "8099"],
]);

const transportation = new Map([
  ["Taxi services", "4121"],
  ["Bus lines", "4131"],
  ["Airlines and air carries", "4511"],
  ["Toll and bridge fees", "4784"],
  ["Travel and Tour agencies", "4722"],
  ["Courier services", "4215"],
  ["Delivery and logistics", "4214"],
  ["Airport and airport terminals", "4582"],
  ["Other transportation services", "4749"],
]);

const serviceProvider = new Map([
  ["Repair services", "7699"],
  ["Publishing/Printing services", "2741"],
  ["Telecom services", "4814"],
  ["Tailor/Alteration services", "5697"],
  ["Other training services", "8299"],
  ["Other professional services", "8999"],
  ["Agricultural services", "0763"],
  ["Car wash", "7542"],
  ["Car parking lots and garages", "7523"],
  ["Towing services", "7549"],
  ["IT and computer services", "4816"],
]);

const beauty = new Map([
  ["Gyms", "7941"],
  ["Barber and beauty shop", "7230"],
  ["Beauty spa", "7298"],
  ["Massage parlour", "7297"],
]);

const leisure = new Map([
  ["Nightclubs", "5813"],
  ["Cinemas", "7922"],
  ["Recreation centres", "7999"],
  ["Other events and entertainment", "7999"],
  ["Sports and others", "7941"],
]);

const government = new Map([
  ["Religious organization", "8661"],
  ["Political organization", "8651"],
  ["Government agencies", "9399"],
  ["Charity", "8398"],
  ["Other organizations", "8699"],
]);

const school = new Map([
  ["Primary and Secondary Schools", "8211"],
  ["Universities and higher Institutions", "8220"],
  ["Other school services", "8299"],
]);

const utilitiesServices = new Map([
  ["Cable and other pay television", "4899"],
  ["Telecoms and call services", "4813"],
  ["Utilities, eletric, water and gas", "4900"],
]);

const food = new Map([
  ["Eateries and resturant", "5812"],
  ["Bar and drinking place", "5813"],
  ["Fast food resturants", "5814"],
  ["Hotels, Motels and Resorts", "7011"],
  ["Other food services", "5499"],
]);

const retail = new Map([
  ["Departmental stores", "5311"],
  ["Supermarket and grocery stores", "8411"],
  ["Retail stores", "5499"],
  ["Telcoms and phone sells", "4812"],
  ["Service stations (filling stations)", "5541"],
  ["Bakaries", "5462"],
  ["Provision and variety stores", "5331"],
  ["Other stores", "5399"],
  ["Men's, Women's clothing stores", "5691"],
  ["Children stores", "5641"],
  ["Electronic stores", "5732"],
  ["Book stores", "5942"],
  ["Men's, Women's children clothing", "5137"],
  ["Toys and Game", "5945"],
  ["Stationary, office and school supplies", "5943"],
  ["Cosmetics stores", "5977"],
  ["Appliance stores", "5722"],
  ["Jewerly and watch stores", "5944"],
  ["Other retail stores", "5999"],
]);

export const businessSections = {
  Health: health,
  "Beauty, fitness and wellness": beauty,
  "Food, drinks and hospitality": food,
  "Leisure and entertainment": leisure,
  "Government and NGO": government,
  "Retail and commerce": retail,
  "Schools and educations services": school,
  "Utilities services": utilitiesServices,
  "Transportation and delivery": transportation,
  "Services provider": serviceProvider,
};
