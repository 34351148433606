import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { feePaymentStatus } from "../../utils/functions";
import { Notify } from "../notification";
import { useCreateClass, useQueryClass } from "../../hooks/useClass";
import { SpinnerWhite } from "../../components/spinner/Spinner";
import { Controller, set, useFieldArray, useForm } from "react-hook-form";
import {
  CloseModalIcon,
} from "../../assests/icons/Icons";

import { useCreateFee, useQueryFee } from "../../hooks/useFee";
import storage from "../../utils/storage";
import Select from "react-select";

import "./styles.scss";

export const ClassListTable = ({ column, data }) => {
  const history = useHistory();
  const [copied, setCopied] = useState(true);
  const [copiedIndex, setCopiedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(false);

  //////////////////////////////////////////////////////////////////////
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [showSchoolFees, setShowSchoolFees] = useState(false);
  const [showSchoolFees2, setShowSchoolFees2] = useState(false);
  const [showSchoolFees3, setShowSchoolFees3] = useState(false);
  const [showSchoolFees4, setShowSchoolFees4] = useState(false);
  const [showSchoolFees5, setShowSchoolFees5] = useState(false);
  const [showSchoolFees6, setShowSchoolFees6] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showAddClass, setShowAddClass] = useState(false);

  const [showEditClassModal, setShowEditClassModal] = useState(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSinglePaymentModal, setShowSinglePaymentModal] = useState(false);
  const [showMutiplePaymentModal, setShowMultiplePaymentModal] =
    useState(false);
  const [showMutiplePaymentModal2, setShowMultiplePaymentModal2] =
    useState(false);
  const [showMutiplePaymentModal3, setShowMultiplePaymentModal3] =
    useState(false);
  const [showMutiplePaymentModal4, setShowMultiplePaymentModal4] =
    useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [checked, setChecked] = useState([]);
  const [showInputAmount, setShowInputAmount] = useState(true);
  const [showInputAmountDonate, setShowInputAmountDonate] = useState(true);
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(0);
  //const [seq, setSeq] = useState(0);
  const [className, setClassName] = useState("");
  const [feeId, setFeeId] = useState("");
  const [feeName, setFeeName] = useState("");
  const [remark, setRemark] = useState("");
  const [classCategory, setClassCategory] = useState("");
  const [imageName, setImageName] = useState("");
  const [selectedFeeOption, setSelectedFeeOption] = useState([]);
  const [addInfoCount, setAddInfoCount] = useState(0);
  const [selectedClassOption, setSelectedClassOption] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [imageId, setImageId] = useState();
  const [deactivateModal, setDeactivatetModal] = useState(false);
  const [statusClass, setStatusClass] = useState(1);
  const [classId, setClassId] = useState("");

  /////////////////////////////////////////////////////////////

  async function handleCopy(linkUrl, index) {
    try {
      await navigator.clipboard.writeText(linkUrl);
      /* Resolved - text copied to clipboard successfully */

      setCopied(false);
      setCopiedIndex(index);

      setTimeout(() => {
        setCopied(true);
        setCopiedIndex(null);
      }, 3000);
    } catch (err) {
      console.error("Failed to copy: ", err);
      /* Rejected - text failed to copy to the clipboard */
    }
  }

  function linkPreview(linkUrl) {
    if (linkUrl) {
      window.open(linkUrl);
    }
  }

  const handleRowClick = (trans) => {
    if (navigator.onLine) {
      // history.push(`${link}${trans.linkId}/${trans.linkCode}`);
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  const {
    isLoading: isFetchingClass,
    data: classData,
    mutate: fetchClass,
  } = useCreateClass({
    classId,
    cusId,
    className,
    classCategory,
    status: statusClass,
  });

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      field: [{ className: "", classCategory: "" }],
      fieldFee: [{ feeName: "", feeAmount: "", feeDescription: "" }],
      fieldClass: [{ className: "", classCategory: "" }],
    },
  });

  //SUBMIT CLASS DATA
  const onSubmitClass = (data) => {
    console.log("CLASS DATA", data.fieldClass);

    for (let i in data.fieldClass) {
      var classInfo = data.fieldClass[i];

      const { className, classCategory } = classInfo;
      if (className) {
        setClassCategory(classCategory);
        setClassName(className);
        fetchClass();
      }

      //console.log(classCategory)
      //console.log(className)
    }
  };

  //CLOSE MODALS
  const closeModal = () => {
    setShowEditClassModal(false);
    setDeactivatetModal(false);
    //setShowModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowPaymentModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal4(false);
    setShowDonation(false);
    resetField("fieldFee");
    resetField("field");
    resetField("fieldCreateFee");
    resetField(`fieldCreateFee.addInfo`);
    resetField("fieldSingleLink");
    resetField(`fieldSingleLink.addInfo`);
    resetField("fieldClass");
    resetField("classFeeApplied");
    setSelectedClassOption([]);
    setClassOptions([]);
    setAddInfoCount(0);
    setImageName("");
    setImageId("");

    setSelectedFeeOption([]);
    setAddInfoCount(0);
    remove();
    append({ labelName: "", checkOptional: "" });
   
  };

  const startEditFeeModal = () => {
    setShowEditClassModal(true);
    setShowPaymentModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowDonation(false);

  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "field",
  });

  useEffect(() => {
    fetchClass();
  }, [statusClass]);

  const deActivate = () => {
    setStatusClass(0);
  };

  const showDeactive = () => {
    setDeactivatetModal(true);
  };

  const closeDeactive = () => {
    setDeactivatetModal(false);
  };


  useEffect(() => {
    if (classData?.code === "000000") {

      if (classData?.data?.status === "0") {
        
        Notify("success", "Class successful!", "Class deleted successfully");
        closeModal();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        
        Notify("success", "Class successful!", "Class edited successfully");
        closeModal();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
 
      reset();

    } else if (classData?.code === "US0066") {
      Notify(
        "error",
        "Class error",
        "Sorry! this class already exists, please add a new class.",
        10
      );
      //openSchoolModal();
      //reset();
    }
  }, [classData]);




  return (
    <div id="table-container">
      <table className="payment-link-tabs">
        <thead>
          {column.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </thead>
        <tbody>
          {data.map((classes, i) => (
            <tr key={i} onClick={(node, event) => handleRowClick(classes)}>
              <td
                id="font_change"
                className="view"
                onClick={(node, event) => handleRowClick(classes)}
              >
                <a className="bolder__one">{classes.className}</a>
              </td>

              <td onClick={(node, event) => handleRowClick(classes)}>
                <a className="bolder__one">{classes.classCategory}</a>
              </td>

              <td>
                <a
                  className="edit-fees"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowEditClassModal(true);
                    setClassId(classes.classId);
                    setValue(`fieldClass.0.classCategory`, classes.classCategory);
                    setValue(`fieldClass.0.className`, classes.className);
                  }}
                >
                  Edit
                </a>
                <a
                  className="delete-fees"
                  onClick={(e) => {
                    e.stopPropagation();
                    setClassId(classes.classId);
                    showDeactive();
                    //alert("Delete");
                    //linkPreview(`https://pay.deempay.com/${fees.linkCode}`);
                  }}
                >
                  Delete
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showEditClassModal ? (
        <div className="fee__modal">
          <div className="fee__wrap">
            <form onSubmit={handleSubmit(onSubmitClass)} class="flex flex-col">
              {/* HEADER */}
              <div class="flex pt-[20px] pb-[16px] mb-[10px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                <h4 class="modal_title">Edit class</h4>
                <span
                  onClick={closeModal}
                  class="absolute top-[38%] right-[9.8%] cursor-pointer"
                >
                  <CloseModalIcon />
                </span>
              </div>
              {/* BODY */}

              <div class="px-14 overflow-y-auto max-h-[660px] min-h-[350px]">
                {fields.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <div class="my-7">
                        <label class="laBel justify-between flex">
                          <span>Class name</span>

                          {index !== 0 && (
                            <span
                              onClick={() => remove(index)}
                              class="cursor-pointer text-[red] hover:text-[red]"
                            >
                              Clear
                            </span>
                          )}
                        </label>
                        <input
                          type="text"
                          class={`in_Put ${
                            errors.fieldClass?.[index]?.className &&
                            "inPut_error"
                          }`}
                          placeholder="E.g Primary 4"
                          {...register(`fieldClass.${index}.className`, {
                            required: true,
                          })}
                        />
                      </div>

                      <div class="relative">
                        <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                          Class category
                        </span>
                        <select
                          {...register(`fieldClass.${index}.classCategory`, {
                            required: true,
                          })}
                          class={`"form-select block w-full mt-1 p-4 mb-1 text-sm text-[#000000] border-[2px] border-[#f7f7f7] rounded-lg bg-[#f7f7f7] " appearance-none ${
                            errors.fieldClass?.[index]?.classCategory &&
                            " outline-none border-[2px] border-[red] rounded-lg bg-[#f7f7f7] appearance-none"
                          }`}
                        >
                          <option value="" disabled selected>
                            Select category
                          </option>
                          <option>Creche</option>
                          <option>Nursery</option>
                          <option>Primary</option>
                          <option>Secondary</option>
                          <option>Tertiary</option>
                        </select>
                        <svg
                          width="15"
                          height="8"
                          fill="none"
                          class="w-4 h-4 absolute left-[91%] bottom-[18%] pointer-events-none block"
                        >
                          <path
                            d="M13.71.296a1 1 0 0 0-1.42 0l-4.58 4.58a1 1 0 0 1-1.42 0L1.71.296a1 1 0 1 0-1.42 1.41l4.59 4.59a3 3 0 0 0 4.24 0l4.59-4.59a1 1 0 0 0 0-1.41Z"
                            fill="#8D8D8D"
                          />
                        </svg>
                      </div>
                    </div>
                  );
                })}

              </div>

              {/* FOOTER */}
              <div class="flex py-4 mt-[38px] border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
       
                <button
                  type="submit"
                  disabled={isFetchingClass}
                  class="blue_modal_btn ml-4 "
                >
                  {isFetchingClass ? <SpinnerWhite /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}

      {deactivateModal ? (
        <div className="logout__modal">
          <div id="myModal" class="logout-modal">
            <div class="logout-content">
              <div className="logout-container">
                <p className="logout">Delete</p>
              </div>
              <h6 className="modalDropDown">
                Are you sure you want to delete this class? This action is
                irreversible
              </h6>
              <div className="log__btns">
                <button className="stay-btn" onClick={closeDeactive}>
                  Cancel
                </button>
                <button className="logout-btn" onClick={deActivate}>
                  <span className="export-text">
                    {isFetchingClass ? <SpinnerWhite /> : <>Continue</>}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
