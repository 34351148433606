import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { SpinnerWhite } from "../../components/spinner/Spinner";
import { useAuth } from "../../hooks/useAuth";
import useLogin from "../../hooks/useLogin";
import storage from "../../utils/storage";
import { useUserRole } from "../../hooks/useUser";
import { redirectTo } from "../../utils/functions";
import { CloseIcon, OpenIcon, DeemPayIcon } from "../../assests/icons/Icons";

import "./styles.scss";


export const LoginPage = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [apiResponseError, setApiResponseError] = useState("");
  const { login } = useAuth();
  const {
    isLoading: isLoggingIn,
    data,
    mutate: loginUser,
  } = useLogin({
    phoneNo,
    password,
  });
  const {
    isLoading: isFetchingUserRole,
    data: userRole,
    mutate: fetchRole,
  } = useUserRole({ userId });

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (data?.code === "000000") {
      const { data: userDetails } = data;
      const token = userDetails?.token;
      login(userDetails?.token);
      storage.add(
        "userDetails",
        JSON.stringify({
          userId: userDetails?.userId,
          firstName: userDetails?.firstName,
          surname: userDetails?.surname,
          avatar: userDetails?.userHeader,
          phoneNo: userDetails?.phoneNo,
        })
      );
      setUserId(userDetails?.userId);

    } else if (data?.code === "US0002") {
      setApiResponseError(
        <section style={{ color: "red" }}>
          Invalid login credentials
        </section>
      );
    }
  }, [data]);

  useEffect(() => {
    if (userId) {
      fetchRole();
    }
  }, [userId]);

  useEffect(() => {
    if (userRole?.code === "000000") {
      if (userRole?.data.length === 0) {
        history.push("/dashboard/merchant/overview");
        storage.add(
          "onboardingDetails",
          JSON.stringify({
            merchant: true,
            cusId: null,
            onboarded: false,
            singleRole: true,
          })
        );
      } else if (userRole?.data.length === 1) {
        const role = userRole?.data[0];
        storage.add(
          "role",
          JSON.stringify({
            roleId: role?.roleId,
            roleName: role?.roleName,
            cusId: role?.cusId,
            terminalCode: role?.terminalCode,
            chlTermNo: role?.chlTermNo,
          })
        );
        const onboardingDetails =
          role?.roleName === "Admin"
            ? {
                merchant: true,
                cusId: role?.cusId,
                onboarded: true,
                singleRole: true,
              }
            : {
                merchant: false,
                cusId: role?.cusId,
                onboarded: true,
                singleRole: true,
              };
        storage.add("onboardingDetails", JSON.stringify(onboardingDetails));
        const url = redirectTo(role.roleName);
        history.push(url);
      } else {
        history.push("/dashboard/select-business-account");
      }
      reset();
    }
  }, [userRole]);

  const onSubmitHandler = (data) => {
    const { phoneNo, password } = data;
    setPhoneNo(phoneNo);
    setPassword(password);
    loginUser();
  };

  return (
    <div className="login">
      <div className="login-wrap">
        <div className="image-wrap">
        <span className="img"><DeemPayIcon /></span>
        </div>
        <div className="login-content">
          <form1>
            <p>
              Sign in using your phone and password used when creating your
              account
            </p>
          </form1>

          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <h2>Welcome back</h2>
            <p>Enter your credentials to access your account</p>
            <label>Phone number</label>
            <input
              className={`loginInput ${errors.phoneNo && "invalid"}`}
              type="number"
              placeholder="Enter phone number"
              onFocus={() => setApiResponseError("")}
              {...register("phoneNo", {
                required: "Phone number is required",
                minLength: {
                  value: 11,
                  message: "minimum allowed phone number is 11",
                },
                maxLength: {
                  value: 11,
                  message: "maximum allowed phone number is 11",
                },
              })}
            />
            {errors.phoneNo && <section className="error__txt">{errors.phoneNo.message}</section>}

            <label htmlFor="for">Password</label>
            <div className="input">
              <input
                className={`loginInput ${errors.password && "invalid"}`}
                type={show ? "text" : "password"}
                placeholder="Enter password"
                onFocus={() => setApiResponseError("")}
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "minimum allowed password is 6",
                  },
                })}
              />
              {errors.password && <section className="error__txt">{errors.password.message}</section>}

              <span onClick={() => setShow(!show)}>
                {show ? (
                 <span className="logout-eye" ><OpenIcon /></span>
                ) : (
                  <span className="logout-eye" ><CloseIcon /></span>
                )}
              </span>
            </div>
            <div>{apiResponseError}</div>
            <button type="submit" disabled={isLoggingIn || isFetchingUserRole}>
              {isLoggingIn || isFetchingUserRole ? <SpinnerWhite /> : "Sign In"}
            </button>
            <h4>
              <Link to="/forgot-password" className="forgt">
                Forgot password?
              </Link>
            </h4>
          </form>
          <div className="login-create">
            <p>
              New user?
              <span>
                <Link to="/registration" className="link">
                  Create account
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
