import React from "react";
import { useHistory } from "react-router-dom";
import { fetchPaymentClass } from "../../utils/functions";
import { Notify } from "../../components/notification";

import "./styles.scss";

export const Table = ({ column, data, link }) => {
  const history = useHistory();

  const handleRowClick = (trans) => {
    if (navigator.onLine) {
      history.push(`${link}${trans.orderNo}`);
      } else {
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
      }
  };

  return (
    <>
      <div id="table-container">
        <table className="transaction-tabs">
          <thead>
            {column.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </thead>
          <tbody>
            {data.map((transaction, i) => (
              
              <tr
                key={i}
                onClick={(node, event) => handleRowClick(transaction)}
              >
                <td id="font_change" className="bolder__one">
                  <a
                    className="view bolder__one"
                  >
                    {transaction.tradeAmt}
                  </a>
                </td>
                <td>
                  <a  className="view">
                    <span
                      className={fetchPaymentClass(transaction.productCode)}
                    >
                      {transaction.productCode}
                    </span>
                  </a>
                </td>
                <td>
                  <a  className="view">
                    {transaction.terminalName}
                  </a>
                </td>
                <td>
                  <a className="view">
                    {transaction.payAccountName
                      ? transaction.payAccountName.toLowerCase()
                      : transaction.payAccountName}
                  </a>
                </td>
                <td>
                  <a className="view">
                    {transaction.finishTime}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
