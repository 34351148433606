import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout/Layout";
import { useHistory } from "react-router-dom";
import { Notify } from "../../components/notification";
import { FiPlus } from "react-icons/fi";

////////////////////////////////////////////////////////////
import { Spinner } from "../../components/spinner";
import storage from "../../utils/storage";
import {  useQueryClass } from "../../hooks/useClass";
import { useCreateFee, useQueryFee } from "../../hooks/useFee";
import "react-toastify/dist/ReactToastify.css";
import file from "../../helpers/file";
import {
  AddFeeIcon,
  NoPaymentLinkIcon,
} from "../../assests/icons/Icons";
import {
  formatCurrency,
} from "../../utils/functions";
import { Controller, set, useFieldArray, useForm } from "react-hook-form";
import { FeeListTable } from "../../components/table/FeeListTable";
import {
  CloseModalIcon,

} from "../../assests/icons/Icons";
import { SpinnerWhite } from "../../components/spinner/Spinner";
import Select from "react-select";

import "./styles.scss";

const column = ["Fee name", "Fee amount", "Fee description", "Edit/delete"];

////////////////////////////////////////////////////////////////////////////

export const FeesLinkPage = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  //////////////////////////////////////////////////////////////////////
  const selectRef = useRef(null);
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [showSchoolFees, setShowSchoolFees] = useState(false);
  const [showSchoolFees2, setShowSchoolFees2] = useState(false);
  const [showSchoolFees3, setShowSchoolFees3] = useState(false);
  const [showSchoolFees4, setShowSchoolFees4] = useState(false);
  const [showSchoolFees5, setShowSchoolFees5] = useState(false);
  const [showSchoolFees6, setShowSchoolFees6] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showAddClass, setShowAddClass] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSinglePaymentModal, setShowSinglePaymentModal] = useState(false);
  const [showMutiplePaymentModal, setShowMultiplePaymentModal] =
    useState(false);
  const [showMutiplePaymentModal2, setShowMultiplePaymentModal2] =
    useState(false);
  const [showMutiplePaymentModal3, setShowMultiplePaymentModal3] =
    useState(false);
  const [showMutiplePaymentModal4, setShowMultiplePaymentModal4] =
    useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const [show, setShow] = useState(false);
  const [showCreateFeeModal, setShowCreateFeeModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [checked, setChecked] = useState([]);
  const [copied, setCopied] = useState(true);
  const [amount, setAmount] = useState(0);
  const [className, setClassName] = useState("");
  const [feeName, setFeeName] = useState("");
  const [remark, setRemark] = useState("");
  const [imageName, setImageName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [payLinks, setPayLinks] = useState(null);
  const [feeList, setFeeList] = useState(null);
  const [selectedFeeOption, setSelectedFeeOption] = useState([]);
  const [feeOptions, setFeeOptions] = useState([]);
  const [addInfoCount, setAddInfoCount] = useState(0);
  const [payLinkDetails, setPayLinkDetails] = useState(null);
  const [createLinkForm, setCreateLinkForm] = useState(null);
  const [linkType, setLinkType] = useState(1);

  const [selectedClassOption, setSelectedClassOption] = useState([]);
  const [classOptions, setClassOptions] = useState(null);

  const [fetchingClass, setFetchingClass] = useState(false);
  const [classPresent, setClassPresent] = useState(false);
  const [callFeeUpdate, setCallFeeUpdate] = useState(false);

  const [classFeeApplied, setClassFeeApplied] = useState([]);

  const [feeDetails, setFeeDetails] = useState([]);

  const [imageId, setImageId] = useState();
 

  const uploadRef = useRef();

  /////////////////////////////////////////////////////////////////////////

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {
      switch (profileType) {
        case "paymentLinks":
          history.replace("/dashboard/merchant/payment-link");
          break;
        case "fees":
          history.replace("/dashboard/merchant/payment-link/fees");
          break;
        case "classes":
          history.replace("/dashboard/merchant/payment-link/classes");
          break;
        default:
          history.replace("/dashboard/merchant/payment-link");
      }
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  const {
    isLoading: isCreatingFee,
    data: feeData,
    mutate: fetchCreateFee,
  } = useCreateFee({
    cusId,
    feeName,
    amount,
    remark,
    ...feeDetails,
  });

  const {
    isLoading: isQueryingFee,
    data: feeQueryData,
    mutate: fetchQueryFee,
  } = useQueryFee({
    cusId,
  });

  const {
    isLoading: isFetchingClassDetails,
    data: queryClassData,
    mutate: fetchQueryClass,
  } = useQueryClass({
    cusId,
  });

  const {
    register,
    handleSubmit,
    resetField,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      field: [{ className: "", classCategory: "" }],
      fieldFee: [{ feeName: "", feeAmount: "", feeDescription: "" }],
      fieldClass: [{ className: "", classCategory: "" }],
    },
  });

  //CLOSE MODALS
  const closeModal = () => {
    setShowCreateFeeModal(false);
    setShowModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowPaymentModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal4(false);
    setShowDonation(false);
    resetField("fieldFee");
    resetField("field");
    resetField("fieldCreateFee");
    resetField(`fieldCreateFee.addInfo`);
    resetField("fieldSingleLink");
    resetField(`fieldSingleLink.addInfo`);
    resetField("fieldClass");
    resetField("classFeeApplied");
    setSelectedClassOption([]);
    setClassOptions([]);
    setAddInfoCount(0);
    setImageName("");
    setImageId("");

    setSelectedFeeOption([]);
    setAddInfoCount(0);
    remove();
    append({ labelName: "", checkOptional: "" });
    //append({feeName: "", feeAmount: "", feeDescription: "" })

    //setPayLinkDetails(null);
  };

  const startCreateFeeModal = () => {
    setShowCreateFeeModal(true);
    setShowPaymentModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowDonation(false);

  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "field",
  });

  useEffect(() => {
    fetchQueryFee();
  }, []);

  useEffect(() => {
    //fetchQueryClass();
  }, []);

  useEffect(() => {
    if (feeData?.code === "000000") {
      //console.log(feeData);

      Notify("success", "Fee successful!", "Fee created successfully");

      resetField("fieldFee");

      closeModal();

      fetchQueryFee();
    } else if (feeData?.code === "US0066") {
      setAmount(0);
      setRemark("");
      setFeeName("");

      Notify(
        "error",
        "Fee error",
        "Sorry! this fee name already exists, input a new fee name.",
        10
      );
    }
  }, [feeData]);

  useEffect(() => {
    if (feeQueryData?.code === "000000") {
      console.log(feeQueryData);

      const formattedFeeList = feeQueryData.data.map((f) => {
        return {
          feeId: f.feeId,
          feeName: f.feeName,
          feeAmount: formatCurrency(f.amount),
          feeAmountUnformat: f.amount,
          feeRemark: f.remark,
          feeStatus: f.status,
          feeClass: f.appliedClasses,
        };
      });
      setFeeList(formattedFeeList);

      var feeOptions = [];

      if (feeQueryData.data.length > 0) {
        if (linkType === 1) {
          //openMutiplePaymentModal3();
        } else {
          //openSelectSchoolFeeModal();
        }
        feeQueryData.data.forEach(function (entry) {
          var signFee = {};
          signFee["value"] = entry.feeId;
          signFee["label"] = entry.feeName;
          signFee["amount"] = entry.amount;
          feeOptions.push(signFee);
        });
        //console.log("feeOptions",feeOptions)
      }

      setFeeOptions(feeOptions);
    }
  }, [feeQueryData]);

  useEffect(() => {
    if (queryClassData?.code === "000000") {
      // const listOfClass = queryClassData.data.map((l) => {
      //   return {
      //     classList: l.className,
      //     category: l.classCategory,
      //   };
      // });
      // setClassList(listOfClass);

      //console.log(queryClassData);
      setClassOptions([]);

      var classOption = [];

      if (queryClassData.data.length > 0) {
        queryClassData.data.forEach(function (entry) {
          var signClass = {};
          signClass["value"] = entry.classId;
          signClass["label"] = entry.className;
          signClass["category"] = entry.classCategory;
          classOption.push(signClass);
        });
        //console.log("feeOptions",feeOptions)
      }
      console.log("class-Option", classOption);
      //setClassOptions(classOption);
      setClassOptions(classOption);
      //classOption.length > 0 ? setClassPresent(true) : setClassPresent(false);

      //console.log("class-Options", classOptions);
      //setFetchingClass(false);
    }
  }, [queryClassData]);

  //SUBMIT MULTIPLE PAYMENT DATA
  const onSubmitFeeHandler = (data) => {
    console.log(data.fieldCreateFee);
    var createForm = {};

    for (let i in data.fieldFee) {
      const { feeName, feeAmount, feeDescription } = data.fieldFee[i];
      //setAmount(feeAmount);
      //setRemark(feeDescription);
      //setFeeName(feeName);

      var createFee = {};

      createFee["feeName"] = feeName;
      createFee["amount"] = feeAmount;
      createFee["remark"] = feeDescription;

      var appliedClass = [];
      if (data?.classFeeApplied) {
        for (let n in data?.classFeeApplied[i]) {
          var singleClassId = {};
          var classes = data?.classFeeApplied[i][n];
          if (classes?.value) {
            singleClassId["classId"] = classes?.value;
            appliedClass.push(singleClassId);
          }
        }
      }

      createFee["appliedClasses"] = appliedClass;
      setFeeDetails(createFee);

      if (parseInt(i) === data.fieldFee.length - 1) {
        console.log(feeName);
        setCallFeeUpdate(true);
      }
      // call create fee api
      if (feeName) {
        fetchCreateFee();
      }
    }
    createForm["pageTitle"] = data?.fieldCreateFee?.pageTitle;
    createForm["pageDescription"] = data?.fieldCreateFee?.description;
    createForm["redirectUrl"] = data?.fieldCreateFee?.redirect;
    createForm["message"] = data?.fieldCreateFee?.afterPaymentMsg;
    createForm["linkCode"] = data?.fieldCreateFee?.customLinkCode;

    createForm["imageId"] = imageId;

    if (data?.fieldCreateFee?.collectPhoneNum) {
      createForm["collectPhone"] = 1;
    } else {
      createForm["collectPhone"] = 0;
    }

    var labels = [];

    for (let i in data?.fieldCreateFee?.addInfo) {
      var singleInfo = {};
      var adInfo = data?.fieldCreateFee?.addInfo[i];
      if (adInfo?.labelName) {
        singleInfo["labelName"] = adInfo.labelName;
        singleInfo["optional"] = adInfo.checkOptional === true ? 1 : 0;
        labels.push(singleInfo);
      }
    }
    createForm["labels"] = labels;

    var fees = [];
    for (let i in selectedFeeOption) {
      var singleFee = {};
      var fee = selectedFeeOption[i];
      if (fee?.value) {
        singleFee["feeId"] = fee.value;
        fees.push(singleFee);
      }
    }
    createForm["fees"] = fees;

    if (data?.fieldCreateFee?.pageTitle) {
      setPayLinkDetails(createForm);
    }
  };

  return (
    <>
      <Layout>
        <header>
          <main className="fees">
            <section id="sect">
              <a>
                <span
                  className="personal-info"
                  onClick={(node, event) => handleRowClick("paymentLinks")}
                >
                  Payment links
                </span>
              </a>
              <a>
                <span
                  className="activ"
                  onClick={(node, event) => handleRowClick("fees")}
                >
                  Fees
                </span>
              </a>
              <a>
                <span onClick={(node, event) => handleRowClick("classes")}>
                  Classes
                </span>
              </a>
            </section>
          </main>
          <main className="fee__n">
            <div className="home3-bar">
              <h3>Fees</h3>
              <div className="topbar">
                <span className="top-notify">
                  <span
                    className="btn_blue_card card__space"
                    onClick={() => {
                      startCreateFeeModal(true);
                      fetchQueryClass();
                    }}
                  >
                    <FiPlus className="icon__mg" />
                    <span className="blueCard_text">New</span>
                  </span>
                </span>
              </div>
            </div>

            {isQueryingFee || feeList === null ? (
              <div class="mt-[200px]">
                <Spinner />
              </div>
            ) : feeList?.length > 0 ? (
              <FeeListTable
                column={column}
                data={feeList}
                //link={"/dashboard/merchant/payment-link/"}
              />
            ) : (
              <>
                <section className="noData">
                  <NoPaymentLinkIcon />
                  <h2>No Fee</h2>
                  <h4>
                    Create a new fee to facilitate easy of payment collection.
                  </h4>
                </section>
              </>
            )}

            {/* Modal for fee creation */}

            <form onSubmit={handleSubmit(onSubmitFeeHandler)}>
              {showCreateFeeModal ? (
                <div className="fee__modal">
                  <div className="fee__wrap">
                    <div class="flex flex-col">
                      {/* HEADER */}
                      <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                        <h4 class="text-base font-medium text-[#595959] items-center justify-center flex">
                          Create Fee
                        </h4>
                        <span
                          onClick={closeModal}
                          class="absolute top-[38%] right-[9.8%] cursor-pointer"
                        >
                          <CloseModalIcon />
                        </span>
                      </div>
                      {/* BODY */}
                      <div class="px-14 py-[30px] overflow-y-auto max-h-[660px] min-h-[450px]">
                        {fields.map((item, index) => {
                          return (
                            <div key={item.id}>
                              <div class="mb-7">
                                <label class="laBel justify-between flex">
                                  <span>Fee name</span>

                                  {index !== 0 && (
                                    <span
                                      onClick={() => remove(index)}
                                      class="cursor-pointer text-[red] hover:text-[red]"
                                    >
                                      Clear
                                    </span>
                                  )}
                                </label>
                                <input
                                  type="text"
                                  class={`in_Put ${
                                    errors.fieldFee?.[index]?.feeName &&
                                    "inPut_error"
                                  }`}
                                  placeholder="E.g Transport levy"
                                  {...register(`fieldFee.${index}.feeName`, {
                                    required: true,
                                  })}
                                />
                                <p class="hidden mt-1 text-sm text-red-600 dark:text-red-500">
                                  Enter fee name
                                </p>
                              </div>

                              <label class="laBel mb-2">Fee amount</label>
                              <div class="flex mb-7">
                                <span class="half_label">NGN</span>
                                <input
                                  type="number"
                                  class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                                    errors.fieldFee?.[index]?.feeAmount &&
                                    "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[red] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[red] border-2 outline-none "
                                  }`}
                                  placeholder="Eg. 5,000"
                                  {...register(`fieldFee.${index}.feeAmount`, {
                                    required: true,
                                  })}
                                />
                              </div>

                              <div class="relative mb-7">
                                <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                                  Class fee apply
                                </span>

                                <Controller
                                  name={`classFeeApplied.${index}`}
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: false,
                                  }}
                                  render={({ field }) => {
                                    return (
                                      <Select
                                        {...field}
                                        //defaultValue={selectedFeeOption}
                                        //value={classFeeApplied[index]}
                                        // onChange={(ep) => {
                                        //   // var classeleted = classFeeApplied;
                                        //   // classeleted[index] = ep;
                                        //   // setClassFeeApplied(classeleted);
                                        // }}
                                        options={classOptions}
                                        placeholder="Select class"
                                        isMulti
                                        name="classes"
                                        //closeMenuOnSelect={false}
                                        className="basic-multi-select"
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            // borderColor: state.isFocused
                                            //   ? "#1a71ff"
                                            //   : "#8d8d8d",
                                            backgroundColor: "#f7f7f7",
                                            //height: '52px',
                                            borderWidth: "2px",
                                            borderRadius: "8px",
                                            minHeight: "52px",
                                          }),
                                        }}
                                      />
                                    );
                                  }}
                                />
                                <p style={{ color: "red" }}>
                                  {errors.hobbies?.message}
                                </p>
                              </div>

                              <div class="col-span-full mb-7">
                                <label for="about" class="laBel">
                                  Fee Description ( Optional )
                                </label>
                                <div class="mt-2">
                                  <textarea
                                    rows="3"
                                    class="block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff] "
                                    placeholder="Type something"
                                    {...register(
                                      `fieldFee.${index}.feeDescription`
                                    )}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <button
                          type="button"
                          onClick={() =>
                            append({
                              feeName: "",
                              feeAmount: "",
                              feeDescription: "",
                            })
                          }
                          class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                        >
                          <AddFeeIcon />
                          <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                            Add another fee
                          </span>
                        </button>
                      </div>
                      {/* FOOTER */}
                      <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                        {/* <button
                          //onClick={openMutiplePaymentModal}
                          class="white_modal_btn"
                        >
                          Back
                        </button> */}
                        <button
                          //onClick={openMutiplePaymentModal3}
                          class="blue_modal_btn ml-4"
                        >
                          {isCreatingFee ? <SpinnerWhite /> : "Create"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </form>
          </main>
        </header>
      </Layout>
    </>
  );
};
