import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CgArrowTopRight } from "react-icons/cg";
import { Spinner } from "../../../../components/spinner";
import { Table } from "../../../../components/table/Table";
import {
  formatCurrency,
  formatDateTime,
  truncateName,
} from "../../../../utils/functions";
import { reversePaymentMethods } from "../../../../utils/constants/paymentMethods";
import { Notify } from "../../../../components/notification";

import "./styles.scss";

const column = [
  "Amount",
  "Payment method",
  "Station name",
  "Customer name",
  "Date",
];

function HomeSection3({ transactions, loading, fetch }) {
  const [formattedTransactions, setFormattedTransactions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (transactions && !!transactions.length) {
      const formattedTransactions = transactions.map((transaction) => ({
        payAccountName: truncateName(transaction.payAccountName),
        tradeAmt: formatCurrency(transaction.tradeAmt),
        terminalName:
          transaction.terminalName === null
            ? "Main account"
            : transaction.terminalName,
        productCode: reversePaymentMethods.get(transaction.productCode),
        finishTime: formatDateTime(transaction.finishTime),
        orderNo: transaction.orderNo,
      }));
      setFormattedTransactions(formattedTransactions.slice(0, 5));
    }
  }, [transactions]);

  const checkInternet = () => {
    if (navigator.onLine) {
      history.push("/dashboard/merchant/transactions");
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  const checkTransaction = () => {
    if (navigator.onLine) {
      setFormattedTransactions([]);
      fetch();
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <header>
      <main className="home3-main">
        <div className="home3-bar">
          <h3>Recent Transactions</h3>
          <div className="topbar">
            <span className="top-notify">
              <button className="filter__card" onClick={checkTransaction}>
                <span className="check__text">Check Transactions</span>
              </button>
              <div onClick={(node, event) => checkInternet()} className="mini-card">
                <a className="link">
                  <span
                    className="see-all"
                  >
                    See all
                  </span>
                </a>
                <CgArrowTopRight className="percentage" />
              </div>
            </span>
          </div>
        </div>

        <div className="transactionTable">
          <Table
            column={column}
            data={formattedTransactions}
            link={"/dashboard/merchant/transactions/"}
          />
          {loading ? (
            <>
              <div style={{ marginTop: "40px" }}>
                <Spinner color={"#1a71ff"} />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </main>
    </header>
  );
}

export default HomeSection3;
