import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import Layout from "../../components/layout/Layout";
import { useForm } from "react-hook-form";
import { Notify } from "../../components/notification";
import { OnboardingList } from "../../components/onboarding-list/OnboardingList";
import storage from "../../utils/storage";
import { genderList, genders } from "../../utils/constants/gender";
import { documentList, documents } from "../../utils/constants/documents";
import { useFileUpload } from "../../hooks/useFileUpload";
import file from "../../helpers/file";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles.scss";
import { SpinnerWhite } from "../../components/spinner/Spinner";

export const PersonalInformationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [userFirstName, setUserFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [userId, setUserId] = useState("");
  const [isBvn, setIsBvn] = useState(true);
  const [isVotersCard, setIsVotersCard] = useState(false);
  const [isNin, setIsNin] = useState(false);
  const [isPassport, setIsPassport] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isNotUpload, setIsNotUpload] = useState(true);
  const [certificateType, setCertificateType] = useState("");
  const [fileSuffix, setFileSuffix] = useState("");
  const [base64Certificate, setBase64Certificate] = useState("");
  const [genderForm, setGenderForm] = useState("Male");
  const [legalTypeForm, setLegalTypeForm] = useState(
    "National Identification Number (NIN)"
  );

  const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));
  if (onboardingDetails.onboarded) {
    history.push("/dashboard/merchant/overview");
  }

  const filledPersonalInfo = JSON.parse(storage.fetch("personalInformation"));
  useEffect(() => {
    if (filledPersonalInfo) {
      setValue("birthday", filledPersonalInfo.birthday);
      if (filledPersonalInfo.legalNo !== "-") {
        setValue("legalNo", filledPersonalInfo.legalNo);
      }
      if (filledPersonalInfo.bvn !== "-") {
        setValue("bvn", filledPersonalInfo.bvn);
      }
      genders.forEach(function (value, key) {
        if (value === filledPersonalInfo.gender) {
          setGenderForm(key);
          setValue("gender", key);
        }
      });

      documents.forEach(function (value, key) {
        if (key === filledPersonalInfo.legalType) {
          setLegalTypeForm(key);
          setValue("legalType", key);
        }
      });
    }
  }, []);

  // Form Validation
  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const notify = () =>
    toast.success("Uploading File!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const {
    data,
    isLoading,
    mutate: uploadFile,
  } = useFileUpload({
    userId,
    certificateType,
    fileSuffix,
    base64Certificate,
  });

  const legalType = watch("legalType");

  useEffect(() => {
    const userDetails = JSON.parse(storage.fetch("userDetails"));
    setUserFirstName(userDetails.firstName);
    setSurname(userDetails.surname);
    setUserId(userDetails.userId);
    setValue("firstName", userDetails.firstName);
    setValue("lastName", userDetails.surname);
  }, []);

  useEffect(() => {
    if (legalType === "Bank Verification Number (BVN)") {
      setIsVotersCard(false);
      setIsNin(false);
      setIsPassport(false);
      setIsBvn(true);
      setIsDriver(false);
      setIsNotUpload(true);
      setIsUploadFile(false);
      setCertificateType(documents.get(legalType));
    } else if (legalType === "International Passport") {
      setIsVotersCard(false);
      setIsNin(false);
      setIsPassport(true);
      setIsBvn(false);
      setIsDriver(false);
      setIsNotUpload(false);
      setIsUploadFile(true);
      setCertificateType(documents.get(legalType));
    } else if (legalType === "National Identification Number (NIN)") {
      setIsVotersCard(false);
      setIsNin(true);
      setIsPassport(false);
      setIsBvn(false);
      setIsDriver(false);
      setIsNotUpload(true);
      setIsUploadFile(false);
      setCertificateType(documents.get(legalType));
    } else if (legalType === "Voter's Card") {
      setIsVotersCard(true);
      setIsNin(false);
      setIsPassport(false);
      setIsBvn(false);
      setIsDriver(false);
      setIsNotUpload(false);
      setIsUploadFile(true);
      setCertificateType(documents.get(legalType));
    } else if (legalType === "Driver's License") {
      setIsVotersCard(false);
      setIsNin(false);
      setIsPassport(false);
      setIsBvn(false);
      setIsDriver(true);
      setIsNotUpload(false);
      setIsUploadFile(true);
      setCertificateType(documents.get(legalType));
    }
  }, [legalType]);

  useEffect(() => {
    resetField("legalNo");
  }, [legalType]);

  useEffect(() => {
    if (data?.code === "000000") {
      history.push("/dashboard/merchant/onboarding/bank-details", {
        from: location,
      });
    } else if (!!isUploadFile && data?.code === "US0052") {
      Notify(
        "error",
        "Upload a file!",
        "Please upload the required file to continue."
      );
    }
  }, [data]);

  const onSubmitHandler = (data) => {
    const { legalNo, firstName, lastName, gender, birthday, legalType, bvn } =
      data;

    const payload = {
      legalName: `${firstName} ${lastName}`,
      surname: lastName,
      firstName,
      legalNo: legalNo ? legalNo : "-",
      legalType,
      gender: genders.get(gender),
      birthday,
      bvn: bvn ? bvn : "-",
    };

    storage.add("personalInformation", JSON.stringify(payload));

    if (isBvn || isNin) {
      history.push("/dashboard/merchant/onboarding/bank-details");
    } else if (isDriver || isPassport || isVotersCard) {
      if (base64Certificate) {
        document.getElementById("uploadText").style.color = "black";
        uploadFile();
      }
      {
        document.getElementById("uploadText").style.color = "red";
      }
    }
  };

  const handleFileUpload = async (e) => {
    const certificate = e.target.files[0];
    const fileType = certificate.type.split("/");
    setFileSuffix(fileType[1]);
    const base64Certificate = await file.convertToBase64(certificate);
    setBase64Certificate(base64Certificate);

    setIsUploading(true);
  };

  useEffect(() => {
    if (isUploading === true) {
      notify();
    }
  }, [isUploading]);

  return (
    <>
      <Layout>
        <header>
          <main className="personal-main">
            <div className="row">
              <div className="column">
                <OnboardingList />
              </div>

              <div className="column">
                <div className={isNotUpload ? "account-wrap" : "upload-wrap"}>
                  <h3>Personal Information</h3>
                  <p>
                    Please tell us more about yourself. This is required to
                    verify your identity. Your name should match with that on
                    your ID documents.
                  </p>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <label>Gender</label>
                    <div className="business-option">
                      <select
                        type="text"
                        placeholder="Gender"
                        {...register("gender")}
                      >
                        {genderList.map((gender, i) => (
                          <option
                            key={i}
                            selected={gender === genderForm && "selected"}
                          >
                            {gender}
                          </option>
                        ))}
                      </select>
                      <MdKeyboardArrowDown className="business-down" />
                    </div>

                    <label>First Name</label>
                    <input
                      className={`personalInput ${
                        errors.firstName && "invalid"
                      }`}
                      type="name"
                      placeholder={"Enter first name"}
                      {...register("firstName", {
                        required: "First name is required",
                      })}
                    />
                    {errors.firstName && (
                      <section className="error__txtbtm">
                        {errors.firstName.message}
                      </section>
                    )}

                    <label>Last Name</label>
                    <input
                      className={`personalInput ${
                        errors.lastName && "invalid"
                      }`}
                      type="name"
                      placeholder={"Enter last name"}
                      {...register("lastName", {
                        required: "Last name is required",
                      })}
                    />
                    {errors.lastName && (
                      <section className="error__txtbtm">
                        {errors.lastName.message}
                      </section>
                    )}

                    <label>Identification Type</label>
                    <div className="business-option">
                      <select
                        type="text"
                        placeholder="Identifications"
                        {...register("legalType")}
                      >
                        {documentList.map((doc, i) => (
                          <option
                            key={i}
                            selected={i === legalTypeForm && "selected"}
                          >
                            {doc}
                          </option>
                        ))}
                      </select>
                      <MdKeyboardArrowDown className="business-down" />
                    </div>

                    {isBvn && (
                      <>
                        <label>Bank Verification Number (BVN)</label>
                        <input
                          className={`personalInput ${errors.bvn && "invalid"}`}
                          type="text"
                          placeholder="Enter BVN"
                          {...register("bvn", {
                            required: "BVN Number is required",
                          })}
                        />
                        {errors.bvn && (
                          <section className="error__txtbtm">
                            {errors.bvn.message}
                          </section>
                        )}
                      </>
                    )}

                    {isNin && (
                      <>
                        <label>National Identification Number</label>
                        <input
                          className={`personalInput ${
                            errors.legalNo && "invalid"
                          }`}
                          type="text"
                          placeholder="Enter NIN Number"
                          {...register("legalNo", {
                            required:
                              "National Identification Number is required",
                          })}
                        />
                        {errors.legalNo && (
                          <section className="error__txtbtm">
                            {errors.legalNo.message}
                          </section>
                        )}
                      </>
                    )}

                    <label>Birthday</label>
                    <input
                      className={`personalInput ${
                        errors.birthday && "invalid"
                      }`}
                      type="date"
                      placeholder="Select your birthday"
                      {...register("birthday", {
                        required: "Birthday is required",
                      })}
                    />
                    {errors.birthday && (
                      <section className="error__txt">
                        {errors.birthday.message}
                      </section>
                    )}

                    {isUploading ? (
                      <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    ) : (
                      ""
                    )}

                    {isUploadFile && (
                      <>
                        <p>Upload Document</p>
                        <div className="btn-Wrap">
                          <label className="btn" for="upload">
                            Choose file
                          </label>
                          <input
                            type="file"
                            id="upload"
                            placeholder="Choose file"
                            onChange={handleFileUpload}
                          />
                        </div>
                        {legalType === "International Passport" && (
                          <label id="uploadText" className="document-label">
                            Upload a copy of your passport data page
                          </label>
                        )}

                        {legalType === "Voter's Card" && (
                          <label id="uploadText" className="document-label">
                            Upload a copy of your personal voter's card (PVC)
                          </label>
                        )}

                        {legalType === "Driver's License" && (
                          <label id="uploadText" className="document-label">
                            Upload a copy of your driver's license
                          </label>
                        )}
                      </>
                    )}
                    <button
                      type="submit"
                      disabled={isLoading ? isLoading : false}
                    >
                      {isLoading ? <SpinnerWhite /> : "Next"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </header>
      </Layout>
    </>
  );
};
