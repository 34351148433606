import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { feePaymentStatus } from "../../utils/functions";
import { Notify } from "../notification";
import { useCreateClass, useQueryClass } from "../../hooks/useClass";

import { SpinnerWhite } from "../../components/spinner/Spinner";
import { Controller, set, useFieldArray, useForm } from "react-hook-form";
import {
  AddFeeIcon,
  ArrowDownIcon,
  CloseModalIcon,
} from "../../assests/icons/Icons";

import { useCreateFee, useQueryFee } from "../../hooks/useFee";
import storage from "../../utils/storage";
import Select from "react-select";

import "./styles.scss";

export const FeeListTable = ({ column, data }) => {
  const history = useHistory();
  const [copied, setCopied] = useState(true);
  const [copiedIndex, setCopiedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(false);

  //////////////////////////////////////////////////////////////////////
  const selectRef = useRef(null);
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [showSchoolFees, setShowSchoolFees] = useState(false);
  const [showSchoolFees2, setShowSchoolFees2] = useState(false);
  const [showSchoolFees3, setShowSchoolFees3] = useState(false);
  const [showSchoolFees4, setShowSchoolFees4] = useState(false);
  const [showSchoolFees5, setShowSchoolFees5] = useState(false);
  const [showSchoolFees6, setShowSchoolFees6] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [showAddClass, setShowAddClass] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSinglePaymentModal, setShowSinglePaymentModal] = useState(false);
  const [showMutiplePaymentModal, setShowMultiplePaymentModal] =
    useState(false);
  const [showMutiplePaymentModal2, setShowMultiplePaymentModal2] =
    useState(false);
  const [showMutiplePaymentModal3, setShowMultiplePaymentModal3] =
    useState(false);
  const [showMutiplePaymentModal4, setShowMultiplePaymentModal4] =
    useState(false);
  const [showEditFeeModal, setShowEditFeeModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [feeId, setFeeId] = useState("");
  const [feeName, setFeeName] = useState("");
  const [remark, setRemark] = useState("");
  const [imageName, setImageName] = useState("");
  const [selectedFeeOption, setSelectedFeeOption] = useState([]);
  const [feeOptions, setFeeOptions] = useState([]);
  const [addInfoCount, setAddInfoCount] = useState(0);
  const [selectedClassOption, setSelectedClassOption] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [feeDetails, setFeeDetails] = useState([]);

  const [imageId, setImageId] = useState();
  const [deactivateModal, setDeactivatetModal] = useState(false);
  const [statusFee, setStatusFee] = useState(1);

  const uploadRef = useRef();

  /////////////////////////////////////////////////////////////

  const {
    isLoading: isCreatingFee,
    data: feeData,
    mutate: fetchCreateFee,
  } = useCreateFee({
    feeId,
    cusId,
    feeName,
    status: statusFee,
    amount,
    remark,
    ...feeDetails,
  });

  const {
    isLoading: isQueryingFee,
    data: feeQueryData,
    mutate: fetchQueryFee,
  } = useQueryFee({
    cusId,
  });

  const {
    isLoading: isFetchingClassDetails,
    data: queryClassData,
    mutate: fetchQueryClass,
  } = useQueryClass({
    cusId,
  });

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      field: [{ className: "", classCategory: "" }],
      fieldFee: [{ feeName: "", feeAmount: "", feeDescription: "" }],
      fieldClass: [{ className: "", classCategory: "" }],
    },
  });

  //CLOSE MODALS
  const closeModal = () => {
    setShowEditFeeModal(false);
    setDeactivatetModal(false);
    //setShowModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowPaymentModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal4(false);
    //setShowDonation(false);
    resetField("fieldFee");
    resetField("field");
    resetField("fieldCreateFee");
    resetField(`fieldCreateFee.addInfo`);
    resetField("fieldSingleLink");
    resetField(`fieldSingleLink.addInfo`);
    resetField("fieldClass");
    resetField("classFeeApplied");
    setSelectedClassOption([]);
    setClassOptions([]);
    setAddInfoCount(0);
    setImageName("");
    setImageId("");

    setSelectedFeeOption([]);
    setAddInfoCount(0);
    remove();
    append({ labelName: "", checkOptional: "" });
  };

  const startEditFeeModal = () => {
    setShowEditFeeModal(true);
    setShowPaymentModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    //setShowDonation(false);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "field",
  });

  useEffect(() => {
    if (feeData?.code === "000000") {
      //console.log(feeData);

      if (feeData?.data?.status === "0") {
        Notify("success", "Fee successful!", "Fee deleted successfully");
        resetField("fieldFee");
        closeModal();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        Notify("success", "Fee successful!", "Fee edited successfully");
        resetField("fieldFee");
        closeModal();
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }

      //fetchQueryFee();
    } else if (feeData?.code === "US0066") {
      setAmount(0);
      setRemark("");
      setFeeName("");

      Notify(
        "error",
        "Fee error",
        "Sorry! this fee name already exists, input a new fee name.",
        10
      );
    }
  }, [feeData]);

  useEffect(() => {
    if (queryClassData?.code === "000000") {
      setClassOptions([]);

      var classOption = [];

      if (queryClassData.data.length > 0) {
        queryClassData.data.forEach(function (entry) {
          var signClass = {};
          signClass["value"] = entry.classId;
          signClass["label"] = entry.className;
          signClass["category"] = entry.classCategory;
          classOption.push(signClass);
        });
      }
      setClassOptions(classOption);
    }
  }, [queryClassData]);

  //SUBMIT FEE EDIT DATA
  const onSubmitFeeHandler = (data) => {
    var createForm = {};

    for (let i in data.fieldFee) {
      const { feeName, feeAmount, feeDescription } = data.fieldFee[i];

      var createFee = {};
      createFee["feeName"] = feeName;
      createFee["amount"] = feeAmount;
      createFee["remark"] = feeDescription;

      var appliedClass = [];
      if (data?.classFeeApplied) {
        for (let n in data?.classFeeApplied[i]) {
          var singleClassId = {};
          var classes = data?.classFeeApplied[i][n];
          if (classes?.value) {
            singleClassId["classId"] = classes?.value;
            appliedClass.push(singleClassId);
          }
        }
      }

      createFee["appliedClasses"] = appliedClass;
      setFeeDetails(createFee);
      if (feeName) {
        fetchCreateFee();
      }
    }
  };

  async function handleCopy(linkUrl, index) {
    try {
      await navigator.clipboard.writeText(linkUrl);

      setCopied(false);
      setCopiedIndex(index);
      setTimeout(() => {
        setCopied(true);
        setCopiedIndex(null);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  function linkPreview(linkUrl) {
    if (linkUrl) {
      window.open(linkUrl);
    }
  }

  useEffect(() => {
    fetchCreateFee();
  }, [statusFee]);

  const deActivate = () => {
    setStatusFee(0);
  };

  const showDeactive = () => {
    setDeactivatetModal(true);
  };

  const closeDeactive = () => {
    setDeactivatetModal(false);
  };

  const handleRowClick = (trans) => {
    if (navigator.onLine) {
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <div id="table-container">
      <table className="payment-link-tabs">
        <thead>
          {column.map((header, i) => (
            <th key={i}>{header}</th>
          ))}
        </thead>
        <tbody>
          {data.map((fees, i) => (
            <tr key={i} onClick={(node, event) => handleRowClick(fees)}>
              <td
                id="font_change"
                className="view"
                onClick={(node, event) => handleRowClick(fees)}
              >
                <a className="bolder__one">{fees.feeName}</a>
              </td>

              <td onClick={(node, event) => handleRowClick(fees)}>
                <a className="bolder__one">{fees.feeAmount}</a>
              </td>

              <td onClick={(i) => handleRowClick}>
                <a className="view">{fees.feeRemark}</a>
              </td>

              <td>
                <a
                  className="edit-fees"
                  onClick={(e) => {
                    e.stopPropagation();
                    startEditFeeModal(true);
                    fetchQueryClass();
                    setFeeId(fees.feeId);
                    setValue(`fieldFee.0.feeName`, fees.feeName);
                    setValue(`fieldFee.0.feeAmount`, fees.feeAmountUnformat);
                    setValue(`fieldFee.0.feeDescription`, fees.feeRemark);

                    var selectedClassOption = [];

                    if (fees?.feeClass?.length > 0) {
                      fees.feeClass.forEach(function (entry) {
                        var signClass = {};
                        signClass["value"] = entry.classId;
                        signClass["label"] = entry.className;
                        signClass["category"] = entry.classCategory;
                        selectedClassOption.push(signClass);
                      });
                      //console.log("feeOptions",feeOptions)
                    }
                    if (selectedClassOption) {
                      setValue(`classFeeApplied.0`, selectedClassOption);
                    }
                  }}
                >
                  Edit
                </a>
                <a
                  className="delete-fees"
                  onClick={(e) => {
                    e.stopPropagation();
                    //alert("Delete");
                    setFeeId(fees.feeId);
                    showDeactive();
                  }}
                >
                  Delete
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Modal for fee creation */}

      <form onSubmit={handleSubmit(onSubmitFeeHandler)}>
        {showEditFeeModal ? (
          <div className="fee__modal">
            <div className="fee__wrap">
              <div class="flex flex-col">
                {/* HEADER */}
                <div class="flex pt-[20px] pb-[16px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                  <h4 class="text-base font-medium text-[#595959] items-center justify-center flex">
                    Edit Fee
                  </h4>
                  <span
                    onClick={closeModal}
                    class="absolute top-[38%] right-[9.8%] cursor-pointer"
                  >
                    <CloseModalIcon />
                  </span>
                </div>
                {/* BODY */}
                <div class="sm:px-14 px-8 py-[30px] overflow-y-auto max-h-[660px] min-h-[450px]">
                  {fields.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <div class="mb-7">
                          <label class="laBel justify-between flex">
                            <span>Fee name</span>

                            {index !== 0 && (
                              <span
                                onClick={() => remove(index)}
                                class="cursor-pointer text-[red] hover:text-[red]"
                              >
                                Clear
                              </span>
                            )}
                          </label>
                          <input
                            type="text"
                            defaultValue="test"
                            class={`in_Put ${
                              errors.fieldFee?.[index]?.feeName && "inPut_error"
                            }`}
                            placeholder="E.g Transport levy"
                            {...register(`fieldFee.${index}.feeName`, {
                              required: true,
                            })}
                          />
                          <p class="hidden mt-1 text-sm text-red-600 dark:text-red-500">
                            Enter fee name
                          </p>
                        </div>

                        <label class="laBel mb-2">Fee amount</label>
                        <div class="flex mb-7">
                          <span class="half_label">NGN</span>
                          <input
                            type="number"
                            defaultValue="20000"
                            class={`"rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[#f7f7f7] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[#1a71ff] dark:focus:border-[1a71ff]" ${
                              errors.fieldFee?.[index]?.feeAmount &&
                              "rounded-none rounded-r-lg bg-[#f7f7f7] text-[#000000] focus:ring-[#1a71ff] focus:border-[#1a71ff] block flex-1 min-w-0 w-full text-sm p-4  dark:bg-[#f7f7f7] dark:border-[red] dark:placeholder-[#8d8d8d] dark:text-[#000000] dark:focus:ring-[red] dark:focus:border-[red] border-2 outline-none "
                            }`}
                            placeholder="Eg. 5,000"
                            {...register(`fieldFee.${index}.feeAmount`, {
                              required: true,
                            })}
                          />
                        </div>

                        <div class="relative mb-7">
                          <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                            Class fee apply
                          </span>

                          <Controller
                            name={`classFeeApplied.${index}`}
                            control={control}
                            defaultValue=""
                            rules={{
                              required: false,
                            }}
                            render={({ field }) => {
                              return (
                                <Select
                                  {...field}
                                  //defaultValue={selectedFeeOption}
                                  //value={classFeeApplied[index]}
                                  // onChange={(ep) => {
                                  //   // var classeleted = classFeeApplied;
                                  //   // classeleted[index] = ep;
                                  //   // setClassFeeApplied(classeleted);
                                  // }}
                                  options={classOptions}
                                  placeholder="Select class"
                                  isMulti
                                  name="classes"
                                  //closeMenuOnSelect={false}
                                  className="basic-multi-select"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      // borderColor: state.isFocused
                                      //   ? "#1a71ff"
                                      //   : "#8d8d8d",
                                      backgroundColor: "#f7f7f7",
                                      //height: '52px',
                                      borderWidth: "2px",
                                      borderRadius: "8px",
                                      minHeight: "52px",
                                    }),
                                  }}
                                />
                              );
                            }}
                          />
                          <p style={{ color: "red" }}>
                            {errors.hobbies?.message}
                          </p>
                        </div>

                        <div class="col-span-full mb-7">
                          <label for="about" class="laBel">
                            Fee Description ( Optional )
                          </label>
                          <div class="mt-2">
                            <textarea
                              rows="3"
                              class="block w-full h-[87px] rounded-lg border-[none] p-4 text-gray-900 bg-[#f7f7f7] ring-1 ring-inset ring-[#f7f7f7]  focus:ring-[#1a71ff] "
                              placeholder="Type something"
                              {...register(`fieldFee.${index}.feeDescription`)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <button
                          type="button"
                          onClick={() =>
                            append({
                              feeName: "",
                              feeAmount: "",
                              feeDescription: "",
                            })
                          }
                          class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                        >
                          <AddFeeIcon />
                          <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                            Add another fee
                          </span>
                        </button> */}
                </div>
                {/* FOOTER */}
                <div class="flex py-4  border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                  {/* <button
                          //onClick={openMutiplePaymentModal}
                          class="white_modal_btn"
                        >
                          Back
                        </button> */}
                  <button
                    //onClick={openMutiplePaymentModal3}
                    class="blue_modal_btn ml-4"
                  >
                    {isCreatingFee ? <SpinnerWhite /> : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </form>

      {deactivateModal ? (
        <div className="logout__modal">
          <div id="myModal" class="logout-modal">
            <div class="logout-content">
              <div className="logout-container">
                <p className="logout">Delete</p>
              </div>
              <h6 className="modalDropDown">
                Are you sure you want to delete this fee? This action is
                irreversible
              </h6>
              <div className="log__btns">
                <button className="stay-btn" onClick={closeDeactive}>
                  Cancel
                </button>
                <button className="logout-btn" onClick={deActivate}>
                  <span className="export-text">
                    {isCreatingFee ? <SpinnerWhite /> : <>Continue</>}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
