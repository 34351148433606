export const businessCategories = new Map([
  ["Health", "1"],
  ["Beauty, fitness and wellness", "2"],
  ["Food, drinks and hospitality", "3"],
  ["Leisure and entertainment", "4"],
  ["Government and NGO", "5"],
  ["Retail and commerce", "6"],
  ["Schools and educations services", "7"],
  ["Utilities services", "8"],
  ["Transportation and delivery", "9"],
  ["Services provider", "10"],
]);

export const reversebusinessCategories = new Map([
  ["1", "Health"],
  ["2", "Beauty, fitness and wellness"],
  ["3", "Food, drinks and hospitality"],
  ["4", "Leisure and entertainment"],
  ["5", "Government and NGO"],
  ["6", "Retail and commerce"],
  ["7", "Schools and educations services"],
  ["8", "Utilities services"],
  ["9", "Transportation and delivery"],
  ["10", "Services provider"],
]);

export const businessCategoriesList = Array.from(businessCategories.keys());
