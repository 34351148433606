import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import { useMerchantQuery } from "../../hooks/useMerchant";
import Layout from "../../components/layout/Layout";
import { NIBSS, PaySticker } from "../../assests/svgs";
import { Logo, NQR } from "../../assests/images";
import storage from "../../utils/storage";
import { addSpace } from "../../utils/functions";
import { Notify } from "../../components/notification";

import "./styles.scss";

export const PaymentStandPage = () => {
  const history = useHistory();
  const exportRef = useRef();
  const logoUrl = JSON.parse(storage.fetch("merchantDetails")).logoUrl;
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [merchantName, setMerchantName] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [virtualAccNo, setVirtualAccNo] = useState("");
  const [virtualBank, setVirtualBank] = useState("");
  const [gtAccNo, setGtAccNo] = useState("");
  const [gtVirtualBank, setGtVirtualBank] = useState("");
  const [providusVirtualAcc, setProvidusVirtualAcc] = useState("");
  const [showGt, setShowGt] = useState(true);
  const [showWema, setShowWema] = useState(true);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 480px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 480px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const { data, mutate: fetchMerchantDetails } = useMerchantQuery({ cusId });

  useEffect(() => {
    fetchMerchantDetails();
  }, []);

  useEffect(() => {
    if (data?.code === "000000") {
      if (data?.data.merchantInfo.gtbVirtualAccountNumber === null) {
        setShowGt(false);
      }
      if (data?.data.merchantInfo.virtualAccountNumber === null) {
        setShowWema(false);
      }
      if (
        data?.data.merchantInfo.virtualAccountNumber === null &&
        data?.data.merchantInfo.gtbVirtualAccountNumber === null
      ) {
        setShowWema("Pending");
        setShowGt("Pending");
      }
      setMerchantName(data?.data.merchantInfo.cusFullName);
      setQrCode(data?.data.merchantInfo.qrCode);
      setGtAccNo(data?.data.merchantInfo.gtbVirtualAccountNumber);
      setGtVirtualBank(data?.data.merchantInfo.gtbVirtualAccountBankName);
      setVirtualAccNo(data?.data.merchantInfo.virtualAccountNumber);
      setVirtualBank(data?.data.merchantInfo.virtualAccountBankName);
      setProvidusVirtualAcc(data?.data.merchantInfo.providusVirtualAccountNumber)
    }
  }, [data]);

  const qrDownload = (
    <QRCode
      id="qrCodeId"
      size={matches ? 230 : 320}
      value={qrCode}
      bgColor="white"
      fqColor="black"
      level="M"
    />
  );

  const qrcode = (
    <QRCode
      id="qrCodeId"
      size={230}
      value={qrCode}
      bgColor="white"
      fqColor="black"
      level="M"
      imageSettings={{
        src: logoUrl ? logoUrl : Logo,
        excavate: true,
        width: 40,
        height: 40,
      }}
    />
  );

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {
      switch (profileType) {
        case "businessProfile":
          history.replace("/dashboard/merchant/settings/business");
          break;
        case "personalProfile":
          history.replace("/dashboard/merchant/settings");
          break;
        case "bankProfile":
          history.replace("/dashboard/merchant/settings/bank");
          break;
        case "accountSettings":
          history.replace("/dashboard/merchant/settings/accounts");
          break;
        case "paymentStand":
          history.replace("/dashboard/merchant/settings/payment-stand");
          break;
        default:
          history.replace("/dashboard/merchant/settings");
      }
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <Layout>
      <header>
        <main className="main__paymentstand">
          <section className="sections" id="sect">
            <a>
              <span
                className="personal-info"
                onClick={(node, event) => handleRowClick("personalProfile")}
              >
                Personal Details
              </span>
            </a>
            <a>
              <span
                className="activee"
                onClick={(node, event) => handleRowClick("businessProfile")}
              >
                Business Details
              </span>
            </a>
            <a>
              <span onClick={(node, event) => handleRowClick("bankProfile")}>
                Bank Details
              </span>
            </a>
            <a>
              <span
                onClick={(node, event) => handleRowClick("accountSettings")}
              >
                Account Settings
              </span>
            </a>
            <a>
              <span
                className="active-stand"
                onClick={(node, event) => handleRowClick("paymentStand")}
              >
                Payment Sticker
              </span>
            </a>
          </section>
          <div className="intro-txt">
            <h2>Payment Stickers</h2>
            <h4>Download your sticker to start receiving payments.</h4>
          </div>
          <div>
            <div className="form" ref={exportRef}>
              <img
                src={PaySticker}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
              <div className="new_card">
                <h1>
                  {virtualAccNo === null
                    ? "0000000000"
                    : addSpace(virtualAccNo)}
                </h1>
                <h3>{virtualBank === null ? "Wema Bank" : virtualBank}</h3>
                <h4>{merchantName}</h4>
              </div>
              <div className="qr_card">
                <div>{qrDownload}</div>
              </div>
            </div>
          </div>
          <div className="line-border">
            <div className="payment-stand">
              <div className="header-left"></div>
              <div className="icons">
                {/* <button
                  onClick={() => exportAsImage(exportRef.current, "DeemPay")}
                  className="filter-btn"
                >
                  <img src={SaveSticker} alt="" width="15" height="15" />
                  <span className="save">Save</span>
                </button> */}
              </div>
            </div>
            <section>
              <form>
                <div className="bottom-line">
                  <h3>{merchantName}</h3>
                </div>
                <card>
                  <section>{qrcode}</section>
                  <div className="nibss__images">
                    <img
                      src={NQR}
                      alt=""
                      className="nibss"
                      width="45%"
                    />
                    <img src={NIBSS} alt=""  width="24%" />
                  </div>
                </card>
                <h5>Scan and pay with any bank app</h5>
                <span className="line--wrapper">
                  <h6>OR</h6>
                </span>

                <div className="accounts__section">
                  {showWema && (
                    <>
                      <div className="gtPart">
                        <p className="payWith">Account Number</p>
                        <p className="accNo">
                          {providusVirtualAcc === null
                            ? "Pending"
                            : addSpace(providusVirtualAcc)}
                        </p>
                      </div>
                      <div className="gtPart">
                        <p className="payWith">Bank</p>
                        <span className="wemaBank">
                        Providus Bank
                        </span>
                      </div>
                      <span className="line__wrapper2">
                        <h6>OR</h6>
                      </span>
                    </>
                  )}

                  {showGt && (
                    <>
                    <div className="gtPart">
                      <p className="payWith">Account Number</p>
                      <p className="accNo">
                        {gtAccNo === null 
                          ? "Pending"
                          : addSpace(gtAccNo)}
                      </p>
                    </div>
                    <div className="gtPart">
                      <p className="payWith">Bank</p>
                      <p className="wemaBank">
                      Guaranty Trust Bank
                      </p>
                    </div>
                    </>
                  )}
                </div>
              </form>
            </section>
            {/* <Link to={"//www.deempay.com/payment-stand"} target="_blank">
              <button className="request">Request customized sticker</button>
            </Link> */}
          </div>
        </main>
      </header>
    </Layout>
  );
};
