import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { Link, useHistory } from "react-router-dom";
import storage from "../../utils/storage";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { Spinner } from "../../components/spinner";
import { formatCount } from "../../utils/functions";
import { reverseRoles } from "../../utils/constants/roleIds";
import { useQuerySubAccount } from "../../hooks/useSubAccount";
import { SubAccountTable } from "../../components/table/SubAccountTable";
import { Notify } from "../../components/notification";
import { pageList, pages } from "../../utils/constants/pages";
import { NoSubAccountIcon } from "../../assests/icons/Icons";
import "./styles.scss";

const column = ["Station name", "Staff role", "Staff name", "Staff contact"];

export const SubAccountPage = () => {
  const history = useHistory();
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [role, setRole] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(10);
  const [totalSubAcc, setTotalSubAcc] = useState(0);

  const {
    isLoading: isFetchingSubAccount,
    data,
    mutate: fetchSubAccount,
  } = useQuerySubAccount({
    cusId,
  });

  useEffect(() => {
    const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));
    if (onboardingDetails.onboarded) {
      fetchSubAccount();
    } else {
      history.push("/dashboard/merchant/onboarding");
      Notify(
        "info",
        "Onboarding pending",
        "Please complete the onboarding process to start using your DeemPay account!",
        10
      );
    }
  }, []);

  useEffect(() => {
    if (data?.code === "000000") {
      const subaccountData = [];
      // To filter the subaccount returned as admin
      data.data.forEach(function(item, index, array) {
        if(item.roleId  !== "1"){
          subaccountData.push(item);
        }
      });

      const formattedRole = subaccountData.map((r) => {
        return {
          terminalName: r.terminalName,
          firstName: r.firstName,
          surname: r.surname,
          roleId: reverseRoles.get(r.roleId),
          phoneNo: r.phoneNo,
          terminalCode: r.terminalCode,
          chlTermNo: r.chlTermNo,
        };
      });
      setRole(formattedRole);
      setTotalSubAcc(data?.data.length);
      setPageCount(Math.ceil(data.data.length / currentSize));
    }
  }, [data]);

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleChange = (e) => {
    setCurrentSize(pages.get(e.target.value));
    setCurrentPage(1);
  };

  return (
    <Layout>
      <header>
        <main className="subaccount__main">
          <div className="main-sub">
            <section className="header-container">
              <div>
                <h1>
                  Retail sub-accounts
                  <span className="small__card">{formatCount(totalSubAcc)}</span>
                </h1>
              </div>
              <div className="subHeader-right">
                <div className="btn-div">
                  <Link to="/dashboard/merchant/sub-accounts/create">
                    <button className="blueBtn">
                      <span className="blue--txt">
                        <FiPlus className="new-img" />
                        Create
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="btn-div"></div>
              </div>
            </section>
          </div>
          {isFetchingSubAccount || role.length > 0 ? (
            <>
              <div className="transactionTable">
                <SubAccountTable
                  column={column}
                  data={role}
                  link={"/dashboard/merchant/sub-accounts/"}
                />

                {isFetchingSubAccount ? (
                  <div style={{ marginTop: "40px", marginBottom: "1rem" }}>
                    <Spinner color={"#1a71ff"} />
                  </div>
                ) : (
                  <>
                    <div className="pagination--Details">
                      <div className="items-to-show">
                        <p>Items to show: {currentSize} </p>
                        <select onChange={handleChange}>
                          {pageList.map((page, i) => (
                            <option key={i} selected={page === currentSize}>
                              {page}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="business-down" />
                      </div>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IoIosArrowRoundForward />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel={<IoIosArrowRoundBack />}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination__container"}
                        activeClassName={"active"}
                        previousLinkClassName={"pages-p"}
                        nextLinkClassName={"pages-p"}
                        forcePage={currentPage - 1}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : !!isFetchingSubAccount || role.length === 0 ? (
            <>
              <section className="no__Data">
                <NoSubAccountIcon />
                <h2>No Sub-account!</h2>
                <h4>
                  You do not have any retail sub-account created. You may need
                  to clear your filter or search query. Create a retail
                  sub-account that can also collect money on your behalf.
                </h4>
              </section>
            </>
          ) : (
            ""
          )}
        </main>
      </header>
    </Layout>
  );
};
