import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Spinner } from "../../../../components/spinner";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import storage from "../../../../utils/storage";
import { MdKeyboardArrowDown } from "react-icons/md";
import useRegister from "../../../../hooks/useRegister";
import useCheckPhone from "../../../../hooks/useCheckPhone";
import user from "../../../../helpers/user";
import { roleIds, roleIdList } from "../../../../utils/constants/roleIds";
import ManagerLayout from "../../../../components/layout/ManagerLayout";
import VerifyModal from "../../../../components/modals/verifymodal/VerifyModal";

import "./styles.scss";

export const ManagerCreateSubAccountPage = () => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const [roleId, setRoleId] = useState(3);
  const [smsAlert, setSmsAlert] = useState("");
  const [parentPhoneNo, setParentPhoneNo] = useState("");
  const [isPassword, setIsPassword] = useState(false);
  const [isPhoneNoChecked, setIsPhoneNoChecked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      parentPhoneNo: user.phoneNo(),
    },
  });

  const phoneNo = watch("phoneNo");

  const {
    isLoading: isCheckingPhone,
    data: checkPhoneData,
    mutate: phoneCode,
  } = useCheckPhone({
    phoneNo,
  });

  const {
    isLoading: isSendingCode,
    data,
    mutate: sendCode,
  } = useRegister({
    phoneNo,
    templateId: "M1001",
  });

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  useEffect(() => {
    if (phoneNo?.length === 11) {
      phoneCode();
    }
  }, [phoneNo]);

  useEffect(() => {
    if (checkPhoneData?.code === "000000") {
      if (
        checkPhoneData?.data.registeredUser !== null ||
        checkPhoneData?.data.registeredMerchant !== null
      ) {
        setIsPassword(false);
      } else {
        setIsPassword(true);
      }
      setIsPhoneNoChecked(true);
    }
  }, [checkPhoneData]);

  useEffect(() => {
    if (data?.code === "000000") {
      storage.add(
        "userSubAccountPayload",
        JSON.stringify({
          phoneNo,
          firstName,
          surname,
          parentPhoneNo: parentPhoneNo ? parentPhoneNo : user.phoneNo(),
          password,
          roleId: roleIds.get(roleId),
          terminalCode: terminalName,
          terminalName,
          smsAlert: smsAlert ? "1" : "2",
          email: email ? email : "",
        })
      );
      openModal();
    }
  }, [data]);

  const onSubmitHandler = (data) => {
    const {
      firstName,
      surname,
      parentPhoneNo,
      password,
      terminalName,
      roleId,
      smsAlert,
      email,
    } = data;
    setFirstName(firstName);
    setSurname(surname);
    setParentPhoneNo(parentPhoneNo);
    setPassword(password);
    setTerminalName(terminalName);
    setEmail(email);
    setRoleId(roleId);
    setSmsAlert(smsAlert);
    if (isPhoneNoChecked) {
      sendCode();
    } else {
      phoneCode();
    }
  };

  const handleChange = (e) => {
    setRoleId(roleIds.get(e.target.value));
  };

  return (
    <ManagerLayout>
      <header>
        <main className="create__sub">
          <section>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="add--line--bottom">
                <p className="add--content">Create Sub-account</p>
              </div>
              <div className="form--card">
                <span>
                  <div className="left-input">
                    <label className="">Station name</label>
                    <input
                      className={`addInput ${errors.terminalName && "invalid"}`}
                      type="text"
                      placeholder="Enter station name"
                      {...register("terminalName", {
                        required: "Station name is required",
                      })}
                    />
                  </div>
                  <div className="right-input addropDown">
                    <label className="">Sub-account type</label>
                    <select
                      className={`addInput ${errors.roleid && "invalid"}`}
                      onChange={handleChange}
                      {...register("roleId")}
                    >
                      {roleIdList.map((role, i) => (
                        <option key={i} selected={role === role}>
                          {role}
                        </option>
                      ))}
                    </select>
                    <MdKeyboardArrowDown className="add--down" />
                  </div>
                </span>

                    <label className="">First name</label>
                    <input
                      className={`addInput ${errors.firstName && "invalid"}`}
                      type="text"
                      placeholder="Enter Staff first name"
                      {...register("firstName", {
                        required: "First name is required",
                      })}
                    />
                    {errors.firstName && (
                      <aside className="error__sub">{errors.firstName.message}</aside>
                    )}
                    <label className="">Last name</label>
                    <input
                      className={`addInput ${errors.surname && "invalid"}`}
                      type="text"
                      placeholder="Enter staff last name"
                      {...register("surname", {
                        required: "Last name is required",
                      })}
                    />
                    {errors.surname && <aside className="error__sub">{errors.surname.message}</aside>}
                {isCheckingPhone ? (
                  <Spinner color={"#1a71ff"} />
                ) : (
                  <>
                    <p className="label">Staff Phone number</p>
                    <input
                      className={`addInput ${errors.phoneNo && "invalid"}`}
                      type="number"
                      placeholder="Enter staff phone number"
                      {...register("phoneNo", {
                        required: "Staff phone number is required",
                        minLength: {
                          value: 11,
                          message: "minimum allowed number is 11",
                        },
                        maxLength: {
                          value: 11,
                          message: "maximum allowed number is 11",
                        },
                      })}
                    />
                    {errors.phoneNo && <aside className="error__sub">{errors.phoneNo.message}</aside>}
                  </>
                )}

                <p className="label">Staff Email</p>
                <input
                  className={`addInput ${errors.email && "invalid"}`}
                  type="email"
                  placeholder="Enter staff email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && <aside className="error__sub">{errors.email.message}</aside>}
                {isPassword && (
                  <>
                    <p className="label">Staff Password</p>
                    <div className="input">
                      <input
                        className={`registerInput ${
                          errors.password && "invalid"
                        }`}
                        type={show ? "text" : "password"}
                        placeholder="Enter Staff password"
                        {...register("password", {
                          required: "password is required",
                          pattern: {
                            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                            message:
                              "Your password should contain at least a number and a letter and minimum of 6 characters",
                          },
                        })}
                      />
                      {errors.password && (
                        <aside className="error__sub">{errors.password.message}</aside>
                      )}

                      <span onClick={() => setShow(!show)}>
                        {show ? (
                          <AiOutlineEyeInvisible className="input-eye" />
                        ) : (
                          <AiOutlineEye className="input-eye" />
                        )}
                      </span>
                    </div>
                  </>
                )}
                <div className="btn__bottom">
                  <div className="sms-section">
                  </div>
                  <button type="submit" disabled={isSendingCode}>
                    {isSendingCode ? <Spinner /> : "Create account"}
                  </button>
                </div>
              </div>
            </form>
          </section>
          <VerifyModal showModal={showModal} setShowModal={setShowModal} />
        </main>
      </header>
    </ManagerLayout>
  );
};
