import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import ManagerLayout from "../../../../components/layout/ManagerLayout";
import storage from "../../../../utils/storage";
import { NIBSS, PaySticker } from "../../../../assests/svgs";
import { addSpace } from "../../../../utils/functions";
import { NQR, Logo, SaveSticker } from "../../../../assests/images";
import exportAsImage from "../../../../helpers/exportAsImage";

import "./styles.scss";

export const ManagerSubPaymentStickerPage = () => {
  const exportRef = useRef();
  const { emvcoCode, terminalCode, gtAccNo, providusVirtualAcc } = useParams();
  const merchantName = JSON.parse(
    storage.fetch("merchantDetails")
  ).merchantName;
  const logoUrl = JSON.parse(storage.fetch("merchantDetails")).logoUrl;
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 480px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 480px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const qrcode = (
    <QRCode
      id="qrCodeId"
      size={230}
      value={emvcoCode}
      bgColor="white"
      fqColor="black"
      level="M"
      imageSettings={{
        src: logoUrl ? logoUrl : Logo,
        excavate: true,
        width: 40,
        height: 40,
      }}
    />
  );

  const qrDownload = (
    <QRCode
      id="qrCodeId"
      size={matches ? 230 : 320}
      value={emvcoCode}
      bgColor="white"
      fqColor="black"
      level="M"
    />
  );

  return (
    <ManagerLayout>
      <header>
        <main className="main__stickyM">
          <div className="payment-stand">
            <div className="header-left"></div>
            <div className="icons">
              {/* <button
                onClick={() => exportAsImage(exportRef.current, "DeemPay")}
                className="filter-btn"
              >
                <img src={SaveSticker} alt="" width="15" height="15" />
                <span className="save">Save</span>
              </button> */}
            </div>
          </div>
          <section>
            <form>
              <div>
                <div className="form" ref={exportRef}>
                  <img
                    src={PaySticker}
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                  <div className="new_card">
                    {/* <h1>
                      {virtualAccNo === null
                        ? "0000000000"
                        : addSpace(virtualAccNo)}
                    </h1> */}
                    <h3>Wema Bank</h3>
                    <h4>{merchantName}</h4>
                    <h5>({terminalCode})</h5>
                  </div>
                  <div className="qr_card">
                    <div className="qr-crd">{qrDownload}</div>
                  </div>
                </div>
              </div>

              <section className="sticker__">
                <form>
                  <div className="bottom-line">
                    <h3>{merchantName}</h3>
                    <h6>({terminalCode})</h6>
                  </div>
                  <card>
                    <section>{qrcode}</section>
                    <div className="nibss__images">
                      <img src={NQR} alt="" className="nibss" width="45%" />
                      <img src={NIBSS} alt="" width="24%" />
                    </div>
                  </card>
                  <h5>Scan and pay with any bank app</h5>
                  <span className="line--wrapper">
                    <h6>OR</h6>
                  </span>
                  <div className="transferPart">
                    <div className="gtPart">
                      <p className="payWith">Account Number</p>
                      <p className="accNo">
                        {providusVirtualAcc === null ? "Pending" : addSpace(providusVirtualAcc)}
                      </p>
                    </div>
                    <div className="gtPart">
                      <p className="payWith">Bank</p>
                      <span className="wemaBank">Providus Bank</span>
                    </div>
                    <span className="line__wrapper2">
                      <h6>OR</h6>
                    </span>
                    <div className="gtPart">
                      <p className="payWith">Account Number</p>
                      <p className="accNo">
                        {gtAccNo === null
                          ? "Pending"
                          : addSpace(gtAccNo)}
                      </p>
                    </div>
                    <div className="gtPart">
                      <p className="payWith">Bank</p>
                      <p className="wemaBank">Guaranty Trust Bank</p>
                    </div>
                  </div>
                </form>
              </section>
            </form>
          </section>
        </main>
      </header>
    </ManagerLayout>
  );
};
