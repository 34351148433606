import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import HomeSection from "../home/homesection/HomeSection";
import HomeSection3 from "../home/homesection3/HomeSection3";
import storage from "../../../utils/storage";
import { useUserRole } from "../../../hooks/useUser";
import { useDevice } from "../../../hooks/useDevice";
import { useMerchantQuery } from "../../../hooks/useMerchant";
import { Notify } from "../../../components/notification";
import { useQueryTransaction } from "../../../hooks/useTransaction";
import { requestForToken, returnToken } from "../../../firebase";
import { Button, notification } from 'antd';
import {
  useQuerySubAccount,
  useCreateVirtualSubAcc,
} from "../../../hooks/useSubAccount";
import SupervisorLayout from "../../../components/layout/SupervisorLayout";

export const SupervisorHomePage = () => {
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const userId = JSON.parse(storage.fetch("userDetails")).userId;
  const phoneNo = JSON.parse(storage.fetch("userDetails")).phoneNo;
  const chlTermNo = JSON.parse(storage.fetch("role")).chlTermNo;
  const terminalCode = JSON.parse(storage.fetch("role")).terminalCode;

  const history = useHistory();
  const [payload, setPayload] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [latestTransaction, setLatestTransaction] = useState({});
  const [totalTransactions, setTotalTransactions] = useState(0);
 
   // Get firebase token for the browser
   requestForToken();
   var appToken = returnToken();
   const clientType = "03";

  const { 
    data, 
    mutate: fetchMerchantDetails
   } = useMerchantQuery(payload);

  const {
    isLoading: isFetchingTransactions,
    data: merchantTransactions,
    mutate: fetchTransactions,
  } = useQueryTransaction({
    cusId,
  });

  const {
     data: deviceData, 
     mutate: fetchDevice 
    } = useDevice({
    cusId,
    terminalCode: chlTermNo,
    appToken,
    clientType,
  });

  const {
    data: subData,
    mutate: fetchSubAccountDetails,
  } = useQuerySubAccount({
    cusId,
    phoneNo,
    terminalCode,
  });

  const { data: virtualData, mutate: fetchVirtualSubAcc } =
    useCreateVirtualSubAcc({
      cusId,
      terminalCode,
    });

  const { 
    data: userData, 
    mutate: fetchRole 
  } = useUserRole({ userId });

  const handleRequestPermission = () => {
    if (navigator.permissions) {

      Notification.requestPermission(function (result) {
        if (result === 'denied') {
          return;
        } else if (result === 'default') {
          return;
        }
      });
    }
  };

  useEffect(() => {
    fetchSubAccountDetails();
  }, []);

  const close = () => {
    handleRequestPermission()
  };

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="big" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );
    notification.open({
      message: 'Require notification permission!',
      description:
        'Please grant notification permission so that you can receive payment notication on your computer.',
      btn,
      key,
      duration: 0,
      onClose: close,
    });
  };

  useEffect(() => {
    if (userId) {
      fetchRole();
    }
  }, [userId]);

  useEffect(() => {
    if (userData?.code === "000000") {
    }
  }, [userData]);

  useEffect(() => {
    const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));

    if (onboardingDetails.onboarded) {
      const userId = JSON.parse(storage.fetch("userDetails")).userId;
      setPayload({ userId, cusId: onboardingDetails.cusId });
      fetchTransactions();
    } else {
      history.push("/dashboard/merchant/onboarding");
      Notify(
        "info",
        "Onboarding pending",
        "Please complete the onboarding process to start using your DeemPay account!",
        10
      );
    }
  }, []);

  useEffect(() => {
    if (!!Object.keys(payload).length) {
      fetchMerchantDetails();
    }
  }, [payload]);

  useEffect(() => {
    if (merchantTransactions?.data.result.length > 0) {
      setLatestTransaction(merchantTransactions?.data.result[0]);
      setTransactions(merchantTransactions?.data.result);
      setTotalTransactions(merchantTransactions?.data?.total);
    }
  }, [merchantTransactions]);

  useEffect(() => {
    if (data?.code === "000000") {
      const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));
      if (onboardingDetails.singleRole) {
        storage.add(
          "merchantDetails",
          JSON.stringify({
            merchantName: data?.data.merchantInfo.cusFullName,
            merchantNumber: data?.data.merchantInfo.merchantNumber,
            qrCode: data?.data.merchantInfo.qrCode,
            logoUrl: data?.data.merchantInfo.logoUrl,
            subMerchantName: data?.data.merchantInfo.subMerchantName,
            subMerchantNumber: data?.data.merchantInfo.subMerchantNumber,
            contactPhone: data?.data.merchantInfo.contactPhone,
            contactEmail: data?.data.merchantInfo.contactEmail,
            virtualBank: data?.data.merchantInfo.virtualAccountBankName,
            virtualAccNo: data?.data.merchantInfo.virtualAccountNumber,
            virtualAccName: data?.data.merchantInfo.virtualAccountName,
          })
        );
      }

      if (Notification.permission !== 'granted') {
        openNotification()
       
      } else {
   
        const intervalId = setInterval(function () {
          if (appToken === "") {
            appToken = returnToken();
          } else {
            fetchDevice();
            
            clearInterval(intervalId);
          }
        }, 3000);
      }

    }
  }, [data]);

  useEffect(() => {
    if (subData?.code === "000000") {
      if (subData?.data[0].virtualAccountNumber === null) {
        fetchVirtualSubAcc();
      }
      storage.add(
        "cashierDetails",
        JSON.stringify({
          virtualBank: subData?.data[0].virtualAccountBankName,
          virtualAccNo: subData?.data[0].virtualAccountNumber,
          virtualAccName: subData?.data[0].virtualAccountName,
        })
      );
    }
  }, [subData]);

  useEffect(() => {
    if (virtualData?.code === "000000") {
      storage.add(
        "cashierDetails",
        JSON.stringify({
          virtualBank: virtualData?.data.virtualAccountBankName,
          virtualAccNo: virtualData?.data.virtualAccountNumber,
        })
      );
    }
  }, [virtualData]);

  useEffect(() => {
    window.addEventListener('error', e => {
        if (e.message === 'ResizeObserver loop limit exceeded') {
            const resizeObserverErrDiv = document.getElementById(
                'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
                'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
                resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
        }
    });
}, []);

  return (
    <SupervisorLayout>
      <HomeSection
      latestTransaction={latestTransaction}
       />
      <HomeSection3
        total={totalTransactions}
        transactions={transactions}
        loading={isFetchingTransactions}
        fetch={fetchTransactions}
      />
    </SupervisorLayout>
  );
};
