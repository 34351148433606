// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { isSupported, getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxaWBRz85QK18PZy3plrT4sIPYZq2Wqdk",
  authDomain: "deempay-d9d45.firebaseapp.com",
  projectId: "deempay-d9d45",
  storageBucket: "deempay-d9d45.appspot.com",
  messagingSenderId: "378119180106",
  appId: "1:378119180106:web:fcf172089cd34c68c27bb3",
  measurementId: "G-L7L13M0GND",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

var tokenAssign = "";

const assignToken = (token) => {
  tokenAssign = token;
  console.log("assignToken: ", token);
};

export const returnToken = () => {
  if (isSupported) {
    console.log("isSupported: ", isSupported);
    return tokenAssign;
  } else {
    return "noToken";
  }
};

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BJHpFwSUTqdMZCgFJ-zLyfsvd6KUuU6pxqyA80wNDkMDSbkaBG1x-HCBbTuwKgKs8xNgPL3Ah_rgkbeffcUWO-8",
  })
    .then((currentToken) => {
      if (currentToken) {
        tokenAssign = currentToken;
        return currentToken;
        //Perform any other neccessary action with the token
      } else {
        //Show permission requst ui
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occured while retrieving token", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
      window.postMessage(payload);
    });
  });
