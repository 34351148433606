import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from "antd";
import { Spinner } from "../../components/spinner";
import { DeemPayIcon, VerifyPhoneIcon } from "../../assests/icons/Icons";
import storage from "../../utils/storage";
import { formatPhoneNo } from "../../utils/functions";
import useRegister from "../../hooks/useRegister";
import useVerify from "../../hooks/useVerify";
import useLogin from "../../hooks/useLogin";
import { useAuth } from "../../hooks/useAuth";

import "./styles.scss";

export const VerificationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [validCode, setValidCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isCounterDone, setIsCounterDone] = useState(true);
  const [isResendDone, setIsResendDone] = useState(false);
  var [minutes, setMinutes] = useState("");
  var [seconds, setSeconds] = useState("");
  var [inval, setInval] = useState("");
  const [userId, setUserId] = useState("");
  const { login } = useAuth();

  const {
    isLoading: isVerifying,
    data,
    mutate: verifyUser,
  } = useVerify({
    phoneNo,
    password,
    validCode,
  });

  const {
    isLoading: isSendingCode,
    data: resendCodeData,
    mutate: sendCode,
  } = useRegister({
    phoneNo,
    templateId: "M1001",
  });

  const {
    isLoading: isLoggingIn,
    data: loginData,
    mutate: loginUser,
  } = useLogin({
    phoneNo,
    password,
  });

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const startCounter = () => {
    const startTiming = 1;
    let time = startTiming * 60;
    let _countIntvl = 0;
    const timerInterval = setInterval(function () {
      _countIntvl += 1;
      setInval(timerInterval);
      const minute_2 = Math.floor(time / 60);
      setMinutes(minute_2);
      let second_2 = time % 60;
      second_2 = second_2 < 10 ? "0" + second_2 : second_2;
      setSeconds(second_2);
      time--;
      if (second_2 === "00" && minute_2 === 0) {
        clearInterval(timerInterval);
        setIsCounterDone(false);
        setIsResendDone(true);
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  };

  useEffect(() => {
    startCounter();
  }, []);

  useEffect(() => {
    const registrationPayload = JSON.parse(
      storage.fetch("userRegistrationPayload")
    );
    const userPhoneNo = storage.fetch("userPhoneNo");
    const pathFrom = history.location?.state?.from?.pathname;

    if (pathFrom === "/forgot-password" && userPhoneNo) {
      setPhoneNo(userPhoneNo);
    } else if (pathFrom === "/registration" && registrationPayload) {
      setPhoneNo(registrationPayload.phoneNo);
      setPassword(registrationPayload.password);
      setFirstName(registrationPayload.firstName);
      setLastName(registrationPayload.lastName);
    } else {
      storage.clear();
      history.push("/registration");
    }
  }, []);

  useEffect(() => {
    const handleVerificationFailure = () => {
      history.push("/registration");
    };
  
    const handleExisitingAccount = () => {
      history.push("/login");
    };

    if (data?.code === "000000") {
      loginUser();
      storage.add(
        "userRegistrationPayload",
        JSON.stringify({
          firstName,
          lastName,
          phoneNo,
        })
      );     
    } else if (data?.code === "100002") {
      storage.clear();
      Modal.error({
        title: "Verification failed",
        content: `Sorry! ${data?.message}`,
        okText: "Retry",
        onOk: () => handleVerificationFailure(),
      });
      reset();
    } else if (data?.code === "US0001") {
      storage.clear();
      Modal.info({
        title: "Account exists!",
        content:
          "Account already exists for this number. Please proceed to login.",
        onOk: () => handleExisitingAccount(),
      });
    }
  }, [data]);

  useEffect(() => {
    if (loginData?.code === "000000") {
      const { data: userDetails } = loginData;
      const token = userDetails?.token;
      login(token);
      storage.add(
        "userDetails",
        JSON.stringify({
          userId: userDetails?.userId,
          firstName: firstName,
          surname:  lastName,
          avatar: userDetails?.userHeader,
          phoneNo: userDetails?.phoneNo,
        })
      );

      setUserId(userDetails?.userId);
      history.push("/dashboard/merchant/onboarding");
      reset();
      storage.add(
        "onboardingDetails",
        JSON.stringify({
          merchant: true,
          cusId: null,
          onboarded: false,
          singleRole: true,
        })
      );
    }
  }, [loginData]);

  const onSubmitHandler = (data) => {
    const pathFrom = history.location?.state?.from?.pathname;

    if (pathFrom === "/forgot-password") {
      storage.add("validCode", data.validCode);
      history.push("/password-reset", { from: location });
    } else if (pathFrom === "/registration") {
      setValidCode(data.validCode);
      verifyUser();
    }
  };

  const resendValidCode = () => {
    const registrationPayload = JSON.parse(
      storage.fetch("userRegistrationPayload")
    );
    const userPhoneNo = storage.fetch("userPhoneNo");
    const pathFrom = history.location?.state?.from?.pathname;

    if (pathFrom === "/forgot-password" && userPhoneNo) {
      setPhoneNo(userPhoneNo);
      sendCode();
      setIsCounterDone(true);
      setIsResendDone(false);
      startCounter();
    } else if (pathFrom === "/registration" && registrationPayload) {
      setPhoneNo(registrationPayload.phoneNo);
      sendCode();
      setIsCounterDone(true);
      setIsResendDone(false);
      startCounter();
    }
  };

  return (
    <div className="verify">
      <div className="verify-wrap">
        <div className="image-wrap">
          <span className="imgg">
            <DeemPayIcon />
          </span>
        </div>
        <div className="verify-content">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div>
              <span className="img">
                <VerifyPhoneIcon />
              </span>
            </div>
            <h2>Verify your number</h2>
            <h4>
              {`Please enter the verification code sent to ${formatPhoneNo(
                phoneNo
              )} for
              confirmation`}
            </h4>
            <label>Enter valid code</label>
            <input
              className={`verifyInput ${errors.validCode && "invalid"}`}
              type="number"
              placeholder="Enter code here"
              {...register("validCode", {
                required: "Verification code is required",
                minLength: {
                  value: 6,
                  message: "minimum allowed valid code is 6",
                },
                maxLength: {
                  value: 6,
                  message: "minimum allowed valid code is 6",
                },
              })}
            />
            {errors.validCode && (
              <section className="error__text">
                {errors.validCode.message}
              </section>
            )}

            {isCounterDone && (
              <p>
                Your verification code will expire in
                <span className="timer">
                  {minutes}:{seconds} s.
                </span>
              </p>
            )}
            {isResendDone && (
              <p className="para">
                Your verification code has expired
                <span className="resend" onClick={resendValidCode}>
                  Resend.
                </span>
              </p>
            )}
            <button type="submit" disabled={isVerifying || isSendingCode || isLoggingIn}>
              {isVerifying || isSendingCode || isLoggingIn ? <Spinner size={30} /> : "Verify"}
            </button>
          </form>
          <div className="verify-create">
            <p>
              Need help?
              <span>
                <Link
                  target="_blank"
                  to="//www.deempay.com/supportcenter"
                  className="link2"
                >
                  Contact support
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
