import React from "react";
import storage from "../../utils/storage";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { VerifiedIcon } from "../../assests/icons/Icons";
import Layout from "../../components/layout/Layout";
import { OnboardingList } from "../../components/onboarding-list/OnboardingList";

import "./styles.scss";

export const OnboardingPage = () => {
  const history = useHistory();
  const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));
  if(onboardingDetails.onboarded){
    history.push("/dashboard/merchant/overview");
  }
  return (
    <>
      <Layout>
        <header>
          <main className="account-main">
            <div className="row">
              <div className="column">
                <OnboardingList />
              </div>
              <div className="column">
                <div className="account-wrap">
                  <h3>Account Verification</h3>
                  <form>
                   <span className="iimg"><VerifiedIcon /></span>
                    <h4>Account verification successful</h4>
                    <p>On to the next!</p>
                    <h5>
                      You have successfully activated your DeemPay account.
                      Please complete your profile details to start accepting money
                      instantly.
                    </h5>
                    <Link
                      to="/dashboard/merchant/onboarding/business-details"
                      className="link"
                    >
                      <button>Next</button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </header>
      </Layout>
    </>
  );
};
