import {
  HomeIcon,
  OnboardingIcon,
  CollectPaymentIcon,
  SubAccountIcon,
  TransactionIcon,
  SettingsIcon,
  SettlementIcon,
} from "../../../assests/icons/Icons";

export const merchantLinks = [
  // {
  //   name: "",
  //   route: "/dashboard/merchant/onboarding",
  //   icon: <OnboardingIcon />,
  //   subLinks: null,
  //   keyWord: "onboarding",
  // },
  {
    name: "",
    route: "/dashboard/merchant/overview",
    icon: <HomeIcon />,
    subLinks: null,
    keyWord: "overview",
  },
  {
    name: "",
    route: "/dashboard/merchant/accept-payment",
    icon: <CollectPaymentIcon />,
    subLinks: null,
    keyWord: "accept-payment",
  },
  {
    name: "",
    route: "/dashboard/merchant/fee-payment",
    icon: <CollectPaymentIcon />,
    subLinks: null,
    keyWord: "fee-payment",
  },
  {
    name: "",
    route: "/dashboard/merchant/transactions",
    icon: <TransactionIcon />,
    subLinks: null,
    keyWord: "transactions",
  },
  {
    name: "",
    route: "/dashboard/merchant/settlement",
    icon: <SettlementIcon />,
    subLinks: null,
    keyWord: "settlement"
  },
  {
    name: "",
    route: "/dashboard/merchant/sub-accounts",
    icon: <SubAccountIcon />,
    subLinks: null,
    keyWord: "sub-accounts",
  },
  {
    name: "",
    route: "/dashboard/merchant/settings",
    icon: <SettingsIcon />,
    subLinks: null,
    keyWord: "settings",
  },
];
