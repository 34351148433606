import {
  HomeIcon,
  OnboardingIcon,
  CollectPaymentIcon,
  SubAccountIcon,
  TransactionIcon,
  SettingsIcon,
  SettlementIcon,
  FeePaymentIcon,
} from "../../../assests/icons/Icons";

export const merchantLinks = [
  {
    name: "Onboarding",
    route: "/dashboard/merchant/onboarding",
    icon: <OnboardingIcon />,
    subLinks: null,
    keyWord: "onboarding",
  },
  {
    name: "Overview",
    route: "/dashboard/merchant/overview",
    icon: <HomeIcon />,
    subLinks: null,
    keyWord: "overview",
  },
  {
    name: "Receive payment",
    route: "/dashboard/merchant/accept-payment",
    icon: <CollectPaymentIcon />,
    subLinks: null,
    keyWord: "accept-payment",
  },
  {
    name: "Transactions",
    route: "/dashboard/merchant/transactions",
    icon: <TransactionIcon />,
    subLinks: null,
    keyWord: "transactions",
  },
  {
    name: "Settlement",
    route: "/dashboard/merchant/settlement",
    icon: <SettlementIcon />,
    subLinks: null,
    keyWord: "settlement"
  },
  {
    name: "Retail sub-accounts",
    route: "/dashboard/merchant/sub-accounts",
    icon: <SubAccountIcon />,
    subLinks: null,
    keyWord: "sub-accounts",
  },
  {
    name: "Payment links",
    route: "/dashboard/merchant/payment-link",
    icon: <FeePaymentIcon />,
    subLinks: null,
    keyWord: "payment-link",
  },
  {
    name: "Settings",
    route: "/dashboard/merchant/settings",
    icon: <SettingsIcon />,
    subLinks: null,
    keyWord: "settings",
  },
];
