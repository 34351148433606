import React, { useRef, useState, useEffect } from "react";
import QRCode from "qrcode.react";
import { useHistory } from "react-router-dom";
import { NIBSS, PaySticker } from "../../../../assests/svgs";
import { NQR, Logo } from "../../../../assests/images";
import storage from "../../../../utils/storage";
import {
  useQuerySubAccount,
  useCreateVirtualSubAcc,
} from "../../../../hooks/useSubAccount";
import { addSpace } from "../../../../utils/functions";
import { Notify } from "../../../../components/notification";

import "./styles.scss";
import SupervisorLayout from "../../../../components/layout/SupervisorLayout";

export const SupervisorPaymentStandPage = () => {
  const exportRef = useRef();
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const terminalCode = JSON.parse(storage.fetch("role")).terminalCode;
  const merchantName = JSON.parse(
    storage.fetch("merchantDetails")
  ).merchantName;
  const logoUrl = JSON.parse(storage.fetch("merchantDetails")).logoUrl;

  const [emvcoCode, setEmvcoCode] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const [virtualNo, setVirtualNo] = useState("");
  const [virtualName, setVirtualName] = useState("");
  const [gtVirtualAcc, setGtVirtualAcc] = useState("");
  const [providusVirtualAcc, setProvidusVirtualAcc] = useState("");
  const [fidelityVirtualAcc, setFidelityVirtualAcc] = useState("");
  const history = useHistory();
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 480px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 480px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const { data, mutate: fetchSubAccountDetails } = useQuerySubAccount({
    cusId,
    terminalCode,
  });

  const { data: virtualData, mutate: fetchVirtualSubAcc } =
    useCreateVirtualSubAcc({
      cusId,
      terminalCode,
    });

  useEffect(() => {
    fetchSubAccountDetails();
  }, []);

  useEffect(() => {
    if (data?.code === "000000") {
      setTerminalName(data?.data[0].terminalName);
      setEmvcoCode(data?.data[0].emvcoCode);
      setVirtualNo(data?.data[0].virtualAccountNumber);
      setVirtualName(data?.data[0].virtualAccountBankName);
      setGtVirtualAcc(data?.data[0].gtbVirtualAccountNumber);
      setFidelityVirtualAcc(data?.data[0].fidelityVirtualAccountNumber);
      setProvidusVirtualAcc(data?.data[0].providusVirtualAccountNumber);
    }
  }, [data]);

  useEffect(() => {
    if (virtualData?.code === "000000") {
      setVirtualName(virtualData?.data.virtualAccountBankName);
      setVirtualNo(virtualData?.data.virtualAccountNumber);
    }
  }, [virtualData]);

  const qrcode = (
    <QRCode
      id="qrCodeId"
      size={230}
      value={emvcoCode}
      bgColor="white"
      fqColor="black"
      level="M"
      imageSettings={{
        src: logoUrl ? logoUrl : Logo,
        excavate: true,
        width: 40,
        height: 40,
      }}
    />
  );

  const qrDownload = (
    <QRCode
      id="qrCodeId"
      size={matches ? 230 : 320}
      value={emvcoCode}
      bgColor="white"
      fqColor="black"
      level="M"
    />
  );

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {
      switch (profileType) {
        case "businessProfile":
          history.replace("/dashboard/supervisor/settings/business");
          break;
        case "personalProfile":
          history.replace("/dashboard/supervisor/settings");
          break;
        case "bankProfile":
          history.replace("/dashboard/supervisor/settings/bank");
          break;
        case "accountSettings":
          history.replace("/dashboard/supervisor/settings/accounts");
          break;
        case "paymentStand":
          history.replace("/dashboard/supervisor/settings/payment-stand");
          break;
        default:
          history.replace("/dashboard/supervisor/settings");
      }
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <SupervisorLayout>
      <header>
        <main className="main__paymentstandC">
          <section className="sections" id="sect">
            <a>
              <span
                className="personal-info"
                onClick={(node, event) => handleRowClick("personalProfile")}
              >
                Personal Details
              </span>
            </a>
            <a>
              <span
                onClick={(node, event) => handleRowClick("businessProfile")}
              >
                Business Details
              </span>
            </a>
            <a>
              <span onClick={(node, event) => handleRowClick("bankProfile")}>
                Bank Details
              </span>
            </a>
            <a>
              <span
                onClick={(node, event) => handleRowClick("accountSettings")}
              >
                Account Settings
              </span>
            </a>
            <a>
              <span
                className="active-stand"
                onClick={(node, event) => handleRowClick("paymentStand")}
              >
                Payment Sticker
              </span>
            </a>
          </section>
          <div className="intro-txt">
            <h2>Payment Sticker</h2>
            <h4>Download your sticker to start receiving payments.</h4>
          </div>
          <div>
            <div className="form" ref={exportRef}>
              <img
                src={PaySticker}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
              <div className="new_cardC">
                <h1>
                  {virtualNo === null ? "0000000000" : addSpace(virtualNo)}
                </h1>
                <h3>{virtualName === null ? "Wema Bank" : virtualName}</h3>
                <h4>{merchantName}</h4>
                <h5>({terminalName})</h5>
              </div>
              <div className="qr_card">
                <div className="qr-crd">{qrDownload}</div>
              </div>
            </div>
          </div>
          <div className="line-borderC">
            <div className="payment-stand">
              <div className="header-left"></div>
              <div className="icons">
              </div>
            </div>

            <section>
              <form>
                <div className="bottom-line">
                  <h3>{merchantName}</h3>
                  <h6>({terminalName})</h6>
                </div>
                <card>
                  <section>{qrcode}</section>
                  <div className="nibss__images">
                    <img src={NQR} alt="" className="nibss" width="45%" />
                    <img src={NIBSS} alt="" width="24%" />
                  </div>
                </card>
                <h5>Scan and pay with any bank app</h5>
                <span className="line--wrapper">
                  <h6>OR</h6>
                </span>

                <div className="transferPart">
                  <div className="gtPart">
                    <p className="payWith">Account Number</p>
                    <p className="accNo">
                      {providusVirtualAcc === null
                        ? "Pending"
                        : addSpace(providusVirtualAcc)}
                    </p>
                  </div>
                  <div className="gtPart">
                    <p className="payWith">Bank</p>
                    <span className="wemaBank">Providus Bank</span>
                  </div>
                  <span className="line__wrapper2">
                    <h6>OR</h6>
                  </span>
                  <div className="gtPart">
                    <p className="payWith">Account Number</p>
                    <p className="accNo">
                      {gtVirtualAcc === null 
                        ? "Pending"
                        : addSpace(gtVirtualAcc)}
                    </p>
                  </div>
                  <div className="gtPart">
                    <p className="payWith">Bank</p>
                    <p className="wemaBank">Guaranty Trust Bank</p>
                  </div>
                </div>
              </form>
            </section>

            {/* <Link to={"//www.deempay.com/payment-stand"} target="_blank">
              <button className="request">Request customized sticker</button>
            </Link> */}
          </div>
        </main>
      </header>
    </SupervisorLayout>
  );
};
