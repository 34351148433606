export const linkStatus = new Map([
    ["Active", "1"],
    ["Inactive", "0"],
  ]);
  
  export const reverseLinkStatus = new Map([
    ["1", "Active"],
    ["0", "Inactive"],
  ]);
  
  export const linkStatusList = Array.from(linkStatus.keys());
  