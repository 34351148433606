import React, { useState, useEffect } from "react";
import ManagerLayout from "../../../../components/layout/ManagerLayout";
import { Link, useParams } from "react-router-dom";
import {
  useQuerySubAccount,
  useCreateVirtualSubAcc,
} from "../../../../hooks/useSubAccount";
import storage from "../../../../utils/storage";
import {
  formatCount,
  formatCurrency,
  formatDateTime,
  truncateName,
} from "../../../../utils/functions";
import {
  useQueryTransaction,
  useTradeSummary,
} from "../../../../hooks/useTransaction";
import { FiPlus } from "react-icons/fi";
import { Spinner } from "../../../../components/spinner";
import { reverseRoles } from "../../../../utils/constants/roleIds";
import { Table } from "../../../../components/table/Table";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowDown } from "react-icons/md";
import { pageList, pages } from "../../../../utils/constants/pages";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { reversePaymentMethods } from "../../../../utils/constants/paymentMethods";
import RemoveModal from "../../../../components/modals/removemodal/RemoveModal";
import { periodList, periods } from "../../../../utils/constants/periods";

import "./styles.scss";

const column = [
  "Amount",
  "Payment method",
  "Station name",
  "Customer name",
  "Date",
];

export const ManagerSubAccountDetailsPage = () => {
  const currentDate = new Date().toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const { chlTermNo, phoneNo } = useParams();
  const terminalCode = phoneNo.split("=")[1];
  const contactPhone = phoneNo.split("=")[0]; //this also is phoneNo from the link
  const [transactions, setTransactions] = useState([]);
  const [role, setRole] = useState({});
  const [summaryType, setSummaryType] = useState("CURRENT_DAY");
  const [sumTypeDisplay, setSumTypeDisplay] = useState("1 Day");
  const [totalAmount, setTotalAmount] = useState(0);
  const [tradeCount, setTradeCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [emvcoCode, setEmvcoCode] = useState("");
  const [gtAccNo, setGtAccNo] = useState("");
  const [providusVirtualAcc, setProvidusVirtualAcc] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currentSize, setCurrentSize] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);
  const [displayDate, setDisplayDate] = useState(currentDate);

  const toggleDeleteModal = () => {
    storage.add(
      "modalData",
      JSON.stringify({
        terminalCode,
        phoneNo: contactPhone,
      })
    );
    setDeleteModal(!deleteModal);
  };

  const {
    isLoading: isFetchingSubAccountDetails,
    data,
    mutate: fetchSubAccountDetails,
  } = useQuerySubAccount({
    cusId,
    phoneNo: contactPhone,
    terminalCode,
  });

  const {
    isLoading: isFetchingSummary,
    data: summaryData,
    mutate: fetchTradeSummary,
  } = useTradeSummary({
    cusId,
    summaryType,
    terminalCode: chlTermNo, //TerminalCode here reqiure to pass the cashiersub merchantNo(chlTermNo)
  });

  const {
    isLoading: isFetchingTransaction,
    data: transactionData,
    mutate: fetchTransactions,
  } = useQueryTransaction({
    cusId,
    page: currentPage,
    pageSize: currentSize,
    terminalCode: chlTermNo, //TerminalCode here reqiure to pass the cashiersub merchantNo(chlTermNo)
  });

  const { data: virtualData, mutate: fetchVirtualSubAcc } =
    useCreateVirtualSubAcc({
      cusId,
      terminalCode,
    });

  useEffect(() => {
    fetchTradeSummary();
  }, [summaryType]);

  useEffect(() => {
    fetchSubAccountDetails();
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, currentSize]);

  useEffect(() => {
    if (data?.code === "000000") {
      if (data?.data[0].gtbVirtualAccountNumber === null) {
        fetchVirtualSubAcc();
      }
      setRole(data?.data[0]);
      setEmvcoCode(data?.data[0].emvcoCode);
      setGtAccNo(data?.data[0].gtbVirtualAccountNumber);
      setProvidusVirtualAcc(data?.data[0].providusVirtualAccountNumber);
    }
  }, [data]);

  useEffect(() => {
    if (summaryData?.code === "000000") {
   setTotalAmount(summaryData?.data?.sumTradeAmt);
   setTradeCount(summaryData?.data?.sumCnt);

   if (summaryType !== "ALL") {
     let startDate = new Date(summaryData?.data?.startDate);
     let endDate = new Date(summaryData?.data?.endDate);

     if (
       startDate.getFullYear() === endDate.getFullYear() &&
       startDate.getMonth() === endDate.getMonth() &&
       startDate.getDate() === endDate.getDate()
     ) {
       let startDateStr = startDate.toLocaleDateString("en-us", {
         year: "numeric",
         month: "short",
         day: "numeric",
       });
       setDisplayDate(startDateStr);
     } else if (
       startDate.getFullYear() === endDate.getFullYear() &&
       startDate.getMonth() === endDate.getMonth()
     ) {
       let startDateStr = startDate.toLocaleDateString("en-us", {
         day: "numeric",
         month: "short",
       });
       let endDateStr = endDate.toLocaleDateString("en-us", {
         day: "numeric",
       });
       setDisplayDate(
         startDateStr +
           " - " +
           endDateStr +
           ", " +
           endDate.toLocaleDateString("en-us", { year: "numeric" })
       );
     } else if (
       startDate.getFullYear() === endDate.getFullYear() &&
       startDate.getMonth() !== endDate.getMonth()
     ) {
       let startDateStr = startDate.toLocaleDateString("en-us", {
         month: "short",
         day: "numeric",
       });
       let endDateStr = endDate.toLocaleDateString("en-us", {
         month: "short",
         day: "numeric",
       });
       setDisplayDate(
         startDateStr +
           " - " +
           endDateStr +
           ", " +
           endDate.toLocaleDateString("en-us", { year: "numeric" })
       );
     } else {
       let startDateStr = startDate.toLocaleDateString("en-us", {
         year: "numeric",
         month: "short",
         day: "numeric",
       });
       let endDateStr = endDate.toLocaleDateString("en-us", {
         year: "numeric",
         month: "short",
         day: "numeric",
       });
       setDisplayDate(startDateStr + " - " + endDateStr);
     }
   } else {
     let startDate = new Date(summaryData?.data?.minDate);
     let endDate = new Date(summaryData?.data?.maxDate);

     if (
       startDate.getFullYear() === endDate.getFullYear() &&
       startDate.getMonth() === endDate.getMonth() &&
       startDate.getDate() === endDate.getDate()
     ) {
       let startDateStr = startDate.toLocaleDateString("en-us", {
         year: "numeric",
         month: "short",
         day: "numeric",
       });
       setDisplayDate(startDateStr);
     } else if (
       startDate.getFullYear() === endDate.getFullYear() &&
       startDate.getMonth() === endDate.getMonth()
     ) {
       let startDateStr = startDate.toLocaleDateString("en-us", {
         day: "numeric",
         month: "short",
       });
       let endDateStr = endDate.toLocaleDateString("en-us", {
         day: "numeric",
       });
       setDisplayDate(
         startDateStr +
           " - " +
           endDateStr +
           ", " +
           endDate.toLocaleDateString("en-us", { year: "numeric" })
       );
     } else if (
       startDate.getFullYear() === endDate.getFullYear() &&
       startDate.getMonth() !== endDate.getMonth()
     ) {
       let startDateStr = startDate.toLocaleDateString("en-us", {
         month: "short",
         day: "numeric",
       });
       let endDateStr = endDate.toLocaleDateString("en-us", {
         month: "short",
         day: "numeric",
       });
       setDisplayDate(
         startDateStr +
           " - " +
           endDateStr +
           ", " +
           endDate.toLocaleDateString("en-us", { year: "numeric" })
       );
     } else {
       let startDateStr = startDate.toLocaleDateString("en-us", {
         year: "numeric",
         month: "short",
         day: "numeric",
       });
       let endDateStr = endDate.toLocaleDateString("en-us", {
         year: "numeric",
         month: "short",
         day: "numeric",
       });
       setDisplayDate(startDateStr + " - " + endDateStr);
     }
   }
 }
}, [summaryData]);

  useEffect(() => {
    if (transactionData?.code === "000000") {
      const formattedTransactions = transactionData.data.result.map((t) => {
        return {
          payAccountName: truncateName(t.payAccountName),
          tradeAmt: formatCurrency(t.tradeAmt),
          terminalName:
            t.terminalName === null ? "Main account" : t.terminalName,
          productCode: reversePaymentMethods.get(t.productCode),
          finishTime: formatDateTime(t.finishTime),
          orderNo: t.orderNo,
        };
      });
      setTransactions(formattedTransactions);
      setPageCount(Math.ceil(transactionData.data.total / currentSize));
    }
  }, [transactionData]);

  const handleChangeSummary = (e) => {
    setSummaryType(periods.get(e.target.value));
    setSumTypeDisplay( e.target.value )
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleChange = (e) => {
    setCurrentSize(pages.get(e.target.value));
    setCurrentPage(1);
  };

  return (
    <ManagerLayout>
      <header>
        <main className="main__main">
          <div className="main-sub">
            <section className="header-container">
              <div>
                <h1>Sub-account Details</h1>
              </div>
              <div className="subHeader-right">
                <div className="btn-div">
                  <Link
                    to={`/dashboard/manager/add-sub-account/${terminalCode}`}
                  >
                    <button className="whiteBtn add">
                      <span className="white--txt">
                        <FiPlus className="filter-img" />
                        Add
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="btn-div">
                  <button className="redBtn" onClick={toggleDeleteModal}>
                    <span className="red--txt">Remove</span>
                  </button>
                </div>
              </div>
            </section>

            <div className="main-card">
              <div className="summary-card">
                <div className="main-title">
                  <span className="main-descr">Sub-account Details</span>
                </div>
                {isFetchingSubAccountDetails ? (
                  <div style={{ marginTop: "5rem" }}>
                    <Spinner color={"#1a71ff"} />
                  </div>
                ) : (
                  <div className="card-dsicrption">
                    <div className="description">
                      <p>Station name</p>
                      <p className="second secnd">{role.terminalName}</p>
                    </div>
                    <div className="description">
                      <p>Sub-account type</p>
                      <p className="secnd">{reverseRoles.get(role.roleId)}</p>
                    </div>
                    <div className="description">
                      <p>Staff name</p>
                      <p className="secnd">{`${role.firstName} ${role.surname}`}</p>
                    </div>
                    <div className="description">
                      <p>Phone</p>
                      <p className="secnd">{role.phoneNo}</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="transaction-cards">
                <div className="transaction-summary">
                  <p className="transaction-title">Transaction Summary</p>
                </div>
                {isFetchingSummary ? (
                  <div style={{ marginTop: "5rem" }}>
                    <Spinner color={"#1a71ff"} />
                  </div>
                ) : (
                  <div className="card-dsicrption">
                    <div className="description27">
                      <p style={{ color: "#595959" }}>Summary type</p>
                      <span className="home3-sub">
                        <select onChange={handleChangeSummary}>
                          {periodList.map((period, i) => (
                            <option key={i} selected={period === sumTypeDisplay}>
                              {period}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="date-down" />
                      </span>
                    </div>
                    <div className="description2">
                      <p style={{ color: "#595959" }}>Transaction count</p>
                      <p className="second">{formatCount(tradeCount)}</p>
                    </div>
                    <div className="description2">
                      <p style={{ color: "#595959" }}>Total amount</p>
                      <p className="second">{formatCurrency(totalAmount)}</p>
                    </div>
                    <div className="description2">
                      <p style={{ color: "#595959" }}>Transaction timeline</p>
                      <p className="second">
                        {displayDate}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="subTable">
              <div className="table-header">
                <div className="header">
                  <h1 className="active">Transactions</h1>
                  <div className="green"></div>
                  <Link
                    to={`/dashboard/manager/sub-accounts/paymentstand/${emvcoCode}/${terminalCode}/${gtAccNo}/${providusVirtualAcc}`}
                  >
                    <h1 className="notActive">Payment Sticker</h1>
                  </Link>
                </div>
              </div>
              <div className="transactionTable">
                <Table
                  column={column}
                  data={transactions}
                  link={"/dashboard/manager/transactions/"}
                />
                {isFetchingTransaction ? (
                  <>
                    <div style={{ marginTop: "40px", marginBottom: "8rem" }}>
                      <Spinner color={"#1a71ff"} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pagination--Details">
                      <div className="items-to-show">
                        <p>Items to show: {currentSize} </p>
                        <select onChange={handleChange}>
                          {pageList.map((page, i) => (
                            <option key={i} selected={page === currentSize}>
                              {page}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="business-down" />
                      </div>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IoIosArrowRoundForward />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel={<IoIosArrowRoundBack />}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination__container"}
                        activeClassName={"active"}
                        previousLinkClassName={"pages-p"}
                        nextLinkClassName={"pages-p"}
                        forcePage={currentPage - 1}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {deleteModal && <RemoveModal clodeModal={toggleDeleteModal} />}
          </div>
        </main>
      </header>
    </ManagerLayout>
  );
};
