import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  OnboardingIcon,
  CollectPaymentIcon,
  SubAccountIcon,
  TransactionIcon,
  SettingsIcon,
  SettlementIcon,
  SidebarIcon,
  LogoutIcon,
  FeePaymentIcon,
} from "../../assests/icons/Icons";
import { Close, Open } from "../../assests/svgs";
import { Avatar } from "../avatar";
import storage from "../../utils/storage";
import { truncateString } from "../../utils/functions";
import LogoutModal from "../modals/logoutmodal/LogoutModal";

import "./styles.scss";

export const NavbarPage = () => {
  const [click, setClick] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState(true);

  let logoImage = "";
  if (storage.fetch("merchantDetails")) {
    logoImage = JSON.parse(storage.fetch("merchantDetails")).logoUrl;
  }

  let businessName = "";
  if (storage.fetch("merchantDetails")) {
    businessName = JSON.parse(storage.fetch("merchantDetails")).merchantName;
  }

  const toggleLogoutModal = () => {
    setLogoutModal(!logoutModal);
  };

  const handleClick = () => setClick(!click);

  useEffect(() => {
    const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));

    if (onboardingDetails.onboarded === true) {
      setIsOnboarded(false);
    } else {
      setIsOnboarded(true);
    }
  }, []);

  return (
    <div>
      <div className="navbar">
        <div className="navbar-container">
          <div className="nav-logo">
            <Link to="/dashboard/merchant/overview">
              <SidebarIcon />
            </Link>
          </div>

          <div className="mobile-icon" onClick={handleClick}>
            {click ? (
              <img src={Close} alt="logo" />
            ) : (
              <img src={Open} alt="logo" />
            )}
          </div>
          <nav className={click ? "navbar-menu active" : "navbar-menu"}>
            <ul>
              <li className="navbar-dashboard">
                <div onClick={handleClick}>
                  <span className="navbar-image">
                    <Avatar imageUrl={logoImage} />
                  </span>
                  <span className="span">
                    <p className="userName">{truncateString(businessName)}</p>
                    <h5 className="userRole">Admin</h5>
                  </span>
                </div>
              </li>

              <li className="menu">MENU</li>

              {isOnboarded && (
                <Link to="/dashboard/merchant/onboarding" className="link3">
                  <li onClick={handleClick}>
                    <span>
                      <OnboardingIcon className="navbar-Icon" />
                    </span>
                    Onboarding
                  </li>
                </Link>
              )}
              <Link to="/dashboard/merchant/overview" className="link3">
                <li onClick={handleClick}>
                  <span>
                    <HomeIcon className="navbar-Icon" />
                  </span>
                  Overview
                </li>
              </Link>
              <Link to="/dashboard/merchant/accept-payment" className="link3">
                <li onClick={handleClick}>
                  <span>
                    <CollectPaymentIcon className="navbar-Icon" />
                  </span>
                  Receive payment
                </li>
              </Link>
              <Link to="/dashboard/merchant/transactions" className="link3">
                <li onClick={handleClick}>
                  <span>
                    <TransactionIcon className="navbar-Icon" />
                  </span>
                  Transactions
                </li>
              </Link>
              <Link to="/dashboard/merchant/settlement" className="link3">
                <li onClick={handleClick}>
                  <span>
                    <SettlementIcon className="navbar-Icon" />
                  </span>
                  Settlement
                </li>
              </Link>
              <Link to="/dashboard/merchant/sub-accounts" className="link3">
                <li onClick={handleClick}>
                  <span>
                    <SubAccountIcon className="navbar-Icon" />
                  </span>
                  Retail sub-account
                </li>
              </Link>
              <Link to="/dashboard/merchant/payment-link" className="link3">
                <li onClick={handleClick}>
                  <span>
                    <FeePaymentIcon className="navbar-Icon" />
                  </span>
                  Payment links
                </li>
              </Link>

              <Link to="/dashboard/merchant/settings" className="link3">
                <li onClick={handleClick}>
                  <span>
                    <SettingsIcon className="navbar-Icon" />
                  </span>
                  Settings
                </li>
              </Link>
            </ul>
            <div onClick={toggleLogoutModal} className="navbar-out">
              <span className="navbar-Iconr">
                <LogoutIcon />
              </span>
              Sign out
            </div>
          </nav>
        </div>
      </div>
      {logoutModal && <LogoutModal closeModal={toggleLogoutModal} />}
    </div>
  );
};
