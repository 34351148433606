export const genders = new Map([
  ["Male", "1"],
  ["Female", "2"],
]);

export const reverseGenders = new Map([
  ["1", "Male"],
  ["2", "Female"],
]);

export const genderList = Array.from(genders.keys());
