import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../components/layout/Layout";
import { Spinner } from "../../components/spinner";
import useRegister from "../../hooks/useRegister";
import { useMerchantQuery } from "../../hooks/useMerchant";
import { useValidateBankAccount } from "../../hooks/useBank";
import { MdKeyboardArrowDown } from "react-icons/md";
import { banks, bankList } from "../../utils/constants/banks";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import storage from "../../utils/storage";
import BankModal from "../../components/modals/bankmodal/BankModal";
import { Notify } from "../../components/notification";
import { EditIcon } from "../../assests/icons/Icons";
import useLogin from "../../hooks/useLogin";
import { SpinnerMedium } from "../../components/spinner/Spinner";
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";

import "./styles.scss";

export const BankProfilePage = () => {
  const contactPhone = JSON.parse(storage.fetch("userDetails")).phoneNo;
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [cardNo, setCardNo] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [cardName, setCardName] = useState("");
  const [bankName, setBankName] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [isClicked, setIsClicked] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isBank, setIsBank] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const [isBankChecked, setIsBankChecked] = useState(false);
  const [apiResponseError, setApiResponseError] = useState("");
  const history = useHistory();

  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const {
    isLoading: isLoggingIn,
    data: loginData,
    mutate: loginUser,
  } = useLogin({
    phoneNo: contactPhone,
    password,
  });

  const {
    isLoading: isFetchingBank,
    data: merchantData,
    mutate: fetchMerchantDetails,
  } = useMerchantQuery({ cusId });

  const bankAccount = watch("bankAccount");
  const bankId = watch("bankId");
  const {
    isLoading: isValidating,
    data: validateData,
    mutate: fetchBankDetails,
  } = useValidateBankAccount({
    bankId: banks.get(bankId),
    bankAccount,
  });

  const {
    isLoading: isSendingCode,
    data,
    mutate: sendCode,
  } = useRegister({
    phoneNo: contactPhone,
    templateId: "M1003",
  });

  useEffect(() => {
    fetchMerchantDetails();
  }, []);

  useEffect(() => {
    if (bankAccount?.length === 10) {
      fetchBankDetails();
    } else {
      setAccountDetails({});
    }
  }, [bankAccount]);

  useEffect(() => {
    resetField("cardName");
    if (bankAccount) {
      fetchBankDetails();
    }
    setAccountDetails({});
  }, [bankId]);

  useEffect(() => {
    if (loginData?.code === "000000") {
      setIsClicked(false);
      setIsConfirm(false);
      setIsBank(true);
      login(loginData?.data.token);
      setValue("bankAccount", cardNo);
      setValue("cardName", cardName);
    } else if (loginData?.code === "US0002") {
      setApiResponseError(
        <section style={{ color: "red" }}>password is wrong</section>
      );
    }
  }, [loginData]);

  useEffect(() => {
    if (merchantData?.code === "000000") {
      setCardName(merchantData?.data.cardInfo.cardName);
      setCardNo(merchantData?.data.cardInfo.cardNo);

      banks.forEach(function (value, key) {
        if (value === merchantData?.data.cardInfo.bankCode) {
          setBankName(key);
          setValue("bankId", key);
        }
      });
    }
  }, [merchantData]);

  useEffect(() => {
    if (validateData?.code === "000000") {
      setAccountDetails(validateData?.data);
      setIsBankChecked(true);
      setValue("cardName", validateData?.data.account_name);

      setCardName(validateData?.data.account_name);
      setCardNo(validateData?.data.account_number);
    } else if (validateData?.code === "CH0003") {
      resetField("bankAccount");
      resetField("cardName");
      Notify(
        "error",
        "Invalid bank account!",
        "Please check your bank account and your selected bank to make sure they are both correct.",
        10
      );
    }
  }, [validateData]);

  useEffect(() => {
    if (data?.code === "000000") {
      storage.add(
        "userBankPayload",
        JSON.stringify({
          bankCode: banks.get(bankId),
          phoneNo: contactPhone,
          cardNo: bankAccount,
          cardName: accountDetails.account_name,
        })
      );
      openModal();
    } else if (data?.code === "MS0003") {
      Notify(
        "error",
        "Send Code failed!",
        "The send valid code is not successful. Try again later.",
        10
      );
    }
  }, [data]);

  const changeView = () => {
    setIsClicked(false);
    setIsConfirm(true);
    setValue("bankAccount", cardNo);
    setValue("cardName", cardName);
  };

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  const onSubmitHandler1 = (data) => {
    setPassword(data.password);
    loginUser();
  };

  const onSubmitHandler = (data) => {
    const { bankCode, cardName, cardNo } = data;

    setBankCode(bankCode);
    setCardName(cardName);
    setCardNo(cardNo);
    if (isBankChecked) {
      sendCode();
    } else {
      fetchBankDetails();
    }
  };

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {
      switch (profileType) {
        case "businessProfile":
          history.replace("/dashboard/merchant/settings/business");
          break;
        case "personalProfile":
          history.replace("/dashboard/merchant/settings");
          break;
        case "bankProfile":
          history.replace("/dashboard/merchant/settings/bank");
          break;
        case "accountSettings":
          history.replace("/dashboard/merchant/settings/accounts");
          break;
        case "paymentStand":
          history.replace("/dashboard/merchant/settings/payment-stand");
          break;
        default:
          history.replace("/dashboard/merchant/settings");
      }
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <>
      <Layout>
        <header>
          <main className="bank-settingsA">
            <section id="sect">
              <a>
                <span
                  className="personal-info"
                  onClick={(node, event) => handleRowClick("personalProfile")}
                >
                  Personal Details
                </span>
              </a>
              <a>
                <span
                  onClick={(node, event) => handleRowClick("businessProfile")}
                >
                  Business Details
                </span>
              </a>
              <a>
                <span
                  className="activee"
                  onClick={(node, event) => handleRowClick("bankProfile")}
                >
                  Bank Details
                </span>
              </a>
              <a>
                <span
                  onClick={(node, event) => handleRowClick("accountSettings")}
                >
                  Account Settings
                </span>
              </a>
              <a>
                <span onClick={(node, event) => handleRowClick("paymentStand")}>
                  Payment Sticker
                </span>
              </a>
            </section>
            <div className="intro-txt">
              <h2>Bank Information</h2>
              <h4>View and update your bank information.</h4>
            </div>
            <div className="line-border">
              <div className="row">
                <div className="column">
                  {isClicked && (
                    <>
                      <form>
                        <div className="name_row">
                          <div className="biz_option">
                            <label>Bank</label>
                            <select
                              className={`bankInput ${
                                errors.bankId && "invalid"
                              }`}
                              type="text"
                              placeholder="Bank"
                              disabled={true}
                            >
                              <option selected={bankName}>{bankName}</option>
                              {bankList.map((bankId, i) => (
                                <option key={i}>{bankId}</option>
                              ))}
                            </select>
                            {isFetchingBank ? (
                              <>
                                <span className="acc-Name">
                                  <span className="spinner_input">
                                    <SpinnerMedium className="spinner_input" />
                                  </span>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="first-row">
                            <label>Account Number</label>
                            <input
                              className={`bankInput ${
                                errors.bankAccount && "invalid"
                              }`}
                              type="number"
                              placeholder={cardNo}
                              disabled={true}
                            />
                            {isFetchingBank ? (
                              <>
                                <span className="acc-Name">
                                  <span className="spinner_input">
                                    <SpinnerMedium className="spinner_input" />
                                  </span>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {errors.bankAccount && (
                              <section className="error__txt">
                                {errors.bankAccount.message}
                              </section>
                            )}
                          </div>
                        </div>

                        <label>Account Name</label>
                        {isValidating ? (
                          <Spinner color={"#1a71ff"} />
                        ) : (
                          <input
                            type="name"
                            placeholder={
                              accountDetails.account_name
                                ? accountDetails.account_name
                                : cardName
                            }
                            disabled={true}
                          />
                        )}
                        {isFetchingBank ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}

                        <button
                          type="submit"
                          class="text-[#ffffff] hover:bg-[#d9d9d9] rounded-lg py-3 items-center justify-center flex space-x-2 bg-[#ffffff] border border-[#d9d9d9] max-w-[150px] mt-4"
                          onClick={changeView}
                        >
                          <EditIcon />
                          <span class="items-center justify-center flex text_16_500 text-[#595959]">
                            Edith details
                          </span>
                        </button>
                      </form>
                    </>
                  )}

                  {isConfirm && (
                    <>
                      <form onSubmit={handleSubmit(onSubmitHandler1)}>
                        <label>Confirm password</label>
                        <div className="input">
                          <input
                            className={`bankInput ${
                              errors.password && "invalid"
                            }`}
                            type={show ? "text" : "password"}
                            placeholder="Enter password"
                            onFocus={() => setApiResponseError("")}
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 6,
                                message: "minimum allowed password is 6",
                              },
                            })}
                          />
                          {errors.password && (
                            <p className="error__mssg">
                              {errors.password.message}
                            </p>
                          )}
                          <span onClick={() => setShow(!show)}>
                            {show ? (
                              <AiOutlineEyeInvisible className="input-eye" />
                            ) : (
                              <AiOutlineEye className="input-eye" />
                            )}
                          </span>
                        </div>
                        <p className="error__mssg">{apiResponseError}</p>
                        <button disabled={isLoggingIn} className="btn--submit">
                          {isLoggingIn ? <Spinner /> : "Submit"}
                        </button>
                      </form>
                    </>
                  )}

                  {isBank && (
                    <>
                      <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <div className="name_row">
                          <div className="biz_option">
                            <label>Bank</label>
                            <select
                              className={`bankInput ${
                                errors.bankId && "invalid"
                              }`}
                              type="text"
                              placeholder="Bank"
                              {...register("bankId", {
                                required: "Bank number is required",
                              })}
                            >
                              <option selected="Select bank">
                                Select bank
                              </option>
                              {bankList.map((bankId, i) => (
                                <option
                                  key={i}
                                  selected={bankId === bankName && "selected"}
                                >
                                  {bankId}
                                </option>
                              ))}
                            </select>
                            {isFetchingBank ? (
                              <>
                                <span className="spin--input">
                                  <SpinnerMedium className="spin__input" />
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            <MdKeyboardArrowDown className="biz_down" />
                          </div>

                          <div className="first-row">
                            <label>Account Number</label>
                            <input
                              className={`bankInput ${
                                errors.bankAccount && "invalid"
                              }`}
                              type="number"
                              placeholder={"Enter bank account number"}
                              {...register("bankAccount", {
                                required: "Account number is required",
                                minLength: {
                                  value: 10,
                                  message: "minimum allowed number is 10",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "maximum allowed number is 10",
                                },
                              })}
                            />
                            {isFetchingBank ? (
                              <>
                                <span className="acc-Name">
                                  <span className="spinner_input">
                                    <SpinnerMedium className="spinner_input" />
                                  </span>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {errors.bankAccount && (
                              <section className="error__txt">
                                {errors.bankAccount.message}
                              </section>
                            )}
                          </div>
                        </div>

                        <label>Account Name</label>
                        <input
                          type="name"
                          placeholder={"Enter bank account name"}
                          disabled={true}
                          {...register("cardName", {
                            required: "Account name is required",
                          })}
                        />
                        {errors.cardName && (
                          <section className="error__txt">
                            {errors.cardName.message}
                          </section>
                        )}
                        {isFetchingBank || isValidating ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        <button
                          type="submit"
                          disabled={isSendingCode}
                          className="btn--submit"
                        >
                          {isSendingCode ? <Spinner /> : "Save Changes"}
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
            <BankModal showModal={showModal} setShowModal={setShowModal} />
          </main>
        </header>
      </Layout>
    </>
  );
};
