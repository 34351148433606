
import React from "react";
import { SidebarPage } from "../cashiersidebar";
import { NavbarPage } from "../cashiernavbar";
import { HeaderPage } from "../cashierheader";

function CashierLayout({ children }) {
  return (
    <>
      <div>
        <main>
          <SidebarPage />
          <NavbarPage />
          <HeaderPage />
          {children}
        </main>
      </div>
    </>
  );
}

export default CashierLayout;
