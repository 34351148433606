export const reverseBusinessSection = new Map([
    ["8062", "Hospitals"],
    ["5912", "Phmarmacy and drug stores"],
    ["8071", "Dental and medicine laboratories"],
    ["8099", "Other medical services"],
    ["4121", "Taxi services"],
    ["4131", "Bus lines"],
    ["4511", "Airlines and air carries"],
    ["4784", "Toll and bridge fees"],
    ["4722", "Travel and Tour agencies"],
    ["4215", "Courier services"],
    ["4214", "Delivery and logistics"],
    ["4582", "Airport and airport terminals"],
    ["4749", "Other transportation services"],
    ["7699", "Repair services"],
    ["2741", "Publishing/Printing services"],
    ["4814", "Telecom services"],
    ["5697", "Tailor/Alteration services"],
    ["8299", "Other training services"],
    ["8999", "Other professional services"],
    ["0763", "Agricultural services"],
    ["7542", "Car wash"],
    ["7523", "Car parking lots and garages"],
    ["7549", "Towing services"],
    ["4816", "IT and computer services"],
    ["7941", "Gyms"],
    ["7230", "Barber and beauty shop"],
    ["7298", "Beauty spa"],
    ["7297", "Massage parlour"],
    ["5813", "Nightclubs"],
    ["7922", "Cinemas"],
    ["7999", "Recreation centres"],
    ["7999", "Other events and entertainment"],
    ["7941", "Sports and others"],
    ["8661", "Religious organization"],
    ["8651", "Political organization"],
    ["9399", "Government agencies"],
    ["8398", "Charity"],
    ["8699", "Other organizations"],
    ["5311", "Departmental stores"],
    ["8411", "Supermarket and grocery stores"],
    ["5499", "Retail stores"],
    ["4812", "Telcoms and phone sells"],
    ["5541", "Service stations (filling stations)"],
    ["5462", "Bakaries"],
    ["5331", "Provision and variety stores"],
    ["5399", "Other stores"],
    ["5691", "Men's, Women's clothing stores"],
    ["5641", "Children stores"],
    ["5732", "Electronic stores"],
    ["5942", "Book stores"],
    ["5137", "Men's, Women's children clothing"],
    ["5945", "Toys and Game"],
    ["5943", "Stationary, office and school supplies"],
    ["5977", "Cosmetics stores"],
    ["5722", "Appliance stores"],
    ["5944", "Jewerly and watch stores"],
    ["5999", "Other retail stores"],
    ["8211", "Primary and Secondary Schools"],
    ["8220", "Universities and higher Institutions"],
    ["8299", "Other school services"],
    ["4899", "Cable and other pay television"],
    ["4813", "Telecoms and call services"],
    ["4900", "Utilities, eletric, water and gas"],
    ["5812", "Eateries and resturant"],
    ["5813", "Bar and drinking place"],
    ["5814", "Fast food resturants"],
    ["7011", "Hotels, Motels and Resorts"],
    ["5499", "Other food services"],
  ]);



  
 
 
  