import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { OnboardingList } from "../../components/onboarding-list/OnboardingList";
import {
  businessCategories,
  businessCategoriesList,
} from "../../utils/constants/businessCategories";
import { businessSections } from "../../utils/constants/businessSections";
import storage from "../../utils/storage";
import user from "../../helpers/user";

import "./styles.scss";
import {
  customerList,
  customers,
} from "../../utils/constants/customers";
import {
  nigeriaStates,
  nigeriaStatesList,
} from "../../utils/constants/nigeriaStates";
import { nigeriaLGAs } from "../../utils/constants/nigeriaLGAs";
import { registerType } from "../../utils/constants/registerType";

export const BusinessDetailsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [isRegisteredBusiness, setIsRegisteredBusiness] = useState(true);

  const onboardingDetails = JSON.parse(storage.fetch("onboardingDetails"));
  if (onboardingDetails.onboarded) {
    history.push("/dashboard/merchant/overview");
  }

  // Form Validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      contactPhone: user.phoneNo(),
    },
  });

  const filledBusinessDetails = JSON.parse(storage.fetch("businessDetails"));
  const [bizCatForm, setBizCatForm] = useState("Health");
  const [bizSecForm, setBizSecForm] = useState("Hospitals");
  const [bizLegalTypeForm, setBizLegalTypeForm] = useState(
    "Registered business name"
  );
  const [ngStateForm, setNgStateForm] = useState("Abia");
  const [ngLgaForm, setNgLgaForm] = useState("Aba North");
  const businessType = watch("cusType");
  const businessCategory = watch("industry1", bizCatForm);
  const nigeriaState = watch("province", ngStateForm);
  const [bizTypeForm, setBizTypeForm] = useState(
    "Registered business / Company"
  );

  useEffect(() => {
    let _bizForm = "";
    if (filledBusinessDetails) {
      if (filledBusinessDetails.cusType === "2") {
        setIsRegisteredBusiness(true);
      } else {
        setIsRegisteredBusiness(false);
      }
      customers.forEach(function (value, key) {
        if (value === filledBusinessDetails.cusType) {
          setBizTypeForm(key);
          setValue("cusType", key);
        }
      });

      businessCategories.forEach(function (value, key) {
        if (value === filledBusinessDetails.industry1) {
          setBizCatForm(key);
          setValue("industry1", key);
          _bizForm = key;
        }
      });

      businessSections[_bizForm].forEach(function (value, key) {
        if (value === filledBusinessDetails.industry2) {
          setBizSecForm(key);
          setValue("industry2", key);
        }
      });

      setValue("merName", filledBusinessDetails.merName);
      setValue("contactEmail", filledBusinessDetails.contactEmail);
      setValue("regAddress", filledBusinessDetails.regAddress);
      setNgStateForm(filledBusinessDetails.province);
      setValue("province", filledBusinessDetails.province);
      setNgLgaForm(filledBusinessDetails.city);
      setValue("city", filledBusinessDetails.city);

      if (filledBusinessDetails.legalType !== "-") {
        setBizLegalTypeForm(filledBusinessDetails.legalType);
      }

      if (filledBusinessDetails.taxNo !== "-") {
        setValue("taxNo", filledBusinessDetails.taxNo);
      }
    }
  }, []);

  useEffect(() => {
    if (businessType === "Sole trader / Individual business") {
      setIsRegisteredBusiness(false);
    } else {
      setIsRegisteredBusiness(true);
    }
  }, [businessType]);

  const onSubmitHandler = (data) => {
    const {
      city,
      contactEmail,
      cusType,
      merName,
      province,
      regAddress,
      taxNo,
      industry1,
      industry2,
      legalType,
      contactPhone,
    } = data;

    const payload = {
      industry1: businessCategories.get(industry1)
        ? businessCategories.get(industry1)
        : "-",
      industry2: businessSections[industry1].get(industry2)
        ? businessSections[industry1].get(industry2)
        : "-",
      legalType: isRegisteredBusiness ? legalType : "-",
      city,
      contactEmail,
      cusType: customers.get(cusType),
      contactPhone: contactPhone ? contactPhone : user.phoneNo(),
      merName,
      province,
      regAddress:
        province !== "FCT-Abuja"
          ? `${regAddress} ${city} ${province} state`
          : `${regAddress} ${city} ${province}`,
      taxNo: taxNo ? taxNo : "-",
    };

    storage.add("businessDetails", JSON.stringify(payload));
    history.push("/dashboard/merchant/onboarding/personal-information", {
      from: location,
    });
  };

  return (
    <>
      <Layout>
        <header>
          <main className="business-main">
            <div className="row">
              <div className="column">
                <OnboardingList />
              </div>

              <div className="column">
                <div
                  className={
                    isRegisteredBusiness ? "account-wrap" : "sole-wrap"
                  }
                >
                  <h3>Business Details</h3>
                  <p>
                    Please tell us more about your business. This information is
                    required to verify your business.
                  </p>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <label>Business Type</label>
                    <div className="business-option">
                      <select
                        type="text"
                        placeholder="Select business type"
                        {...register("cusType", {
                          required: "Business type is required",
                        })}
                      >
                        {customerList.map((customer, i) => (
                          <option
                            key={i}
                            selected={customer === bizTypeForm && "selected"}
                          >
                            {customer}
                          </option>
                        ))}
                      </select>
                      <MdKeyboardArrowDown className="business-down" />
                      {errors.category && (
                        <section className="error__mssg">
                          {errors.cusType.message}
                        </section>
                      )}
                    </div>
                    <label>Business Name</label>
                    <input
                      className={`businessInput ${errors.merName && "invalid"}`}
                      type="text"
                      placeholder="Business name"
                      {...register("merName", {
                        required: "Business name is required",
                      })}
                    />
                    {errors.merName && (
                      <section className="error__txt">
                        {errors.merName.message}
                      </section>
                    )}
                    <label>Business Industry</label>
                    <div className="business-option">
                      <select
                        type="text"
                        placeholder="Business category"
                        {...register("industry1", {
                          required: "industry is required",
                        })}
                      >
                        {businessCategoriesList.map((category, i) => (
                          <option
                            key={i}
                            selected={category === bizCatForm && "selected"}
                          >
                            {category}
                          </option>
                        ))}
                      </select>
                      <MdKeyboardArrowDown className="business-down" />
                      {errors.industry1 && (
                        <section className="error__txt">
                          {errors.industry1.message}
                        </section>
                      )}
                    </div>
                    <label>Business Category</label>
                    <div className="business-option">
                      <select
                        type="text"
                        placeholder="Business category"
                        {...register("industry2", {
                          required: "Business Category is required",
                        })}
                      >
                        {Array.from(
                          businessSections[businessCategory].keys()
                        ).map((bizSection, i) => (
                          <option
                            key={i}
                            selected={bizSection === bizSecForm && "selected"}
                          >
                            {bizSection}
                          </option>
                        ))}
                      </select>
                      <MdKeyboardArrowDown className="business-down" />
                      {errors.industry2 && (
                        <section className="error__txt">
                          {errors.industry2.message}
                        </section>
                      )}
                    </div>
                    {isRegisteredBusiness && (
                      <>
                        <label>Business Registration Type</label>
                        <div className="business-option">
                          <select
                            type="text"
                            placeholder="Business registration type"
                            {...register("legalType", {
                              required:
                                "Business registration type is required",
                            })}
                          >
                            {registerType.map((legType, i) => (
                              <option
                                key={i}
                                selected={
                                  legType === bizLegalTypeForm && "selected"
                                }
                              >
                                {legType}
                              </option>
                            ))}
                          </select>
                          <MdKeyboardArrowDown className="business-down" />
                          {errors.legalType && (
                            <section className="error__txt">
                              {errors.legalType.message}
                            </section>
                          )}
                        </div>
                      </>
                    )}
                    {isRegisteredBusiness && (
                      <>
                        <label>TIN (optional)</label>
                        <input
                          type="text"
                          placeholder="TIN"
                          {...register("taxNo")}
                        />
                      </>
                    )}
                    <label>Business Phone Number</label>
                    <input
                      type="number"
                      placeholder="08000000000"
                      className={`businessInput ${
                        errors.contactPhone && "invalid"
                      }`}
                      {...register("contactPhone", {
                        required: "Phone number is required",
                        minLength: {
                          value: 11,
                          message: "minimum allowed number is 11",
                        },
                        maxLength: {
                          value: 11,
                          message: "maximum allowed number is 11",
                        },
                      })}
                    />
                    {errors.contactPhone && (
                      <section className="error__txt">
                        {errors.contactPhone.message}
                      </section>
                    )}
                    <label>Business Email</label>
                    <input
                      className={`businessInput ${errors.email && "invalid"}`}
                      type="email"
                      placeholder="Business email"
                      {...register("contactEmail", {
                        required: "business email is required",
                        pattern: {
                          value:
                            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.contactEmail && (
                      <section className="error__txt">
                        {errors.contactEmail.message}
                      </section>
                    )}

                    <div className="apart_state">
                      <div className="biz_option">
                        <label>State</label>
                        <select
                          type="text"
                          placeholder="State"
                          {...register("province", {
                            required: "State is required",
                          })}
                        >
                          {nigeriaStatesList.map((category, i) => (
                            <option
                              key={i}
                              selected={category === ngStateForm && "selected"}
                            >
                              {category}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="biz_down" />
                        {errors.province && (
                          <section className="error__txt">
                            {errors.province.message}
                          </section>
                        )}
                      </div>

                      <div className="biz_option">
                        <label>LGA</label>
                        <select
                          type="text"
                          placeholder="City"
                          {...register("city", {
                            required: "City is required",
                          })}
                        >
                          {Array.from(nigeriaLGAs[nigeriaState].keys()).map(
                            (section, i) => (
                              <option
                                key={i}
                                selected={section === ngLgaForm && "selected"}
                              >
                                {section}
                              </option>
                            )
                          )}
                        </select>
                        <MdKeyboardArrowDown className="biz_down" />
                        {errors.city && (
                          <section className="error__txt">
                            {errors.city.message}
                          </section>
                        )}
                      </div>
                    </div>

                    <label>Business Address</label>
                    <input
                      className={`businessInput ${
                        errors.regAddress && "invalid"
                      }`}
                      type="text"
                      placeholder="Business address"
                      {...register("regAddress", {
                        required: "Business address is required",
                      })}
                    />
                    {errors.regAddress && (
                      <section className="error__txt">
                        {errors.regAddress.message}
                      </section>
                    )}

                    <button type="submit">Next</button>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </header>
      </Layout>
    </>
  );
};
