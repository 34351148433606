export const nigeriaStates = new Map([
  ["Abia"],
  ["Adamawa"],
  ["Akwa Ibom"],
  ["Anambra"],
  ["Bauchi"],
  ["Bayelsa"],
  ["Benue"],
  ["Borno"],
  ["Cross River"],
  ["Delta"],
  ["Ebonyi"],
  ["Edo"],
  ["Ekiti"],
  ["Enugu"],
  ["FCT-Abuja"],
  ["Gombe"],
  ["Imo"],
  ["Jigawa"],
  ["Kaduna"],
  ["Kano"],
  ["Katsina"],
  ["Kebbi"],
  ["Kogi"],
  ["Kwara"],
  ["Lagos"],
  ["Nasarawa"],
  ["Niger"],
  ["Ogun"],
  ["Ondo"],
  ["Osun"],
  ["Oyo"],
  ["Plateau"],
  ["Rivers"],
  ["Sokoto"],
  ["Taraba"],
  ["Yobe"],
  ["Zamfara"],
]);

export const nigeriaStatesList = Array.from(nigeriaStates.keys());
