import {
  HomeIcon,
  CollectPaymentIcon,
  TransactionIcon,
  SettingsIcon,
} from "../../../assests/icons/Icons";

export const merchantLinks = [
  {
    name: "Overview",
    route: "/dashboard/cashier/overview",
    icon: <HomeIcon />,
    subLinks: null,
    keyWord: "overview"
  },
  {
    name: "Receive payment",
    route: "/dashboard/cashier/accept-payment",
    icon: <CollectPaymentIcon />,
    subLinks: null,
    keyWord: "accept-payment"
  },
  {
    name: "Transactions",
    route: "/dashboard/cashier/transactions",
    icon: <TransactionIcon />,
    subLinks: null,
    keyWord: "transactions"
  },
  {
    name: "Settings",
    route: "/dashboard/cashier/settings",
    icon: <SettingsIcon />,
    subLinks: null,
    keyWord: "settings"
  },
];
