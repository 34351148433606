import React, { useState, useEffect } from "react";
import { Wallet, Calender } from "../../../../assests/svgs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { periodList, periods } from "../../../../utils/constants/periods";
import { useHistory } from "react-router-dom";
import {
  useTradeSummary,
  useSummaryGraph,
} from "../../../../hooks/useTransaction";
import storage from "../../../../utils/storage";
import {
  formatCurrency,
  formatCount,
} from "../../../../utils/functions";
import { Spinner } from "../../../../components/spinner";
import { SpinnerWhite } from "../../../../components/spinner/Spinner";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import "./styles.scss";

function HomeSection({ latestTransaction, loading }) {
  const currentDate = new Date().toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  //const chlTermNo = JSON.parse(storage.fetch("role")).chlTermNo;
  const roleFetch = JSON.parse(storage.fetch("role"))
  const chlTermNo = roleFetch.chlTermNo;
  const terminalName = roleFetch.terminalCode;
  const [summaryType, setSummaryType] = useState("CURRENT_DAY");
  const [totalAmount, setTotalAmount] = useState(0);
  const [tradeCount, setTradeCount] = useState(0);
  const [dataGraphSummary, setdataGraphSummary] = useState([]);
  const [displayDate, setDisplayDate] = useState(currentDate);
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 500px)").matches)
  const dayjs = require('dayjs')
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)

  const history = useHistory();

  useEffect(() => {
    window
    .matchMedia("(max-width: 500px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  const {
    isLoading: isFetchingSummary,
    data,
    mutate: fetchTradeSummary,
  } = useTradeSummary({
    cusId,
    summaryType,
    terminalCode: chlTermNo,
  });
   
  useEffect(() => {
    const weekDays = [
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY",
    ];

    for (let i = 0; i < weekDays.length; i++) {
      setdataGraphSummary((dataGraphSummary) => [
        ...dataGraphSummary,
        {
          xlabel: weekDays[i],
          "Current amount": 0,
          "Previous amount": 0,
        },
      ]);
    }
  }, []);

  const { data: graphData, mutate: fetchGraphData } = useSummaryGraph({
    cusId,
    terminalCode: chlTermNo,
  });

  useEffect(() => {
    fetchTradeSummary();
  }, [summaryType]);

  useEffect(() => {
    fetchGraphData();
  }, []);

  useEffect(() => {
    if (data?.code === "000000") {
      setTotalAmount(data?.data?.sumTradeAmt);
      setTradeCount(data?.data?.sumCnt);

      if (summaryType !== "ALL") {
        let startDate = new Date(data?.data?.startDate);
        let endDate = new Date(data?.data?.endDate);

        if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth() &&
          startDate.getDate() === endDate.getDate()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr);
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() !== endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr + " - " + endDateStr);
        }
      } else {
        let startDate = new Date(data?.data?.minDate);
        let endDate = new Date(data?.data?.maxDate);

        if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth() &&
          startDate.getDate() === endDate.getDate()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr);
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else if (
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() !== endDate.getMonth()
        ) {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          });
          setDisplayDate(
            startDateStr +
              " - " +
              endDateStr +
              ", " +
              endDate.toLocaleDateString("en-us", { year: "numeric" })
          );
        } else {
          let startDateStr = startDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          let endDateStr = endDate.toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          setDisplayDate(startDateStr + " - " + endDateStr);
        }
      }
    }
  }, [data]);

  const getWeekDay = (day) => {
    var days = {
      MONDAY: "Mon",
      TUESDAY: "Tue",
      WEDNESDAY: "Wed",
      THURSDAY: "Thur",
      FRIDAY: "Fri",
      SATURDAY: "Sat",
      SUNDAY: "Sun",
    };
    return days[day];
  };

  const getMonths = (month) => {
    var months = {
      JANUARY: "Jan",
      FEBRUARY: "Feb",
      MARCH: "Mar",
      APRIL: "Apr",
      MAY: "May",
      JUNE: "Jun",
      JULY: "Jul",
      AUGUST: "Aug",
      SEPTEMBER: "Sep",
      OCTOBER: "Oct",
      NOVEMBER: "Nov",
      DECEMBER: "Dec",
    };
    return months[month];
  };

  const getDateWords = (str) => {
    var dateWords = {
      MONDAY: "Mon",
      TUESDAY: "Tue",
      WEDNESDAY: "Wed",
      THURSDAY: "Thur",
      FRIDAY: "Fri",
      SATURDAY: "Sat",
      SUNDAY: "Sun",
      JANUARY: "Jan",
      FEBRUARY: "Feb",
      MARCH: "Mar",
      APRIL: "Apr",
      MAY: "May",
      JUNE: "Jun",
      JULY: "Jul",
      AUGUST: "Aug",
      SEPTEMBER: "Sep",
      OCTOBER: "Oct",
      NOVEMBER: "Nov",
      DECEMBER: "Dec",
    };

    return dateWords[str];
  };

  useEffect(() => {
    if (graphData?.code === "000000") {
      setdataGraphSummary([]);

      const currentWeekData = graphData.data.weekData.thisWeekData;
      const lastWeekData = graphData.data.weekData.lastWeekData;

      const currentMonthData = graphData.data.monthData.thisMonthData;
      const lastMonthData = graphData.data.monthData.lastMonthData;

      const currentYearData = graphData.data.yearDataJson.thisYearData;
      const lastYearData = graphData.data.yearDataJson.lastYearData;

      switch (summaryType) {
        case "CURRENT_DAY":
          for (let i = 0; i < currentWeekData.length; i++) {
            setdataGraphSummary((dataGraphSummary) => [
              ...dataGraphSummary,
              {
                xlabel: currentWeekData[i].sequence,
                "Current amount": currentWeekData[i].totalAmount,
                "Previous amount": lastWeekData[i].totalAmount,
              },
            ]);
          }
          break;
        case "ONE_WEEK":
          for (let i = 0; i < currentWeekData.length; i++) {
            setdataGraphSummary((dataGraphSummary) => [
              ...dataGraphSummary,
              {
                xlabel: currentWeekData[i].sequence,
                "Current amount": currentWeekData[i].totalAmount,
                "Previous amount": lastWeekData[i].totalAmount,
              },
            ]);
          }
          break;
        case "ONE_MONTH":
          let len = 0;

          if (currentMonthData.length === lastMonthData.length) {
            len = currentMonthData.length;

            for (let i = 0; i < len; i++) {
              setdataGraphSummary((dataGraphSummary) => [
                ...dataGraphSummary,
                {
                  xlabel: currentMonthData[i].sequence,
                  "Current amount": currentMonthData[i].totalAmount,
                  "Previous amount": lastMonthData[i].totalAmount,
                },
              ]);
            }
            break;
          } else if (currentMonthData.length > lastMonthData.length) {
            len = currentMonthData.length;

            for (let i = 0; i < len; i++) {
              if (i < lastMonthData.length) {
                setdataGraphSummary((dataGraphSummary) => [
                  ...dataGraphSummary,
                  {
                    xlabel: currentMonthData[i].sequence,
                    "Current amount": currentMonthData[i].totalAmount,
                    "Previous amount": lastMonthData[i].totalAmount,
                  },
                ]);
              } else {
                setdataGraphSummary((dataGraphSummary) => [
                  ...dataGraphSummary,
                  {
                    xlabel: currentMonthData[i].sequence,
                    "Current amount": currentMonthData[i].totalAmount,
                    "Previous amount": 0,
                  },
                ]);
              }
            }

            break;
          } else {
            len = lastMonthData.length;

            for (let i = 0; i < len; i++) {
              if (i < currentMonthData.length) {
                setdataGraphSummary((dataGraphSummary) => [
                  ...dataGraphSummary,
                  {
                    xlabel: lastMonthData[i].sequence,
                    "Current amount": currentMonthData[i].totalAmount,
                    "Previous amount": lastMonthData[i].totalAmount,
                  },
                ]);
              } else {
                setdataGraphSummary((dataGraphSummary) => [
                  ...dataGraphSummary,
                  {
                    xlabel: lastMonthData[i].sequence,
                    "Current amount": 0,
                    "Previous amount": lastMonthData[i].totalAmount,
                  },
                ]);
              }
            }
            break;
          }

        case "THREE_MONTH":
          for (let i = 0; i < currentYearData.length; i++) {
            setdataGraphSummary((dataGraphSummary) => [
              ...dataGraphSummary,
              {
                xlabel: currentYearData[i].sequence,
                "Current amount": currentYearData[i].totalAmount,
                "Previous amount": lastYearData[i].totalAmount,
              },
            ]);
          }
          break;
        case "SIX_MONTH":
          for (let i = 0; i < currentYearData.length; i++) {
            setdataGraphSummary((dataGraphSummary) => [
              ...dataGraphSummary,
              {
                xlabel: currentYearData[i].sequence,
                "Current amount": currentYearData[i].totalAmount,
                "Previous amount": lastYearData[i].totalAmount,
              },
            ]);
          }
          break;
        case "ALL":
          for (let i = 0; i < currentYearData.length; i++) {
            setdataGraphSummary((dataGraphSummary) => [
              ...dataGraphSummary,
              {
                xlabel: currentYearData[i].sequence,
                "Current amount": currentYearData[i].totalAmount,
                "Previous amount": lastYearData[i].totalAmount,
              },
            ]);
          }
          break;
        default:
          for (let i = 0; i < currentWeekData.length; i++) {
            setdataGraphSummary((dataGraphSummary) => [
              ...dataGraphSummary,
              {
                xlabel: currentWeekData[i].sequence,
                "Current amount": currentWeekData[i].totalAmount,
                "Previous amount": lastWeekData[i].totalAmount,
              },
            ]);
          }
          break;
      }
    }
  }, [graphData]);

  const handleChange = (e) => {
    setSummaryType(periods.get(e.target.value));
    fetchGraphData();
  };

  function CustomTooltip({ active, payload, label }) {
    let curValueLabel = "";
    let preValueLabel = "";
    if (getWeekDay(label)) {
      curValueLabel = "This week";
      preValueLabel = "Last week";
    } else if (getMonths(label)) {
      curValueLabel = "This year";
      preValueLabel = "Last year";
    } else {
      curValueLabel = "This month";
      preValueLabel = "Last month";
    }

    if (active) {
      return (
        <div className="tooltip">
          <h4>{label[0] + label.substr(1).toLowerCase()}</h4>
          <p>
            {curValueLabel}: {formatCurrency(payload[1].value.toFixed(2))}{" "}
          </p>
          <p className="reduceMagin">
            {preValueLabel} : {formatCurrency(payload[0].value.toFixed(2))}{" "}
          </p>
        </div>
      );
    }
    return null;
  }
  
  function formatCase(str){
    var strLow = str.toLowerCase()
    return str[0]+ strLow.substring(1)
  }

  return (
    <>
     <header>
        <main className="main">
          <div className="home-top">
            <h3>
              Overview
              <span className="home3-sub">
                <select onChange={handleChange}>
                  {periodList.map((period, i) => (
                    <option key={i} selected={period === "1 Day"}>
                      {period}
                    </option>
                  ))}
                </select>
                <MdKeyboardArrowDown className="date-down" />
              </span>
            </h3>

            <div className="topbar">
            <span className="top-notify">
              <img src={Calender} alt="" className="calinda" />
                <span className="date__time">
                  {displayDate}
                </span>
              </span>
            </div>

          </div>
          <div className="home-card">
            <div className="card">
              <h3>Revenue</h3>
              {isFetchingSummary ? (
                <SpinnerWhite />
              ) : (
                <h2 id="font_change">{formatCurrency(totalAmount)}</h2>
              )}
            </div>
            <div className="cards">
              <h3>Transactions</h3>
              {isFetchingSummary || loading ? (
                <Spinner color={"#1a71ff"} />
              ) : (
                <div className="o-cards">
                 {!!Object.keys(latestTransaction).length ? (
                      <div className="mini-cards">
                        <div className="card-right">
                          <h5>{`${
                            (latestTransaction?.payAccountName &&
                              formatCase( latestTransaction?.payAccountName.split(" ")[0]) ) ||
                            "Someone"
                          } paid ${formatCurrency(
                            latestTransaction.tradeAmt
                          )}`}</h5>
                          <p>{dayjs(latestTransaction?.finishTime).fromNow()}</p>
                        </div>
                        <img src={Wallet} alt="" />
                      </div>
                    ) : (
                      
                        <div className="mini-cards">
                          <div className="card-right">
                            <h5>No transaction yet Start</h5>
                            <p>accepting payment instantly</p>
                          </div>
                          <img src={Wallet} alt="" />
                        </div>  
                    )}
                  <span></span>
                  <h1>{formatCount(tradeCount)}</h1>
                </div>
              )}
            </div>
          </div>

          <div className="chart">
            <h2 className="chart-total">Total sold</h2>
            <ResponsiveContainer width="100%" aspect={matches ? 1.2 : 3.5}>
              <AreaChart
                width={500}
                height={250}
                data={dataGraphSummary}
                margin={{
                  top: 10,
                  right: 0,
                  left: 10,
                  bottom: 10,
                }}
              >
                <CartesianGrid
                  stroke="#eeebeb"
                  vertical="true"
                  horizontal="true"
                  strokeDasharray="6 6"
                />
                <XAxis
                  dataKey="xlabel"
                  tick={{
                    fill: "#B8B8B8",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                  tickLine=""
                  axisLine=""
                  tickSize="22"
                  tickFormatter={(str) => {
                    let xlabel = getDateWords(str);
                    if (xlabel) {
                      return xlabel;
                    } else {
                      return str;
                    }
                  }}
                />
                <YAxis
                  tick={{
                    fill: "#B8B8B8",
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: "sans-serif",
                  }}
                  tickLine=""
                  axisLine=""
                  tickSize="22"
                  tickFormatter={(str) => {
                    let formatCot = formatCount(str);
                    let formatCur = formatCurrency(str).split(".")[0];
                    return formatCur[0] + "\u200A" + "\u200A" + formatCot;
                  }}
                />
                <defs>
                  <linearGradient
                    id="colorCurrentAmount"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="10%" stopColor="#D8EDFE" stopOpacity={1} />
                    <stop offset="95%" stopColor="#D8EDFE" stopOpacity={0.1} />
                  </linearGradient>
                  <linearGradient
                    id="colorPreviousAmount"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="10%" stopColor="#D9D9D9" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#D9D9D9" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Tooltip content={<CustomTooltip />} cursor={false} />
                <Area
                  type="monotone"
                  dataKey="Previous amount"
                  stroke="#a7a4a4"
                  activeDot={{ stroke: "#fff", strokeWidth: 2, r: 6 }}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#colorPreviousAmount)"
                />
                <Area
                  type="monotone"
                  dataKey="Current amount"
                  stroke="#1a71ff"
                  activeDot={{ stroke: "#fff", strokeWidth: 4, r: 8 }}
                  strokeWidth={3}
                  fillOpacity={1}
                  fill="url(#colorCurrentAmount)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </main>
      </header>
    </>
  );
}

export default HomeSection;
