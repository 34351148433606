import Layout from "../../components/layout/Layout";
import { SettlementDetailTable } from "../../components/table/SettlementDetailTable";
import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "../../components/spinner";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import {
  useQuerySettlement,
  useQuerySettlementDetail,
} from "../../hooks/useSettlement";
import {
  formatCurrency,
  formatDateTime,
  formatCount,
  truncateShortName,
  truncateLongName,
} from "../../utils/functions";
import { pageList, pages } from "../../utils/constants/pages";
import { useParams } from "react-router-dom";
import storage from "../../utils/storage";
import { banks } from "../../utils/constants/banks";

import "./styles.scss";

const column = [
  "Customer Name",
  "Gross Amount",
  "Fee",
  "Settled Amount",
  "Payment Method",
  "Date",
  "Transaction Reference",
];

export const SettlementDetailsPage = () => {
  const { settleBatchNo } = useParams();
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const settleBatchNoCh = settleBatchNo.replace(/-/g, '/');
  const [transactions, setTransactions] = useState([]);
  const [exporttransactions, setExportTransactions] = useState([]);
  const [settlementDetail, setSettlementDetail] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState("1");
  const [currentSize, setCurrentSize] = useState(25);
  const [settlements, setSettlements] = useState([]);
  const [isExportLoading, setIsExportLoading ] = useState(false);
  const exportSettleTransactionRef = useRef(null);

  const {
    isLoading: isFetchingSettlementDetail,
    data: settleDetailData,
    mutate: fetchSettlementDetails,
  } = useQuerySettlementDetail({
    cusId,
    settleBatchNo: settleBatchNoCh,
    page: currentPage,
    pageSize: currentSize,
  });


  const {
    data: settleDetailDataExport,
    mutate: fetchSettlementDetailsExport,
  } = useQuerySettlementDetail({
    cusId,
    settleBatchNo: settleBatchNoCh,
    page: '1',
    pageSize: transactionCount,
  });

  const {
    isLoading: isFetchingSettlement,
    data: settleData,
    mutate: fetchSettlements,
  } = useQuerySettlement({
    cusId,
    page: currentPage,
    pageSize: currentSize,
    settleBatchNo: settleBatchNoCh,
  });

  useEffect(() => {
    fetchSettlementDetails();
  }, [currentPage]);

  useEffect(() => {
    fetchSettlements();
  }, []);

  const handlePageClick = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleChange = (e) => {
    setCurrentSize(pages.get(e.target.value));
    setCurrentPage(1);
  };

  const formatDate = (undate) => {
    return new Date(undate).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    })
  }

  useEffect(() => {
    if (settleDetailData?.code === "000000") {
      setSettlementDetail(settleDetailData.data);
      const formattedTransactions = settleDetailData.data.list.map((t) => {
        return {
          customerName: truncateLongName(t.payAccountName),
          grossAmount: formatCurrency(t.gross),
          fee: formatCurrency(t.fee),
          settledAmount: formatCurrency(t.settleAmount),
          paymentMethod:  getPayMethod(t.payment),
          transactionReference: truncateShortName(t.orderNo),
          settledReference: t.settleBatchNo,
          date: formatDate(t.date),
        };
      });
      setTransactionCount(settleDetailData.data.totalCount);
      setTransactions(formattedTransactions);
      setPageCount(Math.ceil(settleDetailData.data.totalCount / currentSize));
      if(isExportLoading){
        fetchSettlementDetailsExport()
      }
    }
  }, [settleDetailData]);

  useEffect(() => {
    if (settleDetailDataExport?.code === "000000") {
      const unFormattedTransactions = settleDetailDataExport.data.list.map((t) => {
        return {
          customerName: t.payAccountName,
          grossAmount: t.gross,
          fee: t.fee,
          settledAmount: t.settleAmount,
          paymentMethod:  getPayMethod(t.payment),
          transactionReference: t.orderNo,
          settledReference: t.settleBatchNo,
          date: formatDate(t.date),
        };
      });
      setExportTransactions(unFormattedTransactions);
      setTimeout(() => {
      exportSettleTransactionRef.current.link.click();
      }, 500);
      setIsExportLoading(false)
    }
  }, [settleDetailDataExport]);

  useEffect(() => {
    if (settleData?.code === "000000") {
      setSettlements(settleData.data.list[0]);
    }
  }, [settleData]);
  const getBankName = (bankCode) => {
    let bankNam = ''
    banks.forEach(function(value, key) {
      if (bankCode === value){
        bankNam = key
      }
    })
    return bankNam 
  };

  const fetchSettleExport = () => {
    if(transactionCount != 0){
      fetchSettlementDetailsExport()
      setIsExportLoading(true)
    }else{
      fetchSettlementDetails()
      setIsExportLoading(true)
    }
  };

  const getPayMethod = (paymentMet) => {
       let paymentMethod = ''
       switch (paymentMet) {
         case "T1002":
           paymentMethod = "Dynamic QR";
           break;
           case "T1003":
           paymentMethod = "Fixed QR";
           break;
         case "T1004":
             paymentMethod = "Bank Transfer";
             break;
         case "Static Virtual Account":
           paymentMethod = "Bank Transfer";
           break;
           default:
             paymentMethod = "Bank Transfer";
       }
         return paymentMethod
      };

  return (
    <Layout>
      <header>
        <main className="settlement__main">
          <div className="main-settle">
            <section className="header-container">
              <div>
                <h1>Settlement details</h1>
              </div>
            </section>

            <div className="settlement-card">
              <div className="card">
                <div className="main-title">
                  <span className="main-descr">Settlement Details</span>
                </div>
                <div className="card-dsicrption">

                  {isFetchingSettlement || !settlements.settleAccountNo ? (
                    <div style={{ marginTop: "80px" }}>
                      <Spinner color={"#1a71ff"} />
                    </div>
                  ) : (
                    <>
                      <div className="description">
                        <p>Bank account</p>
                        <p className="snd ">{settlements.settleAccountNo}</p>
                      </div>
                      <div className="description">
                        <p>Bank</p>
                        <p className="second">{getBankName(settlements.bankCode)}</p>
                      </div>
                      <div className="description">
                        <p>Reference</p>
                        <p className="second">{settlements.settleBatchNo}</p>
                      </div>
                      <div className="description">
                        <p>Date Deposited</p>
                        <p className="second"> {formatDateTime(settlements.createTime)} </p>
                      </div>
                    </>
                  )}

                </div>
              </div>
              <div className="transact-cards">
                <div className="transaction-summary">
                  <p className="transaction-title">Settlement Summary</p>
                </div>
                <div className="card-dsicrption">
                  {isFetchingSettlementDetail || !settlementDetail.totalSettleAmount ? (
                    <div style={{ marginTop: "80px" }}>
                      <Spinner color={"#1a71ff"} />
                    </div>
                  ) : (
                    <>
                      <div className="description2">
                        <p style={{ color: "#595959" }}>
                          No of transaction deposited
                        </p>
                        <p className="second">{formatCount(transactionCount)}</p>
                      </div>
                      <div className="description2">
                        <p style={{ color: "#595959" }}>Total gross amount</p>
                        <p id="font_change" className="second">{formatCurrency(settlementDetail.totalGross)}</p>
                      </div>
                      <div className="description2">
                        <p style={{ color: "#595959" }}>Total transaction fee</p>
                        <p id="font_change" className="second">{formatCurrency(settlementDetail.totalFee)}</p>
                      </div>
                      <div className="description2">
                        <p style={{ color: "#595959" }}>Total amount settled</p>
                        <p id="font_change" className="second">{formatCurrency(settlementDetail.totalSettleAmount)}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="subTable">
              <div className="table-header">
                <div className="header">
                  <h1 className="active">Transactions details</h1>
                </div>
                <div className="header"> 
                  <CSVLink 
                  data={exporttransactions}
                  filename={`Settlement_report_${settlements.createTime}`}
                  ref={exportSettleTransactionRef}
                  >
                  </CSVLink>
                  <button id="export-btn" onClick={fetchSettleExport}>{isExportLoading ? <Spinner /> : "Export"}</button>
                </div>
              </div>
              <div className="transactionTable">
                <SettlementDetailTable
                  column={column}
                  data={transactions}
                />
                {isFetchingSettlementDetail ? (
                  <>
                    <div style={{ marginTop: "40px", marginBottom: "8rem" }}>
                      <Spinner color={"#1a71ff"} />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pagination--D">
                      <div className="items-to-show">
                        <p>Items to show: {currentSize} </p>
                        <select onChange={handleChange}>
                          {pageList.map((page, i) => (
                            <option key={i} selected={page === currentSize}>
                              {page}
                            </option>
                          ))}
                        </select>
                        <MdKeyboardArrowDown className="business-down" />
                      </div>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IoIosArrowRoundForward />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel={<IoIosArrowRoundBack />}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination__container"}
                        activeClassName={"active"}
                        previousLinkClassName={"pages-p"}
                        nextLinkClassName={"pages-p"}
                        forcePage={currentPage - 1}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      </header>
    </Layout>
  );
};


