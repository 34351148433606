import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from "antd";
import { CloseIcon, OpenIcon, DeemPayIcon } from "../../assests/icons/Icons";
import { Spinner } from "../../components/spinner";
import storage from "../../utils/storage";
import usePasswordReset from "../../hooks/usePasswordReset";

import "./styles.scss";

export const RecoverPasswordPage = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [validCode, setValidCode] = useState("");
  const [password, setPassword] = useState("");

  const {
    isLoading: isReseting,
    data,
    mutate: resetPassword,
  } = usePasswordReset({
    phoneNo,
    validCode,
    password,
  });

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const pathFrom = history.location?.state?.from?.pathname;
    const userValidCode = storage.fetch("validCode");
    const userPhoneNo = storage.fetch("userPhoneNo");

    if (pathFrom === "/verification" && userPhoneNo && userValidCode) {
      setPhoneNo(userPhoneNo);
      setValidCode(userValidCode);
    } else {
      storage.clear();
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    const handleSuccess = () => {
      storage.clear();
      history.push("/login");
    };

    const handleExpiredCode = () => {
      storage.clear();
      history.push("/forgot-password");
    };

    if (data?.code === "000000") {
      Modal.success({
        title: "Congratulations!",
        content: "You have successfully reset your password",
        onOk: () => handleSuccess(),
      });
      reset();
    } else if (data?.code === "100002") {
      Modal.error({
        title: "Password reset failed",
        content: `Sorry! ${data?.message}`,
        okText: "Retry",
        onOk: () => handleExpiredCode(),
      });
      reset();
    } else if (data?.code === "US0014") {
      Modal.error({
        title: "User doesn't exist!",
        content: "Sorry!, this number does not exist with us.",
        okText: "Retry",
        onOk: () => handleExpiredCode(),
      });
      reset();
    }
  }, [data]);

  const onSubmit = (data) => {
    setPassword(data.password);
    resetPassword();
  };

  return (
    <>
      <div className="recover">
        <div className="recover-wrap">
          <div className="image-wrap">
          <span className="img"><DeemPayIcon /></span>
          </div>
          <div className="verify-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2>Create new password</h2>
              <h4>
                The password should contain upto 6
                characters and a mix of digit and letter.
              </h4>
              <label>Password</label>
              <div className="input">
                <input
                  className={`registerInput ${errors.password && "invalid"}`}
                  type={show ? "text" : "password"}
                  placeholder="Password"
                  {...register("password", {
                    required: "password is required",
                    pattern: {
                      value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                      message:
                        "Your password should contain at least a number and a letter and minimum of 6 characters",
                    },
                  })}
                />
                {errors.password && (
                  <section>{errors.password.message}</section>
                )}

                <span onClick={() => setShow(!show)}>
                  {show ? (
                     <span className="input-eye" ><OpenIcon /></span>
                     ) : (
                       <span className="input-eye" ><CloseIcon /></span>
                  )}
                </span>
              </div>
              <button type="submit" disabled={isReseting}>
                {isReseting ? <Spinner /> : "Reset password"}
              </button>
            </form>
            <div className="verify-create">
              <p>
                Need help?{" "}
                <span>
                  <Link
                    target="_blank"
                    to="//www.deempay.com/supportcenter"
                    className="link2"
                  >
                    Contact support
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
