import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../components/layout/Layout";
import { useHistory } from "react-router-dom";
import { Notify } from "../../components/notification";
import { FiPlus } from "react-icons/fi";

import "./styles.scss";

////////////////////////////////////////////////////////////
import { Spinner } from "../../components/spinner";
import storage from "../../utils/storage";
import { useCreateClass, useQueryClass } from "../../hooks/useClass";
import "react-toastify/dist/ReactToastify.css";
import {
  AddFeeIcon,
  NoPaymentLinkIcon,
} from "../../assests/icons/Icons";
import { useFieldArray } from "react-hook-form";
import { ClassListTable } from "../../components/table/ClassListTable";
import { CloseModalIcon } from "../../assests/icons/Icons";
import { SpinnerWhite } from "../../components/spinner/Spinner";

import "./styles.scss";

////////////////////////////////////////////////////////////////////////////

const column = ["Class name", "Class category", "Edit/delete"];

export const ClassLinkPage = () => {
  const history = useHistory();
  const [showCreateClassModal, setShowCreateClassModal] = useState(false);

  //////////////////////////////////////////////////////////////////////
  const selectRef = useRef(null);
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [showSchoolFees, setShowSchoolFees] = useState(false);
  const [showSchoolFees2, setShowSchoolFees2] = useState(false);
  const [showSchoolFees3, setShowSchoolFees3] = useState(false);
  const [showSchoolFees4, setShowSchoolFees4] = useState(false);
  const [showSchoolFees5, setShowSchoolFees5] = useState(false);
  const [showSchoolFees6, setShowSchoolFees6] = useState(false);
  const [showAddClass, setShowAddClass] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSinglePaymentModal, setShowSinglePaymentModal] = useState(false);
  const [showMutiplePaymentModal, setShowMultiplePaymentModal] =
    useState(false);
  const [showMutiplePaymentModal2, setShowMultiplePaymentModal2] =
    useState(false);
  const [showMutiplePaymentModal3, setShowMultiplePaymentModal3] =
    useState(false);
  const [showMutiplePaymentModal4, setShowMultiplePaymentModal4] =
    useState(false);
  const [showDonation, setShowDonation] = useState(false);

  const [className, setClassName] = useState("");
  const [classCategory, setClassCategory] = useState("");
  const [imageName, setImageName] = useState("");
  const [classList, setClassList] = useState(null);
  const [selectedFeeOption, setSelectedFeeOption] = useState([]);
  const [addInfoCount, setAddInfoCount] = useState(0);
  const [selectedClassOption, setSelectedClassOption] = useState([]);
  const [classOptions, setClassOptions] = useState(null);
  const [fetchingClass, setFetchingClass] = useState(false);
  const [classPresent, setClassPresent] = useState(false);
  const [imageId, setImageId] = useState();

  /////////////////////////////////////////////////////////////////////////

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {
      switch (profileType) {
        case "paymentLinks":
          history.replace("/dashboard/merchant/payment-link");
          break;
        case "fees":
          history.replace("/dashboard/merchant/payment-link/fees");
          break;
        case "classes":
          history.replace("/dashboard/merchant/payment-link/classes");
          break;
        default:
          history.replace("/dashboard/merchant/payment-link");
      }
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  const {
    isLoading: isFetchingClass,
    data: classData,
    mutate: fetchClass,
  } = useCreateClass({
    cusId,
    className,
    classCategory,
  });

  const {
    isLoading: isFetchingClassDetails,
    data: queryClassData,
    mutate: fetchQueryClass,
  } = useQueryClass({
    cusId,
  });

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      field: [{ className: "", classCategory: "" }],
      fieldFee: [{ feeName: "", feeAmount: "", feeDescription: "" }],
      fieldClass: [{ className: "", classCategory: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "field",
  });

  useEffect(() => {
    fetchQueryClass();
  }, []);

  //SUBMIT CLASS DATA
  const onSubmitClass = (data) => {
    console.log("CLASS DATA", data.fieldClass);

    for (let i in data.fieldClass) {
      var classInfo = data.fieldClass[i];

      const { className, classCategory } = classInfo;
      if (className) {
        setClassCategory(classCategory);
        setClassName(className);
        fetchClass();
      }

    }
  };

  useEffect(() => {
    if (classData?.code === "000000") {
      Notify(
        "success",
        "Class added successful!",
        "A new class was created successfully"
      );
      reset();
      closeModal();
      fetchQueryClass();

    } else if (classData?.code === "US0066") {
      Notify(
        "error",
        "Class error",
        "Sorry! this class already exists, please add a new class.",
        10
      );
    }
  }, [classData]);

  useEffect(() => {
    if (queryClassData?.code === "000000") {
      console.log(queryClassData);

      const formattedClassList = queryClassData.data.map((f) => {
        return {
          classId: f.classId,
          className: f.className,
          classStatus: f.status,
          classCategory: f.classCategory
        };
      });
      setClassList(formattedClassList);

      var classOption = [];

      if (queryClassData.data.length > 0) {
        queryClassData.data.forEach(function (entry) {
          var signClass = {};
          signClass["value"] = entry.classId;
          signClass["label"] = entry.className;
          signClass["category"] = entry.classCategory;
          classOption.push(signClass);
        });
      }
      console.log("class-Option", classOption);

      setClassOptions(classOption);
      classOption.length > 0 ? setClassPresent(true) : setClassPresent(false);

      console.log("class-Options", classOptions);
      setFetchingClass(false);
    }
  }, [queryClassData]);

  const startCreateClassModal = () => {
    setShowCreateClassModal(true);
    setShowPaymentModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowDonation(false);

  };

  //CLOSE MODALS
  const closeModal = () => {
    setShowCreateClassModal(false);
    //setShowModal(false);
    setShowSchoolFees(false);
    setShowAddClass(false);
    setShowSchoolFees2(false);
    setShowSchoolFees3(false);
    setShowSchoolFees4(false);
    setShowSchoolFees5(false);
    setShowSchoolFees6(false);
    setShowPaymentModal(false);
    setShowPaymentModal(false);
    setShowSinglePaymentModal(false);
    setShowMultiplePaymentModal(false);
    setShowMultiplePaymentModal2(false);
    setShowMultiplePaymentModal3(false);
    setShowMultiplePaymentModal4(false);
    setShowDonation(false);
    resetField("fieldFee");
    resetField("field");
    resetField("fieldCreateFee");
    resetField(`fieldCreateFee.addInfo`);
    resetField("fieldSingleLink");
    resetField(`fieldSingleLink.addInfo`);
    resetField("fieldClass");
    resetField("classFeeApplied");
    setSelectedClassOption([]);
    setClassOptions([]);
    setAddInfoCount(0);
    setImageName("");
    setImageId("");

    setSelectedFeeOption([]);
    setAddInfoCount(0);
    remove();
    append({ labelName: "", checkOptional: "" });
  };

  return (
    <>
      <Layout>
        <header>
          <main className="classes">
            <section id="sect">
              <a>
                <span
                  className="personal-info"
                  onClick={(node, event) => handleRowClick("paymentLinks")}
                >
                  Payment links
                </span>
              </a>
              <a>
                <span onClick={(node, event) => handleRowClick("fees")}>
                  Fees
                </span>
              </a>
              <a>
                <span
                  className="activ"
                  onClick={(node, event) => handleRowClick("classes")}
                >
                  Classes
                </span>
              </a>
            </section>
          </main>

          <main className="fee__n">
            <div className="home3-bar">
              <h3>Classes</h3>
              <div className="topbar">
                <span className="top-notify">
                  <span
                    className="btn_blue_card card__space"
                    onClick={() => startCreateClassModal()}
                  >
                    <FiPlus className="icon__mg " />
                    <span className="blueCard_text">New</span>
                  </span>
                </span>
              </div>
            </div>
            {isFetchingClassDetails || classList === null ? (
              <div class="mt-[200px]">
                <Spinner />
              </div>
            ) : classList?.length > 0 ? (
              <ClassListTable
                column={column}
                data={classList}
              />
            ) : (
              <>
                <section className="noData">
                  <NoPaymentLinkIcon />
                  <h2>No class</h2>
                  <h4>
                    Create a class to facilitate easy of payment collection.
                  </h4>
                </section>
              </>
            )}

            {showCreateClassModal ? (
              <div className="fee__modal">
                <div className="fee__wrap">
                  <form
                    onSubmit={handleSubmit(onSubmitClass)}
                    class="flex flex-col"
                  >
                    {/* HEADER */}
                    <div class="flex pt-[20px] pb-[16px] mb-[10px] border-b-[1px] border-[#D7D7D7] justify-center relative">
                      <h4 class="modal_title">Add class</h4>
                      <span
                        onClick={closeModal}
                        class="absolute top-[38%] right-[9.8%] cursor-pointer"
                      >
                        <CloseModalIcon />
                      </span>
                    </div>
                    {/* BODY */}

                    <div class="sm:px-14 px-8 overflow-y-auto max-h-[660px] min-h-[450px]">
                      {fields.map((item, index) => {
                        return (
                          <div key={item.id}>
                            <div class="my-7">
                              <label class="laBel justify-between flex">
                                <span>Class name</span>

                                {index !== 0 && (
                                  <span
                                    onClick={() => remove(index)}
                                    class="cursor-pointer text-[red] hover:text-[red]"
                                  >
                                    Clear
                                  </span>
                                )}
                              </label>
                              <input
                                type="text"
                                class={`in_Put ${
                                  errors.fieldClass?.[index]?.className &&
                                  "inPut_error"
                                }`}
                                placeholder="E.g Primary 4"
                                {...register(`fieldClass.${index}.className`, {
                                  required: true,
                                })}
                              />
                            </div>

                            <div class="relative">
                              <span class="text-sm font-normal text-[#595959] dark:text-[#595959]">
                                Class category
                              </span>
                              <select
                                {...register(
                                  `fieldClass.${index}.classCategory`,
                                  {
                                    required: true,
                                  }
                                )}
                                class={`"form-select block w-full mt-1 p-4 mb-1 text-sm text-[#000000] border-[2px] border-[#f7f7f7] rounded-lg bg-[#f7f7f7] " appearance-none ${
                                  errors.fieldClass?.[index]?.classCategory &&
                                  " outline-none border-[2px] border-[red] rounded-lg bg-[#f7f7f7] appearance-none"
                                }`}
                              >
                                <option value="" disabled selected>
                                  Select category
                                </option>
                                <option>Creche</option>
                                <option>Nursery</option>
                                <option>Primary</option>
                                <option>Secondary</option>
                                <option>Tertiary</option>
                              </select>
                              <svg
                                width="15"
                                height="8"
                                fill="none"
                                class="w-4 h-4 absolute left-[91%] bottom-[18%] pointer-events-none block"
                              >
                                <path
                                  d="M13.71.296a1 1 0 0 0-1.42 0l-4.58 4.58a1 1 0 0 1-1.42 0L1.71.296a1 1 0 1 0-1.42 1.41l4.59 4.59a3 3 0 0 0 4.24 0l4.59-4.59a1 1 0 0 0 0-1.41Z"
                                  fill="#8D8D8D"
                                />
                              </svg>
                            </div>
                          </div>
                        );
                      })}

                      <button
                        type="button"
                        onClick={() => {
                          append({ className: "", classCategory: "" });
                        }}
                        class="full_blue_btn py-3 items-center justify-center flex space-x-2 mb-6 mt-7"
                      >
                        <AddFeeIcon />
                        <span class="text_14_400 text-[#ffffff] items-center justify-center flex">
                          Add another class
                        </span>
                      </button>
                    </div>

                    {/* FOOTER */}
                    <div class="flex py-4 mt-[38px] border-t-[1px] border-[#D7D7D7] justify-end content-end sm:pr-14 pr-8">
                      <button
                        onClick={() => {
                          reset();
                        }}
                        class="white_modal_btn"
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        disabled={isFetchingClass}
                        class="blue_modal_btn ml-4 "
                      >
                        {isFetchingClass ? <SpinnerWhite /> : "Add"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </main>
        </header>
      </Layout>
    </>
  );
};
