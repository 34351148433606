import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useRegister from "../../../../hooks/useRegister";
import { Spinner } from "../../../../components/spinner";
import storage from "../../../../utils/storage";
import useLogin from "../../../../hooks/useLogin";
import { EditIcon } from "../../../../assests/icons/Icons";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import CashierLayout from "../../../../components/layout/CashierLayout";
import AccountModal from "../../../../components/modals/accountmodal/AccountModal";
import { useAuth } from "../../../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import { Notify } from "../../../../components/notification";

import "./styles.scss";

export const CashierAccountSettingsPage = () => {
  const phoneNo = JSON.parse(storage.fetch("userDetails")).phoneNo;
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isClicked, setIsClicked] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [apiResponseError, setApiResponseError] = useState("");
  const history = useHistory();

  const { login } = useAuth();

  const openModal = () => {
    setShowModal(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const {
    isLoading: isLoggingIn,
    data: loginData,
    mutate: loginUser,
  } = useLogin({
    phoneNo,
    password,
  });

  const {
    isLoading: isSendingCode,
    data,
    mutate: sendCode,
  } = useRegister({
    phoneNo,
    templateId: "M1001",
  });

  useEffect(() => {
    if (data?.code === "000000") {
      setIsClicked(true);
      setIsConfirm(false);
      reset();
      openModal();
    }
  }, [data]);

  useEffect(() => {
    if (loginData?.code === "000000") {
      sendCode();
      login(loginData?.data.token);
    } else if (loginData?.code === "US0002") {
      setApiResponseError(
        <section style={{ color: "red" }}>password is wrong</section>
      );
    }
  }, [loginData]);

  const changeView = () => {
    setIsClicked(false);
    setIsConfirm(true);
  };

  const onSubmitHandler = (data) => {
    setPassword(data.password);
    loginUser();
  };

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {

      switch (profileType) {
          case 'businessProfile':
            history.replace("/dashboard/cashier/settings/business");
          break; 
          case 'personalProfile':
            history.replace("/dashboard/cashier/settings")
          break;
          case 'bankProfile':
            history.replace("/dashboard/cashier/settings/bank")
          break;
          case 'accountSettings':
            history.replace("/dashboard/cashier/settings/accounts")
          break;
          case 'paymentStand':
            history.replace("/dashboard/cashier/settings/payment-stand")
          break;
          default:
            history.replace("/dashboard/cashier/settings")
        }
      } else {
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
      }
  };

  return (
    <>
      <CashierLayout>
        <header>
          <main className="account-settings">
            <section id="sect">
              {/* <Link to="/dashboard/cashier/settings">
                <span className="personal-info">Personal</span>
              </Link>
              <Link to="/dashboard/cashier/settings/business">
                <span>Business</span>
              </Link>
              <Link to="/dashboard/cashier/settings/bank">
                <span>Bank Details</span>
              </Link>
              <Link to="/dashboard/cashier/settings/accounts">
                <span className="activ">Account Settings</span>
              </Link>
              <Link to="/dashboard/cashier/settings/payment-stand">
                <span>Payment Sticker</span>
              </Link> */}

              <a>
                <span className="personal-info"
                onClick={(node, event) => handleRowClick('personalProfile')}
                >Personal Details</span>
              </a>
              <a>
                <span
                onClick={(node, event) => handleRowClick('businessProfile')}
                >Business Details</span>
              </a>
              <a>
                <span
                 onClick={(node, event) => handleRowClick('bankProfile')}
                >Bank Details</span>
              </a>
              <a>
                <span className="activ"
                onClick={(node, event) => handleRowClick('accountSettings')}
                >Account Settings</span>
              </a>
              <a>
                <span
                onClick={(node, event) => handleRowClick('paymentStand')}
                >Payment Sticker</span>
              </a>

            </section>
            <div className="intro-txt">
              <h2>Account Settings</h2>
              <h4>Edit account specific information.</h4>
            </div>
            <div className="line-border">
              <div className="row">
                <div className="column">
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    {isClicked && (
                      <>
                        <p className="pwd-txt">Change Password</p>
                        <button
                          type="submit"
                          class="text-[#ffffff] hover:bg-[#d9d9d9] rounded-lg py-3 items-center justify-center flex space-x-2 bg-[#ffffff] border border-[#d9d9d9] max-w-[150px] mt-[40px]"
                          onClick={changeView}
                        >
                          <EditIcon />
                          <span class="items-center justify-center flex text_16_500 text-[#595959]">
                            Edith details
                          </span>
                        </button>
                      </>
                    )}
                    {isConfirm && (
                      <>
                        <label>Confirm password</label>
                        <div className="input">
                          <input
                            className={`bankInput ${
                              errors.password && "invalid"
                            }`}
                            type={show ? "text" : "password"}
                            placeholder="Enter current password"
                            onFocus={() => setApiResponseError("")}
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 6,
                                message: "minimum allowed password is 6",
                              },
                            })}
                          />
                          {errors.password && (
                            <p className="error__mssg">
                              {errors.password.message}
                            </p>
                          )}
                          <span onClick={() => setShow(!show)}>
                            {show ? (
                              <AiOutlineEyeInvisible className="input-eye" />
                            ) : (
                              <AiOutlineEye className="input-eye" />
                            )}
                          </span>
                        </div>
                        <p className="error__mssg">{apiResponseError}</p>
                        <button
                          disabled={isLoggingIn || isSendingCode}
                          className="btn--pwd"
                        >
                          {isLoggingIn || isSendingCode ? 
                            <Spinner />
                           : "Submit"
                          }
                        </button>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <AccountModal showModal={showModal} setShowModal={setShowModal} />
          </main>
        </header>
      </CashierLayout>
    </>
  );
};
