import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import BaseRoute from "./routes/BaseRoute";
import storage from "./utils/storage";
import { useAuth } from "./hooks/useAuth";
import auth from "./utils/auth";
//import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import Notification from "./notification/Notification";
import "./assests/stylesheets/index.scss";
import NoInternetConnection from "./nointernet/NoInternetConnection";

const queryClient = new QueryClient();

const App = () => {
  const { authenticate } = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated()) {
      const token = storage.fetch("token");
      authenticate(token);
    }
  }, []);

  return (
    <div>
      < NoInternetConnection>
        <QueryClientProvider client={queryClient}>
          <BaseRoute />
          <Notification />
        </QueryClientProvider>
      </NoInternetConnection>
    </div>
  );
};

export default App;
