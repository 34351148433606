// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    title: "Account verification",
    route: "/dashboard/merchant/onboarding",
    data: null,
  },
  {
    title: "Business information",
    route: "/dashboard/merchant/onboarding/business-details",
    data: "businessDetails",
  },
  {
    title: "Personal information",
    route: "/dashboard/merchant/onboarding/personal-information",
    data: "personalInformation",
  },
  {
    title: "Bank details",
    route: "/dashboard/merchant/onboarding/bank-details",
    data: "bankDetails",
  },
  {
    title: "Documents",
    route: "/dashboard/merchant/onboarding/document-upload",
    data: null,
  },
];
