import React, { useState, useEffect } from "react";
import { useMerchantQuery } from "../../../../hooks/useMerchant";
import storage from "../../../../utils/storage";
import { EditIcon } from "../../../../assests/icons/Icons";
import CashierLayout from "../../../../components/layout/CashierLayout";
import { SpinnerMedium } from "../../../../components/spinner/Spinner";
import { useHistory } from "react-router-dom";
import { Notify } from "../../../../components/notification";

import "./styles.scss";

export const CashierBankProfilePage = () => {
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [cardNo, setCardNo] = useState("");
  const [cardName, setCardName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const history = useHistory();

  const {
    isLoading: isFetchingBank,
    data: merchantData,
    mutate: fetchMerchantDetails,
  } = useMerchantQuery({ cusId });

  useEffect(() => {
    fetchMerchantDetails();
  }, []);

  useEffect(() => {
    if (merchantData?.code === "000000") {
      setCardName(merchantData?.data.cardInfo.cardName);
      setCardNo(merchantData?.data.cardInfo.cardNo);
      setBankName(merchantData?.data.cardInfo.bankName);
    }
  }, [merchantData]);

  const handleRowClick = (profileType) => {
    if (navigator.onLine) {
      switch (profileType) {
        case "businessProfile":
          history.replace("/dashboard/cashier/settings/business");
          break;
        case "personalProfile":
          history.replace("/dashboard/cashier/settings");
          break;
        case "bankProfile":
          history.replace("/dashboard/cashier/settings/bank");
          break;
        case "accountSettings":
          history.replace("/dashboard/cashier/settings/accounts");
          break;
        case "paymentStand":
          history.replace("/dashboard/cashier/settings/payment-stand");
          break;
        default:
          history.replace("/dashboard/cashier/settings");
      }
    } else {
      Notify(
        "error",
        "Network Error!",
        "Please check your internet connection!",
        10
      );
    }
  };

  return (
    <>
      <CashierLayout>
        <header>
          <main className="bank-settings">
            <section id="sect">
              <a>
                <span
                  className="personal-info"
                  onClick={(node, event) => handleRowClick("personalProfile")}
                >
                  Personal Details
                </span>
              </a>
              <a>
                <span
                  onClick={(node, event) => handleRowClick("businessProfile")}
                >
                  Business Details
                </span>
              </a>
              <a>
                <span
                  className="activee"
                  onClick={(node, event) => handleRowClick("bankProfile")}
                >
                  Bank Details
                </span>
              </a>
              <a>
                <span
                  onClick={(node, event) => handleRowClick("accountSettings")}
                >
                  Account Settings
                </span>
              </a>
              <a>
                <span onClick={(node, event) => handleRowClick("paymentStand")}>
                  Payment Sticker
                </span>
              </a>
            </section>
            <div className="intro-txt">
              <h2>Bank Information</h2>
              <h4>View bank information.</h4>
            </div>
            <div className="line-border">
              <div className="row">
                <div className="column">
                  <form>
                    <div className="name_row">
                      <div className="first-row">
                        <label>Bank Name</label>
                        <input
                          type="text"
                          placeholder={bankName}
                          disabled={true}
                        />
                        {isFetchingBank ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="first-row">
                        <label>Account Number</label>
                        <input
                          type="number"
                          placeholder={cardNo}
                          disabled={true}
                        />
                        {isFetchingBank ? (
                          <>
                            <span className="acc-Name">
                              <span className="spinner_input">
                                <SpinnerMedium className="spinner_input" />
                              </span>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <label>Account Name</label>
                    <input
                      type="name"
                      placeholder={
                        accountDetails.account_name
                          ? accountDetails.account_name
                          : cardName
                      }
                      disabled={true}
                    />
                    {isFetchingBank ? (
                      <>
                        <span className="acc-Name">
                          <span className="spinner_input">
                            <SpinnerMedium className="spinner_input" />
                          </span>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    <button
                      type="submit"
                      class="text-[#ffffff] hover:bg-[#d9d9d9] rounded-lg py-3 items-center justify-center flex space-x-2 bg-[#ffffff] border border-[#d9d9d9] max-w-[150px] mt-4"
                      disabled
                    >
                      <EditIcon />
                      <span class="items-center justify-center flex text_16_500 text-[#595959]">
                        Edith details
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </header>
      </CashierLayout>
    </>
  );
};
