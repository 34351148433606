import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "../avatar";
import { merchantLinks } from "./menus/links";
import MenuItem from "./MenuItem";
import { truncateString } from "../../utils/functions";
import LogoutModal from "../modals/logoutmodal/LogoutModal";
import storage from "../../utils/storage";
import { LogoutIcon, SidebarIcon } from "../../assests/icons/Icons";

import "./styles.scss";

export const SidebarPage = () => {
  const [logoutModal, setLogoutModal] = useState(false);
  
  const toggleLogoutModal = () => {
    setLogoutModal(!logoutModal);
  };

  let logoImage = "";
  if (storage.fetch("merchantDetails")){
    logoImage = JSON.parse(storage.fetch("merchantDetails")).logoUrl;
  }

  let businessName = "";
  if (storage.fetch("merchantDetails")){
    businessName = JSON.parse(storage.fetch("merchantDetails")).merchantName;
  }

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-brand">
          <Link to="/dashboard/cashier/overview">
            <SidebarIcon />
          </Link>
        </div>
        <div className="merchant">
          <Avatar imageUrl={logoImage} />
          <span className="span">
            <p className="userName">{truncateString(businessName)}</p>
            <h5 className="userRole">Cashier</h5>
          </span>
        </div>
        <div className="sidebar-menu">
          <div className="menu">MENU</div>
          <ul>
            {merchantLinks.map((link, i) => (
              <MenuItem key={i} item={link} />
            ))}
          </ul>
          <div onClick={toggleLogoutModal} className="sign-out">
            <span>
              <LogoutIcon className="sidebar-Iconr" />
            </span>
            Sign out
          </div>
        </div>
      </div>
      {logoutModal && <LogoutModal closeModal={toggleLogoutModal} />}
    </>
  );
};
