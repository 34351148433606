const abiaLGA = new Map([
    ["Aba North"],
    ["Aba South"],
    ["Arochukwu"],
    ["Bende"],
    ["Ikwuano"],
    ["Isiala Ngwa North"],
    ["Isiala Ngwa South"],
    ["Isiukwuato"],
    ["Obi Ngwa"],
    ["Ohafia"],
    ["Osisioma Ngwa"],
    ["Ugwunagbo"],
    ["Ukwa East"],
    ["Ukwa West"],
    ["Umuahia North"],
    ["Umuahia South"],
    ["Umunneochi"],
  ]);


const adamawaLGA = new Map([
  
    ["Demsa"],
    ["Fufore"],
    ["Ganye"],
    ["Girei"],
    ["Gombi"],
    ["Guyuk"],
    ["Hong"],
    ["Jada"],
    ["Lamurde"],
    ["Madagali"],
    ["Maiha"],
    ["Mayo-Belwa"],
    ["Michika"],
    ["Mubi North"],
    ["Mubi South"],
    ["Numan"],
    ["Shelleng"],
    ["Song"],
    ["Toungo"],
    ["Yola North"],
    ["Yola South"],
  ]);

  const akwaIbomLGA = new Map([
    ["Abak"],
    ["Eket"],
    ["Eastern Obolo"],
    ["Essien Udim"],
    ["Etim-Ekpo"],
    ["Etinan"],
    ["Esit-Eket"],
    ["Ibeno"],
    ["Ibesikpo-Asutan"],
    ["Ibiono-Ibom"],
    ["Ika"],
    ["Ikono"],
    ["Ikot Abasi"],
    ["Ikot Ekpene"],
    ["Ini"],
    ["Itu"],
    ["Mbo"],
    ["Mkpat-Enin"],
    ["Nsit-Atai"],
    ["Nsit Ubium"],
    ["Obot-Akara"],
    ["Okobo"],
    ["Onna"],
    ["Oron"],
    ["Oruk Anam"],
    ["Udung-Uko"],
    ["Ukanafun"],
    ["Uruan"],
    ["Urue-Offong/Oruko"],
    ["Uyo"],
    
  ]);


  const anambraLGA = new Map([

    ["Anambra East"],
    ["Anambra West"],
    ["Ayamelum"],
    ["Ogbaru"],
    ["Onitsha North"],
    ["Onitsha South"],
    ["Oyi"],
    ["Awka North"],
    ["Awka South"],
    ["Anaocha"],
    ["Dunukofia"],
    ["Idemili North"],
    ["Idemili South"],
    ["Njikoka"],
    ["Aguata"],
    ["Ekwusigo"],
    ["Ihiala"],
    ["Nnewi North"],
    ["Nnewi South"],
    ["Orumba South"],
    ["Orumba North"],
    
  ]);


  const bauchiLGA = new Map([
 
    ["Alkaleri"],
    ["Bauchi"],
    ["Bogoro"],
    ["Damban"],
    ["Darazo"],
    ["Dass"],
    ["Gamawa"],
    ["Ganjuwa"],
    ["Giade"],
    ["Itas/Gadau"],
    ["Jama'are"],
    ["Katagum"],
    ["Kirfi"],
    ["Misau"],
    ["Ningi"],
    ["Shira"],
    ["Tafawa Balewa"],
    ["Toro"],
    ["Warji"],
    ["Zaki"],
    
  ]);

  const bayelsaLGA = new Map([
 
    ["Brass"],
    ["Ekeremor"],
    ["Kolokuma/ Opokuma"],
    ["Nembe"],
    ["Ogbia"],
    ["Sagbama"],
    ["Southern Ijaw"],
    ["Yenagoa"],
  ]);
            

  const benueLGA = new Map([
            
    ["Apa"],
    ["Buruku"],
    ["Gboko"],
    ["Guma"],
    ["Gwer East"],
    ["Gwer West"],
    ["Katsina-Ala"],
    ["Konshisha"],
    ["Kwande"],
    ["Logo"],
    ["Makurdi"],
    ["Obi"],
    ["Ogbadibo"],
    ["Ohimini"],
    ["Okpokwu"],
    ["Oju"],
    ["Otukpo"],
    ["Tarka"],
    ["Ukum"],
    ["Ushongo"],
    ["Vandeikya"],
    
  ]);

  const bornoLGA = new Map([
            
    ["Abadam"],
    ["Askira/Uba"],
    ["Bama"],
    ["Bayo"],
    ["Biu"],
    ["Chibok"],
    ["Damboa"],
    ["Dikwa"],
    ["Gubio"],
    ["Guzamala"],
    ["Gwoza"],
    ["Hawul"],
    ["Jere"],
    ["Kaga"],
    ["Kala Balge"],
    ["Konduga"],
    ["Kukawa"],
    ["Kwaya Kusar"],
    ["Mafa"],
    ["Magumeri"],
    ["Maiduguri"],
    ["Marte"],
    ["Mobbar"],
    ["Monguno"],
    ["Ngala"],
    ["Nganzai"],
    ["Shani"],
    
  ]);


  const crossRiverLGA = new Map([
            
    ["Abi"],
    ["Akamkpa"],
    ["Akpabuyo"],
    ["Bakassi"],
    ["Bekwarra"],
    ["Biase"],
    ["Boki"],
    ["Calabar Municipal"],
    ["Calabar South"],
    ["Etung"],
    ["Ikom"],
    ["Obanliku"],
    ["Obubra"],
    ["Obudu"],
    ["Odukpani"],
    ["Ogoja"],
    ["Yakurr"],
    ["Yala"],
    
  ]);

  const deltaLGA = new Map([
            
    ["Burutu"],
    ["Ethiope East"],
    ["Ethiope West"],
    ["Ika North East"],
    ["Ika South"],
    ["Isoko North"],
    ["Isoko South"],
    ["Ndokwa East"],
    ["Ndokwa West"],
    ["Okpe"],
    ["Oshimili North"],
    ["Oshimili South"],
    ["Patani"],
    ["Sapele"],
    ["Udu"],
    ["Ughelli North"],
    ["Ughelli South"],
    ["Ukwuani"],
    ["Uvwie"],
    ["Warri North"],
    ["Warri South"],
    ["Warri South West"],
    
  ]);


  const ebonyiLGA = new Map([

    ["Abakaliki"],
    ["Afikpo North"],
    ["Afikpo South"],
    ["Ebonyi"],
    ["Ezza North"],
    ["Ezza South"],
    ["Ikwo"],
    ["Ishielu"],
    ["Ivo"],
    ["Izzi"],
    ["Ohaozara"],
    ["Ohaukwu"],
    ["Onicha"],
        
  ]);


  const edoLGA = new Map([
            
    ["Akoko-Edo"],
    ["Egor"],
    ["Esan Central"],
    ["Esan North-East"],
    ["Esan South-East"],
    ["Esan West"],
    ["Etsako Central"],
    ["Etsako East"],
    ["Etsako West"],
    ["Igueben"],
    ["Ikpoba-Okha"],
    ["Oredo"],
    ["Orhionmwon"],
    ["Ovia North-East"],
    ["Ovia South-west"],
    ["Owan East"],
    ["Owan West"],
    ["Uhunmwonde"],
    
  ]);


  const ekitiLGA = new Map([

    ["Ado-Ekiti"],
    ["Aiyekire"],
    ["Efon"],
    ["Ekiti East"],
    ["Ekiti South West"],
    ["Ekiti West"],
    ["Emure"],
    ["Ido-Osi"],
    ["Ijero"],
    ["Ikere"],
    ["Ikole"],
    ["Ilejumeje"],
    ["Irepodun/Ifelodun"],
    ["Ise/Orun"],
    ["Moba"],
    ["Oye"],
        
  ]);

  const enuguLGA = new Map([

    ["Aninri"],
    ["Awgu"],
    ["Enugu East"],
    ["Enugu North"],
    ["Enugu South"],
    ["Ezeagu"],
    ["Igbo Etiti"],
    ["Igbo Eze North"],
    ["Igbo Eze South"],
    ["Isi Uzo"],
    ["Nkanu East"],
    ["Nkanu West"],
    ["Nsukka"],
    ["Oji River"],
    ["Udenu"],
    ["Udi"],
    ["Uzo-Uwani"],
    
  ]);

  const fctAbujaLGA = new Map([

    ["Abaji"],
    ["Abuja Municipal"],
    ["Bwari"],
    ["Gwagwalada"],
    ["Kuje"],
    ["Kwali"],
    
  ]);

  const gombeLGA = new Map([
    
    ["Akko"],
    ["Balanga"],
    ["Billiri"],
    ["Dukku"],
    ["Funakaye"],
    ["Gombe"],
    ["Kaltungo"],
    ["Kwami"],
    ["Nafada/Bajoga"],
    ["Shomgom"],
    ["Yamaltu/Deba"],
        
  ]);

  const imoLGA = new Map([
            
    ["Aboh Mbaise"],
    ["Ahiazu Mbaise"],
    ["Ehime Mbano"],
    ["Ezinihitte"],
    ["Ideato North"],
    ["Ideato South"],
    ["Ihitte/Uboma"],
    ["Ikeduru"],
    ["Isiala Mbano"],
    ["Isu"],
    ["Mbaitoli"],
    ["Ngor Okpala"],
    ["Njaba"],
    ["Nkwerre"],
    ["Nwangele"],
    ["Obowo"],
    ["Oguta"],
    ["Ohaji/Egbema"],
    ["Okigwe"],
    ["Onuimo"],
    ["Orlu"],
    ["Orsu"],
    ["Oru East"],
    ["Oru West"],
    ["Owerri Municipal"],
    ["Owerri North"],
    ["Owerri West"],
        
  ]);


  const jigawaLGA = new Map([
            
    ["Auyo"],
    ["Babura"],
    ["Biriniwa"],
    ["Birnin Kudu"],
    ["Buji"],
    ["Dutse"],
    ["Gagarawa"],
    ["Garki"],
    ["Gumel"],
    ["Guri"],
    ["Gwaram"],
    ["Gwiwa"],
    ["Hadejia"],
    ["Jahun"],
    ["Kafin Hausa"],
    ["Kaugama"],
    ["Kazaure"],
    ["Kiri Kasama"],
    ["Kiyawa"],
    ["Maigatari"],
    ["Malam Madori"],
    ["Miga"],
    ["Ringim"],
    ["Roni"],
    ["Sule Tankarkar"],
    ["Taura"],
    ["Yankwashi"],
    
  ]);

  const kadunaLGA = new Map([

    ["Birnin Gwari"],
    ["Chikun"],
    ["Giwa"],
    ["Igabi"],
    ["Ikara"],
    ["Jaba"],
    ["Jema'a"],
    ["Kachia"],
    ["Kaduna North"],
    ["Kaduna South"],
    ["Kagarko"],
    ["Kajuru"],
    ["Kaura"],
    ["Kauru"],
    ["Kubau"],
    ["Kudan"],
    ["Lere"],
    ["Makarfi"],
    ["Sabon Gari"],
    ["Sanga"],
    ["Soba"],
    ["Zangon Kataf"],
    ["Zaria"],
        
  ]);

  const kanoLGA = new Map([
            
    ["Ajingi"],
    ["Albasu"],
    ["Bagwai"],
    ["Bebeji"],
    ["Bichi"],
    ["Bunkure"],
    ["Dala"],
    ["Dambatta"],
    ["Dawakin Kudu"],
    ["Dawakin Tofa"],
    ["Doguwa"],
    ["Fagge"],
    ["Gabasawa"],
    ["Garko"],
    ["Garun Mallam"],
    ["Gaya"],
    ["Gezawa"],
    ["Gwale"],
    ["Gwarzo"],
    ["Kabo"],
    ["Kano Municipal"],
    ["Karaye"],
    ["Kibiya"],
    ["Kiru"],
    ["Kumbotso"],
    ["Kunchi"],
    ["Kura"],
    ["Madobi"],
    ["Makoda"],
    ["Minjibir"],
    ["Nasarawa"],
    ["Rano"],
    ["Rimin Gado"],
    ["Rogo"],
    ["Shanono"],
    ["Sumaila"],
    ["Takai"],
    ["Tarauni"],
    ["Tofa"],
    ["Tsanyawa"],
    ["Tudun Wada"],
    ["Ungogo"],
    ["Warawa"],
    ["Wudil"],   
    
  ]);

  const katsinaLGA = new Map([
            
    ["Bakori"],
    ["Batagarawa"],
    ["Batsari"],
    ["Baure"],
    ["Bindawa"],
    ["Charanchi"],
    ["Dandume"],
    ["Danja"],
    ["Dan Musa"],
    ["Daura"],
    ["Dutsi"],
    ["Dutsin Ma"],
    ["Faskari"],
    ["Funtua"],
    ["Ingawa"],
    ["Jibia"],
    ["Kafur"],
    ["Kaita"],
    ["Kankara"],
    ["Kankia"],
    ["Katsina"],
    ["Kurfi"],
    ["Kusada"],
    ["Mai'Adua"],
    ["Malumfashi"],
    ["Mani"],
    ["Mashi"],
    ["Matazu"],
    ["Musawa"],
    ["Rimi"],
    ["Sabuwa"],
    ["Safana"],
    ["Sandamu"],
    ["Zango"],
    
  ]);
  const kebbiLGA = new Map([

    ["Aleiro"],
    ["Arewa Dandi"],
    ["Argungu"],
    ["Augie"],
    ["Bagudo"],
    ["Birnin Kebbi"],
    ["Dandi"],
    ["Fakai"],
    ["Gwandu"],
    ["Jega"],
    ["Kalgo"],
    ["Koko/Besse"],
    ["Maiyama"],
    ["Ngaski"],
    ["Sakaba"],
    ["Shanga"],
    ["Suru"],
    ["Wasagu/Danko"],
    ["Yauri"],
    ["Zuru"],
        
  ]);

  const kogiLGA = new Map([

    ["Adavi"],
    ["Ajaokuta"],
    ["Ankpa"],
    ["Bassa"],
    ["Dekina"],
    ["Ibaji"],
    ["Idah"],
    ["Igalamela Odolu"],
    ["Ijumu"],
    ["Kabba/Bunu"],
    ["Kogi"],
    ["Lokoja"],
    ["Mopa Muro"],
    ["Ofu"],
    ["Ogori/Magongo"],
    ["Okehi"],
    ["Okene"],
    ["Olamaboro"],
    ["Omala"],
    ["Yagba East"],
    ["Yagba West"],
    
  ]);
  const kwaraLGA = new Map([
            
    ["Asa"],
    ["Baruten"],
    ["Edu"],
    ["Ekiti"],
    ["Ifelodun"],
    ["Ilorin East"],
    ["Ilorin South"],
    ["Ilorin West"],
    ["Irepodun"],
    ["Isin"],
    ["Kaiama"],
    ["Moro"],
    ["Offa"],
    ["Oke Ero"],
    ["Oyun"],
    ["Pategi"],
        
  ]);

  const lagosLGA = new Map([

    ["Agege"],
    ["Ajeromi/Ifelodun"],
    ["Alimosho"],
    ["Amuwo Odofin"],
    ["Apapa"],
    ["Badagry"],
    ["Epe"],
    ["Eti Osa"],
    ["Ibeju/Lekki"],
    ["Ifako/Ijaye"],
    ["Ikeja"],
    ["Ikorudu"],
    ["Kosofe"],
    ["Lagos Island"],
    ["Lagos Mainland"],
    ["Mushin"],
    ["Ojo"],
    ["Oshodi/Isolo"],
    ["Shomolu"],
    ["Surulere"],
    
  ]);


  const nasarawaLGA = new Map([

    ["Akwanga"],
    ["Awe"],
    ["Doma"],
    ["Karu"],
    ["Keana"],
    ["Keffi"],
    ["Kokona"],
    ["Lafia"],
    ["Nasarawa"],
    ["Nasarawa/Eggon"],
    ["Obi"],
    ["Toto"],
    ["Wamba"],
    
  ]);

  const nigerLGA = new Map([
            
    ["Agaie"],
    ["Agwara"],
    ["Bida"],
    ["Borgu"],
    ["Bosso"],
    ["Chanchaga"],
    ["Edati"],
    ["Gbako"],
    ["Gurara"],
    ["Katcha"],
    ["Kontagora"],
    ["Lapai"],
    ["Lavun"],
    ["Magama"],
    ["Mariga"],
    ["Mashegu"],
    ["Mokwa"],
    ["Moya"],
    ["Paikoro"],
    ["Rafi"],
    ["Rijau"],
    ["Shiroro"],
    ["Suleja"],
    ["Tafa"],
    ["Wushishi"],
    
  ]);
  const ogunLGA = new Map([
            
    ["Abeokuta North"],
    ["Abeokuta South"],
    ["Ado-Odo/Ota"],
    ["Egbado North (Yewa North)"],
    ["Egbado South (Yewa South)"],
    ["Ewekoro"],
    ["Ifo"],
    ["Ijebu East"],
    ["Ijebu North"],
    ["Ijebu North East"],
    ["Ijebu Ode"],
    ["Ikenne"],
    ["Imeko Afon"],
    ["Ipokia"],
    ["Obafemi/Owode"],
    ["Odeda"],
    ["Odogbolu"],
    ["Ogun Waterside"],
    ["Remo North"],
    ["Sagamu"],
       
  ]);

  const ondoLGA = new Map([
            
    ["Akoko North-East"],
    ["Akoko North-West"],
    ["Akoko South-East"],
    ["Akoko South-West"],
    ["Akure North"],
    ["Akure South"],
    ["Ese/Odo"],
    ["Idanre"],
    ["Ifedore"],
    ["Ilaje"],
    ["Ile Oluji/Okeigbo"],
    ["Irele"],
    ["Odigbo"],
    ["Okitipupa"],
    ["Ondo East"],
    ["Ondo West"],
    ["Ose"],
    ["Owo"],
    
  ]);
  const osunLGA = new Map([
    ["Aiyedaade"],
    ["Aiyedire"],
    ["Atakunmosa East"],
    ["Atakunmosa West"],
    ["Boluwaduro"],
    ["Boripe"],
    ["Ede North"],
    ["Ede South"],
    ["Egbedore"],
    ["Ejigbo"],
    ["Ife Central"],
    ["Ife East"],
    ["Ife North"],
    ["Ife South"],
    ["Ifedayo"],
    ["Ifelodun"],
    ["Ila"],
    ["Ilesa East"],
    ["Ilesa West"],
    ["Irepodun"],
    ["Irewole"],
    ["Isokan"],
    ["Iwo"],
    ["Obokun"],
    ["Odo-Otin"],
    ["Ola-Oluwa"],
    ["Olorunda"],
    ["Oriade"],
    ["Orolu"],
    ["Osogbo"],
    
  ]);

  const oyoLGA = new Map([
            
    ["Afijio"],
    ["Akinyele"],
    ["Atiba"],
    ["Atigbo"],
    ["Egbeda"],
    ["Ibadan North"],
    ["Ibadan North-East"],
    ["Ibadan North-West"],
    ["Ibadan South-East"],
    ["Ibadan South-West"],
    ["Ibarapa Central"],
    ["Ibarapa East"],
    ["Ibarapa North"],
    ["Ido"],
    ["Irepo"],
    ["Iseyin"],
    ["Itesiwaju"],
    ["Iwajowa"],
    ["Kajola"],
    ["Lagelu"],
    ["Ogbomosho North"],
    ["Ogbomosho South"],
    ["Ogo Oluwa"],
    ["Olorunsogo"],
    ["Oluyole"],
    ["Ona-Ara"],
    ["Orelope"],
    ["Ori Ire"],
    ["Oyo East"],
    ["Oyo West"],
    ["Saki East"],
    ["Saki West"],
    ["Surulere"],
    
  ]);
  const plateauLGA = new Map([

    ["Bokkos"],
    ["Barkin Ladi"],
    ["Bassa"],
    ["Jos East"],
    ["Jos North"],
    ["Jos South"],
    ["Kanam"],
    ["Kanke"],
    ["Langtang North"],
    ["Langtang South"],
    ["Mangu"],
    ["Mikang"],
    ["Pankshin"],
    ["Qua'an Pan"],
    ["Riyom"],
    ["Shendam"],
    ["Wase"],
    
  ]);

  const riversLGA = new Map([

    ["Abua/Odual"],
    ["Ahoada East"],
    ["Ahoada West"],
    ["Akuku-Toru"],
    ["Andoni"],
    ["Asari-Toru"],
    ["Bonny"],
    ["Degema"],
    ["Eleme"],
    ["Emuoha"],
    ["Etche"],
    ["Gokana"],
    ["Ikwerre"],
    ["Khana"],
    ["Obio/Akpor"],
    ["Ogba/Egbema/Ndoni"],
    ["Ogu/Bolo"],
    ["Okrika"],
    ["Omumma"],
    ["Opobo/Nkoro"],
    ["Oyigbo"],
    ["Port Harcourt"],
    ["Tai"],
    
  ]);

  const sokotoLGA = new Map([

    ["Binji"],
    ["Bodinga"],
    ["Dange Shuni"],
    ["Gada"],
    ["Goronyo"],
    ["Gudu"],
    ["Gwadabawa"],
    ["Illela"],
    ["Isa"],
    ["Kebbe"],
    ["Kware"],
    ["Rabah"],
    ["Sabon Birni"],
    ["Shagari"],
    ["Silame"],
    ["Sokoto North"],
    ["Sokoto South"],
    ["Tambuwal"],
    ["Tangaza"],
    ["Tureta"],
    ["Wamakko"],
    ["Wurno"],
    ["Yabo"],
    
  ]);

  const tarabaLGA = new Map([
            
    ["Ardo Kola"],
    ["Bali"],
    ["Donga"],
    ["Gashaka"],
    ["Gassol"],
    ["Ibi"],
    ["Jalingo"],
    ["Karim Lamido"],
    ["Kurmi"],
    ["Lau"],
    ["Sardauna"],
    ["Takum"],
    ["Ussa"],
    ["Wukari"],
    ["Yorro"],
    ["Zing"],
    
  ]);


  const yobeLGA = new Map([
    
    ["Bade"],
    ["Bursari"],
    ["Damaturu"],
    ["Fika"],
    ["Fune"],
    ["Geidam"],
    ["Gujba"],
    ["Gulani"],
    ["Jakusko"],
    ["Karasuwa"],
    ["Machina"],
    ["Nangere"],
    ["Nguru"],
    ["Potiskum"],
    ["Tarmuwa"],
    ["Yunusari"],
    ["Yusufari"],
    
  ]);

  const zamfaraLGA = new Map([

    ["Anka"],
    ["Bakura"],
    ["Birnin Magaji/Kiyaw"],
    ["Bukkuyum"],
    ["Bungudu"],
    ["Gummi"],
    ["Gusau"],
    ["Kaura Namoda"],
    ["Maradun"],
    ["Maru"],
    ["Shinkafi"],
    ["Talata Mafara"],
    ["Tsafe"],
    ["Zurmi"],
    
  ]);

  export const nigeriaLGAs = {
    
    "Abia": abiaLGA,
    "Adamawa": adamawaLGA,
    "Akwa Ibom": akwaIbomLGA,
    "Anambra": anambraLGA,
    "Bauchi": bauchiLGA,
    "Bayelsa": bayelsaLGA,
    "Benue": benueLGA,
    "Borno": bornoLGA,
    "Cross River": crossRiverLGA,
    "Delta": deltaLGA,
    "Ebonyi": ebonyiLGA,
    "Edo": edoLGA,
    "Ekiti": ekitiLGA,
    "Enugu": enuguLGA,
    "FCT-Abuja": fctAbujaLGA,
    "Gombe": gombeLGA,
    "Imo": imoLGA,
    "Jigawa": jigawaLGA,
    "Kaduna": kadunaLGA,
    "Kano": kanoLGA,
    "Katsina": katsinaLGA,
    "Kebbi": kebbiLGA,
    "Kogi": kogiLGA,
    "Kwara": kwaraLGA,
    "Lagos": lagosLGA,
    "Nasarawa": nasarawaLGA,
    "Niger": nigerLGA,
    "Ogun": ogunLGA,
    "Ondo": ondoLGA,
    "Osun": osunLGA,
    "Oyo": oyoLGA,
    "Plateau": plateauLGA,
    "Rivers": riversLGA,
    "Sokoto": sokotoLGA,
    "Taraba": tarabaLGA,
    "Yobe": yobeLGA,
    "Zamfara": zamfaraLGA,
    
  };
