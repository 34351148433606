import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { formatCurrency } from "../utils/functions";
import { requestForToken, onMessageListener } from "../firebase";
import { Logo } from "../assests/images";

import "./styles.scss";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [amount, setAmount] = useState();
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div className="container">
        <img src={Logo} alt="" />
        <div className="message--content">
          <p>
            <b>{notification?.title}</b>
          </p>
          <p>{notification?.body}</p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title && amount) {
      notify();
      setAmount();
    }
  }, [notification]);

  requestForToken();

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event?.data?.data?.type === "1") {
          setAmount(event?.data?.data?.amount);
          setNotification({
            title: "Payment Received",
            body: `You have recieved a payment of ${formatCurrency(
              event?.data?.data?.amount
            )} from ${event?.data?.data?.name}`,
          });
        } else if (event?.data?.data?.type === "5") {
          setAmount(event?.data?.data?.settleAmount);
          setNotification({
            title: "Payment Received",
            body: `You have recieved a payment of ${formatCurrency(
              event?.data?.data?.settleAmount
            )} from ${event?.data?.data?.settleAccountName}`,
          });
        }
      },
      true
    );
  }, []);

  onMessageListener()
    .then((payload) => {
      console.log("payloadNotification", payload);
    })
    .catch((err) => console.log("failed: ", err));
  return <Toaster position="top-right" />;
};

export default Notification;
