export const paymentMethods = new Map([
  ["Dynamic QR", "T1002"],
  ["Fixed QR", "T1003"],
  ["Bank Transfer", "T1004"],
]);

export const reversePaymentMethods = new Map([
  ["T1002", "Dynamic QR"],
  ["T1003", "Fixed QR"],
  ["T1004", "Bank Transfer"],  
  ["T1005", "Transfer (Online)"], 
  ["Static Virtual Account", "Bank Transfer"],

  
]);

export const paymentMethodList = Array.from(paymentMethods.keys());
