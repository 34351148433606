import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Notify } from "../../components/notification";

const MenuItem = ({ item }) => {
  const location = useLocation();
  const history = useHistory();

  const isMatch = (keyword) => {
   return location.pathname.split("/").includes(keyword)
  }

  const checkInternet = (pageUrl) => {
     if (navigator.onLine) {
      history.push(pageUrl);
      } else {
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
      }
  }

  return (
    <>
      {item.route ? (
        <a className="link3">
          <li
            className={
              isMatch(item.keyWord)
                ? "list-items active"
                : "list-items"
            }
             onClick={(node, event) =>checkInternet(item.route)}
          >
            <span className="sidebar-Icon">{item.icon}</span>
            {item.name}
          </li>
         </a>
      ) : ( null
      )}
    </>
  );
};

export default MenuItem;


