import React from "react";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";

import "./styles.scss";

export const NotificationPage = () => {
  return (
    <Layout>
      <header>
        <main className="main--notify">
          <h1 className="no-notification">Notifications</h1>
          <section className="notify__content">
            <aside>
              <div className="notify-form">
                <h2 className="payment__successful">Payment successful</h2>
                <hr />
                <div className="details__part">
                  <h5 className="payer">OBIMKPU IFENNA STANLEY</h5>
                  <h6 className="amount">
                    &#8358;<span>10,000</span>
                  </h6>

                  <div className="qr-type">
                    <h5 className="payment--method">Fixed QR</h5>
                    <h5>
                      <span className="date__string">
                        {new Date().toDateString()}
                      </span>
                      <span>{new Date().toLocaleTimeString()}</span>
                    </h5>
                  </div>
                  <hr />
                  <Link to="/dashboard/merchant/transaction">
                    <h5 className="payer__details">Payment details</h5>
                  </Link>
                </div>
              </div>

              <br />

              <div className="notify-form">
                <h2 className="payment__successful">Payment successful</h2>
                <hr />
                <div className="details__part">
                  <h5 className="payer">James John</h5>
                  <h6 className="amount">
                    &#8358;<span>250,000</span>
                  </h6>

                  <div className="qr-type">
                    <h5 className="payment--method">Dynamic QR</h5>
                    <h5>
                      <span className="date__string">
                        {new Date().toDateString()}
                      </span>
                      <span>{new Date().toLocaleTimeString()}</span>
                    </h5>
                  </div>
                  <hr />
                  <Link to="/dashboard/merchant/transaction">
                    <h5 className="payer__details">Payment details</h5>
                  </Link>
                </div>
              </div>
            </aside>
          </section>
        </main>
      </header>
    </Layout>
  );
};
