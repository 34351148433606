// Authentication
import { LoginPage } from "../pages/login";
import { ForgotPage } from "../pages/forgot";
import { RecoverPasswordPage } from "../pages/recoverpassword";
import { VerificationPage } from "../pages/verification";
import { RegistrationPage } from "../pages/registration";

// Admin
import { HomePage } from "../pages/home";
import { MerchantHomePage } from "../pages/merchant/home";
import { BusinessDetailsPage } from "../pages/businessdetails";
import { PersonalInformationPage } from "../pages/personalinformation";
import { BankDetailsPage } from "../pages/bankdetails";
import { DocumentUploadPage } from "../pages/documentupload";
import { OnboardingPage } from "../pages/onboarding";
import { AcceptPaymentPage } from "../pages/acceptpayment";
import { DynamicPaymentPage } from "../pages/dynamicpayment";
import { PaymentStandPage } from "../pages/paymentstand";
import { PersonalProfilePage } from "../pages/personalprofile";
import { TransactionPage } from "../pages/transactions";
import { BusinessProfilePage } from "../pages/businessprofile";
import { BankProfilePage } from "../pages/bankprofile";
import { TransactionStatusPage } from "../pages/transactionstatus";
import { SubAccountPage } from "../pages/subaccount";
import { SubAccountDetailsPage } from "../pages/subaccountdetails";
import { SubPaymentStickerPage } from "../pages/subaccountpaymentsticker";
import { NotificationPage } from "../pages/notification";
import { SelectBusinessAccountPage } from "../pages/selectbusinessaccount";
import { AddSubAccountPage } from "../pages/addsubaccount/AddSubAccount";
import { CreateSubAccountPage } from "../pages/createsubaccount/CreateSubAccount";
import { SettlementPage } from "../pages/settlement";
import { AccountSettingsPage } from "../pages/accountsettings";
import { SettlementDetailsPage } from "../pages/settlementdetails";
import { FeePaymentPage } from "../pages/feepayment";
import { ClassLinkPage } from "../pages/classes";
import { FeesLinkPage } from "../pages/fees";
import { FeePaymentDetailsPage } from "../pages/feepaymentdetails/FeePaymentDetails";
import { NotFoundPage } from "../pages/not-found-page";

// Manager
import { ManagerHomePage } from "../pages/manager/home";
import { ManagerProfilePage } from "../pages/manager/pages/managerprofile";
import { ManagerAcceptPayment } from "../pages/manager/pages/acceptpayment";
import { ManagerTransactionPage } from "../pages/manager/pages/transactions/ManagerTransactions";
import { ManagerTransactionStatusPage } from "../pages/manager/pages/managertransactionstatus";
import { ManagerDynamicPaymentPage } from "../pages/manager/pages/dynamicpayment/ManagerDynamicPayment";
import { ManagerPaymentStandPage } from "../pages/manager/pages/managerpaymentstand";
import { ManagerSubAccountPage } from "../pages/manager/pages/managersubaccount/ManagerSubAccount";
import { ManagerSubAccountDetailsPage } from "../pages/manager/pages/managersubaccountdetails";
import { ManagerAddSubAccountPage } from "../pages/manager/pages/manageraddsubaccount";
import { ManagerBusinessProfilePage } from "../pages/manager/pages/managerbusinessprofile";
import { ManagerBankProfilePage } from "../pages/manager/pages/managerbankprofile/ManagerBankProfile";
import { ManagerAccountSettingsPage } from "../pages/manager/pages/manageraccountsettings";
import { ManagerCreateSubAccountPage } from "../pages/manager/pages/managercreatesubaccount";
import { ManagerSubPaymentStickerPage } from "../pages/manager/pages/managersubaccountpaymentsticker/ManagerSubPaymentSticker";

// Supervisor
import { SupervisorHomePage } from "../pages/supervisor/home";
import { AcceptPayments } from "../pages/supervisor/pages/acceptpayment";
import { SupervisorTransactionPage } from "../pages/supervisor/pages/transactions";
import { SupervisorProfilePage } from "../pages/supervisor/pages/supervisorprofile";
import { SupervisorBusinessProfilePage } from "../pages/supervisor/pages/supervisorbusinessprofile";
import { SupervisorTransactionStatusPage } from "../pages/supervisor/pages/supervisortransactionstatus";
import { SupervisorDynamicPaymentPage } from "../pages/supervisor/pages/dynamicpayment";
import { SupervisorPaymentStandPage } from "../pages/supervisor/pages/supervisorpaymentstand";
import { SupervisorBankProfilePage } from "../pages/supervisor/pages/supervisorbankprofile";
import { SupervisorAccountSettingsPage } from "../pages/supervisor/pages/supervisoraccountsettings/SupervisorAccountSettings";
import { SupervisorFeePaymentPage } from "../pages/supervisor/pages/supervisorfeepayment";
import { SupervisorFeePaymentDetailsPage } from "../pages/supervisor/pages/supervisorfeepaymentdetails";

// Cashier
import { AcceptPayment } from "../pages/cashier/pages/acceptpayment";
import { CashierTransactionPage } from "../pages/cashier/pages/transactions";
import { CashierProfilePage } from "../pages/cashier/pages/cashierprofile";
import { CashierBusinessProfilePage } from "../pages/cashier/pages/cashierbusinessprofile";
import { CashierHomePage } from "../pages/cashier/home";
import { CashierTransactionStatusPage } from "../pages/cashier/pages/cashiertransactionstatus";
import { CashierDynamicPaymentPage } from "../pages/cashier/pages/dynamicpayment";
import { CashierPaymentStandPage } from "../pages/cashier/pages/cashierpaymentstand";
import { CashierBankProfilePage } from "../pages/cashier/pages/cashierbankprofile";
import { CashierAccountSettingsPage } from "../pages/cashier/pages/cashieraccountsettings/CashierAccountSettings";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  public: [
    {
      exact: true,
      path: "/",
      component: LoginPage,
    },
    {
      exact: true,
      path: "/login",
      component: LoginPage,
    },
    {
      exact: true,
      path: "/registration",
      component: RegistrationPage,
    },
    {
      exact: true,
      path: "/verification",
      component: VerificationPage,
    },
    {
      exact: true,
      path: "/forgot-password",
      component: ForgotPage,
    },
    {
      exact: true,
      path: "/password-reset",
      component: RecoverPasswordPage,
    },
  ],

  private: [
    // Admin
    {
      exact: true,
      path: "/dashboard/merchant/overview",
      component: MerchantHomePage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/onboarding",
      component: OnboardingPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/onboarding/business-details",
      component: BusinessDetailsPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/onboarding/personal-information",
      component: PersonalInformationPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/onboarding/bank-details",
      component: BankDetailsPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/onboarding/document-upload",
      component: DocumentUploadPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/accept-payment",
      component: AcceptPaymentPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/payment-link",
      component: FeePaymentPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/payment-link/fees",
      component: FeesLinkPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/payment-link/classes",
      component: ClassLinkPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/payment-link/:linkId/:linkCode",
      component: FeePaymentDetailsPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/accept-payment/dynamic/:qrCode",
      component: DynamicPaymentPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/transactions",
      component: TransactionPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/transactions/:orderNo",
      component: TransactionStatusPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/sub-accounts",
      component: SubAccountPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/sub-accounts/paymentstand/:emvcoCode/:terminalCode/:gtAccNo/:providusVirtualAcc",
      component: SubPaymentStickerPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/sub-accounts/add/:terminalCode",
      component: AddSubAccountPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/sub-accounts/create",
      component: CreateSubAccountPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/sub-accounts/:chlTermNo/:phoneNo/",
      component: SubAccountDetailsPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/notification",
      component: NotificationPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/settings/payment-stand",
      component: PaymentStandPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/settings",
      component: PersonalProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/settings/bank",
      component: BankProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/settings/business",
      component: BusinessProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/select-business-account",
      component: SelectBusinessAccountPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/settlement",
      component: SettlementPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/settlement/details/:settleBatchNo",
      component: SettlementDetailsPage,
    },
    {
      exact: true,
      path: "/dashboard/merchant/settings/accounts",
      component: AccountSettingsPage,
    },

    // Manager
    {
      exact: true,
      path: "/dashboard/manager/overview",
      component: ManagerHomePage,
    },
    {
      exact: true,
      path: "/dashboard/manager/settings",
      component: ManagerProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/manager/settings/business",
      component: ManagerBusinessProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/manager/accept-payment",
      component: ManagerAcceptPayment,
    },
    {
      exact: true,
      path: "/dashboard/manager/transactions",
      component: ManagerTransactionPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/transactions/:orderNo",
      component: ManagerTransactionStatusPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/accept-payment/dynamic/:qrCode",
      component: ManagerDynamicPaymentPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/settings/payment-stand",
      component: ManagerPaymentStandPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/sub-accounts",
      component: ManagerSubAccountPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/sub-accounts/:chlTermNo/:phoneNo/",
      component: ManagerSubAccountDetailsPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/sub-accounts/paymentstand/:emvcoCode/:terminalCode/:gtAccNo/:providusVirtualAcc",
      component: ManagerSubPaymentStickerPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/settings/bank",
      component: ManagerBankProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/manager/settings/accounts",
      component: ManagerAccountSettingsPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/add-sub-account/:terminalCode",
      component: ManagerAddSubAccountPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/sub-accounts/create",
      component: ManagerCreateSubAccountPage,
    },
    {
      exact: true,
      path: "/dashboard/manager/add-sub-account",
      component: ManagerAddSubAccountPage,
    },

    // Supervisor
    {
      exact: true,
      path: "/dashboard/supervisor/overview",
      component: SupervisorHomePage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/accept-payment",
      component: AcceptPayments,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/payment-link",
      component: SupervisorFeePaymentPage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/payment-link/:linkId/:linkCode",
      component: SupervisorFeePaymentDetailsPage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/transactions",
      component: SupervisorTransactionPage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/settings",
      component: SupervisorProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/settings/business",
      component: SupervisorBusinessProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/accept-payment/dynamic/:qrCode",
      component: SupervisorDynamicPaymentPage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/transactions/:orderNo",
      component: SupervisorTransactionStatusPage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/settings/payment-stand",
      component: SupervisorPaymentStandPage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/settings/bank",
      component: SupervisorBankProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/supervisor/settings/accounts",
      component: SupervisorAccountSettingsPage,
    },


    // Cashier
    {
      exact: true,
      path: "/dashboard/cashier/overview",
      component: CashierHomePage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/accept-payment",
      component: AcceptPayment,
    },
    {
      exact: true,
      path: "/dashboard/cashier/accept-payment",
      component: AcceptPaymentPage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/transactions",
      component: CashierTransactionPage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/settings",
      component: CashierProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/settings/business",
      component: CashierBusinessProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/accept-payment/dynamic/:qrCode",
      component: CashierDynamicPaymentPage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/transactions/:orderNo",
      component: CashierTransactionStatusPage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/settings/payment-stand",
      component: CashierPaymentStandPage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/settings/bank",
      component: CashierBankProfilePage,
    },
    {
      exact: true,
      path: "/dashboard/cashier/settings/accounts",
      component: CashierAccountSettingsPage,
    },

    // Not FoundPage
    {
      path: "*",
      component: NotFoundPage,
    },
  ],
};
