import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Notify } from "../../components/notification";

import "./styles.scss";

export const SettlementTable = ({ column, data }) => {
  const history = useHistory();

  const handleRowClick = (settlement) => {
    if (navigator.onLine) {
      history.push(`/dashboard/merchant/settlement/details/${settlement.settleBatchNo.replace(/\//g, "-")}`);
      } else {
        Notify(
          "error",
          "Network Error!",
          "Please check your internet connection!",
          10
        );
      }
    
  };

  return (
    <>
      <div id="table-container">
        <table className="transaction-tabs">
          <thead>
            {column.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </thead>
          <tbody>
            {data.map((settlement, i) => (
              <tr key={i}
              onClick={(node, event) => handleRowClick(settlement)}
              >
                <td>
                  <a
                    className="view bolder__one"
                  >
                    <div className="bank-details">
                      <span className="">{settlement.bankName}</span>
                      <span className="bank-No">
                        {settlement.settleAccountNo}
                      </span>
                    </div>
                  </a>
                </td>
                <td id="font_change" className="bolder__one">
                  <a
                    className="view bolder__one"
                  >
                    {settlement.collectedAmount}
                  </a>
                </td>
                <td id="font_change" className="bolder__one">
                  <a
                    className="view bolder__one"
                  >
                    {settlement.settleAmount}
                  </a>
                </td>
                <td>
                  <a
                    className="view"
                  >
                    {settlement.settleBatchNo}
                  </a>
                </td>
                <td>
                  <a
                    className="view"
                  >
                    {settlement.dateDeposited}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
