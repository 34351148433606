import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { BiCircle } from "react-icons/bi";
import list from "./list";
import storage from "../../utils/storage";

import "./styles.scss";

export const OnboardingList = () => {
  const location = useLocation();
  const [activeOnboardingPage, setActiveOnboardingPage] = useState(1);

  useEffect(() => {
    const index = list.findIndex((page) => page.route === location.pathname);
    setActiveOnboardingPage(index + 1);
  }, [location]);

  const isActive = (route) => {
    return route === location.pathname;
  };

  const isChecked = (storedData) => {
    if (storedData) {
      const data = storage.fetch(storedData);
      if (data) return true;
      return false;
    }
    return false;
  };

  return (
    <div className="board-wrap">
      <h3>Onboarding</h3>
      <p>{`${activeOnboardingPage}/${list.length}`} Complete</p>
      <ul>
        {list.map((page, i) => (
          <li
            className={isActive(page?.route) && "onboarding-list-item"}
            key={i}
          >
            <span>
              {page?.route === "/dashboard/merchant/onboarding" ? (
                <>
                <Link to={page.route} className="link4">
                <FaCheckCircle className="check-Icon" />
                </Link>
                </>
              ) : (
                <>
                  {isChecked(page?.data) ? (
                    <>
                    <Link to={page.route} className="link4">
                    <FaCheckCircle className="check-Icon" />
                    </Link>
                    </>
                  ) : (
                    <BiCircle className="circle-Icon" />
                  )}
                </>
              )}
            </span>
            <span className="text_list">
              {page?.route === "/dashboard/merchant/onboarding" ? (
                <>
                <Link to={page.route} className="link4">
                  {page?.title}
                </Link>
                </>
              ) : (
                <>
                  {isChecked(page?.data) ? (
                    <>
                    <Link to={page.route} className="link4">
                    {page?.title}
                    </Link>
                    </>
                  ) : (
                    <>
                      {page?.title}
                    </>
                  )}
                </>
              )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};




