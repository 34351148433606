export const linkTypes = new Map([
    ["Collect Payment", 1],
    ["School Fees", 2],
    ["Donation", 3],
  ]);
  
  export const reverseLinkTypes = new Map([
    [1, "Collect Payment"],
    [2, "School Fees"],
    [3, "Donation"],
  ]);
  
  export const linkTypeList = Array.from(linkTypes.keys());
  