import storage from "../utils/storage";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fullName: () => {
    const userDetails = JSON.parse(storage.fetch("userDetails"));
    return `${userDetails?.firstName} ${userDetails.surname}`;
  },

  firstName: () => {
    const userDetails = JSON.parse(storage.fetch("userDetails"));
    return userDetails?.firstName;
  },

  surname: () => {
    const userDetails = JSON.parse(storage.fetch("userDetails"));
    return userDetails?.surname.toUpperCase();
  },

  avatar: () => {
    const userDetails = JSON.parse(storage.fetch("userDetails"));
    return userDetails?.avatar;
  },

  phoneNo: () => {
    const userDetails = JSON.parse(storage.fetch("userDetails"));
    return userDetails?.phoneNo;
  },
};
