export const periods = new Map([
  ["Today", "CURRENT_DAY"],
  ["7 days", "ONE_WEEK"],
  ["1 month", "ONE_MONTH"],
  ["3 months", "THREE_MONTH"],
  ["6 months", "SIX_MONTH"],
  ["All","ALL"],
]);

export const periodList = Array.from(periods.keys());

export const periodss = new Map([
  ["All","ALL"],
]);

export const periodLists = Array.from(periodss.keys());