import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Close } from "../../../assests/svgs";
import { Spinner } from "../../spinner";
import useRegister from "../../../hooks/useRegister";
import storage from "../../../utils/storage";
import { roleIds } from "../../../utils/constants/roleIds";
import { useCreateSubAccount } from "../../../hooks/useSubAccount";
import { Modal } from "antd";
import { formatPhoneNo } from "../../../utils/functions";
import { VerifyPhoneIcon } from "../../../assests/icons/Icons";

import "./styles.scss";
import { SpinnerWhite } from "../../spinner/Spinner";

function VerifyModal({ showModal, setShowModal }) {
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const [roleId, setRoleId] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [surname, setSurname] = useState("");
  const [smsAlert, setSmsAlert] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [validCode, setValidCode] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const [terminalCode, setTerminalCode] = useState("");
  const [parentPhoneNo, setParentPhoneNo] = useState("");
  const [clearCounter, setClearCounter] = useState("");
  const [apiResponseError, setApiResponseError] = useState("");
  const [isCounterDone, setIsCounterDone] = useState(true);
  const [isResendDone, setIsResendDone] = useState(false);
  const history = useHistory();
  var [minutes, setMinutes] = useState("1");
  var [seconds, setSeconds] = useState("00");
  var [inval, setInval] = useState("");

  const {
    isLoading: isVerifying,
    data,
    mutate: fetchingSubAccount,
  } = useCreateSubAccount({
    cusId,
    phoneNo,
    firstName,
    surname,
    validCode,
    roleId,
    smsAlert,
    parentPhoneNo,
    password,
    terminalName,
    terminalCode,
  });

  const {
    isLoading: isSendingCode,
    data: resendCodeData,
    mutate: sendCode,
  } = useRegister({
    phoneNo,
    templateId: "M1001",
  });

  // Form Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {

    if(showModal){

      const subAccountPayload = JSON.parse(
        storage.fetch("userSubAccountPayload")
      );
      setPhoneNo(subAccountPayload.phoneNo);
      setIsCounterDone(true);
      setIsResendDone(false);
      startCounter();
    }
    
  }, [showModal]);

  const startCounter = () => {
    const startTiming = 1;
    let time = startTiming * 60;
    let _countIntvl = 0;
    const timerInterval = setInterval(function () {
      _countIntvl += 1;
      setInval(timerInterval);
      const minute_2 = Math.floor(time / 60);
      setMinutes(minute_2);
      let second_2 = time % 60;
      second_2 = second_2 < 10 ? "0" + second_2 : second_2;
      setSeconds(second_2);
      time--;
      if (second_2 === "00" && minute_2 === 0) {
        clearInterval(timerInterval);
        setIsCounterDone(false);
        setIsResendDone(true);
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  };

  useEffect(() => {
    const handleSubAccountSuccess = () => {
      setShowModal(false);
      const role = JSON.parse(storage.fetch("role")).roleId;
      if (role === "1"){
        history.push("/dashboard/merchant/sub-accounts");
      }else if(role === "2"){
        history.push("/dashboard/manager/sub-accounts");
      }

    };
    if (data?.code === "000000") {
      storage.add(
        "userSubAccountPayload",
        JSON.stringify({
          phoneNo,
          firstName,
          surname,
          parentPhoneNo,
          roleId: roleIds.get(roleId),
          smsAlert,
          password,
          terminalName,
          terminalCode,
        })
      );
      setShowModal(false);
      clearInterval(inval)
      Modal.success({
        title: "Congratulations!",
        content: "Sub-account created successfully.",
        onOk: () => handleSubAccountSuccess(),
      });
      reset();
    } else if (data?.code === "100002") {
      setApiResponseError(
        <p style={{ color: "red" }}>Sorry! The verification code is invalid</p>
      );
      reset();
    }
  }, [data]);

  const onSubmitHandler = (data) => {
    storage.add("validCode", data.validCode);
    setValidCode(data.validCode);
    const subAccountPayload = JSON.parse(
      storage.fetch("userSubAccountPayload")
    );

    if (subAccountPayload) {
      setPhoneNo(subAccountPayload.phoneNo);
      setFirstName(subAccountPayload.firstName);
      setParentPhoneNo(subAccountPayload.parentPhoneNo);
      setSurname(subAccountPayload.surname);
      setTerminalName(subAccountPayload.terminalName);
      setPassword(subAccountPayload.password);
      setTerminalCode(subAccountPayload.terminalName);
      setSmsAlert(subAccountPayload.smsAlert);
      setRoleId(subAccountPayload.roleId);
    }
    fetchingSubAccount();
  };

  const resendValidCode = () => {
    const subAccountPayload = JSON.parse(
      storage.fetch("userSubAccountPayload")
    );

    if (subAccountPayload) {
      setPhoneNo(subAccountPayload.phoneNo);
      sendCode();
      setIsCounterDone(true);
      setIsResendDone(false);
      startCounter();
    }
  };

  const closeModal = () => {
    clearInterval(inval)
    setIsCounterDone(true);
    setIsResendDone(false);
    setShowModal(false); 
  };

  return (
    <>
      {showModal ? (
        <div className="verify-backgd">
          <div className="wrap">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="header-part">
                <span className="img">
                  <VerifyPhoneIcon />
                </span>
                <h2>Verify your number</h2>
                <h4>
                {`Please enter the verification code sent to ${formatPhoneNo(phoneNo)} for confirmation`}
                </h4>
              </div>
              <div className="body-part">
                <label>Enter valid code</label>
                <input
                  className={`verifyInput ${errors.validCode && "invalid"}`}
                  type="number"
                  placeholder="Enter code here"
                  onFocus={() => setApiResponseError("")}
                  {...register("validCode", {
                    required: "Verification code is required",
                    minLength: {
                      value: 6,
                      message: "minimum allowed valid code is 6",
                    },
                    maxLength: {
                      value: 6,
                      message: "minimum allowed valid code is 6",
                    },
                  })}
                />
                {errors.validCode && <p style={{ color: "red" }}>{errors.validCode.message}</p>}

                <img
                  width="4%"
                  src={Close}
                  alt=""
                  onClick={ closeModal }
                /> 
                {isCounterDone && (
                  <>
                    <p>
                      Your verification code will arrive within
                      <span className="timer">
                        {minutes}:{seconds} s.
                      </span>
                    </p>
                    <div>{apiResponseError}</div>
                  </>
                )}
                {isResendDone && (
                  <p className="para">
                    Your verification code will arrive within 0:00s
                    <span className="resend" onClick={resendValidCode}>
                      Resend.
                    </span>
                  </p>
                )}
                <button type="submit" disabled={isVerifying || isSendingCode}>
                  {isVerifying || isSendingCode ? (
                    <SpinnerWhite />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default VerifyModal;
