import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import storage from "../../../utils/storage";
import { useRemoveSubAccount } from "../../../hooks/useSubAccount";
import { Spinner } from "../../spinner";
import { Modal } from "antd";

import "./styles.scss";
import { SpinnerWhite } from "../../spinner/Spinner";

function RemoveModal({ clodeModal }) {
  const history = useHistory();
  const cusId = JSON.parse(storage.fetch("onboardingDetails")).cusId;
  const phoneNo = JSON.parse(storage.fetch("modalData")).phoneNo;
  const terminalCode = JSON.parse(storage.fetch("modalData")).terminalCode;

  const {
    isLoading: isFetchingRemove,
    data,
    mutate: fetchRemove,
  } = useRemoveSubAccount({
    cusId,
    phoneNo,
    terminalCode,
    status: 0,
  });

  const removeAccount = () => {
    fetchRemove();
  };

  useEffect(() => {
    const handleRemoveSuccess = () => {
      history.push("/dashboard/merchant/sub-accounts");
    };

    if (data?.code === "000000") {
      Modal.success({
        title: "Success!",
        content: "Account removed successfully.",
        onOk: () => handleRemoveSuccess(),
      });
    }
  }, []);

  return (
    <div className="remove-modal">
      <div id="myModal" class="modal">
        <div class="modal-content">
          <div className="remove-container">
            <p className="remove">Remove staff</p>
          </div>
          <p className="question">
            Do you want to remove this staff from this sub-account?
          </p>
          <div className="dl__btns">
            <button className="whiteBtn cancel-btn" onClick={clodeModal}>
              <span className="white--txt"> 
              Cancel
              </span>
            </button>
            <button
              className="redBtn"
              onClick={removeAccount}
              type="submit"
              disabled={isFetchingRemove}
            >
              {isFetchingRemove ? (
                <Spinner />
              ) : (
                <span className="red--txt">Remove</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveModal;

//ML Due Collection
